/* @ngInject */
export default ($http, RECORD_COLUMNS) => {
    function Record(data) {
        angular.extend(this, data);
        return this;
    }

    Record.prototype.getMedia = function () {
        return _.filter(this.properties, function (p) {
            return p.type === 'media' && p.value;
        });
    };

    Record.prototype.save = function () {
        var properties = this.properties;
        // var update = {data: this.data};
        var update = _.omit(this, RECORD_COLUMNS.concat(['attributes', 'project', 'survey', 'fields', 'children']));

        // update.lat = this.lat;
        // update.lng = this.lng;

        return $http.put('/records/' + this.id, update).then(function (response) {
            return response.data;
        });
    };

    return Record;
}

