import './project-styling.scss';
import noIcon from '../../assets/icons/no-icon.png';
import { getProjectRole, getProject, getAuthIsAdmin, getProjectColorsInitialised } from '../../store/selectors';
import { initColors } from './color-selector/color';
/* @ngInject */
export default class ProjectStylingController {
  constructor($window, $scope, $state, $ngRedux, project, toastr, $mdDialog, $transitions, ProjectService, ProjectActions, styleView) {

    this.project = project;
    this.toastr = toastr;
    this.ProjectService = ProjectService;
    this.$mdDialog = $mdDialog;
    this.$window = $window;
    this.$scope = $scope;
    this.$state = $state;
    this.noIcon = noIcon;
    this.view = styleView;

    var skipCheck = false;
    $scope.$on('$destroy', $transitions.onStart({}, (trans) => {

      var toState = trans.to();
      var toParams = trans.params();
      var $state = trans.router.stateService;

      if (skipCheck) {
        skipCheck = false;
        return true;
      }

      if (this.isDirty()) {
        return this.checkUnsaved().then((result) => {
          if (!result) {
            return false;
          }
          if (result === 'save') {
            this.saveStyling();
          }
          skipCheck = true;
          $state.go(toState.name, toParams);
        });
      }
    }));

    $scope.$on('$destroy', $ngRedux.connect((state) => {
      const cleanProject = angular.copy(getProject(state));
      const colorsInitialised = getProjectColorsInitialised(state);

      if (!colorsInitialised) {
        cleanProject.css = initColors(project.css);
        $ngRedux.dispatch({ type: 'PROJECT_COLORS_INITIALISED' });
      }

      return {
        role: getProjectRole(state),
        cleanProject,
        project: angular.copy(getProject(state)),
        isAdmin: getAuthIsAdmin(state)
      }
    }, ProjectActions)(this));


    this.readonly = this.role !== 'admin';

    this.pageListConfig = {
      axis: 'y',
      containment: 'parent'
    };

    this.fonts = [
      'Raleway',
      'Crimson Text',
      'Inconsolata',
      'PT Serif',
      'Roboto',
      'Nunito'
    ];

    if (this.project) {
      if (!this.project.colors.background) {
        this.project.colors.background = this.project.colors.primary;
      }
      if (!this.project.colors.theme) {
        this.project.colors.theme = this.project.colors.secondary;
      }
      if (!this.project.colors.appleStatusBar) {
        this.project.colors.appleStatusBar = 'black';
      }

      this.originalColors = angular.copy(this.project.colors);
    }

    this.sampleLongName = project.name;
    this.sampleShortName = _.startCase(project.slug);

    this.colorPickerOptions = {
      inputClass: 'form-control',
      format: 'hexString',
      allowEmpty: false,
      alpha: false,
      dynamicHue: false,
      required: true
    };

    this.projectIcon = null;
    this.imagePreview = null;
    this.colorGroups = ['default', 'primary', 'secondary'];
    this.projectIconChange = this.projectIconChange.bind(this);
  }

  $onInit() {
    this.updateFont();
  }

  checkUnsaved(actionName, saveText, hideDontSave, ev) {
    if (typeof actionName === 'undefined') {
      actionName = 'leaving';
    }
    if (typeof saveText === 'undefined') {
      saveText = 'Save & Exit';
    }

    if (!this.isDirty()) {
      return Promise.resolve();
    }

    return this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      template: require('./unsaved-styling.modal.html'),
      /* @ngInject */
      controller: function ($scope, $mdDialog) {
        $scope.actionName = actionName;
        $scope.hideDontSave = hideDontSave;
        $scope.saveText = saveText;
        $scope.ok = function () {
          $mdDialog.hide('save');
        };
        $scope.dontSave = function () {
          $mdDialog.hide('dont');
        };

        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      }
    }).catch(angular.noop);
  }

  changeView(view) {
    this.view = view;
  }

  isDirty() {
    return !angular.equals(this.project, this.cleanProject) || this.projectIcon;
  }

  saveStyling() {
    var colors = ['default', 'defaultText', 'primary', 'primaryText', 'secondary', 'secondaryText', 'tertiary', 'success', 'warning', 'danger', 'dark', 'medium', 'light'];
    this.originalColors = angular.extend(Object.assign({}, this.originalColors), _.pick(this.project.colors, colors));
    var project = {
      id: this.project.id,
      colors: this.originalColors,
      css: this.project.css,
      font: this.project.font,
      image: this.projectIcon,
      shortName: this.project.shortName
    };
    this.updateProject(project).then((_updatedProject) => {
      this.cleanProject = angular.copy(this.project);
      this.projectIcon = null;
    });
  };

  projectIconChange(e) {
    var vm = this;
    var file = (e.srcElement || e.target).files[0];
    this.projectIcon = file;

    var reader = new FileReader();

    reader.onloadend = function (event) {
      vm.imagePreview = event.target.result;
      vm.$scope.$apply();
    };

    reader.readAsDataURL(file);
  };

  updateFont() {
    if (!this.project.font) {
      return;
    }

    const fontId = 'font-' + this.project.font;
    const exists = document.getElementById(fontId);
    if (!exists) {
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');
      link.id = fontId;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = 'https://fonts.googleapis.com/css?family=' + this.project.font;
      link.media = 'all';
      head.appendChild(link);
    }
  }

}
