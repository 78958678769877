import { getFormAttributes, getProjectCollections } from '../../../store/selectors'
import './project-forms-rules-summary.scss';

const UNARY_OPERATORS = ['answered', 'unanswered'];

export const ProjectFormsRulesSummaryComponent = {
    bindings: {
        attribute: '<'
    },
    template: require('./project-forms-rules-summary.component.html'),
    controllerAs: 'ctrl',
    controller: class ProjectFormsRulesSummaryComponent {
        /* @ngInject */
        constructor($scope, $ngRedux, $timeout) {
            $scope.$on('$destroy', $ngRedux.connect((state) => {
                const attributeMap = _.keyBy(getFormAttributes(state), 'path');
                const collectionMap = _.keyBy(getProjectCollections(state), 'id');

                $timeout(() => this.buildSummary(), 0);
                return {
                    attributeMap,
                    collectionMap
                };
            })(this));
            this.rules = [];
        }

        $onChanges() {
            this.buildSummary();
        }

        isUnaryOperator(operand) {
            return UNARY_OPERATORS.includes(operand);
        }

        buildSummary() {
            const rules = (this.attribute && this.attribute.conditions && this.attribute.conditions.rules) || [];
            if (this.attribute.required) {
                this.rules = [];
                return;
            }

            this.rules = rules.reduce((acc, rule) => {
                const attribute = this.attributeMap[rule.path];
                if (!attribute) {
                    // Attribute can't be found; abandon
                    return acc;
                }

                let out = attribute.label;

                // Create a summary for each condition according to field type
                switch (attribute.type) {
                    case 'integer':
                    case 'float':
                        out += this.summariseNumber(rule);
                        break;
                    case 'text':
                        out += this.summariseText(rule);
                        break;
                    case 'date':
                        out += this.summariseDate(rule);
                        break;
                    case 'datetime':
                        out += this.summariseDatetime(rule);
                        break;
                    case 'multiselect':
                    case 'select':
                        out += this.summariseSelect(rule, attribute.type === 'multiselect');
                        break;
                    case 'boolean':
                        out += this.summariseBoolean(rule);
                        break;
                    default:
                        return acc;
                }

                acc.push(out);
                return acc;
            }, []);
        }

        summariseNumber(rule) {
            var conditions = {
                eq: 'is equal to',
                ne: 'is not equal to',
                lt: 'is less than',
                lte: 'is less than or equal to',
                gt: 'is greater than',
                gte: 'is greater than or equal to',
                answered: 'has been answered',
                unanswered: 'has not been answered'
            };
            return ' ' + conditions[rule.numberCondition] + (!this.isUnaryOperator(rule.numberCondition) ? ' ' + rule.numberComparand : '');
        }

        summariseText(rule) {
            var conditions = {
                is: 'is',
                not: 'is not',
                contains: 'contains',
                notcontains: 'does not contain',
                matches: 'matches regex',
                notmatches: 'does not match regex',
                answered: 'has been answered',
                unanswered: 'has not been answered'
            };
            return ' ' + conditions[rule.textCondition] + (!this.isUnaryOperator(rule.textCondition) ? ' "' + rule.textComparand + '"' : '');
        }

        summariseDate(rule) {
            var conditions = {
                is: 'is',
                not: 'is not',
                before: 'before',
                after: 'after',
                answered: 'has been answered',
                unanswered: 'has not been answered'
            };
            var date = new Date(rule.dateComparand);
            return ' ' + conditions[rule.dateCondition] + (!this.isUnaryOperator(rule.dateCondition) ? ' ' + date.toDateString() : '');
        }

        summariseDatetime(rule) {
            var conditions = {
                before: 'is before',
                after: 'is after',
                beforetime: 'is before',
                aftertime: 'is after'
            };
            var date = new Date(rule.datetimeComparand);
            if (rule.datetimeCondition === 'before' || rule.datetimeCondition === 'after') {
                return ' ' + conditions[rule.datetimeCondition] + (!this.isUnaryOperator(rule.datetimeCondition) ? ' ' + date.toDateString() : '');
            } else {
                return ' ' + conditions[rule.datetimeCondition] + (!this.isUnaryOperator(rule.datetimeCondition) ? ' ' + date.toTimeString() : '');
            }
        }

        summariseSelect(rule, isMultiple) {
            var conditions = {};
            if (isMultiple) {
                conditions = {
                    contains: 'contains',
                    notcontains: 'does not contain',
                    answered: 'has been answered',
                    unanswered: 'has not been answered'
                };
            } else {
                conditions = {
                    is: 'is',
                    not: 'is not',
                    answered: 'has been answered',
                    unanswered: 'has not been answered'
                };
            }

            const collectionId = this.attributeMap[rule.path].collectionId;
            const items = this.collectionMap[collectionId].items;
            // var items = this.attributeMap[rule.path].collection.items;
            var item = _.find(items, { key: rule.selectComparand });
            var value = item ? ('"' + item.value + '"') : rule.selectComparand;
            return ' ' + conditions[rule.selectCondition] + (!this.isUnaryOperator(rule.selectCondition) ? ' ' + value : '');
        }

        summariseBoolean(rule) {
            return ' was answered with ' + (rule.isTrue ? '"Yes"' : '"No"');
        }
    }
}
