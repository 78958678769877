import { TabulatorFull as Tabulator } from 'tabulator-tables';
import formatDistance
    from 'date-fns/formatDistance';
/* @ngInject */
export default class AdminUsersController {
    constructor($state, toastr, $uibModal, CoreoQuery, CoreoAPI, FileSaver, $http, apiHostname, Tabulator) {

        this.$state = $state;
        this.$uibModal = $uibModal;
        this.toastr = toastr;
        this.FileSaver = FileSaver;
        this.$http = $http;

        this.query = new CoreoQuery('/users', {
            flatten: true,
        });

        this.query.limit(10);

        this.roles = ['admin', 'user'];
        this.sources = ['local', 'google', 'facebook', 'twitter'];

        this.table = Tabulator.createTable('#admin-users-table', {
            columns: [{
                title: 'ID',
                field: 'id',
                width: '10%'
            }, {
                title: 'Display Name',
                field: 'displayName',
                headerFilter: true
            }, {
                title: 'Username',
                field: 'username',
                headerFilter: true
            }, {
                title: 'Email',
                field: 'email',
                headerFilter: true
            }, {
                title: 'Role',
                field: 'role'
            }, {
                title: 'Signup Date',
                field: 'createdAt',
                formatter: cell => new Date(cell.getValue()).toLocaleString()
            }, {
                title: 'Last Logged In',
                field: 'lastLoggedIn',
                formatter: (cell) => {
                    const date = cell.getValue();
                    if (!date) return 'Not logged in';
                    return `${formatDistance(new Date(), new Date(date))} ago`;
                },
            }, {
                title: 'Last Active',
                field: 'lastActive',
                formatter: (cell) => {
                    const date = cell.getValue();
                    if (!date) return 'No activity';
                    return `${formatDistance(new Date(), new Date(date))} ago`;
                }
            }],
            initialSort: [
                { column: "id", dir: "desc" }
            ],
            layout: "fitColumns",
            pagination: true,
            paginationMode: "remote",
            paginationSize: 10,
            paginationSizeSelector: [10, 20, 50, 100],
            ajaxURL: apiHostname,

            ajaxRequestFunc: (_url, _config, params) => {
                let { filter, sort, page, size } = params;
                page = page ?? 1;
                size = size ?? 10;

                const whereParams = (filter || []).reduce((acc, f) => {
                    acc.push(`${f.field}: { iLike: "%${f.value}%"}`);
                    return acc;
                }, []).join(',');
                const where = whereParams.length ? `where: {${whereParams}},` : '';

                let order = `order:"reverse:id"`;
                if (sort.length) {
                    const { field, dir } = sort[0];

                    order = `order:"${dir === 'desc' ? 'reverse:' : ''}${field}"`;
                }

                const query = `{
                    data: users(${order},${where}offset: ${size * (page - 1)}, limit: ${size}){id,displayName,email,role,createdAt,username,lastActive,lastLoggedIn}
                    count: usersCount${where ? `(${where})` : ''}
                }`;

                return CoreoAPI.query(query).then(({ data, count }) => ({
                    data,
                    last_page: Math.ceil(count / size)
                }));
            },
            filterMode: "remote",
            sortMode: "remote"
        });
        this.table.on('rowClick', (_event, row) => {
            const { id } = row.getData();
            this.$state.go('admin.user.settings', {
                id
            });
        });
    }

    import() {
        var modal = this.$uibModal.open({
            template: require('./import-users-modal.html'),
            /* @ngInject */
            controller: function ($scope, $timeout, $uibModalInstance) {
                $scope.ok = function () {
                    $uibModalInstance.close($scope.file);
                };

                $scope.readFile = function (file) {
                    $timeout(function () {
                        $scope.file = file;
                    });
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
            }
        });

        modal.result.then((file) => {
            this.importFromFile(file).then((users) => {
                this.query.run();
                this.toastr.success('Created ' + users.length + ' users.');
                var blob = new Blob([JSON.stringify(users)], {
                    type: 'application/json;charset=utf-8'
                });
                this.FileSaver.saveAs(blob, 'users.json');
            });
        }, angular.noop);
    };


    importFromFile() {
        var fd = new FormData();
        fd.append('file', file);
        return $http.post('/users/import', fd, {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        }).then(function (response) {
            return response.data;
        });
    }


}