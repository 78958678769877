import './project-records-invalid-shapefile-export.scss';
export default class ProjectRecordsInvalidShapefileExportController {
    /* @ngInject */
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;

    }
    cancel() {
        this.$mdDialog.cancel();
    }
}