import slackIcon from '../../assets/icons/slack.png';
import webhookIcon from '../../assets/icons/webhook.png';
import { getProject, getProjectForms, getProjectHooks } from '../../store/selectors';
import './project-hooks.scss';

/* @ngInject */
export default class ProjectHooksController {

    constructor($scope, $ngRedux, $mdDialog, toastr, ProjectActions) {
        this.$scope = $scope;
        this.$ngRedux = $ngRedux;
        this.$mdDialog = $mdDialog;
        this.hookIconMap = {
            slack: slackIcon,
            webhook: webhookIcon
        };
        this.toastr = toastr;
        this.$scope.$on( "$destroy",
            this.$ngRedux.connect((state) => {
              console.log(getProjectHooks(state))
            return {
              project: getProject(state),
              hooks: getProjectHooks(state),
              surveys: getProjectForms(state),
            };
          }, ProjectActions)(this)
        );
    }

    deleteHook(hook) {
        if (confirm('Are you sure?')) {
            this.deleteProjectHook(hook.id);
        }
    };

    eventsToArray(events) {
        return _.reduce(events, function (acc, value, ev) {
            if (value) {
                acc.push(ev);
            }
            return acc;
        }, []);
    }

    eventsToObject(events) {
        return _.reduce(events, function (acc, ev) {
            acc[ev] = true;
            return acc;
        }, {});
    }

    openModal(ev, hook = {}) {
        var vm = this;

        this.$mdDialog.show({
            parent: angular.element(document.body),
            // targetEvent: ev,
            clickOutsideToClose: true,
            template: require('./hook-form.html'),
            resolve: {
                hook: () => hook,
                eventsToObject: () => this.eventsToObject
            },
            controllerAs: 'ctrl',
            /* @ngInject */
            controller: function ($scope, $mdDialog, hook, eventsToObject) {

                this.slackIcon = slackIcon;
                this.webhookIcon = webhookIcon;
                hook = angular.copy(hook);
                $scope.surveys = angular.copy(vm.surveys);
                if (hook.config && hook.config.surveys) {
                    for (var i = 0; i < vm.surveys.length; i++) {
                        $scope.surveys[i].selected = _.includes(
                          hook.config.surveys,
                          vm.surveys[i].id
                        );
                    }
                } else {
                    for (var i = 0; i < vm.surveys.length; i++) {
                        $scope.surveys[i].selected = true;
                    }
                }


                if (hook && hook.id) {
                    $scope.hook = _.assign({}, hook, { events: eventsToObject(hook.events) });
                } else {
                    $scope.hook = {
                        projectId: vm.project.id,
                        events: {},
                        config: {},
                        active: true
                    };
                }

                $scope.eventLabels = {
                    'records.create': 'Record Created',
                    'records.unsafeImage': 'Record Unsafe Image Detected',
                    'records.update': 'Record Updated',
                    'projectUsers.create': 'User Joined Project'
                };

                $scope.events = ['records.create', 'records.update', 'projectUsers.create', 'records.unsafeImage'];
                $scope.action = $scope.hook.id ? 'Update' : 'Create';

                $scope.select = function (type) {
                    $scope.hook.type = type;
                };

                $scope.ok = function () {
                    $scope.hook.events = vm.eventsToArray($scope.hook.events);
                    $scope.hook.config = $scope.hook.config || {};
                    $scope.hook.config.surveys = _.map(_.filter($scope.surveys, function (survey) {
                        return survey.selected;
                    }), function (selectedSurvey) {
                        return selectedSurvey.id;
                    });
                    $mdDialog.hide($scope.hook);
                };

                $scope.cancel = function () {
                    $mdDialog.cancel();
                };
            }
        }).then((data) => {
            if (data.id) { //editing hook
                this.updateProjectHook(data).then(() => {
                    this.toastr.success('Hook updated')
                });
            } else {
                this.createProjectHook(data);
            }
        }, angular.noop);
    };
}

