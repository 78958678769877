
import AdminUsersController from './users/admin-users.controller';
import AdminRequestsController from './requests/admin-requests.controller';
import AdminRequestController from './requests/admin-request.controller';
import AdminRequestsService from './requests/admin-requests.service';
import AdminMailingController from './mailing/admin-mailing.controller';
import AdminMailingService from './mailing/admin-mailing.service';
import AdminUsersService from './users/admin-users.service';
import AdminUserController from './users/admin-user.controller';
import AdminUserSettingsController from './users/settings/admin-user-settings.controller';
import AdminUserApiKeyController from './users/apikey/admin-user-apikey.controller';
import AdminUserPasswordController from './users/password/admin-user-password.controller';
import AdminUserProjectsController from './users/projects/admin-user-projects.controller';
import AdminUserOrganisationsController from './users/organisations/admin-user-organisations.controller';
import AdminBoundariesController from './boundaries/admin-boundaries.controller';
import AdminOrganisationsController from './organisations/admin-organisations.controller';
import AdminOrganisationController from './organisations/admin-organisation.controller';
import AppLayoutController from '../layouts/app.layout.controller';

export default angular
    .module('app.admin', ['ngSanitize', 'ui.router'])
    .service('AdminRequestsService', AdminRequestsService)
    .service('AdminMailingService', AdminMailingService)
    .service('AdminUsersService', AdminUsersService)
    .controller('AdminUsersController', AdminUsersController)
    .controller('AdminRequestsController', AdminRequestsController)
    .controller('AdminRequestController', AdminRequestController)
    .controller('AdminMailingController', AdminMailingController)
    .controller('AdminUserController', AdminUserController)
    .controller('AdminUserSettingsController', AdminUserSettingsController)
    .controller('AdminUserApiKeyController', AdminUserApiKeyController)
    .controller('AdminUserPasswordController', AdminUserPasswordController)
    .controller('AdminUserProjectsController', AdminUserProjectsController)
    .controller('AdminUserOrganisationsController', AdminUserOrganisationsController)
    .controller('AdminBoundariesController', AdminBoundariesController)
    .controller('AdminOrganisationsController', AdminOrganisationsController)
    .controller('AdminOrganisationController', AdminOrganisationController)
    .controller('AppLayoutController', AppLayoutController)
    .config(function ($stateProvider) {
        $stateProvider
            .state('admin', {
                abstract: true,
                template: require('../layouts/app.layout.html'),
                url: '/admin',
                controller: 'AppLayoutController as ctrl',
            })
            .state('admin.organisations', {
                url: '/organisations',
                template: require('./organisations/admin-organisations.html'),
                controller: 'AdminOrganisationsController as ctrl',
            })
            .state('admin.organisation', {
                url: '/organisations/:id',
                template: require('./organisations/admin-organisation.html'),
                controller: 'AdminOrganisationController as ctrl',
                resolve: {
                    organisation: ($stateParams, CoreoAPI) => {
                        const query = `query getAdminOrganisation($id: Int!){
                            organisationTiers {
                                id
                                name
                            }
                            organisation(id: $id){
                                id,
                                name,
                                slug,
                                seats,
                                contributorSeats,
                                freeTrialEnd,
                                organisationTierId,
                                owner{
                                    id,
                                    displayName
                                },
                                projects{
                                    id
                                },
                                memberships{
                                    id
                                }
                            }
                        }`;
                        return CoreoAPI.query(query, { variables: { id: $stateParams.id } }).then(result => result.organisation);
                    },
                    organisationTiers: (CoreoAPI) => {
                        const query = `query getOrganisationTiers{
                            organisationTiers {
                                id
                                name
                            }
                        }`;
                        return CoreoAPI.query(query).then(result => result.organisationTiers);
                    }
                }
            })
            .state('admin.users', {
                url: '/users',
                template: require('./users/admin-users.html'),
                controller: 'AdminUsersController as ctrl',
                data: {
                    header: 'Users',
                    description: 'Manage users'
                }
            })
            .state('admin.user', {
                url: '/users/:id',
                abstract: true,
                template: require('./users/admin-user.html'),
                controller: 'AdminUserController as ctrl',
                data: {
                    header: 'Users',
                    description: 'Manage users'
                },
                resolve: {
                    user: function ($stateParams, AdminUsersService) {
                        return AdminUsersService.getById($stateParams.id);
                    },
                    permissions: function ($stateParams, AdminUsersService) {
                        return AdminUsersService.getPermissions($stateParams.id);
                    },
                    organisations: function ($stateParams, AdminUsersService) {
                        return AdminUsersService.getUserOrganisations($stateParams.id);
                    }
                }
            })
            .state('admin.user.settings', {
                url: '',
                template: require('./users/settings/admin-user-settings.html'),
                controller: 'AdminUserSettingsController as ctrl',
                data: {
                    header: 'User',
                }
            })
            .state('admin.user.projects', {
                url: '/projects',
                template: require('./users/projects/admin-user-projects.html'),
                controller: 'AdminUserProjectsController as ctrl',
                data: {
                    header: 'User',
                }
            })
            .state('admin.user.organisations', {
                url: '/organisations',
                template: require('./users/organisations/admin-user-organisations.html'),
                controller: 'AdminUserOrganisationsController as ctrl',
                data: {
                    header: 'User',
                }
            })
            .state('admin.user.apikey', {
                url: '/apikey',
                template: require('./users/apikey/admin-user-apikey.html'),
                controller: 'AdminUserApiKeyController as ctrl',
                data: {
                    header: 'User',
                },
                resolve: {
                    apikey: function ($stateParams, AdminUsersService) {
                        return AdminUsersService.getApiKey($stateParams.id).catch(function (err) {
                            return false;
                        });
                    }
                }
            })
            .state('admin.user.password', {
                url: '/password',
                template: require('./users/password/admin-user-password.html'),
                controller: 'AdminUserPasswordController as ctrl',
                data: {
                    header: 'User'
                }
            })
            .state('admin.mailing', {
                url: '/mailing',
                template: require('./mailing/admin-mailing.html'),
                controller: 'AdminMailingController as ctrl',
                data: {
                    header: 'Mailing',
                    description: 'Send mail to users of a project'
                },
                resolve: {
                    projects: function (AdminMailingService) {
                        return AdminMailingService.getProjects();
                    },
                    templates: function (AdminMailingService) {
                        return AdminMailingService.getTemplates();
                    },
                    domains: function (AdminMailingService) {
                        return AdminMailingService.getDomains();
                    }

                }
            })
            .state('admin.requests', {
                url: '/requests',
                template: require('./requests/admin-requests.html'),
                controller: 'AdminRequestsController as ctrl',
                data: {
                    header: 'Requests',
                    description: 'Request Logs'
                },
                resolve: {
                    projects: function (CoreoGraphQLQuery) {
                        return new CoreoGraphQLQuery('{data: projects(order: "name"){id, name, slug}}').run().then(function (response) {
                            return response.data;
                        });
                    }
                }
            })
            .state('admin.request', {
                url: '/requests/:id',
                template: require('./requests/admin-request.html'),
                controller: 'AdminRequestController as ctrl',
                data: {
                    header: 'Request',
                    description: 'View request details'
                },
                resolve: {
                    request: function ($stateParams, AdminRequestsService) {
                        return AdminRequestsService.getById($stateParams.id);
                    }
                }
            })
            .state('admin.boundaries', {
                url: '/boundaries',
                template: require('./boundaries/admin-boundaries.html'),
                controller: 'AdminBoundariesController as ctrl',
                data: {
                    header: 'Boundaries',
                },
                resolve: {
                    boundarySets: (Boundaries) => Boundaries.getBoundarySets()
                }
            });
    });