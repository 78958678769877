import './record-messages.scss';
import { getAuthUser } from '../../store/selectors';

export const RecordMessagesComponent = {
  template: require('./record-messages.component.html'),
  bindings: {
    record: '<'
  },
  controllerAs: 'ctrl',
  controller: class RecordMessagesComponent {
    /* @ngInject */
    constructor($scope, $http, $ngRedux, CoreoQuery, AuthActions) {
      this.$http = $http;
      this.CoreoQuery = CoreoQuery;
      // this.Auth = Auth;

      this.query = new CoreoQuery('/messages', {
        flatten: true,
        include: ['user']
      });
      this.query.sort('-createdAt');

      this.newMessage = {
        content: ''
      };

      $scope.$on('$destroy', $ngRedux.connect(state => ({
        user: getAuthUser(state)
      }), AuthActions)(this));
    }

    $onChanges(changes) {
      if (changes.record) {
        this.query.filter('recordId', changes.record.currentValue.id);
        this.query.run();
      }
    }

    post() {
      this.$http.post('/messages', {
        recordId: this.record.id,
        projectId: this.record.projectId,
        subject: 'Query Regarding your Recording',
        content: this.newMessage.content
      }).then((response) => {
        this.newMessage.content = '';
        response.data.user = angular.copy(this.user);
        this.query.data.push(response.data);
        this.query.count++;
      });
    }
  }
}