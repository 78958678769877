import './project-record-state.component.scss';
import { getProjectStates } from '../../../store/selectors';

export const ProjectRecordStateComponent = {
    template: require('./project-record-state.component.html'),
    bindings: {
        state: '<',
        onUpdate: '&'
    },
    controllerAs: 'ctrl',
    controller: class ProjectRecordStateComponent {
        /* @ngInject */
        constructor($element, $ngRedux, RecordsService) {
            this.RecordsService = RecordsService;
            this.$element = $element;
            this.states = getProjectStates($ngRedux.getState());
        }

        $onChanges() {
            const state = this.states.find(state => state.stateId === this.state);
            if (!state) {
                return;
            }

            this.icon = state.default ? 'fa-spinner' : (state.verified ? 'fa-check-double' : 'fa-exclamation')
            this.$element.css('background-color', state.color);
        }

        updateState() {
            this.onUpdate({
                state: this.state
            });
        }
    }
};

export default ProjectRecordStateComponent;
