
import OrganisationController from './organisation.controller.js';
import OrganisationDashboardController from './dashboard/organisation-dashboard.controller';
import OrganisationSettingsController from './settings/organisation-settings.controller';
import OrganisationMembersController from './members/organisation-members.controller';
import OrganisationInvitationController from './invitation/organisation-invitation.controller';
import OrganisationFreeTrialExpiredController from './free-trial-expired/free-trial-expired.controller.js';
import { OrganisationFreeTrialBannerComponent } from './free-trial-banner/free-trial-banner.component';
import { getAuthIsAdmin } from '../store/selectors';

import OrganisationsService from './organisations.service';
import { ORGANISATION_ADMIN, ORGANISATION_MEMBER, ORGANISATION_OWNER } from '../permissions/permissions.constants';

export default angular
    .module('app.organisations', ['ngSanitize', 'ui.router'])
    .service('OrganisationsService', OrganisationsService)
    .controller('OrganisationController', OrganisationController)
    .controller('OrganisationFreeTrialExpiredController', OrganisationFreeTrialExpiredController)
    .controller('OrganisationDashboardController', OrganisationDashboardController)
    .controller('OrganisationSettingsController', OrganisationSettingsController)
    .controller('OrganisationMembersController', OrganisationMembersController)
    .controller('OrganisationInvitationController', OrganisationInvitationController)
    .component('organisationFreeTrialBanner', OrganisationFreeTrialBannerComponent)
    .config(function ($stateProvider) {
        $stateProvider
            .state('org', {
                abstract: true,
                url: '/{orgSlug:[^\/]+}',
                resolvePolicy: {
                    when: 'EAGER',
                    async: 'WAIT'
                },
                data: {
                    breadcrumb: {
                        label: '',
                        state: 'organisation.dashboard',
                        icon: 'fas fa-building'
                    }
                },
                resolve: {
                    organisation: function ($stateParams, $ngRedux, OrganisationsActions) {
                        return $ngRedux.dispatch(OrganisationsActions.loadOrganisation($stateParams.orgSlug));
                    }
                },
                onEnter: function ($state, toastr, organisation, $ngRedux) {
                    if (organisation) {
                        const isAdmin = getAuthIsAdmin($ngRedux.getState());
                        const state = $state.get('org');
                        state.data.breadcrumb.label = organisation.name;
                        if (organisation.freeTrialExpired && !isAdmin) {
                            $state.go('free-trial-expired', { orgSlug: organisation.slug });
                        }
                    } else {
                        $state.go('home');
                        toastr.error('Organisation not found');
                    }
                },/* @ngInject */
                onRetain: function ($state, organisation, $ngRedux) {
                    const isAdmin = getAuthIsAdmin($ngRedux.getState());
                    if (organisation && organisation.freeTrialExpired && !isAdmin) {
                        $state.go('free-trial-expired', { orgSlug: organisation.slug });
                    }
                }
            })
            .state('organisation', {
                abstract: true,
                parent: 'org',
                template: require('../layouts/app.layout.html'),
                controller: 'AppLayoutController as ctrl'
            })
            .state('organisation.dashboard', {
                url: '',
                template: require('./dashboard/organisation-dashboard.html'),
                controller: 'OrganisationDashboardController as ctrl',
                resolve: {
                    users: function ($ngRedux, OrganisationsActions) {
                        return $ngRedux.dispatch(OrganisationsActions.loadOrganisationMemberships());
                    }
                },
                data: {
                    breadcrumb: {
                        label: null,
                        skip: true,
                        parent: 'org',
                        icon: 'fas fa-building'
                    }
                }
            })
            .state('free-trial-expired', {
                url: '/free-trial-expired',
                parent: 'organisation',
                template: require('./free-trial-expired/free-trial-expired.html'),
                controller: 'OrganisationFreeTrialExpiredController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Free Trial Expired',
                        parent: 'org'
                    }
                },
            })
            .state('org-admin', {
                abstract: true,
                url: '/orgs/{orgSlug:[^\/]+}',
                resolvePolicy: {
                    when: 'EAGER',
                    async: 'WAIT'
                },
                resolve: {
                    organisation: function ($stateParams, $ngRedux, OrganisationsActions) {
                        return $ngRedux.dispatch(OrganisationsActions.loadOrganisation($stateParams.orgSlug));
                    }
                },
                data: {
                    breadcrumb: {
                        label: '',
                        state: 'organisation.dashboard',
                        icon: 'fas fa-building'
                    }
                },
                onEnter: function ($state, organisation) {
                    const bc = $state.get('org-admin').data.breadcrumb;
                    bc.label = organisation.name;
                    bc.stateParams = { orgSlug: organisation.slug };
                }
            })
            .state('organisation-admin', {
                abstract: true,
                parent: 'org-admin',
                template: require('../layouts/app.layout.html'),
                controller: 'AppLayoutController as ctrl',
                resolve: {
                    users: function ($ngRedux, OrganisationsActions) {
                        return $ngRedux.dispatch(OrganisationsActions.loadOrganisationMemberships());
                    }
                },
                data: {
                    breadcrumb: {
                        label: 'Settings',
                        state: null,
                        parent: 'org-admin'
                    }
                },
                onEnter: function ($state, organisation) {
                    $state.get('org-admin').data.breadcrumb.stateParams = { orgSlug: organisation.slug };
                }
            })
            .state('organisation-admin.members', {
                url: '/members?name&username&role&email&state',
                template: require('./members/organisation-members.html'),
                controller: 'OrganisationMembersController as ctrl',
                params: {
                    email: {
                        value: null,
                        dynamic: true
                    },
                    name: {
                        value: null,
                        dynamic: true
                    },
                    username: {
                        value: null,
                        dynamic: true
                    },
                    role: {
                        value: null,
                        dynamic: true
                    },
                    state: {
                        value: null,
                        dynamic: true
                    }
                },  
                data: {
                    breadcrumb: {
                        label: 'Members',
                        parent: 'organisation-admin'
                    },
                    permissions: [
                        ORGANISATION_ADMIN
                    ]
                }
            })
            .state('organisation-admin.settings', {
                url: '/profile',
                template: require('./settings/organisation-settings.html'),
                controller: 'OrganisationSettingsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Profile',
                        parent: 'organisation-admin'
                    },
                    permissions: [
                        ORGANISATION_OWNER
                    ]
                }
            })
            .state('organisation-invitation', {
                url: '/invitations/org/:token',
                parent: 'unauthenticated',
                template: require('./invitation/organisation-invitation.html'),
                controller: 'OrganisationInvitationController as ctrl',
            });
    });