import * as Sentry from '@sentry/browser';
import './project-records.scss';
import { getProjectCollections, getRecordsFilterCount, getProjectForms, getProjectStates, getProjectAttributes, getRecordsView, getRecordsFilterOpen, getRecordsTotal, getRecordsSelected, getRecordsRecordOpen, getProjectId, getRecordsFilterQueryString, getRecordsFilterQuery, getRecordsShowDeleted, getProjectVerification, getRecordsColumns, getRecordsFilter, getRecordsFilterDirty, getRecordsFormId, getRecordsFilterAttributes, getRecordsFormHasGeometry, getRecordsSelectedIds, getRecordsSelectedCount, getRecordsSelectionSource, getRecordsShouldFilterOnSelf, getRecordsFilterCustomBoundary, getRecordsFormAttributes, getProjectHasSurveysUnavailableForShapefileExport, getRecordsForm } from '../../store/selectors';
const UNSAFE_OPERATION_CAP = 25;
const MAX_AUTOCOMPLETE_USERS = 300;
/* @ngInject */
export default class ProjectRecordsController {


    constructor($ngRedux, $q, $rootScope, $timeout, $scope, $state, $uibModal, CoreoAPI, RecordsActions, Boundaries, ProjectsService, ProjectService, ProjectMapsService, $mdDialog, toastr, localStorageService) {
        // Save dependencies
        this.$q = $q;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.CoreoAPI = CoreoAPI;
        this.ProjectsService = ProjectsService;
        this.ProjectService = ProjectService;
        this.ProjectMapsService = ProjectMapsService;
        this.$mdDialog = $mdDialog;
        this.$ngRedux = $ngRedux;
        this.toastr = toastr;
        this.localStorageService = localStorageService;
        this.Boundaries = Boundaries;

        const state = $ngRedux.getState();

        this.projectAttributes = getProjectAttributes(state);
        this.collections = getProjectCollections(state);
        this.verification = getProjectVerification(state);

        this.forms = getProjectForms(state);
        this.states = getProjectStates(state);
        this.projectId = getProjectId(state);

        this.today = new Date();

        $scope.$on('$destroy', $ngRedux.connect((state) => {
            const selectedCount = getRecordsSelectedCount(state);
            const mapSelecting = getRecordsSelectionSource(state) === 'map' && (selectedCount > 0);
            const mapDrawing = getRecordsFilterCustomBoundary(state);
            const mapActive = mapSelecting || mapDrawing;

            return {
                totalRecords: getRecordsTotal(state),
                view: getRecordsView(state),
                filterOpen: getRecordsFilterOpen(state),
                filter: getRecordsFilterAttributes(state),
                recordOpen: getRecordsRecordOpen(state),
                selected: getRecordsSelected(state),
                showDeleted: getRecordsShowDeleted(state),
                columns: getRecordsColumns(state),
                filterDirty: getRecordsFilterDirty(state) || (selectedCount > 0),
                filterCount: getRecordsFilterCount(state),
                formId: getRecordsFormId(state),
                hasGeometry: getRecordsFormHasGeometry(state),
                mapSelecting,
                mapActive,
                filterOnSelf: getRecordsShouldFilterOnSelf(state),
                formAttributes: getRecordsFormAttributes(state),
                selectedCount
            };
        }, RecordsActions)(this));

        this.sidebar = null;
        this.map = null;

        if (!this.hasGeometry) {
            this.mapReady = true;
        }

        this.states = _.map(this.states, function (state) {
            return _.assign({}, state, { active: false });
        });


        this.loading = true;

        const filter = getRecordsFilter(state);
        this.userSearchText = filter.user && filter.user.displayName || '';
        this.selectedUser = filter.user || null;
        this.selectedState = null;
        this.boundarySearchText = '';
        this.selectedBoundary = filter.boundary || null;
        this.recordId = filter.recordId || null;

        this.selectedStates = filter.states || [];
        this.selectedForms = filter.forms || [];
        this.fromDate = filter.from ? new Date(filter.from) : null;
        this.toDate = filter.to ? new Date(filter.to) : null;

        this.tooltips = {
            mapFiltering: 'Shift-click records on the map to filter',
            dateCreated: 'Filter by the date records were created',
            boundary: 'Filter records within a particular boundary',
            recorder: 'Filter records created by a particular user',
            status: 'Filter records by a particular status',
            id: 'Filter records by id',
        };
    }


    refreshMap() {
        if (this.map) {
            this.$timeout(() => this.map.resize(), 350);
        }
    };

    resetFilter() {
        this.recordsResetFilter();
        this.fromDate = null;
        this.toDate = null;
        this.selectedBoundary = this.boundarySearchText = null;
        this.selectedUser = this.userSearchText = null;
        this.selectedState = null;
        this.selectedStates = [];
        this.selectedForms = [];
        this.showDeleted = false;
        this.recordId = null;
    }

    update(filter) {
        this.updateFilter(filter);
    }

    updateUser() {
        this.update({
            userId: this.selectedUser ? this.selectedUser.id : null,
            user: this.selectedUser
        });
    }

    updateBoundary() {
        if (!this.selectedBoundary) {
            return this.update({
                boundary: null
            });
        }

        this.Boundaries.getBoundary(this.selectedBoundary.boundaryId).then(boundary => {
            this.update({
                boundary
            });
        });
    }

    updateFilterStates() {
        this.update({
            states: this.selectedStates
        });
    }

    updateDate(date, direction) {
        if (direction === 'to' && date) {
            date.setHours(23, 59, 59, 999);
        }
        this.update({
            [direction]: date ? date.valueOf() : null
        });
    }

    updateRecordId() {
        this.update({
            recordId: this.recordId,
        })
    }

    clearRecordId() {
        this.recordId = null;
        this.update({ recordId: null });
    }

    clearDate(direction) {
        this[direction === 'from' ? 'fromDate' : 'toDate'] = null;
        this.update({
            [direction]: null
        });
    }

    setShowDeleted(showDeleted) {
        this.updateShowDeleted(showDeleted);
    }

    getUsers(search) {
        var query = `query CoreoAASearchRecorders{
            project(id:${this.projectId}){
                projectUsers(
                    where:{ user: { 
                            or: [
                                {displayName: { iLike: "%${search}%"}},
                                {username: { iLike: "%${search}%"}}
                            ]
                        } 
                    }, 
                    limit: ${MAX_AUTOCOMPLETE_USERS}){
                    result{
                        user{
                            displayName,
                            username,
                            id
                        }
                    }
                }
            }
        }`;
        return this.CoreoAPI.query(query).then(result => {
            return result.project.projectUsers.result.map(pu => pu.user);
        });
    }

    getBoundaries(search) {
        return this.Boundaries.searchBoundaries(search);
    }

    handleSurveylessMap() {
        var query = `{recordsGeoJSON(where:{ projectId: ${this.projectId} }){ type, features{ type, properties{id},geometry{ type, coordinates } }}}`;
        this.CoreoAPI.query(query).then(result => {
            const surveylessMapLayerId = "surveyless-map-layer";
            this.map.addLayer({
                id: surveylessMapLayerId,
                type: "circle",
                source: {
                    type: "geojson",
                    data: result.recordsGeoJSON
                },
                paint: {
                    'circle-radius': 6,
                    'circle-color': '#0051ac'
                }
            });
            this.map.on('mousemove', (e) => {
                const features = this.map.queryRenderedFeatures(e.point, { layers: [surveylessMapLayerId] });
                this.map.getCanvas().style.cursor = features.length ? 'pointer' : '';
            });
            this.map.on('click', (e) => {
                var features = this.map.queryRenderedFeatures(e.point, {
                    layers: [surveylessMapLayerId]
                });
                if (features && features.length > 0) {
                    this.$timeout(() => {
                        this.handleRecordClick(features[0]);
                    }, 0);
                }
            });
        });
    }

    handleMap(map) {
        this.map = map;
        map.on('moveend', _.debounce(this.updateUrl.bind(this), 200));

        if (this.initialSearch && this.initialSearch.z && this.initialSearch.c) {
            var center = this.initialSearch.c.split(',').map(Number.parseFloat);
            var zoom = this.initialSearch.z;

            map.jumpTo({
                zoom: zoom,
                center: center
            });
        }

        if (this.ProjectService.getProjectId() === 1) {
            this.handleSurveylessMap();
        }
    }

    viewRecord() {
        var url = this.$state.href('project.record', { record_id: this.selected.id, project_id: this.selected.projectId });
        window.open(url, '_blank');
    };

    updateState(state) {
        const stateToUpdate = typeof state === 'undefined' ? this.selectedState : state;
        this.recordUpdateState(stateToUpdate);
    };

    updateFilterAttribute(attribute, value, operator) {
        this.updateAttributeFilter(attribute, value, operator);
    }

    openMenu($mdMenu, ev) {
        // originatorEv = ev;
        $mdMenu.open(ev);
    };

    exportRecords(format, selected, all) {
        const storeState = this.$ngRedux.getState();
        const selectedRecordIds = getRecordsSelectedIds(storeState);
        if (selected && (!selectedRecordIds || selectedRecordIds.length === 0)) {
            throw new Error("Selection export called with 0 selected records")
        }
        // If an export is being attempted on a form that cannot support shapefiles, throw an error
        const form = getRecordsForm(storeState);
        if (format.key === 'shapefile' &&
            (all ? getProjectHasSurveysUnavailableForShapefileExport(storeState) : !form.shapefileExportAvailable)
        ) {
            return this.unavailableShapefileExport();
        }
        let query;
        if (all) {
            query = {};
        } else if (selected) {
            query = { id: { in: selectedRecordIds } };
        } else {
            query = getRecordsFilterQuery(storeState);
        }

        console.log('HERE?!', query);
        var projectId = this.projectId;
        let promise;
        switch (format.key) {
            case 'csv': {
                promise = this.ProjectsService.csvExport(projectId, query, this.showDeleted);
                break;
            }
            case 'geojson': {
                promise = this.ProjectsService.geoJSONExport(projectId, query, this.showDeleted);
                break;
            }
            case 'shapefile': {

                promise = this.ProjectsService.shapefileExport(projectId, query, this.showDeleted);
                break;
            }
            case 'nbn': {
                break;
            }
            default: {
                console.warn('Do not know how to export', format);
            }
        }
        promise.then(success => {
            if (success) {
                this.$rootScope.$broadcast('jobs:refresh');
            } else {
                this.toastr.error('Job could not be created');
            }
        });
    }

    importRecords($event) {
        const dialog = this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: $event,
            clickOutsideToClose: false,
            template: require('./project-records-import/project-records-import.html'),
            controller: 'ProjectRecordsImportController as ctrl'
        });

        dialog.then((formId) => {
            if (this.formId !== formId) {
                this.updateForm(formId);
            }
            this.$scope.$broadcast('$records:refresh');
        }, angular.noop);
    }

    unavailableShapefileExport($event) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            template: require('./project-records-invalid-shapefile-export/project-records-invalid-shapefile-export.html'),
            controller: 'ProjectRecordsInvalidShapefileExportController as ctrl'
        });
    }

    updateRecords(state, selected) {
        console.log('HERE', state, selected);

        let where;
        const storeState = this.$ngRedux.getState();
        const selectedIds = getRecordsSelectedIds(storeState);
        const total = getRecordsTotal(storeState);
        const recordsCount = selected && selectedIds.length > 0 ? selectedIds.length : total;

        const confirmation = recordsCount > UNSAFE_OPERATION_CAP ? this.verifyBulkOperation('update', recordsCount) : Promise.resolve(true);

        confirmation.then(userCount => {
            if (!userCount) {
                return;
            }
            if (selected && selectedIds.length > 0) {
                where = this.CoreoAPI.gqlStringify({
                    id: {
                        in: selectedIds
                    },
                    projectId: this.projectId
                });
            } else {
                where = getRecordsFilterQueryString(storeState);
            }
            let countConfirmation = userCount === true ? '' : `, count: ${userCount}`;
            var mutation = `mutation{result: verifyRecords(input: {where: ${where}, state: ${state.stateId} ${countConfirmation}})}`;
            this.CoreoAPI.mutation(mutation).then(() => this.$scope.$broadcast('$records:refresh'));
        });
    }

    onMapLoad() {
        this.mapReady = true;
    }

    deleteRecords(selected) {

        let where;
        const storeState = this.$ngRedux.getState();
        const selectedIds = getRecordsSelectedIds(storeState);
        const total = getRecordsTotal(storeState);
        const recordsCount = selected && selectedIds.length > 0 ? selectedIds.length : total;

        const confirmation = recordsCount > UNSAFE_OPERATION_CAP ? this.verifyBulkOperation('delete', recordsCount) : Promise.resolve(true);

        confirmation.then(userCount => {
            if (!userCount) {
                return;
            }
            if (selected && selectedIds.length > 0) {
                where = this.CoreoAPI.gqlStringify({
                    id: {
                        in: selectedIds
                    },
                    projectId: this.projectId
                });
            } else {
                where = getRecordsFilterQueryString(storeState);
            }
            let countConfirmation = userCount === true ? '' : `, count: ${userCount}`;
            var mutation = `mutation{result: deleteRecords(input: {where: ${where} ${countConfirmation}})}`;
            this.CoreoAPI.mutation(mutation).then(() => {
                if (selected) {
                    this.$scope.$broadcast('$records:deleteSelection');
                }
                this.$scope.$broadcast('$records:refresh');
            }, err => {
                console.warn(err);
                this.toastr.error(err.message);
                Sentry.captureException(err);
            });
        });

    }

    verifyBulkOperation(operationName, count) {
        return this.$mdDialog.show({
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            template: require('./project-records-toolbar/verify-bulk-operation.tmpl.html'),
            /* @ngInject */
            controller: function ($scope, $mdDialog) {
                $scope.operationName = operationName;
                $scope.titleOperationName = _.upperFirst(operationName);
                $scope.count = count;
                $scope.userCount = null;

                $scope.ok = function () {
                    $mdDialog.hide($scope.userCount);
                }
                $scope.cancel = function () {
                    $mdDialog.hide(0); //instead of cancel, user had decided not to use the operation
                }
            }
        })

    }

    exports() {
        var modal = $uibModal.open({
            templateUrl: 'project-records-export-modal.html',
            controllerAs: 'exportCtrl',
            /* @ngInject */
            controller: function ($uibModalInstance, surveys) {
                var exportVm = this;
                this.advanced = false;
                this.type = '';
                this.surveys = angular.copy(surveys);
                this.surveyCount = 0;
                this.exportUsers = false;
                this.from = null;
                this.to = null;

                this.sources = [
                    {
                        source: 'RECORD',
                        label: 'Collection attribute',
                        collection: true
                    },
                    {
                        source: 'RECORD',
                        label: 'Attribute',
                        collection: false
                    },
                    {
                        source: 'CONSTANT',
                        label: 'Constant',
                        collection: false
                    },
                ];


                this.dateOptions = {
                    maxDate: new Date(),
                    format: 'dd/MM/yyyy'
                };

                this.popupStart = {
                    open: false
                };
                this.popupEnd = {
                    open: false
                };

                this.openStartPopup = function () {
                    exportVm.popupStart.open = true;
                };
                this.openEndPopup = function () {
                    exportVm.popupEnd.open = true;
                };

                this.licenses = ['CC0', 'CC-BY', 'CC-BY-NC', 'OGL'];

                this.nbnExport = {
                    survey: null,
                    newFields: [],
                    from: null,
                    to: null,
                    defaultFields: [
                        {
                            term: 'taxonID',
                        },
                        {
                            term: 'scientificName',
                        },
                        {
                            term: 'institutionCode',
                        }
                    ]
                };

                this.addNewField = function () {
                    this.nbnExport.newFields.push({});
                };

                this.toggleAdvanced = function () {
                    this.advanced = !this.advanced;
                };

                this.validateField = function (field) {
                    if (!field.source || !field.term) {
                        return false;
                    }
                    switch (field.source.source) {
                        case 'CONSTANT': {
                            if (field.value) {
                                return true;
                            } else {
                                return false;
                            }
                            break;
                        }
                        case 'RECORD': {
                            if (field.attribute && field.source.collection && field.collectionAttribute) {
                                return true;
                            } else if (field.attribute && !field.source.collection) {
                                return true;
                            } else {
                                return false;
                            }
                            break;
                        }
                        default:
                            return false;
                    }

                };

                this.isExportValid = function () {
                    if (!this.nbnExport.survey || !this.nbnExport.metadata || !this.nbnExport.metadata.title || !this.nbnExport.metadata.emailAddress || !this.nbnExport.metadata.abstract || !this.nbnExport.metadata.license || !this.nbnExport.metadata.organizationName) {
                        return false;
                    }

                    var fields = this.nbnExport.defaultFields.concat(this.nbnExport.newFields);
                    for (var i = 0; i < fields.length; i++) {
                        if (!this.validateField(fields[i])) {
                            return false;
                        }
                    }
                    return true;
                };

                this.ok = function () {
                    $uibModalInstance.close({
                        type: this.type,
                        from: this.from,
                        to: this.to,
                        surveyIds: _.map(_.filter(this.surveys, { active: true }), 'id'),
                        exportUsers: this.exportUsers,
                        nbnExport: this.nbnExport
                    });
                };

                this.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
                this.selectType = function (type) {
                    this.type = type;
                };
                this.updateSurveyCount = function () {
                    this.surveyCount = _.filter(this.surveys, { selected: true }).length;
                };
            }
        });

        modal.result.then((result) => {

            //'occurrenceID','datasetName','license','rightsHolder','identificationVerificationStatus','eventDate','recordedBy','decimalLatitude','decimalLongitude','basisOfRecord','occurrenceStatus'
            switch (result.type) {
                case 'csv': {
                    var options = {
                        from: result.from,
                        to: result.to,
                        users: result.exportUsers,
                        surveys: result.surveyIds
                    };

                    this.ProjectsService.exportRecords(this.projectId, options).then((exp) => {
                        this.$rootScope.$broadcast('jobs:refresh');
                    });
                    break;
                }
                case 'darwin': {
                    var rawExport = result.nbnExport;
                    //export object contains objects rather than IDs
                    var flattenedExport = {
                        surveyId: rawExport.survey.id,
                        metadata: rawExport.metadata,
                        fields: [],
                        from: rawExport.from,
                        to: rawExport.to
                    };


                    var rawFields = rawExport.defaultFields.concat(rawExport.newFields);

                    for (var i = 0; i < rawFields.length; i++) {
                        var isCollectionAttributeField = rawFields[i].source.collection;
                        if (rawFields[i].attribute) {
                            rawFields[i].attribute = rawFields[i].attribute.path;
                        }

                        //clean fields of potential unnecessary properties
                        switch (rawFields[i].source.source) {
                            case 'CONSTANT': {
                                rawFields[i] = {
                                    value: rawFields[i].value,
                                    term: rawFields[i].term,
                                    source: rawFields[i].source.source
                                };
                                break;
                            }
                            case 'RECORD': {
                                var collectionAttribute = null;
                                if (isCollectionAttributeField) {
                                    collectionAttribute = rawFields[i].collectionAttribute;
                                }
                                rawFields[i] = {
                                    term: rawFields[i].term,
                                    source: rawFields[i].source.source,
                                    attribute: rawFields[i].attribute
                                };
                                if (collectionAttribute) {
                                    rawFields[i].collectionAttribute = collectionAttribute;
                                }
                                break;
                            }
                        }

                        flattenedExport.fields.push(rawFields[i]);
                    }

                    ProjectsService.nbnExport(this.projectId, flattenedExport).then(function (success) {
                        if (success) {
                            $rootScope.$broadcast('jobs:refresh');
                        } else {
                            toastr.error('Job could not be created');
                        }
                    });

                    break;
                }
            }
        }, angular.noop);
    }
}
