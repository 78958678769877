import angular, { IComponentController, IComponentOptions } from "angular";
import ngRedux from 'ng-redux';
import { MapBaseStyle } from "../../../main/mapbox-base-styles.constants";
import { MapLayerState } from "../../../store/records/records.reducer";
import { getProjectCollections, getProjectMapLayers } from '../../../store/selectors';

import "./map-menu.component.scss";


export const MapMenuComponent: IComponentOptions = {
    bindings: {
        'onStyleChange': '&',
        'onBoundsChange': '&',
        'onLayersChange': '&',
        'baseStyleId': '<',
        'showBounds': '<',
        'layers': '<'

    },
    template: require('./map-menu.component.html'),
    controllerAs: 'ctrl',
    controller: class MapMenuController implements IComponentController {

        /* @ngInject */
        constructor(
            private $element,
            private MAP_BASE_STYLES) { }

        baseStyles: MapBaseStyle[];
        open: boolean = false;

        // Input Bindings
        baseStyleId: MapBaseStyle['id'];
        showBounds: boolean;
        layers: MapLayerState[] = [];

        // Output Bindings
        onStyleChange: (event: { style: MapBaseStyle }) => void;
        onBoundsChange: (event: { bounds: boolean }) => void;
        onLayersChange: (event: { layers: MapLayerState[] }) => void;

        sortConfig = {
            axis: 'y',
            containment: 'parent',
            tolerance: 'pointer'
        };

        $onInit(): void {
            this.baseStyles = this.MAP_BASE_STYLES;
        }

        toggle() {
            this.open = !this.open;
            if (this.open) {
                this.$element.addClass('open');
            } else {
                this.$element.removeClass('open');
            }
        }

        selectBaseStyle(baseStyle: MapBaseStyle) {
            this.onStyleChange({
                style: baseStyle
            });
        }

        updateLayerOrder() {
            this.onLayersChange({ layers: this.layers });
        }

        toggleLayerVisible(event, layer) {
            layer.visible = !layer.visible;
            this.onLayersChange({ layers: this.layers });
        }
    }
};