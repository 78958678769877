/* @ngInject */
export default class AdminBoundariesService {
    constructor(CoreoAPI) {
        this.CoreoAPI = CoreoAPI;
    }

    getBoundarySets() {
        const query = `{data: boundarySets{id,setId,name,description,boundingBox}}`;
        return this.CoreoAPI.query(query).then(result => result.data || []);
    }

    searchBoundaries(search) {
        const query = `{data: boundarySearch(search: "${search}"){name,boundaryId,boundarySet{name,description}}}`;
        return this.CoreoAPI.query(query).then(result => result.data || []);
    }

    getBoundary(boundaryId) {
        const query = `{data: boundary(boundaryId: "${boundaryId}"){name,properties,geometry{type,coordinates},boundaryId,boundarySet{name,description}}}`;
        return this.CoreoAPI.query(query).then(result => result.data);
    }

    createBoundarySet(name, description, nameField, idField, boundarySetId, file) {
        var u = this.CoreoAPI.gqlStringify({
            name,
            description,
            nameField,
            boundarySetId,
            idField
        });
        var mutation = `mutation{result: importBoundaries(input: ${u})}`;
        return this.CoreoAPI.mutation(mutation, { file });
    }
}