import './passwordupdate.scss';
/* @ngInject */
export default class PasswordUpdateController {
    constructor($state, project, $location, $scope, $ngRedux, AuthActions, toastr) {
        this.newp = '';
        this.$location = $location;
        this.$state = $state;
        this.toastr = toastr;
        this.project = project;
        $scope.$on('$destroy', $ngRedux.connect(null, AuthActions)(this));
    }

    update() {
        this.passwordUpdate(this.newp).then(() => {
            this.$location.search({});
            this.$location.replace();
            this.toastr.success('Password updated');
            this.$state.go('home');
        }, (err) => {
            this.err = `${err && err.message ? err.message + ' - ' : ''}Please request a new password reset email. Password reset links are only valid for 12 hours.`;
        });
    };
}
