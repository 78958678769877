import angular from 'angular';
// Services
import ProjectsService from './projects.service';
import ProjectService from './project.service';
import ProjectMapsService from './maps/project-maps.service';
import ProjectPagesService from './pages/project-pages.service';
import ProjectAttributesService from './project-attributes.service';
import ProjectMediaService from './media/project-media.service';
import ItemValueService from './collections/item-value/item-value.service';

import ProjectDependencyErrorService from './dependency-error/project-dependency-error.service';
 
// Controllers
import ProjectsController from './projects.controller';
import ProjectController from './project.controller';
import ProjectDashboardController from './dashboard/project-dashboard.controller';
import ProjectRecordsController from './records/project-records.controller';
import ProjectRecordController from './record/project-record.controller';
import ProjectConfigController from './config/project-config.controller';
import ProjectSettingsController from './settings/project-settings.controller';
import ProjectRecordStatusSettingsController from './settings/project-record-status-settings.controller';
import ProjectFormsController from './forms/project-forms.controller';
import ProjectFormController from './forms/project-form.controller';
import ProjectApiKeysController from './apikeys/project-apikeys.controller';
import ProjectHooksController from './hooks/project-hooks.controller';
import ProjectCollectionsController from './collections/project-collections.controller';
import ProjectCollectionController from './collections/project-collection.controller';
import ProjectCollectionItemController from './collections/project-collection-item.controller';
import ProjectUsersController from './users/project-users.controller';
import ProjectUserController from './users/project-user.controller';
import ProjectMapsController from './maps/project-maps.controller';
import ProjectMapsConfigController from './maps/project-maps-config.controller';
import ProjectMapsLayersController from './maps/project-maps-layers.controller';
import ProjectMapsLayerController from './maps/project-maps-layer.controller';
import ProjectMapEditorController from './maps/project-map-editor.controller';
import ProjectPageController from './pages/project-page.controller';
import ProjectPageWysiwygController from './pages/project-page-wysiwyg.controller';
import ProjectPageCollectionController from './pages/project-page-collection.controller';
import ProjectPagesController from './pages/project-pages.controller';
import ProjectStylingController from './styling/project-styling.controller';
import ProjectMapConfigController from './maps/project-map-config.controller';
import ProjectCredentialsController from './credentials/project-credentials.controller';
import ProjectFormCreateController from './forms/create/project-form-create.controller';
import ProjectRecordsImportController from './records/project-records-import/project-records-import.controller';
import ProjectRecordsInvalidShapefileExportController from './records/project-records-invalid-shapefile-export/project-records-invalid-shapefile-export.controller';

// Components
import { ProjectWidgetComponent } from './project-widget.directive';
import { ProjectSidebarComponent } from './ui/project-sidebar/project-sidebar.component';
import { ProjectRecordsChipsComponent } from './records/project-records-chips/project-records-chips.component';
import { ProjectRecordsTableComponent } from './records/project-records-table/project-records-table.component';
import { ProjectRecordsFilterComponent } from './records/project-records-filter/project-records-filter.component';
import { ProjectRecordsFilterItemComponent } from './records/project-records-filter/project-records-filter-item.component';
import { ProjectRecordsToolbarComponent } from './records/project-records-toolbar/project-records-toolbar.component';
import { ProjectRecordsMapComponent } from './records/project-records-map/project-records-map.component';
import { ProjectMapComponent } from './maps/project-map.component';
import { ProjectFormsBlockTextComponent } from './forms/block/text/project-forms-block-text.component';
import { ProjectFormsBlockEditorComponent } from './forms/block/editor/project-forms-block-editor.component';
import { ProjectFormsBlockQuestionComponent } from './forms/block/question/project-forms-block-question.component';
import { ProjectFormsBlockDeletedComponent } from './forms/block/deleted/project-forms-block-deleted.component';
import { ProjectFormsBlockComponent } from './forms/block/project-forms-block.component';
import { ProjectFormsSectionDividerComponent } from './forms/block/project-forms-section-divider.component';
import { ProjectFormsRulesSummaryComponent } from './forms/rules/project-forms-rules-summary.component';
import { ProjectMediaItemComponent } from './project-media-item.component';
import { ProjectCollectionSelectComponent } from './ui/project-collection-select/project-collection-select.component';
import { ProjectMapBaseSelectorComponent } from './maps/base-selector/project-map-base-selector.component';
import { SlackHookFormComponent } from './hooks/slack/slack-hook-form.component';
import { SlackHookSummaryComponent } from './hooks/slack/slack-hook-summary.component';
import { WebhookHookFormComponent } from './hooks/webhook/webhook-hook-form.component';
import { WebhookHookSummaryComponent } from './hooks/webhook/webhook-hook-summary.component';
import { MapboxJsonEditorComponent } from './maps/mapbox-style-editor/mapbox-json-editor.component';
import { MapboxPropEditorComponent } from './maps/mapbox-style-editor/mapbox-prop-editor.component';
import { MapboxStyleEditorComponent } from './maps/mapbox-style-editor/mapbox-style-editor.component';
import { HeatmapStyleEditorComponent } from './maps/heatmap-style-editor/heatmap-style-editor.component';
import { ProjectRecordStateComponent } from './record/record-state/project-record-state.component';
import { ProjectRecordHistoryComponent } from './record/record-history/project-record-history.component';
import { ProjectRecordDiffComponent } from './record/record-diff/project-record-diff.component';
import { MediaManagerComponent } from './media/media-manager.component';
import { MediaManagerItemComponent } from './media/media-manager-item.component';
import { ColorSelectorComponent } from './styling/color-selector/color-selector.component';
import { ProjectRecordsAttributeFilterComponent } from './records/project-records-attribute-filter/project-records-attribute-filter.component';
import { ItemValueComponent } from './collections/item-value/item-value.component';
import { MapDataLayerEditorComponent } from './maps/map-data-layer-editor/map-data-layer-editor.component'; 
import { MapMenuComponent } from './maps/map-menu/map-menu.component';
// Directives
import ProjectFormsBlockQuestionSummaryDirective from './forms/block/question/summary/project-forms-block-question-summary.directive';
import ProjectCollectionsGeometryDirective from './collections/project-collections-geometry.directive';
import ProjectFormsRulesComponent from './forms/rules/project-forms-rules.component';
import ProjectFormsCollectionDirective from './forms/collection/project-forms-collection.directive';

// Filters
import ProjectPagesBlockInfoFilter from './pages/project-pages-block-info.filter';
import ProjectFormsQuestionInfoFilter from './forms/project-forms-question-info.filter';
import MapboxExpressionSummaryFilter from './maps/mapbox-style-editor/mapbox-expression-summary.filter';

// Constants

import { PROJECT_ADMIN } from '../permissions/permissions.constants';
import { getProjectCollection, getAuthToken, getProjectId, getProjectForm, getProjectLocked, getOrganisation, getProjectSlug, getAuthIsAdmin } from '../store/selectors';

import './projects.scss';

export default angular
    .module('app.projects', ['ngSanitize', 'ui.router'])
    .service('ProjectService', ProjectService)
    .service('ProjectsService', ProjectsService)
    .service('ProjectMapsService', ProjectMapsService)
    .service('ProjectPagesService', ProjectPagesService)
    .service('ProjectAttributesService', ProjectAttributesService)
    .service('ProjectMediaService', ProjectMediaService)
    .service('ProjectDependencyErrorService', ProjectDependencyErrorService)
    .service('ItemValueService', ItemValueService)
    .controller('ProjectsController', ProjectsController)
    .controller('ProjectController', ProjectController)
    .controller('ProjectDashboardController', ProjectDashboardController)
    .controller('ProjectRecordsController', ProjectRecordsController)
    .controller('ProjectConfigController', ProjectConfigController)
    .controller('ProjectSettingsController', ProjectSettingsController)
    .controller('ProjectRecordStatusSettingsController', ProjectRecordStatusSettingsController)
    .controller('ProjectFormsController', ProjectFormsController)
    .controller('ProjectFormController', ProjectFormController)
    .controller('ProjectApiKeysController', ProjectApiKeysController)
    .controller('ProjectHooksController', ProjectHooksController)
    .controller('ProjectCollectionsController', ProjectCollectionsController)
    .controller('ProjectCollectionController', ProjectCollectionController)
    .controller('ProjectCollectionItemController', ProjectCollectionItemController)
    .controller('ProjectUsersController', ProjectUsersController)
    .controller('ProjectUserController', ProjectUserController)
    .controller('ProjectMapsController', ProjectMapsController)
    .controller('ProjectMapsConfigController', ProjectMapsConfigController)
    .controller('ProjectMapsLayersController', ProjectMapsLayersController)
    .controller('ProjectMapsLayerController', ProjectMapsLayerController)
    .controller('ProjectMapEditorController', ProjectMapEditorController)
    .controller('ProjectRecordController', ProjectRecordController)
    .controller('ProjectPageController', ProjectPageController)
    .controller('ProjectPageWysiwygController', ProjectPageWysiwygController)
    .controller('ProjectPageCollectionController', ProjectPageCollectionController)
    .controller('ProjectPagesController', ProjectPagesController)
    .controller('ProjectStylingController', ProjectStylingController)
    .controller('ProjectMapConfigController', ProjectMapConfigController)
    .controller('ProjectCredentialsController', ProjectCredentialsController)
    .controller('ProjectFormCreateController', ProjectFormCreateController)
    .controller('ProjectRecordsImportController', ProjectRecordsImportController)
    .controller('ProjectRecordsInvalidShapefileExportController', ProjectRecordsInvalidShapefileExportController)
    .component('projectWidget', ProjectWidgetComponent)
    .component('projectSidebar', ProjectSidebarComponent)
    .component('projectRecordsChips', ProjectRecordsChipsComponent)
    .component('projectRecordsTable', ProjectRecordsTableComponent)
    .component('projectRecordsFilter', ProjectRecordsFilterComponent)
    .component('projectRecordsFilterItem', ProjectRecordsFilterItemComponent)
    .component('projectRecordsToolbar', ProjectRecordsToolbarComponent)
    .component('projectRecordsMap', ProjectRecordsMapComponent)
    .component('projectMap', ProjectMapComponent)
    .component('projectFormsBlock', ProjectFormsBlockComponent)
    .component('projectFormsSectionDivider', ProjectFormsSectionDividerComponent)
    .component('projectFormsRulesSummary', ProjectFormsRulesSummaryComponent)
    .component('projectFormsBlockText', ProjectFormsBlockTextComponent)
    .component('projectFormsBlockEditor', ProjectFormsBlockEditorComponent)
    .component('projectFormsBlockQuestion', ProjectFormsBlockQuestionComponent)
    .component('projectFormsBlockDeleted', ProjectFormsBlockDeletedComponent)
    .component('projectFormsRules', ProjectFormsRulesComponent)
    .component('projectMediaItem', ProjectMediaItemComponent)
    .component('projectCollectionSelect', ProjectCollectionSelectComponent)
    .component('projectMapBaseSelector', ProjectMapBaseSelectorComponent)
    .component('slackHookSummary', SlackHookSummaryComponent)
    .component('slackHookForm', SlackHookFormComponent)
    .component('webhookHookForm', WebhookHookFormComponent)
    .component('webhookHookSummary', WebhookHookSummaryComponent)
    .component('mapboxStyleEditor', MapboxStyleEditorComponent)
    .component('heatmapStyleEditor', HeatmapStyleEditorComponent)
    .component('mapboxPropEditor', MapboxPropEditorComponent)
    .component('mapboxJsonEditor', MapboxJsonEditorComponent)
    .component('mapDataLayerEditor', MapDataLayerEditorComponent)
    .component('mapMenu', MapMenuComponent)
    .component('projectRecordState', ProjectRecordStateComponent)
    .component('projectRecordHistory', ProjectRecordHistoryComponent)
    .component('projectRecordDiff', ProjectRecordDiffComponent)
    .component('mediaManager', MediaManagerComponent)
    .component('mediaManagerItem', MediaManagerItemComponent)
    .component('colorSelector', ColorSelectorComponent)
    .component('projectRecordsAttributeFilter', ProjectRecordsAttributeFilterComponent)
    .component('itemValue', ItemValueComponent)
    .directive('projectFormsBlockQuestionSummary', () => new ProjectFormsBlockQuestionSummaryDirective())
    .directive('projectCollectionsGeometry', (Mapbox) => new ProjectCollectionsGeometryDirective(Mapbox))
    .directive('projectFormsCollection', ($timeout, toastr) => new ProjectFormsCollectionDirective($timeout, toastr))
    .filter('blockInfo', ProjectPagesBlockInfoFilter)
    .filter('questionInfo', ProjectFormsQuestionInfoFilter)
    .filter('mapboxExpression', MapboxExpressionSummaryFilter)
    .constant('PROJECT_RECORD_VIEWS',
        [
            { label: 'Map & Table', id: 'map-table', icon: 'fa-columns' },
            { label: 'Map', id: 'map', icon: 'fa-map' },
            { label: 'Table', id: 'table', icon: 'fa-table' }
        ]
    )
    .run(($transitions, $ngRedux) => {
        $transitions.onEnter({}, function (trans) {
            const state = $ngRedux.getState();
            const toState = trans.to();

            if (getAuthIsAdmin(state) || !getProjectLocked(state) || !(toState.data && toState.data.lockable)) {
                return true;
            }

            const fromState = trans.from();

            // If we are already in settings, do nothing
            if (fromState.name === 'project.settings') {
                return false;
            }

            const orgSlug = getOrganisation(state).slug;
            const id = getProjectSlug(state);
            return trans.router.stateService.target('project.settings', {
                id,
                orgSlug
            });
        });
    })
    .config(function ($stateProvider, $qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
        $stateProvider
            .state('project', {
                abstract: true,
                parent: 'org',
                url: '/{id:[^\/]+}',
                template: require('../layouts/project.layout.html'),
                controller: 'ProjectController as ctrl',
                resolvePolicy: {
                    when: 'EAGER',
                    async: 'WAIT'
                },
                resolve: {
                    project: function ($stateParams, $ngRedux, ProjectActions) {
                        return $ngRedux.dispatch(ProjectActions.loadProject($stateParams.id))
                    }
                },
                onEnter: function ($transition$, $state, $ngRedux, project) {
                    if (project === null) {
                        return $transition$.router.stateService.go('home', {
                        });
                    } else {
                        $state.get('project').data = {
                            breadcrumb: {
                                label: project.name,
                                state: 'project.dashboard',
                                icon: 'fas fa-mobile-alt'
                            }
                        };
                        const orgState = $state.get('org');
                        orgState.data.breadcrumb.label = project.organisation.name;
                    }
                }
            })
            .state('project.dashboard', {
                url: '',
                template: require('./dashboard/project-dashboard.html'),
                controller: 'ProjectDashboardController as ctrl',
                data: {
                    breadcrumb: {
                        parent: 'project',
                        label: null,
                        skip: true
                    },
                    hasTutorial: true
                }
            })
            .state('project.map-layers', {
                url: '/map-layers',
                template: require('./maps/project-maps-layers.html'),
                controller: 'ProjectMapsLayersController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Map Layers',
                        parent: 'project.config'
                    }
                }
            })
            .state('project.map-layer', {
                url: '/map-layers/:layer_id',
                template: require('./maps/project-maps-layer.html'),
                controller: 'ProjectMapsLayerController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Map Layer',
                        parent: 'project.config'
                    }
                }
            })
            // .state('project.maps-config', {
            //     url: '/maps-config',
            //     template: require('./maps/project-maps-config.html'),
            //     controller: 'ProjectMapsConfigController as ctrl',
            //     data: {
            //         breadcrumb: {
            //             label: 'Map Overlays',
            //             parent: 'project.config'
            //         },
            //         lockable: true,
            //         permissions: [
            //             PROJECT_ADMIN
            //         ]
            //     }
            // })
            // .state('project.map-config', {
            //     url: '/maps-config/:map_id',
            //     template: require('./maps/project-map-editor.html'),
            //     controller: 'ProjectMapEditorController as ctrl',
            //     unscopedParent: 'project.maps-config',
            //     resolve: {
            //         map: function ($stateParams, project, ProjectMapsService) {
            //             return ProjectMapsService.getMap(project.id, $stateParams.map_id);
            //         }
            //     },
            //     data: {
            //         breadcrumb: {
            //             label: '',
            //             parent: 'project.maps-config'
            //         },
            //         lockable: true,
            //         permissions: [
            //             PROJECT_ADMIN
            //         ]
            //     },
            //     onEnter: function ($state, map) {
            //         $state.get('project.map-config').data.breadcrumb.label = map.name;
            //     }
            // })
            .state('project.maps-config', {
                url: '/maps-config',
                template: require('./maps/project-map-editor.html'),
                controller: 'ProjectMapEditorController as ctrl',
                unscopedParent: 'project.maps-config',
                // resolve: {
                //     map: function ($stateParams, project, ProjectMapsService) {
                //         return ProjectMapsService.getMap(project.id, $stateParams.map_id);
                //     }
                // },
                data: {
                    breadcrumb: {
                        label: 'Map Builder',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
                // onEnter: function ($state, map) {
                //     $state.get('project.map-config').data.breadcrumb.label = map.name;
                // }
            })
            .state('project.maps', {
                url: '/maps?z&c&m',
                reloadOnSearch: false,
                template: require('./maps/project-maps.html'),
                controller: 'ProjectMapsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Maps',
                        parent: 'project'
                    }
                },
                resolve: {
                    map: function (project, ProjectMapsService) {
                        return ProjectMapsService.getDefaultMap(project.id);
                    }
                }
            })
            .state('project.config', {
                url: '/config',
                template: require('./config/project-config.html'),
                controller: 'ProjectConfigController as ctrl',

                data: {
                    breadcrumb: {
                        label: 'Configuration',
                        parent: 'project'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.pages', {
                url: '/pages/',
                template: require('./pages/project-pages.html'),
                controller: 'ProjectPagesController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Pages',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.page', {
                url: '/pages/:page_id',
                views: {
                    '': {
                        template: require('./pages/project-page.html'),
                        controller: 'ProjectPageController as ctrl'
                    },
                    'page@project.page': {
                        templateProvider: page => {
                            switch (page.type) {
                                case 'collection': {
                                    return require('./pages/project-page-collection.html');
                                }
                                case 'map': {
                                    return require('./pages/project-page-map.html');
                                }
                                case 'wysiwyg': {
                                    return require('./pages/project-page-wysiwyg.html');
                                }
                                default: {
                                    return 'Unknown page type';
                                }
                            }
                        },
                        controllerProvider: page => {
                            switch (page.type) {
                                case 'wysiwyg': {
                                    return 'ProjectPageWysiwygController'
                                }
                                case 'collection': {
                                    return 'ProjectPageCollectionController'
                                }
                            }
                        },
                        controllerAs: 'viewCtrl'
                    }
                },
                unscopedParent: 'project.pages',
                resolvePolicy: {
                    when: 'EAGER'
                },
                resolve: {
                    page: ($ngRedux, $stateParams, project, ProjectPageActions) => {
                        return $ngRedux.dispatch(ProjectPageActions.initPage($stateParams.page_id));
                    },
                    previewUrl: ($ngRedux, $sce, project, apiHostname) => {
                        const state = $ngRedux.getState();
                        const token = getAuthToken(state);
                        const appId = getProjectId(state);
                        const env = apiHostname.indexOf('dev.coreo') === -1 ? 'prod' : 'dev';

                        if (window.location.hostname === 'localhost') {
                            return $sce.trustAsResourceUrl(`http://localhost:3333/preview?appId=${appId}&token=${token}&env=${env}`);
                        } else {
                            return $sce.trustAsResourceUrl(`https://coreo-app-preview.netlify.app/preview?appId=${appId}&token=${token}&env=${env}`);
                        }
                    }
                },
                data: {
                    breadcrumb: {
                        label: '',
                        parent: 'project.pages'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                },
                onEnter: function ($state, page) {
                    $state.get('project.page').data.breadcrumb.label = page.title;
                }
            })
            .state('project.styling', {
                url: '/styling',
                template: require('./styling/project-styling.html'),
                controller: 'ProjectStylingController as ctrl',
                resolve: {
                    styleView: () => 'app'
                },
                data: {
                    breadcrumb: {
                        label: 'Styling',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.styling-pwa', {
                url: '/styling-pwa',
                template: require('./styling/project-styling.html'),
                controller: 'ProjectStylingController as ctrl',
                resolve: {
                    styleView: () => 'pwa'
                }
            })
            .state('project.forms', {
                url: '/forms',
                template: require('./forms/project-forms.html'),
                controller: 'ProjectFormsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Forms',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.form', {
                url: '/forms/:form_id',
                template: require('./forms/project-form.html'),
                controller: 'ProjectFormController as ctrl',
                unscopedParent: 'project.forms',
                resolvePolicy: {
                    when: 'EAGER'
                },
                resolve: {
                    init: ($ngRedux, $stateParams, project, ProjectFormActions, $q, ProjectActions) => {
                        return $q.all([
                            $ngRedux.dispatch(ProjectFormActions.initForm($stateParams.form_id)),
                            $ngRedux.dispatch(ProjectActions.loadCollections())
                        ]);
                        // return $ngRedux.dispatch(ProjectFormActions.initForm($stateParams.form_id));
                    }
                },
                data: {
                    breadcrumb: {
                        label: '',
                        parent: 'project.forms'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                },
                onEnter: function ($state, $ngRedux, $stateParams) {
                    const form = getProjectForm(+$stateParams.form_id)($ngRedux.getState());
                    $state.get('project.form').data.breadcrumb.label = form && form.name;
                }
            })
            .state('project.users', {
                url: '/users',
                template: require('./users/project-users.html'),
                controller: 'ProjectUsersController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Members',
                        parent: 'project'
                    },
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.user', {
                url: '/users/:projectUserId',
                template: require('./users/project-user.html'),
                controller: 'ProjectUserController as ctrl',
                resolve: {
                    projectUser: (CoreoAPI, project, $stateParams) => {
                        const query = `query AAGetProjectUser($projectId: Int!){
              project(id: $projectId){
                projectUsers(where:{userId: ${$stateParams.projectUserId}}){
                  result{
                    userId,
                    user{
                      username,
                      imageUrl,
                      displayName,
                      email,
                      verified,
                      provider
                    },
                    data
                  }
                }
              }
            }`;
                        return CoreoAPI.query(query, {
                            variables: {
                                projectId: project.id,
                                userId: +$stateParams.projectUserId
                            }
                        }).then(result => {
                            return result && result.project && result.project.projectUsers && result.project.projectUsers.result && result.project.projectUsers.result[0];
                        });
                    }
                },
                onEnter: function ($state, projectUser) {
                    const label = projectUser && projectUser.user && projectUser.user.displayName;
                    if (label) {
                        $state.get('project.user').data.breadcrumb.label = label;
                    }
                },
                data: {
                    breadcrumb: {
                        label: 'User',
                        parent: 'project.users'
                    },
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.apikeys', {
                url: '/apikeys',
                template: require('./apikeys/project-apikeys.html'),
                controller: 'ProjectApiKeysController as ctrl',
                resolve: {
                    apikeys: function (project) {
                        return project.apiKeys;
                    }
                },
                data: {
                    breadcrumb: {
                        label: 'API Keys',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.credentials', {
                url: '/credentials',
                template: require('./credentials/project-credentials.html'),
                controller: 'ProjectCredentialsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'OAuth Credentials',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.hooks', {
                url: '/hooks',
                template: require('./hooks/project-hooks.html'),
                controller: 'ProjectHooksController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Hooks',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.settings', {
                url: '/settings',
                template: require('./settings/project-settings.html'),
                controller: 'ProjectSettingsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Project Settings',
                        parent: 'project.config'
                    },
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.verificationsettings', {
                url: '/settings/record-status',
                template: require('./settings/project-record-status-settings.html'),
                controller: 'ProjectRecordStatusSettingsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Record Status Settings',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.collections', {
                url: '/collections',
                template: require('./collections/project-collections.html'),
                controller: 'ProjectCollectionsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Collections',
                        parent: 'project.config'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                }
            })
            .state('project.collection', {
                url: '/collections/:collection_id',
                template: require('./collections/project-collection.html'),
                controller: 'ProjectCollectionController as ctrl',
                unscopedParent: 'project.collections',
                data: {
                    breadcrumb: {
                        label: '',
                        parent: 'project.collections'
                    },
                    lockable: true,
                    permissions: [
                        PROJECT_ADMIN
                    ]
                },
                resolve: {
                    collection: function ($stateParams, $ngRedux, ProjectActions) {
                        return $ngRedux.dispatch(ProjectActions.loadCollection($stateParams.collection_id))
                    }
                },
                onEnter: function ($state, $ngRedux, $stateParams) {
                    const collection = getProjectCollection(+$stateParams.collection_id)($ngRedux.getState());
                    if (collection) {
                        $state.get('project.collection').data.breadcrumb.label = collection.name;
                    }
                }
            })
            .state('project.collection-item', {
                url: '/collections/:collection_id/items/:item_id',
                template: require('./collections/project-collection-item.html'),
                controller: 'ProjectCollectionItemController as ctrl',
                unscopedParent: 'project.collections',
                data: {
                    permissions: [
                        PROJECT_ADMIN
                    ],
                    lockable: true,
                },
                onEnter: function ($state, $stateParams, $ngRedux) {
                    const collection = getProjectCollection(+$stateParams.collection_id)($ngRedux.getState());
                    if (collection) {
                        const bc = $state.get('project.collection').data.breadcrumb
                        bc.label = collection.name;
                        bc.stateParams = { collection_id: +$stateParams.collection_id };
                    }

                    console.log('On Enter project.collection-item', $state, $stateParams, collection);
                    const item = (collection && collection.items || []).find(i => i.id === +$stateParams.item_id);

                    $state.get('project.collection-item').data = {
                        breadcrumb: {
                            label: item && item.value,
                            parent: `project.collection`
                        }
                    };
                }
            })
            .state('project.records', {
                url: '/records',
                template: require('./records/project-records.html'),
                controller: 'ProjectRecordsController as ctrl',
                data: {
                    breadcrumb: {
                        label: 'Records',
                        parent: 'project'
                    }
                },
                onEnter: ($ngRedux, RecordsActions, search) => {
                    $ngRedux.dispatch(RecordsActions.recordsInit(search));
                },
                resolve: {
                    search: function ($location, $q, Boundaries, project, CoreoAPI) {
                        const search = $location.search();
                        const promises = [];

                        if (search && search.b) {
                            promises.push(Boundaries.getBoundary(search.b).then(b => search.b = b));
                        }
                        if (search && search.u) {
                            const query = `{project(id:${project.id}){projectUsers(where:{ userId: ${search.u} }){result{user{displayName,id}}}}}`;
                            promises.push(CoreoAPI.query(query).then(result => {
                                search.u = result.project.projectUsers.result[0].user;
                            }));
                        }

                        return $q.all(promises).then(() => search);
                    }
                }
            })
            .state('project.record', {
                url: '/records/:record_id',
                template: require('./record/project-record.html'),
                controller: 'ProjectRecordController as ctrl',
                unscopedParent: 'project.records',
                resolve: {
                    init: function ($ngRedux, $stateParams, RecordActions) {
                        return $ngRedux.dispatch(RecordActions.getDetailedRecord($stateParams.record_id))
                            .catch(err => {
                                console.warn(err.message);
                                return 'err';
                            })
                    }
                },
                data: {
                    breadcrumb: {
                        label: '',
                        parent: 'project.records'
                    }
                },
                onEnter: function ($state, $stateParams) {
                    $state.get('project.record').data.breadcrumb.label = $stateParams.record_id;
                }
            })
            .state('project.profile', {
                url: '/profile',
                templateUrl: 'auth/profile/profile.html',
                controller: 'ProfileController as ctrl',
                resolve: {
                    profile: function ($http) {
                        return $http.get('/auth/profile').then(function (res) {
                            return res.data;
                        });
                    }
                }
            })
            .state('project.terms', {
                url: '/terms',
                templateUrl: 'legal/terms/terms.html',
                controller: 'LegalTermsController as ctrl'
            })
            .state('project.privacy', {
                url: '/privacy',
                templateUrl: 'legal/privacy/privacy.html',
                controller: 'LegalPrivacyController as ctrl'
            });

    });
