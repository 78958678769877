import PermissionsService from './permissions.service';
import ForPermissionsDirective from './forPermissions.directive';

export default angular
    .module('app.permissions', ['ui.router'])
    .service('PermissionsService', PermissionsService)
    .directive('forPermissions', (PermissionsService) => new ForPermissionsDirective(PermissionsService))
    .run(function ($transitions, toastr, PermissionsService) {

        $transitions.onEnter({}, function (transition) {
            const to = transition.to();
            const permissions = to && to.data && to.data.permissions;
            if (!permissions) {
                return true;
            }
            if (!PermissionsService.checkAccess(permissions)) {
                toastr.error('You do not have access to this page');
                return transition.router.stateService.target('home');
            }
            return true;
        });

    });