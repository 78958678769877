// import './project-forms-block-editor.scss';

export const ProjectFormsSectionDividerComponent = {
    bindings: {
        onRemove: '&?',
        message: '@'
    },
    template: require('./project-forms-section-divider.component.html'),
    controllerAs: 'ctrl',
    controller: class ProjectFormsSectionDividerComponent {
        /* @ngInject */
        constructor() {
        }

        $onInit() {
            this.showRemove = !!this.onRemove;
        }
    }
}