import './project-forms-block-deleted.scss';

export const ProjectFormsBlockDeletedComponent = {
  template: require('./project-forms-block-deleted.component.html'),
  bindings: {
    block: '<',
    attribute: '<',
    forms: '<'
  },
  controllerAs: 'ctrl',
  controller: class ProjectFormsBlockDeletedComponent {
    $onInit() {
      this.variant = this.block;
    }
  }
}