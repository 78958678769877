import { getProjectAttributesForForm } from '../../store/selectors';
import './record-attributes.scss';
export const RecordAttributesComponent = {
    template: require('./record-attributes.component.html'),
    bindings: {
        record: '<',
        edit: '<',
        diffMap: '<',
        onDataChange: '&',
        onAssociationChange: '&'
    },
    controllerAs: 'ctrl',
    controller: class RecordAttributesComponent {
        /* @ngInject */
        constructor($ngRedux, toastr, RecordsService, ProjectService) {
            this.toastr = toastr;
            this.RecordsService = RecordsService;
            this.ProjectService = ProjectService;
            this.$ngRedux = $ngRedux;
        }

        getRecordAttributesForDisplay(surveyId) {
            const attributes = getProjectAttributesForForm(surveyId)(this.$ngRedux.getState());
            return attributes
                .filter(a => (a.visible || a.questionType === 'expression') && !a.parentCollectionId && !(a.questionType === 'child' || a.questionType === 'text'));
        }

        $onChanges() {
            if (!this.record) {
                return;
            }
            this.attributes = this.getRecordAttributesForDisplay(this.record.surveyId);
            this.attachments = this.record.attachments.reduce((acc, attachment) => {
                if (typeof acc[attachment.attributeId] === 'undefined') {
                    acc[attachment.attributeId] = [];
                }
                acc[attachment.attributeId].push(attachment);
                return acc;
            }, {});

            this.associations = this.attributes.filter(a => a.questionType === 'association').reduce((acc, associationAttribute) => {
                const r = (this.record.associates || []).find(a => a.association.id === associationAttribute.id);
                if (typeof r === 'undefined') {
                    return acc;
                }

                acc[associationAttribute.id] = angular.copy(r.record);
                return acc;
            }, {});
            this.data = angular.copy(this.record.data || {});
        }

        handleDataChange(attribute) {
            this.onDataChange({ data: this.data, attribute });
        }

        handleAssociatedToChange(attribute) {
            this.onAssociationChange({ attribute, record: this.associations[attribute.id] });
        }

        //if path is present in the diffmap, return a class to colour the attribute's background
        getDiffMapClass(path) {
            if (!this.diffMap) return;
            const op = this.diffMap[path];
            if (typeof op !== undefined) return 'attr-' + op;
        }
    }
};

