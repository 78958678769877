import { createSelector } from "reselect";

export const getRecordsView = (state) => state.view;
export const getRecordsFilter = (state) => state.filter;
export const getRecordsFilterOpen = (state) => state.filterOpen;
export const getRecordsRecordOpen = (state) => state.recordOpen;
export const getRecordsPagination = (state) => state.pagination;
export const getRecordsShowDeleted = (state) => state.showDeleted;
export const getRecordsOrder = (state) => state.order;
export const getRecordsTotal = (state) => state.total;
export const getRecordsSelected = (state) => state.selected;
export const getRecordsData = (state) => state.data;
export const getRecordsSelectedIds = (state) => state.selectedIds;
export const getRecordsSelectedCount = (state) => state.selectedIds.length;
export const getRecordsSelectionSource = (state) => state.selectionSource;

export const getRecordsSelectedId = createSelector(getRecordsSelected, r => r.id);
export const getRecordsPaginationSize = createSelector(getRecordsPagination, p => p.size);
export const getRecordsPaginationPage = createSelector(getRecordsPagination, p => p.page);
export const getRecordsFilterBoundary = createSelector(getRecordsFilter, filter => filter && filter.boundary);
export const getRecordsFilterCustomBoundary = createSelector(getRecordsFilter, filter => filter && filter.customBoundary);
export const getRecordsFilterCustom = createSelector(getRecordsFilter, filter => filter && filter.custom);
export const getRecordsFilterAttributes = createSelector(getRecordsFilter, filter => filter && filter.attributes);
export const getRecordsFilterCustomOperator = createSelector(getRecordsFilter, filter => filter && filter.customOperator);
export const getRecordsMapIsSatellite = (state) => state.satelliteMap;
export const getRecordsMapIsDrawing = (state) => state.drawingBoundary;
export const getRecordsColumns = (state) => state.columns;
export const getRecordsReloadRequired = (state) => state.reloadRequired;
export const getRecordsFilterDirty = createSelector(getRecordsFilter, filter => filter && filter.dirty);
export const getRecordsFormId = createSelector(getRecordsFilter, filter => filter && filter.formId);
export const getRecordsLayers = state => state.layers;

