import angular from 'angular';
export default angular.module('app.constants', [])
    .constant('hostname', process.env.ADMIN_HOSTNAME)
    .constant('port', process.env.ADMIN_PORT)
    .constant('protocol', process.env.ADMIN_PROTOCOL)
    .constant('version', 1)
    .constant('apiHostname', process.env.API_HOSTNAME)
    .constant('apiProtocol', process.env.API_PROTOCOL)
    .constant('apiPort', process.env.API_PORT || 443)
    .constant('publicHostname', process.env.ADMIN_HOSTNAME)
    .constant('publicProtocol', process.env.ADMIN_PROTOCOL)
    .constant('publicPort', process.env.ADMIN_PORT)
    .constant('appDomain', process.env.ADMIN_APP_DOMAIN)
    .constant('googleAnalyticsId', process.env.ADMIN_GOOGLE_ANALYTICS_ID)
    .constant('mapboxAccessToken', process.env.ADMIN_MAPBOX_ACCESS_TOKEN)
    .constant('mapsUrl', process.env.MAPS_URL)
    .constant('docsUrl', process.env.DOCS_URL)
    .constant('androidUrl', process.env.ANDROID_URL)
    .constant('iosUrl', process.env.IOS_URL)
    ;