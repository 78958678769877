/* @ngInject */
export default class AdminUsers {
    constructor($http, CoreoAPI) {
        this.$http = $http;
        this.CoreoAPI = CoreoAPI;
    }

    get() {
        return this.$http.get('/users').then(function (res) {
            return res.data;
        });
    }

    getById(id) {
        return this.$http.get('/users/' + id).then(function (res) {
            return res.data;
        });
    }

    getVerificationReport(id) {
        return this.$http.get('/users/' + id + '/verificationReport').then(function (res) {
            return res.data;
        });
    }

    getProjectVerificationReport(id, projectId) {
        return this.$http.get('projects/' + projectId + '/users/' + id + '/verificationReport').then(function (res) {
            return res.data;
        });
    }

    update(user) {
        return this.$http.put('/users/' + user.id, user).then(function (res) {
            return res.data;
        });
    }

    updatePassword(userId, password) {
        return this.$http.put('/users/' + userId, { password: password }).then(function (res) {
            return res.data;
        });
    }

    // getPermissions(id) {
    //     return this.$http.get('/users/' + id + '/permissions').then(function (res) {
    //         return res.data;
    //     });
    // }

    getPermissions(userId) {
        var query = `
            query getUserOrganisations($userId: Int!){
                user(id: $userId) {
                    memberships {
                        admin,
                        createdAt,
                        data,
                        moderator
                        project {
                            id
                            name
                            slug
                            shortName
                            organisation {
                                id
                                slug
                            }
                        }
                        projectId,
                        read,
                        role,
                        status,
                        updatedAt,
                        userId,
                        write
                    }
                }
            }
        `
            return this.CoreoAPI.query(query, {
            variables: {
                userId
            }
        }).then(function (data) {
            return data.user.memberships;
        });
    }

    deleteUser(id, force) {
        var body = {};
        if (force) {
            body.force = true;
        }

        return this.$http({
            url: '/users/' + id,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json;charset=utf-8"
            },
            data: body,
        }).then(function (res) {
            return res.data;
        });

    }

    restoreUser(id) {
        var url = '/users/' + id + '/restore';
        return this.$http.put(url).then(function (res) {
            return res.data;
        });
    }

    getApiKey(userId) {
        var url = '/users/' + userId + '/apikey';
        return this.$http.get(url).then(function (res) {
            return res.data;
        });
    }

    createApiKey(userId) {
        var url = '/users/' + userId + '/apikey';
        return this.$http.post(url).then(function (res) {
            return res.data;
        });
    }

    removeApiKey(userId) {
        var url = '/users/' + userId + '/apikey';
        return this.$http.delete(url).then(function (res) {
            return res.data;
        });
    }

    refreshApiKey(userId) {
        var url = '/users/' + userId + '/apikey';
        return this.$http.put(url).then(function (res) {
            return res.data;
        });
    }

    updateOrganisatonRole(userId, organisationId, role) {
        const input = {
            userId,
            organisationId,
            role
        }
        var mutation = 'mutation($input: OrganisationUserUpdateInput!){result: updateOrganisationUser(input: $input){id}}';
        return this.CoreoAPI.mutation(mutation, null, {
            variables: {
            input
        } });
    }

    getUserOrganisations(userId) {
        var query = 'query getUserOrganisations($userId: Int!){user(id: $userId){organisationMemberships {role, organisation {id, name, slug}}}}';
        return this.CoreoAPI.query(query, {
            variables: {
                userId
            }
        }).then(function (data) {
            return data.user.organisationMemberships;
        });
    }
}
