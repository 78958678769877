import './project-settings.scss';
const MAX_AUTOCOMPLETE_USERS = 300;
/* @ngInject */

/* @ngInject */
export default class CloneProjectController {
    constructor(ProjectsService, $mdDialog, CoreoAPI, isAdmin, organisationId) {
        this.ProjectsService = ProjectsService;
        this.$mdDialog = $mdDialog;
        this.CoreoAPI = CoreoAPI;
        this.isAdmin = isAdmin;
        this.organisationId = organisationId;
        this.selectedOrganisation = null;
        this.selectedOrganisationUser = null;
        this.selectedProject = null;
        this.locked = false;
        this.usersLimit = 0;
        this.name = null;
        this.mode = null;
    }

    updateOrganisation() {
        console.log(this.selectedOrganisation)
    }

    getOrganisations(search) {
        var query = `query CoreoAASearchOrganisations{
        organisations(
            where:{ 
                name: { iLike: "%${search}%"}
            }, 
            limit: ${MAX_AUTOCOMPLETE_USERS})
        {
            name,
            id
        }
    }`;
        return this.CoreoAPI.query(query).then(result => {
            return result.organisations;
        });
    }

    getOrganisationUsers(search) {
        var query = `query CoreoAASearchOrganisationUsers{
        organisation(id: ${this.selectedOrganisation.id}){
            memberships(where :{ 
                user: { 
                    or: [
                        {displayName: { iLike: "%${search}%"}},
                        {username: { iLike: "%${search}%"}}
                    ]
                    deletedAt: {
                        eq: null
                    }
                }
            }, limit: ${MAX_AUTOCOMPLETE_USERS}) {
                user {
                    displayName
                    username
                    id
                }
            }
        }
    }`;
        return this.CoreoAPI.query(query).then(result => {
            return result.organisation.memberships.map(m => m.user);
        });
    }

    getProjects(search) {
        const organisationId = this.selectedOrganisation ? this.selectedOrganisation.id : this.organisationId;
        var query = `query CoreoAASearchProjects{
        organisation(id: ${organisationId}) {
            projects(
                where:{ 
                    name: { iLike: "%${search}%"}
                })
            {
                name,
                id
            }
        }
    }`;
        return this.CoreoAPI.query(query).then(result => {
            return result.organisation.projects;
        });
    }

    isReady() {
        if (!this.mode) {
            return false;
        }
        return this.mode === 'clone' ? !!this.name : !!this.selectedProject;
    }

    ok() {
        let result = {};
        if (this.isAdmin) {
            result = {
                action: this.mode,
                organisationId: this.selectedOrganisation && this.selectedOrganisation.id,
                ownerId: this.selectedOrganisationUser && this.selectedOrganisationUser.id,
                projectId: this.selectedProject && this.selectedProject.id,
                locked: this.locked,
                usersLimit: this.usersLimit,
                name: this.name
            }
        } else {
            result = {
                action: this.mode,
                name: this.name,
                projectId: this.selectedProject && this.selectedProject.id
            }
        }
        this.$mdDialog.hide(result);
    }

    cancel() {
        this.$mdDialog.cancel();
    }
}
