/* @ngInject */
export default class ProjectsService {

    constructor($http, CoreoAPI, CoreoGraphQLQuery) {
        this.$http = $http;
        this.CoreoAPI = CoreoAPI;
        this.CoreoGraphQLQuery = CoreoGraphQLQuery;
    }


    // Serialize object to gql
    gqlStringify(obj) {
        // JSON stringify, and strip quotes on field names
        return JSON.stringify(obj).replace(/\"([^(\")"]+)\":/g, "$1:");
    }

    nbnFieldsGqlStringify(obj) {
        return this.gqlStringify(obj).replace(/source:\s*"(.*?)"/g, "source:$1,");
    }

    query() {
        return new this.CoreoGraphQLQuery('{data: viewer{ projects(roles: [ADMIN,MODERATOR]){id, name, description, imageUrl, slug, subdomain}}}');
    }

    getProjects() {
        return this.query().run().then(q => {
            return q.data.projects.map(project => ({
                ...project,
                imageUrl: project.imageUrl && (project.imageUrl.replace('https://coreo.s3-eu-west-1.amazonaws.com', 'https://coreo.imgix.net') + '?w=512') || null
            }))
        });
    }

    getById(id) {
        return this.$http.get('/projects/' + id).then(function (res) {
            return res.data;
        });
    }

    create(data) {
        var query = 'mutation{result: createProject(input: ' + this.CoreoAPI.gqlStringify(data) + '){id,name,slug,states{name}}}';
        return this.CoreoAPI.mutation(query);
    }

    update(id, data) {
        return this.$http.put('/projects/' + id, data).then(function (res) {
            return res.data;
        });
    }

    updateProject(id, project) {
        var data = new FormData();
        Object.keys(project).map(function (k) {
            data.append(k, project[k]);
        });

        return this.$http.put('/projects/' + id, data, {
            headers: {
                'Content-Type': undefined
            }
        }).then(function (res) {
            return res.data;
        });
    }

    getProjectMemberships(id) {
        var query = '{data: project(id: ' + id + '){memberships{admin,data,marketingConsent,moderator,read,role,status,write,createdAt,user{displayName,username,id,email}}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data.memberships;
            });
    }

    createProjectUser(projectId, userId, data) {
        var query = 'mutation{data: createProjectMembership(input: {projectId: ' + projectId + ', userId:' + userId + (data ? ', ' + this.CoreoAPI.gqlStringify(data) : '') + '}){admin,data,moderator,read,role,status,write,user{displayName,id,email}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data.createProjectMembership;
            });
    }

    deleteProjectUser(projectId, userId) {
        var query = 'mutation{data: deleteProjectMembership(input: {projectId: ' + projectId + ', userId:' + userId + '})}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data.deleteProjectMembership;
            });
    }

    updateProjectUserAccess(projectId, userId, field, value) {
        var query = 'mutation{data: updateProjectMembership(input: {projectId: ' + projectId + ', userId:' + userId + ', ' + field + ':' + value.toLowerCase() + '}){admin,data,moderator,read,role,status,write,user{displayName,id,email}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data.createProjectMembership;
            });

    }

    updateProjectUserStatus(id, projectUserId, status) {
        return this.$http.put('/projects/' + id + '/memberships/' + projectUserId, {
            status: status
        }).then(function (res) {
            return res.data;
        });
    }

    createHook(projectId, hook) {
        hook.projectId = projectId;
        var query = 'mutation{data: createProjectHook(input:' + this.CoreoAPI.gqlStringify(hook) + '){ id, type, config, events, action, active, projectId}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    updateHook(projectId, hook) {
        var cleanHook = {};

        for (var key in hook) { //remove angular poperties
            if (hook.hasOwnProperty(key) && key[0] !== '$') {
                cleanHook[key] = hook[key];
            }
        }

        var query = 'mutation{data: updateProjectHook(input:' + this.CoreoAPI.gqlStringify(cleanHook) + '){ id, type, config, events, action, active, projectId}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    deleteHook(projectId, id) {
        var query = 'mutation{data: deleteProjectHook(input:{id:' + id + '})}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    // Gets all collections of a project, without items
    getCollections(id) {
        var query = '{data: project(id: ' + id + '){collections{id,name,itemCount,geometric,updatedAt,type,status, sortMode, sortAttributeId, sortReverse, attributes{filterable,collectionId,label,visible,required,order,id,type,path,meta}}}}';
        return this.CoreoAPI.query(query).then(function (data) {
            return data.data.collections;
        });
    }

    getCollectionsWithItems(id) {
        var query = '{data: project(id: ' + id + '){collections{id,name,type,geometric,attributes{filterable,collectionId,label,visible,required,order,id,type,path,meta},items{id,key,value,sort,mediaItems{id,name,url,size,type,caption,createdAt}}}}}';
        return this.CoreoAPI.query(query).then(function (data) {
            return data.data.collections;
        });
    }

    getCollectionNames(id) {
        var query = '{project(where:{id:' + id + '}){collections{id, name, type}}}';

        return this.CoreoAPI.query(query).then(function (data) {
            return data.project.collections;
        });
    }

    sendTest(id, body) {
        var headers = body instanceof FormData ? {
            'Content-Type': undefined
        } : {};
        return this.$http.post('/records', body, {
            headers: headers
        }).then(function (res) {
            return res.data;
        });
    }

    getSurveysWithCollections(id) {
        var query = '{project(id: ' + id + '){surveys{id,name,slug,anonymous,verification, allowMemberUpdate, titleAttributeId, secondaryTitleAttributeId, visible, private, primary,attributes{filterable,collectionId,label,visible,required,order,id,type,path,meta,collection{items{key,value}}},associations{id,legacy,name,subSurveyId}}}}';
        return this.CoreoAPI.query(query).then(data => data.project.surveys);
    }

    getSurveysWithCollectionAttributes(id) {
        var query = '{project(id: ' + id + '){surveys{id,name,slug,anonymous, allowMemberUpdate,attributes{filterable,collectionId,label,visible,required,order,id,type,path,meta,collection{attributes{label}}}, titleAttributeId, secondaryTitleAttributeId, visible, private, associations{id,legacy,name,subSurveyId}}}}';
        return this.CoreoAPI.query(query).then(data => data.project.surveys);
    }

    updateForm(surveyId, data) {
        data.id = surveyId;
        var query = 'mutation{data: updateSurvey(input:' + this.CoreoAPI.gqlStringify(data) + '){id,name,slug,anonymous,verification,attributes{filterable,collectionId,label,visible,required,order,id,type,path,meta,collection{items{key,value}}}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    deleteForm(surveyId) {
        var query = 'mutation{data: deleteSurvey(input:{id:' + surveyId + '})}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    addAttribute(projectId, data) {
        data.projectId = projectId;
        // data.surveyId = surveyId;
        var query = 'mutation{data: createAttribute(input:' + this.CoreoAPI.gqlStringify(data) + '){ id, label, path, required, order, filterable, meta, projectId, parentCollectionId, surveyId}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    removeAttribute(attributeId) {
        var query = 'mutation{data: deleteAttribute(input:{id:' + attributeId + '})}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    updateAttribute(attributeId, attribute) {
        attribute.id = attributeId;
        var query = 'mutation{data: updateAttribute(input:' + this.CoreoAPI.gqlStringify(attribute) + '){ id, label, path, required, order, filterable, meta, projectId, surveyId}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    importRecordsFromFile(file) {
        var fd = new FormData();
        fd.append('file', file);
        return $http.post('/records/import', fd, {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        }).then(function (response) {
            return response.data;
        });
    }

    createSubSurvey(surveyId, subsurvey) {
        var data = {};
        data.subSurveyId = subsurvey.surveyId;
        data.surveyId = surveyId;

        var query = 'mutation{data: createAssociation(input:' + this.CoreoAPI.gqlStringify(data) + '){ surveyId, subSurveyId }}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    createAssociation(sourceId, targetId, name) {
        var data = {
            surveyId: sourceId,
            subSurveyId: targetId,
            name
        };
        var query = 'mutation{data: createAssociation(input:' + this.CoreoAPI.gqlStringify(data) + '){ id, name, surveyId, subSurveyId }}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    updateAssociation(association) {
        var input = {
            id: association.id,
            name: association.name
        };
        var query = `mutation{result: updateAssociation(input: ${this.CoreoAPI.gqlStringify(input)}){id,name,surveyId,subSurveyId}}`;
        return this.CoreoAPI.mutation(query);
    }

    deleteSubSurvey(surveyId, childSurveyId) {
        var query = 'mutation{data: deleteAssociation(input:{surveyId:' + surveyId + ', subSurveyId:' + childSurveyId + '})}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    createVisualisation(projectId, surveyId, visualisation) {
        visualisation.surveyId = surveyId;
        visualisation.childSurveyId = visualisation.childSurvey;
        delete visualisation.childSurvey;
        var query = 'mutation{data: createVisualisation(input:' + this.CoreoAPI.gqlStringify(visualisation) + '){id,type,config,childSurvey{id,name,slug,attributes{label,order,id,type,path,meta}}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    updateVisualisation(projectId, surveyId, visualisationId, visualisation) {
        visualisation.id = visualisationId;
        delete visualisation.childSurvey;
        var query = 'mutation{data: updateVisualisation(input:' + this.CoreoAPI.gqlStringify(visualisation) + '){id,type,config,childSurvey{id,name,slug,attributes{label,order,id,type,path,meta}}}}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    deleteVisualisation(projectId, surveyId, visualisationId) {
        var query = 'mutation{data: deleteVisualisation(input: {id:' + visualisationId + '})}';
        return new this.CoreoGraphQLQuery(query).run()
            .then(function (data) {
                return data.data;
            });
    }

    // getForms(projectId) {
    //   var query = '{data: project(id: ' + projectId + '){forms{id,surveyId,collectionId,name,title,content}}}';
    //   return this.CoreoAPI.query(query).then(data => data.data.forms);
    // }

    csvExport(projectId, where, showDeleted = false) {
        var query = 'mutation($where: SequelizeJSON){data: createRecordsExport(input:{projectId: ' + projectId + ',' + (showDeleted ? "showDeleted:true," : '') + ' where: $where}){id}}';
        return this.CoreoAPI.mutation(query, null, {
            variables: {
                where: where
            }
        }).then(function () {
            return true;
        }, function () {
            return false;
        });
    }

    nbnExport(options) {
        var metadata = options.metadata;
        var fields = options.fields;
        var query = 'mutation{data: createNBNExport(input:{';
        query += 'metadata:' + this.CoreoAPI.gqlStringify(metadata) + ', ';
        query += 'projectId:' + options.projectId + ', surveyId:' + options.surveyId + ', ';
        if (options.to) {
            query += 'to:"' + options.to + '", ';
        }
        if (options.from) {
            query += 'from:"' + options.from + '", ';
        }
        query += 'fields:' + nbnFieldsGqlStringify(fields) + '}){id}}';

        var graphQLQuery = new this.CoreoGraphQLQuery(query);
        return graphQLQuery.run().then(function (response) {
            if (response.errors && response.errors.length) {
                if (response.errors[0] && (!response.data || !response.data.id)) return false;
            }
            return true;
        });
    }

    recordLogsExport(projectId, surveys, operations) {
        var query = 'mutation{data: createRecordLogsExport(input:{projectId: ' + projectId + ',' + (surveys ? `surveys : ${this.CoreoAPI.gqlStringify(surveys)}` : '') + (operations ? `operations : ${this.CoreoAPI.gqlStringify(operations)}` : '') + '}){id}}';
        return this.CoreoAPI.mutation(query).then(function () {
            return true;
        }, function () {
            return false;
        });
    }

    geoJSONExport(projectId, where, showDeleted = false) {
        var query = 'mutation($where: SequelizeJSON){data: createGeoJSONExport(input:{projectId: ' + projectId + ',' + (showDeleted ? "showDeleted:true," : '') + ' where: $where}){id}}';
        return this.CoreoAPI.mutation(query, null, {
            variables: {
                where: where
            }
        }).then(function () {
            return true;
        }, function () {
            return false;
        });
    }

    shapefileExport(projectId, where, showDeleted = false) {
        var query = 'mutation($where: SequelizeJSON){data: createShapefileExport(input:{projectId: ' + projectId + ',' + (showDeleted ? "showDeleted:true," : '') + ' where: $where}){id}}';
        return this.CoreoAPI.mutation(query, null, {
            variables: {
                where: where
            }
        }).then(function () {
            return true;
        }, function () {
            return false;
        });
    }

    adminCloneProject(projectId, organisationId, ownerId, locked, usersLimit, name) {
        var query = 'mutation($projectId: Int!, $organisationId: Int!, $ownerId: Int!, $locked: Boolean!, $usersLimit: Int!, $name: String){data: adminCloneProject(input:{projectId: $projectId, organisationId: $organisationId, ownerId: $ownerId, locked: $locked, usersLimit: $usersLimit, name: $name}){id}}';
        return this.CoreoAPI.mutation(query, null, {
            variables: {
                projectId,
                organisationId,
                ownerId,
                locked,
                usersLimit,
                name
            }
        }).then(function () {
            return true;
        }, function () {
            return false;
        });
    }

    userCloneProject(projectId, name) {
        var query = 'mutation($projectId: Int!, $name: String){data: userCloneProject(input:{projectId: $projectId, name: $name}){id}}';
        return this.CoreoAPI.mutation(query, null, {
            variables: {
                projectId,
                name
            }
        }).then(function () {
            return true;
        }, function () {
            return false;
        });
    }

    mergeProject(projectId, targetId) {
        var query = 'mutation($projectId: Int!, $targetId: Int!){data: mergeProject(input:{projectId: $projectId, targetId: $targetId}){id}}';
        return this.CoreoAPI.mutation(query, null, {
            variables: {
                projectId,
                targetId
            }
        }).then(function () {
            return true;
        }, function () {
            return false;
        });
    }

    findAvailableSubdomain(subdomain) {
        // Find existing projects with subdomains matching the provided
        var query = '{data: projects(where: { subdomain: { like: "' + subdomain + '%"}}){subdomain}}';
        var graphQLQuery = new this.CoreoGraphQLQuery(query);
        return graphQLQuery.run().then(function (response) {
            // Strip project data down to a list of subdomains
            var existing = _.map(response.data, 'subdomain');
            var newSubdomain = subdomain;
            var count = 1;

            // Check if subdomain is in use; append incrementing counter if not, and repeat.
            while (true) {
                if (existing.indexOf(newSubdomain) === -1) {
                    break;
                }
                newSubdomain = subdomain + count;
                count++;
            }

            return newSubdomain;
        });
    }

    getProjectBySubdomain(subdomain) {
        var query = '{data: projects(where:{subdomain:"' + subdomain + '"}){id, name, shortName, subdomain, colors, css,font}}';
        var graphQLQuery = new this.CoreoGraphQLQuery(query);
        return graphQLQuery.run().then(function (response) {
            return (response.data.length > 0 ? response.data[0] : null);
        });
    }
}
