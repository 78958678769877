import { getProjectStates, getRecordsView, getProjectForms, getRecordsSelectedCount, getProjectRole, getRecordsTotal, getRecordsFormId, getProjectId, getRecordsFilterDirty, getRecordsFormHasGeometry, getProjectAttributesHasGeometry } from '../../../store/selectors';
import "./project-records-toolbar.component.scss";

export const ProjectRecordsToolbarComponent = {
    template: require('./project-records-toolbar.component.html'),
    controllerAs: 'ctrl',
    bindings: {
        onImport: '&',
        onExport: '&',
        onUpdate: '&',
        onDelete: '&'
    },
    controller: class ProjectRecordsToolbarComponent {
        /* @ngInject */
        constructor($ngRedux, $scope, $timeout, PROJECT_RECORD_VIEWS, $mdDialog, ProjectsService, $rootScope, RecordsActions, ProjectService, ProjectActions) {
            this.views = PROJECT_RECORD_VIEWS;
            this.$mdDialog = $mdDialog;
            this.ProjectService = ProjectService;
            this.ProjectsService = ProjectsService;
            this.$rootScope = $rootScope;

            const state = $ngRedux.getState();
            this.surveys = getProjectForms(state).slice(0);
            this.surveys.sort((a, b) => a.sort - b.sort);

            this.states = getProjectStates(state);
            this.surveyMap = this.surveys.reduce((acc, survey) => {
                acc[survey.id] = survey.name;
                return acc;
            }, {});

            // If form no longer exists, change to the first available form
            const formId = getRecordsFormId(state);

            if (formId !== null && typeof this.surveys.find(s => s.id === formId) === 'undefined') {
                $ngRedux.dispatch(RecordsActions.updateForm(null))
            }

            $scope.$on('$destroy', $ngRedux.connect((state) => {
                const viewId = getRecordsView(state);
                const total = getRecordsTotal(state);
                const selectedCount = getRecordsSelectedCount(state);
                const view = PROJECT_RECORD_VIEWS.find(v => v.id === viewId);
                const formId = getRecordsFormId(state);
                const form = this.surveys.find(s => s.id === formId);

                return {
                    view,
                    total,
                    selectedCount,
                    totalRecords: total,
                    role: getProjectRole(state),
                    filtering: getRecordsFilterDirty(state),
                    formId,
                    form,
                    formHasGeometry: getRecordsFormHasGeometry(state),
                    projectHasGeometry: getProjectAttributesHasGeometry(state),
                };
            }, RecordsActions)(this));

            this.recordsCount = 0;

            this.exportFormats = [{
                label: 'CSV',
                key: 'csv',
                hasDialog: false
            }, {
                label: 'GeoJSON',
                key: 'geojson',
                hasDialog: false,
                disabled: !this.formHasGeometry,
            }, {
                label: 'Shapefile',
                key: 'shapefile',
                hasDialog: false,
                disabled: !this.formHasGeometry,
            }];

            $scope.$on('$records:refresh', () => {
                // Update export formats disabled property when records reload
                this.exportFormats = this.exportFormats.map((format) => {
                    let disabled;
                    if (typeof format.disabled === 'boolean') {
                        disabled = !this.formHasGeometry;
                    }

                    return {
                        ...format,
                        disabled,
                    };
                });
            });

            // For 'other' menu, including exports that can;;t be done per record
            this.otherExportFormats = [
                //     {
                //     label: 'NBN Atlas / Darwin Core Format',
                //     key: 'nbn',
                //     hasDialog: true
                // },
                {
                    label: 'Record operation logs',
                    key: 'logs',
                    hasDialog: true
                }];

            this.exportDialogControllers = {
                nbn: this.nbnDialogController,
                logs: this.logsDialogController
            };

            this.exportDialogTemplates = {
                nbn: require('../project-records-export/dialog-templates/nbn.tmpl.html'),
                logs: require('../project-records-export/dialog-templates/logs.tmpl.html')
            };
        }

        openMenu($mdMenu, ev) {
            ev.preventDefault();
            ev.stopPropagation();

            $mdMenu.open(ev);
        }

        changeView(event, view) {
            event.preventDefault();
            event.stopPropagation();
            this.updateView(view.id);
        }

        setForm(menu, id) {
            menu.close();
            this.updateForm(id);
        }

        openExportDialog(ev, type) {
            const template = this.exportDialogTemplates[type];
            this.$mdDialog.show({
                controller: this.exportDialogControllers[type],
                template,
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                resolve: {
                    surveys: () => {
                        return this.surveys
                    },
                    projectId: () => {
                        return this.ProjectService.getProjectId();
                    }
                }
            })
                .then((answer) => {
                    switch (type) {
                        case 'nbn': {
                            var rawExport = answer;
                            //export object contains objects rather than IDs
                            var flattenedExport = {
                                projectId: rawExport.projectId,
                                surveyId: rawExport.survey.id,
                                metadata: rawExport.metadata,
                                fields: [],
                                from: rawExport.from,
                                to: rawExport.to
                            };


                            var rawFields = rawExport.defaultFields.concat(rawExport.newFields);

                            for (var i = 0; i < rawFields.length; i++) {
                                var isCollectionAttributeField = rawFields[i].source.collection;
                                if (rawFields[i].attribute) {
                                    rawFields[i].attribute = rawFields[i].attribute.path;
                                }

                                //clean fields of potential unnecessary properties
                                switch (rawFields[i].source.source) {
                                    case 'CONSTANT': {
                                        rawFields[i] = {
                                            value: rawFields[i].value,
                                            term: rawFields[i].term,
                                            source: rawFields[i].source.source
                                        };
                                        break;
                                    }
                                    case 'RECORD': {
                                        var collectionAttribute = null;
                                        if (isCollectionAttributeField) {
                                            collectionAttribute = rawFields[i].collectionAttribute;
                                        }
                                        rawFields[i] = {
                                            term: rawFields[i].term,
                                            source: rawFields[i].source.source,
                                            attribute: rawFields[i].attribute
                                        };
                                        if (collectionAttribute) {
                                            rawFields[i].collectionAttribute = collectionAttribute;
                                        }
                                        break;
                                    }
                                }

                                flattenedExport.fields.push(rawFields[i]);
                            }

                            this.ProjectsService.nbnExport(flattenedExport).then((exp) => {
                                this.$rootScope.$broadcast('jobs:refresh');
                            });
                            break;
                        }
                        case 'logs': {
                            const { surveys, operations, projectId } = answer;

                            this.ProjectsService.recordLogsExport(projectId, surveys, operations).then((exp) => {
                                this.$rootScope.$broadcast('jobs:refresh');
                            });
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }, function () {
                    //cancelled
                });
        }

        /* @ngInject */
        logsDialogController($scope, $mdDialog, surveys, projectId) {
            $scope.surveys = angular.copy(surveys).map(s => Object.assign(s, { selected: false }));
            const operations = ['update', 'delete', 'revert', 'restore']
            $scope.operations = operations.map(o => ({ name: o, selected: true }));

            $scope.answer = {
                operations: null,
                surveys: null,
                projectId
            }

            $scope.isExportValid = function () {
                if ($scope.surveys.filter(s => s.selected).length === 0 || $scope.surveys.filter(s => s.selected).length === 0) {
                    return false;
                }
                return true;
            };


            $scope.ok = function () {
                $scope.answer.operations = $scope.operations.filter(o => o.selected).map(o => o.name);
                $scope.answer.surveys = $scope.surveys.filter(s => s.selected).map(s => s.id);
                $mdDialog.hide($scope.answer);
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };
        }


        /* @ngInject */
        nbnDialogController($scope, $mdDialog, surveys, projectId) {
            $scope.type = null;

            $scope.selectType = function (type) {
                $scope.type = type;
            };

            $scope.advanced = false;
            $scope.type = '';
            $scope.surveyCount = 0;
            $scope.exportUsers = false;
            $scope.from = null;
            $scope.to = null;

            $scope.surveys = surveys;
            $scope.projectId = projectId;
            $scope.sources = [
                {
                    source: 'RECORD',
                    label: 'Collection attribute',
                    collection: true
                },
                {
                    source: 'RECORD',
                    label: 'Attribute',
                    collection: false
                },
                {
                    source: 'CONSTANT',
                    label: 'Constant',
                    collection: false
                },
            ];


            $scope.dateOptions = {
                maxDate: new Date(),
                format: 'dd/MM/yyyy'
            };

            $scope.licenses = ['CC0', 'CC-BY', 'CC-BY-NC', 'OGL'];

            $scope.nbnExport = {
                projectId: $scope.projectId,
                survey: null,
                newFields: [],
                from: null,
                to: null,
                defaultFields: [
                    {
                        term: 'taxonID',
                    },
                    {
                        term: 'scientificName',
                    },
                    {
                        term: 'institutionCode',
                    }
                ]
            };

            $scope.addNewField = function () {
                $scope.nbnExport.newFields.push({});
            };

            $scope.toggleAdvanced = function () {
                $scope.advanced = !$scope.advanced;
            };

            $scope.validateField = function (field) {
                if (!field.source || !field.term) {
                    return false;
                }
                switch (field.source.source) {
                    case 'CONSTANT': {
                        if (field.value) {
                            return true;
                        } else {
                            return false;
                        }
                        break;
                    }
                    case 'RECORD': {
                        if (field.attribute && field.source.collection && field.collectionAttribute) {
                            return true;
                        } else if (field.attribute && !field.source.collection) {
                            return true;
                        } else {
                            return false;
                        }
                        break;
                    }
                    default:
                        return false;
                }
            };

            $scope.isExportValid = function () {
                if (!$scope.nbnExport.survey || !$scope.nbnExport.metadata || !$scope.nbnExport.metadata.title || !$scope.nbnExport.metadata.emailAddress || !$scope.nbnExport.metadata.abstract || !$scope.nbnExport.metadata.license || !$scope.nbnExport.metadata.organizationName) {
                    return false;
                }

                var fields = $scope.nbnExport.defaultFields.concat($scope.nbnExport.newFields);
                for (var i = 0; i < fields.length; i++) {
                    if (!$scope.validateField(fields[i])) {
                        return false;
                    }
                }
                return true;
            };

            $scope.ok = function () {
                $mdDialog.hide($scope.nbnExport);
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };
            $scope.selectType = function (type) {
                $scope.type = type;
            };
            $scope.updateSurveyCount = function () {
                $scope.surveyCount = _.filter($scope.surveys, { selected: true }).length;
            };
        }



    }
}
