const MAX_AUTOCOMPLETE_USERS = 300;

export class TransferOrganisationOwnershipController {
    /* @ngInject */
    constructor($mdDialog, CoreoAPI, organisation, toastr) {
        this.$mdDialog = $mdDialog;
        this.CoreoAPI = CoreoAPI;
        this.organisation = organisation;
        this.toastr = toastr;

        this.selectedUser = null;
    }

    updateUser() {
        console.log(this.selectedUser);
    }

    getUsers(search) {
        var query = `query CoreoAASearchUsers{
            users(where :{ 
                or: [
                    {displayName: { iLike: "%${search}%"}},
                    {username: { iLike: "%${search}%"}}
                ]
            },  limit: ${MAX_AUTOCOMPLETE_USERS}) {
                displayName
                username
                id
            }
        }`;
        return this.CoreoAPI.query(query).then(result => {
            return result.users;
        });
    }

    ok() {
        const mutation = `mutation TransferOrganisationOwnership($organisationId: Int!, $userId: Int!){
            result: transferOrganisationOwnership(input: { userId: $userId, organisationId: $organisationId }){
                role
            }
        }`;

        this.CoreoAPI.mutation(mutation, null, {
            variables: {
                organisationId: this.organisation.id,
                userId: this.selectedUser.id
            }
        }).then(() => {
            this.toastr.success('Organisation ownership transferred');
        }, err => {
            this.toastr.error(err.message);
        })
        
    };
    cancel() {
        this.$mdDialog.cancel();
    };
}