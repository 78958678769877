import angular from 'angular';

/* @ngInject */
export default class ProjectsController {
  constructor($timeout, $state, $uibModal, $mdDialog, $ngRedux, $scope, ProjectsActions, ProjectsService, Auth) {

    this.$timeout = $timeout;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$mdDialog = $mdDialog;
    this.ProjectsService = ProjectsService;
    this.Auth = Auth;
    this.search = '';

    $scope.$on('$destroy', $ngRedux.connect(state => {
      return {
        projects: state.projects.projects || []
      }
    }, ProjectsActions)(this));
  }

  slugify(text) {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    const to = "aaaaaeeeeeiiiiooooouuuunc------"

    const newText = text.split('').map(
      (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

    return newText
      .toString()                     // Cast to string
      .toLowerCase()                  // Convert the string to lowercase letters
      .trim()                         // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/&/g, '-y-')           // Replace & with 'and'
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-');        // Replace multiple - with single -
  }

  create() {
    return this.newProject();
  }

  newProject(ev) {
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      template: require('./new-modal.html'),
      /* @ngInject */
      controller: function ($scope, $mdDialog) {


        $scope.ok = function () {
          $mdDialog.hide({
            name: $scope.name,
            description: $scope.description
          });
        };

        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      }
    }).then(({ name, description }) => {
      const slug = this.slugify(name);
      this.ProjectsService.findAvailableSubdomain(slug).then(subdomain => {
        if (subdomain !== slug) {
          console.log("Subdomain '" + slug + "' was not available, falling back to '" + subdomain + "'");
        }

        var project = {
          name,
          shortName: name,
          subdomain: subdomain,
          description
        };

        this.createProject(project).then((project) => {
          this.$state.go('project.dashboard', { id: project.slug });
        });
      });

    }, angular.noop);
  }

  import() {

    function deIdData(data) {
      return _.map(data, function (d) {
        delete d.id;
        return d;
      });
    }

    var modal = $uibModal.open({
      template: require('./import-project-modal.html'),
      /* @ngInject */
      controller: function ($scope, $uibModalInstance) {
        $scope.ok = function () {
          $uibModalInstance.close($scope.import);
        };

        $scope.readFile = function (file) {
          var reader = new FileReader();
          reader.onload = function (e) {
            $timeout(function () {
              try {
                $scope.import = JSON.parse(reader.result);
                delete $scope.import.slug;
                delete $scope.import.name;
                delete $scope.import.id;

                $scope.import.recordTypes = deIdData($scope.import.recordTypes);
                $scope.import.collections = deIdData($scope.import.collections);
                $scope.import.properties = deIdData($scope.import.properties);
                _.each($scope.import.collections, function (collection) {
                  collection.items = deIdData(collection.items);
                });
                console.log($scope.import);
              } catch (e) {
                $scope.importError = e;
              }
            });
          };
          reader.readAsText(file);
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });

    modal.result.then(function (data) {
      ProjectsService.create(data).then(function (project) {
        $state.go('project.dashboard', {
          id: project.id
        });
      });
    }, angular.noop);
  }
}
