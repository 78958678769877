/* @ngInject */
export default class AdminRequestsService {
    constructor($http, FileSaver, CoreoAPI, CoreoGraphQLQuery, Auth) {
        this.$http = $http;
        this.FileSaver = FileSaver;
        this.CoreoAPI = CoreoAPI;
        this.CoreoGraphQLQuery = CoreoGraphQLQuery;
        this.Auth = Auth;
    }

    getRequests(params) {

        var query = '{data: requestLogs(order: "<%= order %>", where:  <%= where %>,limit: ' + params.limit + ',offset: ' + params.offset + '){ count, logs{ id, path, method, responseStatus, requestedAt, timeTaken, user { displayName }, project { id, name }}}}';
        var graphQLQuery = new this.CoreoGraphQLQuery(query);

        if (params.order && _.keys(params.order).length > 0) {
            var orderColumn = _.keys(params.order)[0];
            graphQLQuery.sort(orderColumn);
            graphQLQuery.sortDir(params.order[orderColumn]);
        } else {
            graphQLQuery.sort('requestedAt');
            graphQLQuery.sortDir('desc');
        }

        _.each(params.where, function (value, key) {
            if (key === 'path' || key === 'id') {
                graphQLQuery.filter(key, {
                    value: value,
                    op: 'iLike'
                });
            } else if (value) {
                graphQLQuery.filter(key, value);
            }
        });



        return graphQLQuery.run().then(function (response) {
            return response.data;
        });
    }

    getById(id) {
        var query = '{data: requestLog(id: \"' + id + '\") {id, path, method, responseStatus, timeTaken, origin, query, body, requestedAt, responseCode, browser, browserVersion, os, deviceType, appVersion, appBuild, user { id, displayName }, project { id, name, slug }}}';
        var graphQLQuery = new this.CoreoGraphQLQuery(query);

        return graphQLQuery.run().then(function (response) {
            return response.data;
        });
    }
}
