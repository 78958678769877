import { getRecordsReloadRequired, getRecordsState, getProjectId } from "../selectors";

/* @ngInject */
export const recordsMiddleware = (localStorageService) => {
    return store => next => action => {

        if (['RECORDS_LOAD_SUCCESS', 'RECORDS_UPDATE_LAYERS'].includes(action.type)) {
            const state = store.getState();
            const recordsState = getRecordsState(state);
            const projectId = getProjectId(state);

            const { order, pagination, view, filter, layers } = recordsState;
            const { customBoundary, ...restOfFilter } = filter;
            localStorageService.set('COREO_RECORDS_STATE', JSON.stringify({
                order,
                pagination,
                view,
                filter: restOfFilter,
                layers: action.layers || layers,
                projectId
            }));
        }
        return next(action);
    }
}

/* @ngInject */
export const recordsRefreshMiddleware = ($rootScope) => {
    const types = [
        'RECORDS_UPDATE_FILTER',
        'RECORDS_UPDATE_FORM',
        'RECORDS_UPDATE_SHOW_DELETED',
        'RECORDS_UPDATE_SELECTED_STATE_SUCCESS',
        'RECORDS_RESET_FILTER',
        'RECORDS_UPDATE_ATTRIBUTE_FILTER',
        'RECORDS_CLEAR_SELECTION',
    ];

    return store => next => action => {
        const result = next(action);
        if (getRecordsReloadRequired(store.getState()) && types.includes(action.type)) {
            $rootScope.$broadcast('$records:refresh');
        }
        return result;
    }
}