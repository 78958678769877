/* @ngInject */
export default class ProjectUserController {
    constructor(project, projectUser, CoreoAPI, toastr) {
        this.projectUser = projectUser;
        this.user = projectUser.user;
        this.CoreoAPI = CoreoAPI;
        this.project = project;
        this.toastr = toastr;

        if (['google', 'apple', 'facebook'].includes(this.user.provider)) {
            this.providerIcon = 'fab fa-' + this.user.provider;
        } else {
            this.providerIcon = 'fas fa-email';
        }
        this.passwordResetSent = false;
    }

    verifyAccount() {

        const query = `mutation VerifyProjectUser($userId: Int!, $projectId: Int!){
            verifyUserAccount(input:{ userId: $userId, projectId: $projectId})
        }`;
        return this.CoreoAPI.mutation(query, {}, {
            variables: {
                userId: this.projectUser.userId,
                projectId: this.project.id
            }
        }).then(() => {
            this.user.verified = true;
            this.toastr.success('Member account verified');
        })
    }

    passwordReset() {

        const query = `mutation SendPasswordReset($email: String!, $projectId: Int!){
            passwordForgot(input:{ email: $email, projectId: $projectId, legacy: false})
        }`;
        return this.CoreoAPI.mutation(query, {}, {
            variables: {
                email: this.user.email,
                projectId: this.project.id
            }
        }).then(() => {
            this.passwordResetSent = true;
            this.toastr.success('Password reset sent');
        });
    }
}