import { GetInTouchModalController } from './get-in-touch-modal/get-in-touch.modal.controller';

export const openGetInTouchModal = ($mdDialog, ev) => {
    $mdDialog.show({
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        template: require('./get-in-touch-modal/get-in-touch.modal.html'),
        controllerAs: 'ctrl',
        controller: GetInTouchModalController
    })
}