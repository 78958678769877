import './item-value.component.scss';
export const ItemValueComponent = {
    bindings: {
        itemKeys: '<',
        collectionId: '<'
    },
    template: require('./item-value.component.html'),
    controllerAs: 'ctrl',
    controller: class ItemValueComponent {
        /* @ngInject */
        constructor($scope, ItemValueService) {
            this.ItemValueService = ItemValueService;
            this.value = 'loading...'
        }

        $onInit() {
            if (this.itemKeys) {
                if (typeof this.itemKeys === 'string') {
                    this.itemKeys = this.itemKeys.split(',')
                }
                this.ItemValueService.getItemValues(this.itemKeys, this.collectionId).then(values => {
                    this.value = values.join(', ');
                })
            } else {
                this.value = '';
            }
        }
    }

};
