import * as Sentry from '@sentry/browser';
/* @ngInject */
export default class AdminUserSettingsController {
  constructor(user, AdminUsersService, toastr) {
    this.user = user;
    this.AdminUsersService = AdminUsersService;
    this.toastr = toastr;


  }

  save() {
    this.AdminUsersService.update(this.user).then(() => {
      this.toastr.success('User updated');
    }, (err) => {
        this.toastr.error(err && err.data && err.data.msg || 'An error occured');
        Sentry.captureException(err);
    });
  }
}
