import { getAuthOAuthError, getAuthOAuthErrorDescription, getAuthIsLoggingIn, getAuthIsLoggedIn, getAuthLoginError } from "../../store/selectors";
import "./login.scss";

/* @ngInject */
export default class LoginController {
  constructor($state, $stateParams, $ngRedux, $scope, Auth, AuthActions) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.Auth = Auth;
    this.isLoggingIn = false;
    this.passwordType = 'password';

    $scope.$on('$destroy', $ngRedux.connect((state) => {
      return {
        isLoggedIn: getAuthIsLoggedIn(state),
        isLoggingIn: getAuthIsLoggingIn(state),
        loginError: getAuthLoginError(state),
        oauthError: getAuthOAuthError(state),
        oauthErrorDescription: getAuthOAuthErrorDescription(state)
      }
    }, AuthActions)(this));

    this.user = {
    };
  }

  togglePasswordType() {
    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
  }

  doLogin(type, credentials) {
    this.isLoggingIn = true;
    let promise = this.login(type, credentials);

    if (type !== 'local') {
      return;
    }

    promise.then(() => {
      if (this.$stateParams.redirect) {
        this.$state.go(this.$stateParams.redirect.state, this.$stateParams.redirect.params);
      } else {
        this.$state.go('home');
      }
    }, angular.noop);
  }

}
