import "./organisation-dashboard.scss";
import noIcon from '../../assets/icons/no-icon.png';
import orgAvatar from '../../assets/images/office.svg';
import userAvatar from '../../assets/images/user.svg';
import { getOrganisation, getOrganisationMemberships, getOrganisationRole } from "../../store/selectors";

/* @ngInject */
export default class OrganisationDashboardController {

    constructor($scope, $ngRedux, $mdDialog, $state, ProjectsActions) {
        $scope.$on('$destroy', $ngRedux.connect((state) => ({
            organisation: getOrganisation(state),
            organisationRole: getOrganisationRole(state),
            memberships: getOrganisationMemberships(state)
        }))(this));
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.noIcon = noIcon;
        this.userAvatar = userAvatar;
        this.orgAvatar = orgAvatar;
        this.ProjectsActions = ProjectsActions;
        this.$mdDialog = $mdDialog;
        this.orgClass = `org-${this.organisationRole}`;
    }

    memberTracker(member) {
        return member.userId || member.pendingEmail;
    }

    createProject(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('../../projects/new-modal.html'),
            controllerAs: 'ctrl',
            controller: class OrganisationCreateProjectController {
                /* @ngInject */
                constructor($mdDialog) {
                    this.$mdDialog = $mdDialog;
                    this.project = {};
                }

                ok() {
                    this.$mdDialog.hide(this.project);
                };

                cancel() {
                    this.$mdDialog.cancel();
                };
            }
        }).then(({ name, description }) => {
            const project = {
                name,
                shortName: name,
                description,
                organisationId: this.organisation.id
            };

            const action = this.ProjectsActions.createProject(project);
            this.$ngRedux.dispatch(action).then(project => {
                this.$state.go('project.dashboard', { orgSlug: this.organisation.slug, id: project.slug });
            });

        }, angular.noop);
    }
}