export default class GeoJSON {
    /* @ngInject */
    constructor($q) {
        this.$q = $q;
    }

    findJsonBlock(string, state) {
        if (!state) {
            var startPos = string.indexOf("{");
            state = {
                start: startPos,
                pos: startPos + 1,
                depth: 0,
                inString: false
            };
        }

        for (var i = state.pos; i < string.length; i++) {
            if (string[i] === '}' && !state.inString) {
                // Found a closing bracket outside a string
                if (state.depth === 0) {
                    return {
                        success: true,
                        block: JSON.parse(string.substring(state.start, i + 1))
                    };
                } else {
                    state.depth--;
                }
            }
            if (string[i] === '"' && string[i - 1] !== '\\') {
                // Found an unescaped double quote
                state.inString = !state.inString;
            }
            if (string[i] === '{' && !state.inString) {
                // Found an opening bracket outside a string
                state.depth++;
            }
            state.pos = i;
        }

        // Haven't found the end yet. Report failure and return current state.
        return {
            success: false,
            state: state
        };
    }

    readGeoJSONFile(file) {

        var deferred = this.$q.defer();

        var currentSegment = 0;
        var segmentSize = 10000;  // Size of file segments to load
        var maxSegments = 100;    // Maximum number of segments to load

        // Cap segments at file size
        maxSegments = Math.min(maxSegments, Math.ceil(file.size / segmentSize));

        var loadedString = '';
        var state = null;
        var foundFeatures = false;

        var reader = new FileReader();
        var vm = this;
        reader.onloadend = function (event) {

            loadedString += event.target.result;
            var segmentStart = currentSegment * segmentSize;

            if (!foundFeatures) {
                // Find start of 'features' array
                var featureStart = loadedString.indexOf('"features"');
                if (featureStart !== -1) {
                    foundFeatures = true;
                    loadedString = loadedString.substring(featureStart);
                } else {
                    currentSegment++;

                    if (currentSegment < maxSegments) {
                        reader.readAsText(file.slice(segmentStart, segmentStart + segmentSize));
                    } else {
                        // Exceeded limit
                        deferred.reject();
                        // $scope.badFile = true;
                        // $scope.$apply();
                    }

                    return;
                }
            }

            if (!state) {
                // Haven't yet found a property block
                var start = loadedString.indexOf('"properties"');
                if (start !== -1) {
                    // Reset loaded string to start at located 'properties'
                    loadedString = loadedString.substring(start);
                } else {
                    currentSegment++;

                    if (currentSegment < maxSegments) {
                        reader.readAsText(file.slice(segmentStart, segmentStart + segmentSize));
                    } else {
                        // Exceeded limit
                        deferred.reject();

                        // $scope.badFile = true;
                        // $scope.$apply();
                    }

                    return;
                }
            }

            var result = vm.findJsonBlock(loadedString, state);
            if (result.success) {
                deferred.resolve({
                    geoJsonProperties: result.block,
                    geoJsonPaths: Object.keys(result.block)
                });
            } else {
                currentSegment++;

                if (currentSegment < maxSegments) {
                    reader.readAsText(file.slice(segmentStart, segmentStart + segmentSize));
                } else {
                    deferred.reject();
                    // Exceeded limit
                    // $scope.badFile = true;
                    // $scope.$apply();
                }
                return;
            }
        };

        // Read subset of file to extract property list
        reader.readAsText(file.slice(0, segmentSize));
        return deferred.promise;
    }
}