/* @ngInject */
export default class ProjectFormCreateController {
    constructor($uibModalInstance) {
        this.$uibModalInstance = $uibModalInstance;
    }

    ok() {
        this.$uibModalInstance.close({
            name: this.name,
            title: this.title
        });
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }
}