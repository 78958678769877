import { TabulatorFull as Tabulator } from 'tabulator-tables';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import './record-associates.component.scss';

export const RecordAssociatesComponent = {
    // template: require('./record-associates.component.html'),
    template: '<div></div>',
    bindings: {
        record: '<',
        onLoad: '&'
    },
    controllerAs: 'ctrl',
    controller: class RecordAssociatesComponent {
        /* @ngInject */
        constructor($element, $state, ProjectService, RecordsService, Tabulator) {
            this.ProjectService = ProjectService;
            this.data = [];
            this.$element = $element;
            this.$state = $state;
            this.RecordsService = RecordsService;
            this.Tabulator = Tabulator;
        }

        $onInit() {
            this.table = this.Tabulator.createTable(this.$element[0], {
                data: this.data,
                columns: [
                    { title: "Name", field: "name" },
                    { title: "Association", field: "association" },
                    { title: "User", field: "user" },
                    {
                        title: "Date", field: "createdAt", formatter: (cell) => {
                            return format(parseISO(cell.getValue()), 'dd/MM/yyyy HH:mm:ss a')
                        }
                    }
                ],
                layout: "fitDataFill",
                paginationSize: 10,
                pagination: "local"
            });

            this.table.on('rowClick', (e, row) => {
                const rowData = row.getData();
                this.$state.go('project.record', { record_id: rowData.id });
            });
        }

        $onChanges() {
            this.RecordsService.getRecordAssociates(this.record.id).then(data => {
                this.data = data;
                if (this.table) {
                    this.table.setData(data);
                }
                this.onLoad({ data });
            });
        }
    }
};

export default RecordAssociatesComponent;
