import './project-dashboard.scss';
// import Chart from 'chart.js';
import noIcon from '../../assets/icons/no-icon.png';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { getProject, getProjectStats, getAuthRoleForProject, getOrganisationRole, getAuthUserPreferences, getProjectLocked } from '../../store/selectors';
import { PROJECT_DASHBOARD_TUTORIAL, USER_PREFERENCE_VERSIONS, USER_PREFERENCES } from '../../helpers/user-preferences';

/* @ngInject */
export default class ProjectDashboardController {

    constructor($window, $state, $scope, $ngRedux, docsUrl, CoreoAPI, ProjectActions, $rootScope, $timeout) {

        this.$window = $window;
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.CoreoAPI = CoreoAPI;
        this.recordsLoaded = true;
        this.recordActivityLimit = 31;
        this.isLoading = false;
        this.noIcon = noIcon;
        this.isDestroyed = false;
        this.tutorialKey = PROJECT_DASHBOARD_TUTORIAL;

        const state = $ngRedux.getState();
        this.project = getProject(state);
        this.projectRole = getAuthRoleForProject(this.project.id)(state);
        this.organisationRole = getOrganisationRole(state);
        this.projectLocked = getProjectLocked(state);
        this.adminView = (this.projectRole === 'admin' || this.organisationRole === 'admin' || this.organisationRole === 'owner') && !this.project.bespoke;

        this.taskId = null;

        $scope.$on('$destroy', $ngRedux.connect((state) => {
            const stats = getProjectStats(state);
            const userPreferences = getAuthUserPreferences(state);

            if (!stats) {
                return { userPreferences };
            }

            const { usersCount, recordsCount } = stats;

            return {
                recordsCount,
                usersCount,
                userPreferences,
            };
        }, ProjectActions)(this));

        $scope.$on('$destroy', () => {
            this.isDestroyed = true;
            if (this.chart) {
                this.chart.destroy();
            }
        });



        this.recordsPeriods = [{
            label: 'Today', value: 0, interval: 1, intervalUnit: 'HOUR', format: 'HH:mm'
        }, {
            label: 'Week', value: 7, interval: 1, intervalUnit: 'DAY', format: 'iii'
        }, {
            label: 'Month', value: 31, interval: 1, intervalUnit: 'DAY', format: 'dd MMM'
        }, {
            label: 'Year', value: 365, interval: 1, intervalUnit: 'WEEK', format: 'dd MMM'
        }, {
            label: 'All', value: null, interval: 1, intervalUnit: 'WEEK', format: 'dd/MM/yy'
        }];


        this.chartOptions = {
            type: 'line',
            data: {
                labels: [],
                datasets: [{
                    label: 'Records',
                    fill: true,
                    borderColor: '#5FABFF',
                    backgroundColor: '#5FABFF',
                    borderWidth: 3,
                    pointRadius: 3,
                    data: []
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    text: 'Latest Records'
                },
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        scaleLabel: {
                            display: false,
                            labelString: 'Date',
                        },
                        ticks: {
                            autoSkip: true,
                            autoSkipPadding: 5
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true,
                            maxTicksLimit: 5,
                            stepSize: 1
                        }
                    }]
                }
            }
        };

        this.tasks = [];
        if (!this.project.bespoke && this.adminView && !this.project.locked) {
            if (this.project.forms.length === 0) {
                this.tasks.push({
                    id: 'form',
                    title: 'Add a form',
                    description: 'Get started collecting data by creating a form',
                    docsLink: `${docsUrl}/docs/how-to/creating-a-form`,
                    actionText: 'Create a form',
                    actionState: 'project.forms'
                });
                this.taskId = 'form';
            }

            if (!this.project.imageUrl) {
                this.tasks.push({
                    id: 'icon',
                    title: 'Change project icon',
                    description: 'Personalise your app with your own icon',
                    docsLink: docsUrl,
                    actionText: 'Update icon',
                    actionState: 'project.styling'
                });
            }
        }

        this.loadProjectStats(this.project.id);
        this.initChart();
    }

    $onInit() {
        const tutorialUserPref = this.userPreferences[USER_PREFERENCES[this.tutorialKey]];
        if (!this.userPreferences || !tutorialUserPref || tutorialUserPref !== USER_PREFERENCE_VERSIONS[this.tutorialKey]) {
            this.$timeout(() => this.$rootScope.$broadcast('$tutorial:restart'), 200);
        }
    }

    initChart() {
        this.queryRecords(this.recordsPeriods[1]).then(result => {
            var ctx = document.getElementById('records');
            this.chartOptions.data.labels = result.labels;
            this.chartOptions.data.datasets[0].data = result.data;

            import('chart.js').then(({ default: chartModule }) => {
                if (!this.isDestroyed) {
                    this.chart = new chartModule.Chart(ctx, this.chartOptions);
                }
            });
        });
    }

    loadRecords(period) {

        const { value, interval, intervalUnit } = period;

        const to = new Date().toISOString();
        const variables = {
            projectId: this.project.id,
            to,
            interval,
            intervalUnit
        };

        if (value !== null) {
            const date = new Date();
            date.setDate(date.getDate() - value);
            variables.from = date.toISOString().substring(0, 10);
        }

        const query = `query CoreoAdminProjectDashboardStats($projectId: Int!, $from: String, $to: String!, $interval: Int!, $intervalUnit: RecordsTimeGroupedInterval!){
      data: project(id: $projectId){
        recordsTimeGrouped(from: $from, to: $to, interval: $interval, intervalUnit: $intervalUnit){
          date,
          count
        }
      }
    }`;

        return this.CoreoAPI.query(query, { variables }).then((result) => {
            const data = result.data.recordsTimeGrouped;

            return _.reduce(data, function (acc, d) {
                acc.labels.push(format(parseISO(d.date), period.format))
                acc.data.push(d.count);
                return acc;
            }, { labels: [], data: [] });
        });
    };

    queryRecords(period) {
        if (this.isLoading) {
            return;
        }

        this.recordsLoaded = false;
        this.isLoading = true;

        this.recordActivityLimit = period.value;

        return this.loadRecords(period).then((result) => {
            this.recordsLoaded = true;
            this.isLoading = false;
            return result;
        });
    }

    updateRecords(period) {
        if (this.isLoading) {
            return;
        }

        this.queryRecords(period).then((result) => {
            this.chartOptions.data.labels = result.labels;
            this.chartOptions.data.datasets[0].data = result.data;
            this.chart.update();
        });
    };

    toggleTask(taskId) {
        if (this.taskId === taskId) {
            this.taskId = null;
        } else {
            this.taskId = taskId;
        }
    }

    dismissTask($event, taskId) {
        $event.preventDefault();
        $event.stopPropagation();
        this.tasks = this.tasks.filter(t => t.id !== taskId);
    }

}
