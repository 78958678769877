import { getProjectId } from '../../../store/selectors';

export default class ItemValueService {
  /* @ngInject */
  constructor($q, $timeout, $ngRedux, CoreoAPI) {
    this.CoreoAPI = CoreoAPI;
    this.$q = $q;
    this.$timeout = $timeout;
    $ngRedux.connect((state) => ({
      projectId: getProjectId(state)
    }), null)(this);

    this.cache = {};
    this.batch = {};
    this.timer = null;
  }

  getItemValues(keys, collectionId) {
    let missingKeys = [];
    const promises = [];

    // Initialise this collection, if we haven't already
    this.cache[collectionId] = this.cache[collectionId] || {};

    for (const key of keys) {
      if (typeof this.cache[collectionId] === 'undefined' || typeof this.cache[collectionId][key] === 'undefined') {
        // We need to look this key up
        missingKeys.push(key);
      } else {
        // We already have a promise for this so just use that
        promises.push(this.cache[collectionId][key]);
      }
    }

    // If no missing keys, just return now
    if (missingKeys.length === 0) {
      return this.$q.all(promises);
    }

    // Generate a query for the missing keys
    // const query = this.getItems(missingKeys, collectionId);
    const query = this.createBatch(collectionId, keys);

    for (const m of missingKeys) {
      // Cache a promise that resolves with the value
      this.cache[collectionId][m] = query.then(result => result[m] || '');
      // And push that onto this query's promises
      promises.push(this.cache[collectionId][m]);
    }

    if (!this.timer) {
      this.timer = this.$timeout(() => this.runBatch(), 100).then(() => this.timer = null);
    }
    return this.$q.all(promises);
  }

  createBatch(collectionId, keys) {
    if (typeof this.batch[collectionId] !== 'undefined') {
      this.batch[collectionId].keys = this.batch[collectionId].keys.concat(keys);
      return this.batch[collectionId].deferred.promise;
    }

    const deferred = this.$q.defer();
    this.batch[collectionId] = {
      keys,
      deferred
    };
    return deferred.promise;
  }

  runBatch() {
    for (const collectionId in this.batch) {
      const keys = this.batch[collectionId].keys;
      const deferred = this.batch[collectionId].deferred;
      delete this.batch[collectionId];
      this.getItems(keys, collectionId).then(deferred.resolve);
    }
  }

  getItems(keys, collectionId) {

    const query = `query get($keys: [String!], $projectId: Int!, $collectionId: Int!){
      project(id: $projectId) {
        collections(where: { id: $collectionId }){
          items(where: { key: { in: $keys }}){
            key,
            value
          }
        }
      }
    }`;
    return this.CoreoAPI.query(query, {
      variables: {
        collectionId,
        keys,
        projectId: this.projectId
      }
    }).then(result => {
      if (!result && result.project && result.project.collections && result.project.collections[0] && result.project.collections[0].items) {
        return {};
      }
      return result.project.collections[0].items.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});
    });
  }
}
