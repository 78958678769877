import './admin-request.scss';

/* @ngInject */
export default class RequestController {
    constructor(request) {
        this.request = request;

        this.browserIconMap = {
            'chrome': 'fab fa-4x fa-chrome',
            'chromium': 'fab fa-4x fa-chrome',
            'opera': 'fab fa-4x fa-opera',
            'firefox': 'fab fa-4x fa-firefox',
            'edge': 'fab fa-4x fa-edge',
            'safari': 'fab fa-4x fa-safari',
            'internet explorer': 'fab fa-4x fa-internet-explorer'
        };
        this.osIconMap = {
            'macos': 'fab fa-4x fa-apple',
            'windows': 'fab fa-4x fa-windows',
            'android': 'fab fa-4x fa-android',
            'os x': 'fab fa-4x fa-apple',
            'linux': 'fab fa-4x fa-linux'
        };
        var browser = request.browser && request.browser.toLowerCase();
        var os = request.os && request.os.toLowerCase();
        if (os === 'linux' && request.deviceType.toLowerCase() === 'mobile') {
            os = 'android';
            request.os = 'Android';
        }
        this.browserClass = this.getIcon(this.browserIconMap, browser);
        this.osClass = this.getIcon(this.osIconMap, os);

        this.requestProps = [
            { label: 'App Build', value: request.appBuild },
            { label: 'App Version', value: request.appVersion },
            { label: 'Browser', value: request.browser },
            { label: 'Browser Version', value: request.browserVersion },
            { label: 'Method', value: request.method },
            { label: 'Origin', value: request.origin },
            { label: 'OS', value: request.os },
            { label: 'Path', value: request.path },
            { label: 'Query', value: request.query },
            { label: 'Requested At', value: request.requestedAt },
            { label: 'Response Code', value: request.responseCode },
            { label: 'Response Status', value: request.responseStatus },
            { label: 'Time Taken', value: request.timeTaken + 'ms' },
        ];
    }

    isSubstring(string, substring) {
        return string && string.indexOf(substring) !== -1;
    }

    getIcon(map, name) {
        var icon = 'fas fa-question fa-4x';
        _.find(map, (mapIcon, key) => {
            if (this.isSubstring(name, key)) {
                icon = mapIcon;
                return true;
            } else {
                return false;
            }
        });
        return icon;
    }





}
