import angular from 'angular';
import './project-record.scss';
import { getProjectRole, getProjectVerification, getRecordIsEditing, getRecordDraft, getRecord, getProjectGeometry, getProjectCollections, getAuthUser, getProjectRoleMatches } from '../../store/selectors';

/* @ngInject */
export default class RecordController {
    constructor($state, $uibModal, $scope, $ngRedux, toastr, project, RecordsService, ProjectService, CoreoAPI, RecordsActions, RecordActions, init, ProjectActions) {
        this.$state = $state;
        if (init === 'err') {
            return this.$state.go('project.records');
        }

        const state = $ngRedux.getState();

        this.$uibModal = $uibModal;
        this.toastr = toastr;
        this.RecordsService = RecordsService;
        this.CoreoAPI = CoreoAPI;
        this.$ngRedux = $ngRedux;
        this.RecordsActions = RecordsActions;
        this.RecordActions = RecordActions;
        this.ProjectActions = ProjectActions;
        this.project = project;
        this.record = getRecord(state);
        this.verification = getProjectVerification(state);
        this.editableGeometry = angular.copy(this.record.geometry);
        this.editablePoint = {
            lat: this.record.lat,
            lng: this.record.lng
        };
        this.states = project.states;
        this.state = project.states && _.find(project.states, { stateId: this.record.state });
        this.verified = this.state && this.state.verified;
        this.pending = this.state && this.state.default;
        this.survey = ProjectService.getSurvey(this.record.surveyId);

        this.hasPoint = this.record.geometry && this.record.geometry.type === "Point";
        // this.editingAttributes = false;
        this.editingPoint = false;
        this.storedPointCopy = this.record.geometry;
        this.title = '';
        this.title = this.record.title || 'Record ' + record.id;
        this.mediaCount = 0;
        this.user = angular.extend({ imageUrl: '' }, this.record.user || {});

        this.expressionCollectionsLoaded = false;
        this.saving = false;

        this.role = getProjectRole(state);
        this.collections = getProjectCollections(state);
        this.projectBounds = getProjectGeometry(state);

        // Determine if we can edit this record
        // We can edit if any of 
        // 1. We are a project admin
        // 2. We are a project moderator
        // 3. It is our own record
        // 4. The survey has allowMemberUpdate set to true
        const authUser = getAuthUser(state);

        this.canEdit = getProjectRoleMatches(['admin', 'moderator'])(state) ||
            (this.record.userId === authUser.id) ||
            (this.survey.allowMemberUpdate);

        $scope.$on('$destroy', $ngRedux.connect((state) => {
            return {
                record: getRecordIsEditing(state) ? getRecordDraft(state) : getRecord(state),
                editingAttributes: getRecordIsEditing(state)
            };
        }, ProjectActions)(this));

    }

    $onInit() {
        import(/* webpackChunkName: "coreo-expressions" */'@natural-apptitude/coreo-expressions/dist/esm').then(pkg => {
            const ExpressionEvaluator = pkg.CoreoExpressionEvaluator;
            this.expressionEvaluator = new ExpressionEvaluator(this.project.attributes.map(a => ({ ...a })), this.project.collections, this.user);
        })
    }

    startEdit() {
        let preProcessedRecord = angular.copy(this.record);
        let surveyAttributes = this.project.attributes.filter(a => a.surveyId === this.record.surveyId);

        for (const attribute of surveyAttributes.filter(a => !!a.path)) {
            if ( // remove empty string from any question
                typeof this.record.data[attribute.path] === 'string' &&
                this.record.data[attribute.path].length === 0
            ) {
                preProcessedRecord.data[attribute.path] = null;
            } else if ( // remove empty string key from multiselect question arrays
                (attribute.type === 'multiselect')
            ) {
                const value = preProcessedRecord.data[attribute.path];
                preProcessedRecord.data[attribute.path] = value ? value.filter(k => !!k) : [];
            }
        }

        const hasExpressionAttribute = surveyAttributes.some(a => a.questionType === 'expression');
        if (hasExpressionAttribute && !this.expressionCollectionsLoaded) {
            // list of this surveys and child surveys, for which we need collection items from
            const connectedSurveyIds = this.project.attributes.filter(a => this.record.surveyId && a.associatedSurveyId).map(a => a.associatedSurveyId);
            connectedSurveyIds.push(this.record.surveyId) //include the record's survey too
            const requiredCollectionIds = this.project.attributes.filter(a => connectedSurveyIds.includes(a.surveyId) && a.collectionId).map(a => a.collectionId)
            const promises = [];
            for (const collectionId of requiredCollectionIds) {
                promises.push(this.$ngRedux.dispatch(this.ProjectActions.loadCollectionItems(collectionId, true)))
   
            }
            Promise.all(promises).then(() => {
                this.expressionEvaluator.setCollections(this.collections);
                this.expressionCollectionsLoaded = true;
                this.$ngRedux.dispatch(this.RecordActions.recordEdit(preProcessedRecord));
            })
        } else {
        this.$ngRedux.dispatch(this.RecordActions.recordEdit(preProcessedRecord));
        }
    };

    cancelEdit() {
        this.$ngRedux.dispatch(this.RecordActions.recordEditDiscard());
    };

    toggleEdit() {
        if (this.editingAttributes) {
            this.saveEdit();
        } else {
            this.startEdit();
        }
    }

    saveEdit() {
        this.$ngRedux.dispatch(this.RecordActions.recordEditSave());
    };

    toggleEditPoint() {
        if (this.editingPoint) {
            this.saveEditPoint();
        } else {
            this.startEditPoint();
        }
    }

    startEditPoint() {
        this.editablePoint = {
            lng: this.record.lng,
            lat: this.record.lat,
        };
        this.editingPoint = true;
    }

    saveEditPoint() {
        this.editingPoint = false;
        const data = {
            type: "Point",
            coordinates: [this.editablePoint.lng, this.editablePoint.lat]
        }
        return this.$ngRedux.dispatch(this.RecordActions.updateGeometryAndReload(data));
    }

    cancelEditPoint() {
        this.editingPoint = false;
        const record = getRecord(this.$ngRedux.getState());
        this.editablePoint = {
            lng: record.geometry.coordinates[0],
            lat: record.geometry.coordinates[1]
        };
    }

    pointInputChange() {
        this.updateGeometry({
            type: "Point",
            coordinates: [this.editablePoint.lng, this.editablePoint.lat]
        })
    }

    updateGeometry(geometry) {
        this.editableGeometry = {
            ...geometry
        };

        if (this.hasPoint) {
            this.editablePoint = {
                lng: geometry.coordinates[0],
                lat: geometry.coordinates[1]
            };
        }
    }

    saveGeometry() {
        return this.$ngRedux.dispatch(this.RecordActions.updateGeometryAndReload(this.editableGeometry));
    };

    updateState(state) {
        return this.$ngRedux.dispatch(this.RecordActions.recordUpdateState(state));
    };

    openMenu($mdMenu, ev) {
        // originatorEv = ev;
        $mdMenu.open(ev);
    };

    onAssociatesLoad(data) {
        this.associatesCount = data && data.length || 0;
    }

    onMediaLoad(count) {
        this.mediaCount = count;
    }

    handleDataChange(data) {
        this.$ngRedux.dispatch(this.RecordActions.recordUpdateDraftData(data));
    }

    handleMediaChange(attribute, file, attachment, url) {
        if (!file) {
            this.$ngRedux.dispatch(this.RecordActions.recordDeleteDraftAttachment(attribute, attachment));
        } else {
            this.$ngRedux.dispatch(this.RecordActions.recordAddDraftAttachment(attribute, file, url));
        }
    }

    handleAssociationChange(record, attribute) {
        if (!record) {
            this.$ngRedux.dispatch(this.RecordActions.recordUpdateDraftAssociation(null, attribute));
        } else {
            this.$ngRedux.dispatch(this.RecordActions.recordUpdateDraftAssociation(record, attribute));
        }
    }

    deleteRecord() {
        var modal = this.$uibModal.open({
            template: require('./delete-record-modal.html'),
            /* @ngInject */
            controller: function ($scope, $uibModalInstance) {
                $scope.ok = function () {
                    $uibModalInstance.close();
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
            }
        });

        modal.result.then(() => {
            this.$ngRedux.dispatch(this.RecordActions.recordDelete())
        }, angular.noop);
    };

    undeleteRecord() {
        var modal = this.$uibModal.open({
            template: require('./undelete-record-modal.html'),
            /* @ngInject */
            controller: function ($scope, $uibModalInstance) {
                $scope.ok = function () {
                    $uibModalInstance.close();
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
            }
        });

        modal.result.then(() => {
            return this.$ngRedux.dispatch(this.RecordActions.recordRestore());
        }, angular.noop);
    };
}
