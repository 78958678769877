import './project-records-filter-item.component.scss';
import { getProjectForms, getProjectAttributes } from '../../../store/selectors';

export const ProjectRecordsFilterItemComponent = {
    template: require('./project-records-filter-item.component.html'),
    bindings: {
        forms: '<',
        attributes: '<',
        collections: '<',
        id: '<',
        formId: '<',
        attributeId: '<',
        operatorId: '<',
        value: '<',
        valid: '<',
        onUpdate: '&',
        onDelete: '&'
    },
    controllerAs: 'ctrl',
    controller: class ProjectRecordsFilterItemComponent {
        /* @ngInject */
        constructor($element) {
            this.$element = $element;
            this.searchTerm = '';
        }

        $onInit() {
        }

        $onChanges(changes) {
            if (this.valid) {
                this.$element.addClass('valid');
            } else {
                this.$element.removeClass('valid');
            }

            console.log('CHANGES', changes, this);
            if (changes.formId && changes.formId.currentValue !== null) {

            }

            if (changes.attributeId && changes.attributeId.isFirstChange()) {
                this.setAttribute(changes.attributeId.currentValue);
            }

            if (changes.operatorId && changes.operatorId.isFirstChange()) {
                const operator = this.operators.find(o => o.id === this.operatorId);
                this.setOperator(operator);
            }
            console.log('HERE', this.operatorId, this.operatorInputType, this.operatorInputMultiple);
        }

        updateForm() {
            this.attributeId = this.attribute = this.operatorInputType = this.operatorId = null;
            this.setOperator(null);
            this.update();
        }

        getOperators(attribute) {
            switch (attribute.type) {
                case 'number': {
                    return [];
                }
                case 'select': {
                    return [
                        { id: 'eq', label: 'Is', input: 'select' },
                        { id: 'ne', label: 'Is not', input: 'select' },
                        { id: 'in', label: 'One of', input: 'select', multiple: true },
                        { id: 'notIn', label: 'Not one of', input: 'select', multiple: true },
                        { id: 'is:null', label: 'Is blank' },
                        { id: 'not:null', label: 'Is not blank' }
                    ];
                }
                case 'text': {
                    return [
                        { id: 'substring', label: 'Contains', input: 'text' },
                        { id: 'eq', label: 'Is', input: 'text' },
                        { id: 'is:null', label: 'Is blank' },
                        { id: 'not:null', label: 'Is not blank' },
                        { id: 'startsWith', label: 'Starts with', input: 'text' },
                        { id: 'endsWith', label: 'Ends with', input: 'text' },
                        { id: 'ne', label: 'Does not equal', input: 'text' }
                    ];
                }
                case 'integer':
                case 'float': {
                    return [
                        { id: 'eq', label: 'Equals', input: 'number' },
                        { id: 'gt', label: 'Greater than', input: 'number' },
                        { id: 'gte', label: 'Greater than or equal to', input: 'number' },
                        { id: 'lt', label: 'Less than', input: 'number' },
                        { id: 'lte', label: 'Less than or equal to', input: 'number' },
                        { id: 'is:null', label: 'Is blank' },
                        { id: 'not:null', label: 'Is not blank' }
                    ];
                }
                case 'media': {
                    return [
                        { id: 'not:null', label: 'Is not blank' },
                        { id: 'is:null', label: 'Is blank' }
                    ];
                }
                case 'boolean': {
                    return [
                        { id: 'is:true', label: 'True' },
                        { id: 'is:false', label: 'False' },
                        { id: 'is:null', label: 'Is blank' },
                        { id: 'not:null', label: 'Is not blank' }
                    ];
                }
                case 'date':
                case 'datetime': {
                    return [
                        { id: 'lt', label: 'Before', input: 'date' },
                        { id: 'gt', label: 'After', input: 'date' },
                        { id: 'is:null', label: 'Is blank' },
                        { id: 'not:null', label: 'Is not blank' }
                    ];
                }
                default: {
                    console.warn('Unknown type when building operators', attribute);
                    return [];
                }
            }
        }

        setAttribute(attributeId) {
            if (attributeId === null) {
                this.attribute = null;
                this.operators = [];
                return;
            }

            this.attribute = this.attributes[this.formId].find(a => a.id === attributeId);
            this.operators = this.getOperators(this.attribute);
        }

        updateAttribute() {
            this.setAttribute(this.attributeId);
            this.setOperator(this.operators[0]);
            delete this.value;
            this.update();
        }

        updateOperator() {
            const operator = this.operators.find(o => o.id === this.operatorId);
            this.setOperator(operator);
            this.update();
        }

        setOperator(operator) {
            if (!operator) {
                this.operatorId = null;
                this.operatorInputMultiple = false;
                this.operatorInputType = null;
                return;
            }

            this.operator = operator;
            this.operatorId = operator.id;
            this.operatorInputType = operator.input || null;
            this.operatorInputMultiple = operator.multiple || false;

            switch (this.operatorInputType) {
                case 'select': {
                    const collectionId = this.attribute.collectionId;
                    const collection = this.collections.find(c => c.id === collectionId);
                    this.options = collection && collection.items || [];

                    break;
                }
            }
        }

        updateValue() {
            this.update();
        }

        selectedItemChanged(item) {
            this.value = item && item.key || null;
            this.update();
        }

        update() {
            this.onUpdate({
                filter: {
                    formId: this.formId,
                    attributeId: this.attributeId,
                    attributePath: this.attribute && this.attribute.path,
                    operatorId: this.operatorId,
                    value: this.value,
                    id: this.id
                },
            });
        }

        delete() {
            this.onDelete({
                id: this.id
            });
        }
    }
};