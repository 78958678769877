import { getProjectMapLayers } from "../../store/selectors";

/* @ngInject */
export default class ProjectMapLayersController {


    constructor($scope, $ngRedux) {
        $scope.$on('$destroy', $ngRedux.connect((state) => ({
            layers: getProjectMapLayers(state)
        }), null)(this));
        console.log('HERE', this.layers);
    }
}