import './project-map-config.scss';
import { getProjectCollections, getProjectForms, getProject } from '../../store/selectors';
/* @ngInject */
export default class ProjectMapConfigController {
    constructor($ngRedux, $scope, $state, map, ProjectMapsService, MAP_BASE_STYLES, MAPBOX_STYLE_SPEC, mapsUrl) {
        this.map = map;
        this.ProjectMapsService = ProjectMapsService;
        this.$scope = $scope;
        this.layer = null;
        this.layerString = '';
        this.layerIndex = null;
        this.sourceOptions = [];
        this.mapsUrl = mapsUrl;
        this.settings = false;
        this.layerTypes = _.keys(MAPBOX_STYLE_SPEC);

        const state = $ngRedux.getState();
        this.collections = getProjectCollections(state);
        this.surveys = getProjectForms(state);
        this.project = getProject(state);

        this.images = map && map.style && map.style.metadata && map.style.metadata['coreo:images'] || [];
        this.imagesArr = _.reduce(this.images, function (acc, url, key) {
            acc.push({ key: key, url: url });
            return acc;
        }, []);

        this.editorOptions = {
            lineWrapping: true,
            lineNumbers: false,
            mode: {
                name: 'javascript',
                json: true
            }
        };

        this.baseMapOptions = MAP_BASE_STYLES;
    }

    handleMap(mapHandle) {
        this.mapHandle = mapHandle;
    };

    newLayer() {
        var newLayer = {
            name: 'New Layer',
            projectMapId: this.map.id,
            type: 'symbol'
        };
        this.map.layers.push(newLayer);
        this.layer = newLayer;
    };

    updateMap() {

        this.ProjectMapsService.updateMap(this.map.id, {
            base: this.map.base,
            name: this.map.name,
            shared: this.map.shared,
            description: this.map.description
        }).then(() => this.reloadMapFromServer());
    };

    reloadMapFromServer() {
        return this.ProjectMapsService.getMap(this.project.id, this.map.id).then(map => this.map = map);
    };

    saveLayer() {
        var promise = this.layer.id ? this.ProjectMapsService.updateMapLayer(this.layer.id, this.layer)
            : this.ProjectMapsService.createMapLayer(this.layer);

        return promise.then((response) => {
            this.layer.id = response.id;
            var index = this.map.layers.indexOf(this.layer);
            if (index !== -1) {
                this.map.layers[index] = response;
            }
            this.reloadMapFromServer();
        });
    };

    deleteLayer() {
        if (!this.layer.id) {
            var index = this.map.layers.indexOf(this.layer);
            this.map.layers.splice(index, 1);
            this.layer = null;
            return;
        }

        this.ProjectMapsService.deleteMapLayer(this.layer.id).then(() => {
            this.layer = null;
            this.reloadMapFromServer();
        });
    };

    updateLayer() {
        var styleLayer = _.find(this.map.style.layers, (layer) => {
            return layer && layer.metadata && layer.metadata['coreo:layerId'] === this.layer.id;
        });
        if (typeof styleLayer !== 'undefined') {
            styleLayer.paint = this.layer.paint;
            styleLayer.layout = this.layer.layout;
            styleLayer.type = this.layer.type;
        }
        this.$scope.$broadcast('map:updated');
    };

    updateLayerOrder() {
        Promise.all(_.map(this.map.layers, (layer, index) => {
            return this.ProjectMapsService.updateMapLayer(layer.id, {
                sort: index
            });
        })).then(() => this.reloadMapFromServer());
    };

    clearLayer() {
        this.layer = null;
        this.layerIndex = null;
        this.layerString = null;
        this.sourceOptions = [];
    };

    selectLayer(layer, index) {
        if (this.layer === layer) {
            return this.clearLayer();
        }
        this.layer = layer;
        this.layerIndex = index;
        this.layerString = JSON.stringify(layer, null, 2);
        this.settings = false;
        this.updateSourceOptions();
    };

    updateSourceOptions() {
        this.sourceOptions = this.layer.sourceType === 'records' ? this.surveys : this.collections;
    };

    setDefaultPosition() {
        var zoom = this.mapHandle.getZoom();
        var center = this.mapHandle.getCenter();
        this.ProjectMapsService.updateMapPosition(this.map.id, zoom, center.lat, center.lng).then(() => {
            this.map.zoom = zoom;
            this.map.center = { coordinates: center.toArray() };
        });
    };

    toggleSettings() {
        this.settings = !this.settings;
        if (this.settings) {
            this.layer = null;
        }
    }
}