import format from 'date-fns/format';

export const ProjectRecordsChipsComponent = {
    template: '<md-chips md-on-remove="ctrl.handleRemove($index)" ng-model="ctrl.chips" readonly="true" md-removable="true"><md-chip-template><strong>{{$chip}}</strong></md-chip-template></md-chips>',
    controllerAs: 'ctrl',
    bindings: {
        filter: '<',
        users: '<',
        onRemove: '&'
    },

    controller: class ProjectRecordsChipsComponent {
        /* @ngInject */
        constructor($element, $scope, $timeout) {
            this.$element = $element;
            this.$scope = $scope;
            this.$timeout = $timeout;
        }
        $onInit() {
            this.chips = [];
            this.chipIds = [];
        }

        updateUserChip(userId) {
            if (!userId) {
                return;
            }

            var user = _.find(this.users, { id: userId });
            if (user) {
                this.chips.push(user.displayName);
                this.chipIds.push({ key: 'userId', value: null });
            }
        }

        updateSurveyChips(surveys) {
            if (_.every(surveys, { active: false })) {
                return;
            }
            var activeSurveys = _.filter(surveys, { active: true });
            this.chips = this.chips.concat(_.map(activeSurveys, 'name'));
            this.chipIds = this.chipIds.concat(_.map(activeSurveys, function (survey) {
                return {
                    key: 'survey',
                    value: survey.id
                };
            }));
        }

        updateStateChips(states) {
            if (_.every(states, { active: false })) {
                return;
            }
            var activeStates = _.filter(states, { active: true });
            this.chips = this.chips.concat(_.map(activeStates, 'name'));
            this.chipIds = this.chipIds.concat(_.map(activeStates, function (state) {
                return {
                    key: 'state',
                    value: state.stateId
                };
            }));
        }

        updateDateChips(label, key, date) {
            if (!date) {
                return;
            }
            this.chips.push(label + ': ' + format(date, 'DD/MM/YYYY'));
            this.chipIds.push({ key: key, value: null });
        }

        updateAttributeChips(attributes) {
            _.each(attributes, (attribute, path) => {
                if (!attribute.value) {
                    return;
                }

                switch (attribute.type) {
                    case 'geometryquery':
                    case 'select': {
                        _.each(attribute.value, (item) => {
                            this.chips.push(item.value);
                            this.chipIds.push({ key: 'attribute', value: item.key, path: attribute.path });
                        });
                        break;
                    }
                    case 'text': {
                        this.chips.push(attribute.value);
                        this.chipIds.push({ key: 'attribute', path: attribute.path });
                        break;
                    }
                    case 'media': {
                        this.chips.push(attribute.label);
                        this.chipIds.push({ key: 'attribute', path: attribute.path });
                        break;
                    }
                    default: {
                        console.warn('Do not know how to chip', attribute);
                    }
                }

            });
        }

        updateChips(filter) {
            this.chips = [];
            this.chipIds = [];

            this.updateUserChip(filter.userId);
            this.updateSurveyChips(filter.surveys);
            this.updateStateChips(filter.states);
            this.updateDateChips('From', 'from', filter.from);
            this.updateDateChips('To', 'to', filter.to);
            this.updateAttributeChips(filter.attributes);
        }


        $onChanges(changes) {
            if (changes && changes.filter && changes.filter.currentValue) {
                this.updateChips(changes.filter.currentValue);
            }
        }

        handleRemove(index) {
            this.onRemove({
                chip: this.chipIds[index]
            });
        }
    }
}