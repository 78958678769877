
const initialState = {
    loading: false,
    error: null,
    project: null,
    colorsInitialised: false,
};

const projectReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'LOAD_PROJECT': {
            return {
                ...state,
                loading: true,
                error: null
                // project: null
            };
        }

        case 'LOAD_PROJECT_SUCCESS': {
            const { project } = action;

            return {
                ...state,
                project,
                error: null,
                loading: false
            };
        }

        case 'LOAD_PROJECT_FAILURE': {
            const { error } = action;
            return {
                ...state,
                loading: false,
                project: null,
                error
            };
        }

        case 'LOAD_PROJECT_STATS_SUCCESS': {
            const { stats } = action;
            return {
                ...state,
                project: {
                    ...state.project,
                    stats
                }
            };
        }

        case 'UPDATE_PROJECT_SUCCESS': {
            const { project } = action;
            return {
                ...state,
                project: {
                    ...state.project,
                    ...project
                }
            };
        }

        case 'CREATE_FORM_SUCCESS': {
            const { form } = action;
            return {
                ...state,
                project: {
                    ...state.project,
                    forms: [
                        ...state.project.forms,
                        form
                    ]
                }
            };
        }

        case 'DELETE_FORM_SUCCESS': {
            const { id } = action;
            const idx = state.project.forms.findIndex(f => f.id === id);

            return {
                ...state,
                project: {
                    ...state.project,
                    forms: [
                        ...state.project.forms.slice(0, idx),
                        ...state.project.forms.slice(idx + 1)
                    ],
                    attributes: state.project.attributes.filter(a => a.surveyId !== id)
                }
            }
        }

        case 'UPDATE_FORMS_SUCCESS': {
            const { forms } = action;

            return {
                ...state,
                project: {
                    ...state.project,
                    forms: [
                        ...forms
                    ]
                }
            }
        }

        case 'UPDATE_FORM_SUCCESS': {
            const { form } = action;
            const idx = state.project.forms.findIndex(f => f.id === form.id);

            return {
                ...state,
                project: {
                    ...state.project,
                    forms: [
                        ...state.project.forms.slice(0, idx),
                        {
                            ...state.project.forms[idx],
                            ...form
                        },
                        ...state.project.forms.slice(idx + 1)
                    ]
                }
            };
        }

        case 'CREATE_COLLECTION_SUCCESS': {
            const { collection } = action;
            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...state.project.collections,
                        collection
                    ]
                }
            };
        }

        case 'UPDATE_COLLECTION_JOB_SUCCESS': {
            const { collection, newAttributes } = action;
            const idx = state.project.collections.findIndex(c => c.id === collection.id);

            if (idx === -1) {
                return state;
            }
            return {
                ...state,
                project: {
                    ...state.project,
                    attributes: [
                        ...state.project.attributes,
                        ...newAttributes
                    ],
                    collections: [
                        ...state.project.collections.slice(0, idx),
                        collection,
                        ...state.project.collections.slice(idx + 1)
                    ]
                }
            };
        }

        case 'UPDATE_COLLECTION_SUCCESS': {
            const { collection } = action;
            const idx = state.project.collections.findIndex(c => c.id === collection.id);

            if (idx === -1) {
                return state;
            }
            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...state.project.collections.slice(0, idx),
                        {
                            ...state.project.collections[idx],
                            ...collection
                        },
                        ...state.project.collections.slice(idx + 1)
                    ]
                }
            };
        }

        case 'CREATE_COLLECTION_ITEM_SUCCESS': {
            const { item } = action;
            const collectionIdx = state.project.collections.findIndex(c => c.id === item.collectionId);
            if (collectionIdx === -1) {
                return state;
            };
            const { collectionId, ...newItem } = item;

            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...state.project.collections.slice(0, collectionIdx),
                        {
                            ...state.project.collections[collectionIdx],
                            items: [
                                ...state.project.collections[collectionIdx].items,
                                {
                                    ...newItem,
                                    mediaItems: []
                                }
                            ],
                            itemCount: state.project.collections[collectionIdx].itemCount + 1
                        },
                        ...state.project.collections.slice(collectionIdx + 1)
                    ]
                }
            };
        }

        case 'UPDATE_COLLECTION_ITEM_SUCCESS': {
            const { collectionId, item } = action;
            const collectionIdx = state.project.collections.findIndex(c => c.id === collectionId);
            const itemIdx = state.project.collections[collectionIdx].items.findIndex(i => i.id === item.id);

            const collection = {
                ...state.project.collections[collectionIdx],
                items: [
                    ...state.project.collections[collectionIdx].items.slice(0, itemIdx),
                    {
                        ...state.project.collections[collectionIdx].items[itemIdx],
                        ...item
                    },
                    ...state.project.collections[collectionIdx].items.slice(itemIdx + 1),
                ]
            };

            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...state.project.collections.slice(0, collectionIdx),
                        collection,
                        ...state.project.collections.slice(collectionIdx + 1)
                    ]
                }
            };
        }

        case 'UPDATE_COLLECTION_ITEMS_SUCCESS': {
            const { id, items } = action;
            const sortIndex = items.reduce((acc, item) => ({
                ...acc,
                [item.id]: item.sort
            }), {});
            const collectionIdx = state.project.collections.findIndex(c => c.id === id);
            const collection = {
                ...state.project.collections[collectionIdx],
                items: state.project.collections[collectionIdx].items.map(item => ({
                    ...item,
                    sort: sortIndex.hasOwnProperty(item.id) ? sortIndex[item.id] : item.sort
                }))
            };

            if (collection.sortMode === 'manual') {
                collection.items.sort((a, b) => a.sort - b.sort);
            }

            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...state.project.collections.slice(0, collectionIdx),
                        collection,
                        ...state.project.collections.slice(collectionIdx + 1)
                    ]
                }
            };
        }

        case 'DELETE_COLLECTION_SUCCESS': {
            const { id } = action;
            const idx = state.project.collections.findIndex(c => c.id === id);
            if (idx === -1) {
                return state;
            }
            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...state.project.collections.slice(0, idx),
                        ...state.project.collections.slice(idx + 1)
                    ]
                }
            };
        }

        case 'DELETE_COLLECTION_ITEM_SUCCESS': {
            const { collectionId, itemId } = action;
            const collectionIdx = state.project.collections.findIndex(c => c.id === collectionId);
            const itemIdx = state.project.collections[collectionIdx].items.findIndex(i => i.id === itemId);
            const collection = {
                ...state.project.collections[collectionIdx],
                items: [
                    ...state.project.collections[collectionIdx].items.slice(0, itemIdx),
                    ...state.project.collections[collectionIdx].items.slice(itemIdx + 1),
                ],
                itemCount: state.project.collections[collectionIdx].itemCount - 1
            };

            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...state.project.collections.slice(0, collectionIdx),
                        collection,
                        ...state.project.collections.slice(collectionIdx + 1)
                    ]
                }
            };

        }

        case 'LOAD_COLLECTION':
        case 'REFRESH_COLLECTION': {
            const { collection } = action;
            const id = collection.id;

            const collectionIdx = state.project.collections.findIndex(c => c.id === id);

            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...state.project.collections.slice(0, collectionIdx),
                        collection,
                        ...state.project.collections.slice(collectionIdx + 1)
                    ]
                }
            }
        }

        case 'LOAD_COLLECTIONS_SUCCESS': {
            return {
                ...state,
                project: {
                    ...state.project,
                    collections: action.collections
                }
            };
        }

        case 'CREATE_ATTRIBUTE_SUCCESS': {
            // Existing attributes in the store loaded from the project
            // don't contain the projectId, so ensure it isn't stored with
            // one now.
            const { attribute: { projectId, ...newAttribute } } = action;
            return {
                ...state,
                project: {
                    ...state.project,
                    attributes: [
                        ...state.project.attributes,
                        newAttribute
                    ]
                }
            };
        }

        case 'UPDATE_ATTRIBUTE_SUCCESS': {
            const { attribute } = action;
            const idx = state.project.attributes.findIndex(a => a.id === attribute.id);
            if (idx === -1) {
                return state;
            }
            return {
                ...state,
                project: {
                    ...state.project,
                    attributes: [
                        ...state.project.attributes.slice(0, idx),
                        attribute,
                        ...state.project.attributes.slice(idx + 1)
                    ]
                }
            };
        }

        case 'DELETE_ATTRIBUTE_SUCCESS': {
            const { id } = action;
            const idx = state.project.attributes.findIndex(a => a.id === id);
            if (idx === -1) {
                return state;
            }
            return {
                ...state,
                project: {
                    ...state.project,
                    attributes: [
                        ...state.project.attributes.slice(0, idx),
                        ...state.project.attributes.slice(idx + 1)
                    ]
                }
            };
        }

        case 'FORM_SAVE_SUCCESS': {
            const { form: { id, attributes: formAttributes, ...restOfForm } } = action;
            const idx = state.project.forms.findIndex(f => f.id === id);

            if (idx === -1) {
                return state;
            }

            // The new attributes array will be all the existing attributes not associated to this
            // form, plus this forms attributes
            const attributes = [
                ...state.project.attributes.filter(a => a.surveyId !== id),
                ...formAttributes
            ];

            return {
                ...state,
                project: {
                    ...state.project,
                    attributes,
                    forms: [
                        ...state.project.forms.slice(0, idx),
                        {
                            ...state.project.forms[idx],
                            ...restOfForm
                        },
                        ...state.project.forms.slice(idx + 1)
                    ]
                }
            };
        }

        case 'PAGE_SAVE_SUCCESS': {
            const { page, mediaItems } = action;
            const idx = state.project.pages.findIndex(p => p.id === page.id);

            if (idx === -1) {
                return state;
            }

            return {
                ...state,
                project: {
                    ...state.project,
                    pages: [
                        ...state.project.pages.slice(0, idx),
                        {
                            ...page,
                            mediaItems
                        },
                        ...state.project.pages.slice(idx + 1)
                    ]
                }
            };
        }

        case 'PAGE_PUBLISH_SUCCESS': {
            const { id } = action;
            const idx = state.project.pages.findIndex(p => p.id === id);

            if (idx === -1) {
                return state;
            }

            return {
                ...state,
                project: {
                    ...state.project,
                    pages: [
                        ...state.project.pages.slice(0, idx),
                        {
                            ...state.project.pages[idx],
                            published: true
                        },
                        ...state.project.pages.slice(idx + 1)
                    ]
                }
            };
        }

        case 'PAGE_UNPUBLISH_SUCCESS': {
            const { id } = action;
            const idx = state.project.pages.findIndex(p => p.id === id);

            if (idx === -1) {
                return state;
            }

            return {
                ...state,
                project: {
                    ...state.project,
                    pages: [
                        ...state.project.pages.slice(0, idx),
                        {
                            ...state.project.pages[idx],
                            published: false
                        },
                        ...state.project.pages.slice(idx + 1)
                    ]
                }
            };
        }

        case 'REORDER_PROJECT_PAGES': {
            const { order } = action;
            const orderMap = order.reduce((acc, o) => {
                acc[o.id] = o.order;
                return acc;
            }, {});

            return {
                ...state,
                project: {
                    ...state.project,
                    pages: state.project.pages.map(page => ({
                        ...page,
                        order: orderMap[page.id]
                    }))
                }
            };
        }

        case 'CREATE_CREDENTIAL_SUCCESS': {
            const { credential } = action;
            return {
                ...state,
                project: {
                    ...state.project,
                    credentials: [
                        ...state.project.credentials,
                        credential
                    ]
                }
            };
        }

        case 'UPDATE_CREDENTIAL_SUCCESS': {
            const { credential } = action;
            const idx = state.project.credentials.findIndex(c => c.id === credential.id);
            return {
                ...state,
                project: {
                    ...state.project,
                    credentials: [
                        ...state.project.credentials.slice(0, idx),
                        credential,
                        ...state.project.credentials.slice(idx + 1)
                    ]
                }
            };
        }

        case 'CREATE_APIKEY_SUCCESS': {
            const { apikey } = action;
            return {
                ...state,
                project: {
                    ...state.project,
                    apiKeys: [
                        ...state.project.apiKeys,
                        apikey
                    ]
                }
            };
        }

        case 'DELETE_APIKEY_SUCCESS': {
            const { id } = action;
            const idx = state.project.apiKeys.findIndex(c => c.id === id);
            return {
                ...state,
                project: {
                    ...state.project,
                    apiKeys: [
                        ...state.project.apiKeys.slice(0, idx),
                        ...state.project.apiKeys.slice(idx + 1)
                    ]
                }
            };
        }

        case 'ADD_PROJECT_PAGE_SUCCESS': {
            const { page } = action;
            return {
                ...state,
                project: {
                    ...state.project,
                    pages: [
                        ...state.project.pages,
                        page
                    ]
                }

            }
        }

        case 'CREATE_PROJECT_RECORD_STATUS_SUCCESS': {
            const { recordStatus } = action;
            return {
                ...state,
                project: {
                    ...state.project,
                    states: [
                        ...state.project.states,
                        recordStatus
                    ]
                }

            }
        }

        case 'UPDATE_PROJECT_RECORD_STATUS_SUCCESS': {
            const { recordStatus } = action;
            const idx = state.project.states.findIndex(s => s.stateId === recordStatus.stateId);
            if (idx === -1) {
                return state;
            }
            const states = angular.copy(state.project.states);
            let newStates = [
                ...states.slice(0, idx),
                recordStatus,
                ...states.slice(idx + 1)
            ]

            if (recordStatus.default) {
                for (const status of newStates) {
                    status.default = recordStatus.stateId === status.stateId;
                }
            }

            const compare = (a, b) => {
                if (a.sort < b.sort) {
                    return -1;
                } else if (a.sort < b.sort) {
                    return 1;
                } else {
                    return 0;
                }
            }

            newStates = newStates.sort(compare);

            return {
                ...state,
                project: {
                    ...state.project,
                    states: newStates
                }
            };
        }

        case 'UPDATE_PROJECT_RECORD_STATUS_ORDER_SUCCESS': {
            const { states } = action;
            return {
                ...state,
                project: {
                    ...state.project,
                    states: states
                }
            };
        }

        case 'DELETE_PROJECT_RECORD_STATUS_SUCCESS': {
            const { stateId } = action;
            const idx = state.project.states.findIndex(s => s.stateId === stateId);
            return {
                ...state,
                project: {
                    ...state.project,
                    states: [
                        ...state.project.states.slice(0, idx),
                        ...state.project.states.slice(idx + 1)
                    ]
                }

            }
        }

        case 'DELETE_PROJECT_PAGE_SUCCESS': {
            const { id } = action;
            const pageIdx = state.project.pages.findIndex(p => p.id === id);
            return {
                ...state,
                project: {
                    ...state.project,
                    pages: [
                        ...state.project.pages.slice(0, pageIdx),
                        ...state.project.pages.slice(pageIdx + 1)
                    ],
                    welcomePageId: id === state.project.welcomePageId ? null : state.project.welcomePageId
                }

            }
        }

        case 'DELETE_COLLECTION_ITEM_MEDIA_SUCCESS': {
            const { project: { collections } } = state;
            const { collectionId, itemId, mediaItemId } = action;
            const collectionIdx = collections.findIndex(c => c.id === collectionId);
            const collection = collections[collectionIdx];
            const itemIdx = collection.items.findIndex(i => i.id == itemId);
            const item = collection.items[itemIdx];
            const mediaItemIdx = item.mediaItems.findIndex(i => i.id === mediaItemId);

            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...collections.slice(0, collectionIdx),
                        {
                            ...collection,
                            items: [
                                ...collection.items.slice(0, itemIdx),
                                {
                                    ...item,
                                    mediaItems: [
                                        ...item.mediaItems.slice(0, mediaItemIdx),
                                        ...item.mediaItems.slice(mediaItemIdx + 1)
                                    ]
                                },
                                ...collection.items.slice(itemIdx + 1)
                            ]
                        },
                        ...collections.slice(collectionIdx + 1)
                    ]
                }
            }
        }

        case 'UPDATE_COLLECTION_ITEM_MEDIA_SUCCESS': {
            const { project: { collections } } = state;
            const { collectionId, itemId, mediaItemId, mediaItemUpdate } = action;
            const collectionIdx = collections.findIndex(c => c.id === collectionId);
            const collection = collections[collectionIdx];
            const itemIdx = collection.items.findIndex(i => i.id == itemId);
            const item = collection.items[itemIdx];
            const mediaItemIdx = item.mediaItems.findIndex(i => i.id === mediaItemId);
            const mediaItem = item.mediaItems[mediaItemIdx];

            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...collections.slice(0, collectionIdx),
                        {
                            ...collection,
                            items: [
                                ...collection.items.slice(0, itemIdx),
                                {
                                    ...item,
                                    mediaItems: [
                                        ...item.mediaItems.slice(0, mediaItemIdx),
                                        {
                                            ...mediaItem,
                                            ...mediaItemUpdate
                                        },
                                        ...item.mediaItems.slice(mediaItemIdx + 1)
                                    ]
                                },
                                ...collection.items.slice(itemIdx + 1)
                            ]
                        },
                        ...collections.slice(collectionIdx + 1)
                    ]
                }
            }
        }

        case 'CREATE_COLLECTION_ITEM_MEDIA_SUCCESS': {
            const { project: { collections } } = state;
            const { collectionId, itemId, mediaItem } = action;
            const collectionIdx = collections.findIndex(c => c.id === collectionId);
            const collection = collections[collectionIdx];
            const itemIdx = collection.items.findIndex(i => i.id == itemId);
            const item = collection.items[itemIdx];
            const mediaItems = angular.copy(item.mediaItems);

            mediaItems.push(mediaItem);

            const compare = (a, b) => {
                if (a.sort < b.sort) {
                    return -1;
                } else if (a.sort < b.sort) {
                    return 1;
                } else {
                    return 0;
                }
            }

            const sortedMediaItems = mediaItems.sort(compare);
            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...collections.slice(0, collectionIdx),
                        {
                            ...collection,
                            items: [
                                ...collection.items.slice(0, itemIdx),
                                {
                                    ...item,
                                    mediaItems: sortedMediaItems
                                },
                                ...collection.items.slice(itemIdx + 1)
                            ]
                        },
                        ...collections.slice(collectionIdx + 1)
                    ]
                }
            }
        }

        case 'SORT_COLLECTION_ITEM_MEDIA_SUCCESS': {
            const { project: { collections } } = state;
            const { collectionId, itemId, orderMap } = action;
            const collectionIdx = collections.findIndex(c => c.id === collectionId);
            const collection = collections[collectionIdx];
            const itemIdx = collection.items.findIndex(i => i.id == itemId);
            const item = collection.items[itemIdx];
            const mediaItems = angular.copy(item.mediaItems);

            for (const mediaItem of mediaItems) {
                mediaItem.sort = orderMap[mediaItem.id];
            }

            const compare = (a, b) => {
                if (a.sort < b.sort) {
                    return -1;
                } else if (a.sort < b.sort) {
                    return 1;
                } else {
                    return 0;
                }
            }

            const sortedMediaItems = mediaItems.sort(compare);


            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...collections.slice(0, collectionIdx),
                        {
                            ...collection,
                            items: [
                                ...collection.items.slice(0, itemIdx),
                                {
                                    ...item,
                                    mediaItems: sortedMediaItems
                                },
                                ...collection.items.slice(itemIdx + 1)
                            ]
                        },
                        ...collections.slice(collectionIdx + 1)
                    ]
                }
            }
        }
        case "CREATE_PROJECT_HOOK_SUCCESS": {
            const { hook } = action;

            return {
                ...state,
                project: {
                    ...state.project,
                    hooks: [
                        ...state.project.hooks,
                        hook
                    ],
                },
            };
        }

        case "UPDATE_PROJECT_HOOK_SUCCESS": {
            const { hook } = action;
            const idx = state.project.hooks.findIndex(
                (h) => h.id === hook.id
            );
            return {
                ...state,
                project: {
                    ...state.project,
                    hooks: [
                        ...state.project.hooks.slice(0, idx),
                        hook,
                        ...state.project.hooks.slice(idx + 1),
                    ],
                },
            };
        }

        case "DELETE_PROJECT_HOOK_SUCCESS": {
            const { id } = action;
            const idx = state.project.hooks.findIndex(
                (h) => h.id === id
            );
            return {
                ...state,
                project: {
                    ...state.project,
                    hooks: [
                        ...state.project.hooks.slice(0, idx),
                        ...state.project.hooks.slice(idx + 1),
                    ],
                },
            };
        }

        case "CREATE_PROJECT_MAP_SUCCESS": {
            const { map } = action;

            return {
                ...state,
                project: {
                    ...state.project,
                    maps: [
                        ...state.project.maps,
                        map
                    ],
                }
            };
        }

        case "DELETE_PROJECT_MAP_SUCCESS": {
            const { id } = action;

            const idx = state.project.maps.findIndex(m => m.id === id);
            if (idx === -1) {
                return state;
            }

            return {
                ...state,
                project: {
                    ...state.project,
                    maps: [
                        ...state.project.maps.slice(0, idx),
                        ...state.project.maps.slice(idx + 1),
                    ],
                }
            };
        }

        case "LOAD_PROJECT_COLLECTION_ITEMS": {
            const { project: { collections } } = state;
            const { collectionId, items } = action;
            const collectionIdx = collections.findIndex(c => c.id === collectionId);
            const collection = collections[collectionIdx];

            return {
                ...state,
                project: {
                    ...state.project,
                    collections: [
                        ...state.project.collections.slice(0, collectionIdx),
                        {
                            ...collection,
                            items
                        },
                        ...state.project.collections.slice(collectionIdx + 1),
                    ]
                }
            };
        }

        case 'PROJECT_COLORS_INITIALISED':
            return {
                ...state,
                colorsInitialised: true,
            };

        default: {
            return state;
        }
    }
}

export default projectReducer;
