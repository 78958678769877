import './project-maps.scss';
import bbox from '@turf/bbox';

export const ProjectMapComponent = {
    template: '<div class="project-map"><ng-transclude></ng-transclude></div>',
    controllerAs: 'ctrl',
    transclude: true,
    bindings: {
        config: '<',
        onMap: '&',
        onClick: '&',
        filter: '<',
        bounds: '<'
    },
    controller: class ProjectMapComponent {
        /* @ngInject */
        constructor($element, $scope, $timeout, $q, CoreoAPI, Mapbox) {
            this.$element = $element;
            this.$scope = $scope;
            this.$timeout = $timeout;
            this.$q = $q;
            this.CoreoAPI = CoreoAPI;
            this.Mapbox = Mapbox;

            this.map = null;
            this.isPanning = false;
            this.layerIds = [];
            this.mapDefered = $q.defer();
            this.mapPromise = this.mapDefered.promise;
        }

        $onInit() {
            var $scope = this.$scope;

            $scope.$on('$records:clicked', (event, data) => {
                if (data.geometry) {
                    const bounds = bbox(data.geometry);
                    this.map.fitBounds(bounds, {
                        maxZoom: 14,
                        linear: true,
                        duration: 0
                    });
                }
            });

            $scope.$on('$records:sidebar', (event, sidebar) => {
                if (sidebar) {
                    if (!this.isPanning) {
                        this.map.panBy([-45, 0], {
                            duration: 300
                        });
                        this.isPanning = true;
                    }
                } else if (this.isPanning) {
                    this.map.panBy([45, 0], {
                        duration: 300
                    });
                    this.isPanning = false;
                }
            });

            $scope.$on('map:updated', () => {
                if (this.map && this.config && this.config.style) {
                    this.map.setStyle(this.config.style);
                }
            });

        }

        loadImage(id, url) {
            if (this.map.hasImage(id)) {
                return Promise.resolve();
            }

            return new Promise((resolve, reject) => {
                this.map.loadImage(url, (err, data) => {
                    if (err) {
                        return reject(err);
                    }
                    if (!this.map.hasImage(id)) {
                        try {
                            this.map.addImage(id, data);
                        } catch (e) {
                            console.warn(e);
                        }
                    }
                    resolve(data);
                });
            });
        }

        loadImages() {
            if (this.config.style.metadata && this.config.style.metadata['coreo:images']) {
                _.each(this.config.style.metadata['coreo:images'], (url, id) => this.loadImage(id, url));
            }
        }

        parseLayers() {
            this.layerIds = _.map(_.filter(this.config.style.layers, function (l) {
                return l.metadata && l.metadata['coreo:sourceId'];
            }), 'id');
        }

        createMap() {
            this.Mapbox.get().then(mapboxgl => {
                this.map = new mapboxgl.Map({
                    container: this.$element[0].querySelector('.project-map'),
                    style: this.config.style,
                    renderWorldCopies: false
                });

                this.loadImages();
                this.parseLayers();

                this.map.on('click', (e) => {
                    var features = this.map.queryRenderedFeatures(e.point, {
                        layers: this.layerIds
                    });
                    if (features && features.length > 0) {
                        this.$timeout(() => {
                            this.onClick({
                                data: features[0]
                            });
                        }, 0);
                    }
                });

                this.map.on('load', () => {
                    this.onMap({ map: this.map });
                    this.map.on('mousemove', (e) => {
                        const features = this.map.queryRenderedFeatures(e.point, { layers: this.layerIds });
                        this.map.getCanvas().style.cursor = features.length ? 'pointer' : '';
                    });
                });



                this.$timeout(() => {
                    if (this.bounds && this.bounds.length) {
                        this.map.fitBounds(this.bounds, {
                            padding: 40,
                            duration: 0
                        });
                    }
                    this.$element[0].querySelector('.project-map').classList.remove('loading');
                    this.map.resize();
                    this.mapDefered.resolve(this.map);

                }, 50);

            });
            return this.mapPromise;
        }

        updateMap() {
            this.map.setStyle(this.config.style);
            this.loadImages();
            this.parseLayers();
        }

        buildFilter() {
            var filter = ["all"];

            if (this.filter.userId) {
                filter.push(['==', 'userId', this.filter.userId]);
            }
            if (this.filter.from) {
                filter.push([">=", "createdAt", new Date(this.filter.from).toISOString()]);
            }
            if (this.filter.to) {
                const toDate = new Date(this.filter.to);
                toDate.setHours(23, 59, 59, 999);
                filter.push(["<=", "createdAt", toDate.toISOString()]);
            }
            if (this.filter.attributes) {
                _.each(this.filter.attributes, function (attribute) {
                    var key = attribute.path;
                    if (attribute.value && attribute.value.length > 0) {
                        filter.push(["in", key].concat(_.map(attribute.value, 'key')));
                    }
                });
            }
            if (this.filter.states) {
                var allStates = _.every(this.filter.states, { active: true }) || _.every(this.filter.states, { active: false });
                if (!allStates) {
                    filter.push(["in", "state"].concat(_.map(_.filter(this.filter.states, { active: true }), 'stateId')));
                }
            }
            if (this.filter.surveys) {
                var allSurveys = _.every(this.filter.surveys, { active: true }) || _.every(this.filter.surveys, { active: false });
                if (!allSurveys) {
                    filter.push(["in", "surveyId"].concat(_.map(_.filter(this.filter.surveys, { active: true }), 'id')));
                }
            }
            return filter.length === 1 ? null : filter;
        }

        updateVisibility() {
            var allVisible = _.every(this.filter.surveys, { active: false });
            _.each(this.filter.surveys, (survey) => {
                var layer = this.map.getLayer(survey.slug);
                if (layer) {
                    map.setLayoutProperty(survey.slug, 'visibility', allVisible || survey.active ? 'visible' : 'none');
                }
            });
        }

        applyFilter() {
            var filter = this.buildFilter();
            _.each(this.layerIds, (layerId) => {
                if (!layerId.startsWith('collection')) {
                    this.map.setFilter(layerId, filter);
                }
            });
        }

        $onChanges(changes) {
            if (this.map && changes.filter) {
                this.updateVisibility();
                this.applyFilter();
            }
            if (this.map && changes.bounds) {
                this.map.fitBounds(changes.bounds.currentValue, {
                    padding: 40,
                    duration: 0
                });
            }
            if (changes.config) {
                if (!this.map) {
                    this.createMap();
                } else {
                    this.updateMap();
                }
            }

        }
    }
}