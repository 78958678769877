/* @ngInject */
export default class OrganisationsService {

    constructor($http, CoreoAPI, CoreoGraphQLQuery) {
        this.CoreoAPI = CoreoAPI;
        this.$http = $http;
        this.CoreoGraphQLQuery = CoreoGraphQLQuery;
    }

    getUsers(id) {
        console.log('HERE?!', id);
    }

    createOrganisation(ownerId, name) {
        const data = {
            ownerId,
            name
        };
        var query = 'mutation{data: createOrganisation(input:' + this.CoreoAPI.gqlStringify(data) + '){ id, name }}';
        return new this.CoreoGraphQLQuery(query).run()
        .then(function (data) {
            return data.data;
        });
    }

}