import { getPermissions } from '../store/selectors';

/* @ngInject */
export default class PermissionsService {

    constructor($ngRedux) {
        this.permissions = {};
        $ngRedux.connect(getPermissions, null)(this.permissions);
    }

    checkAccess(permissions) {
        return permissions.some(p => this.permissions[p]);
    }
}