import "./organisation-invitation.scss";
import noIcon from '../../assets/icons/no-icon.png';
import { getAuthIsLoggedIn, getAuthUser, getAuthProvider } from "../../store/selectors";
import { strongPasswordRegex } from '../../store/auth/auth.actions';


/* @ngInject */
export default class OrganisationInvitationController {

    constructor($stateParams, $ngRedux, $state, $scope, OrganisationsActions, AuthActions, CoreoAPI) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.CoreoAPI = CoreoAPI;
        this.OrgActions = OrganisationsActions;
        this.AuthActions = AuthActions;
        this.error = false;
        this.noIcon = noIcon;
        this.image = noIcon;
        this.signingUp = true;
        this.emailMismatch = false;
        this.regex = strongPasswordRegex;
        // this.strongPasswordRegex = '/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z\d$@$!%*#?&]{8,}$/';
        this.regex = strongPasswordRegex;
        this.minPasswordLength = 8;
        this.passwordType = 'password';
        this.ready = false;

        this.signup = {
            token: this.$stateParams.token
        };
    }

    $onInit() {

        // If we are logged in, and the email matches the current user, then just go straight in
        const token = this.$stateParams.token;

        this.$ngRedux.dispatch(this.OrgActions.getOrganisationInvite(token)).then(result => {
            if (!result) {
                this.notFoundError = true;
                return;
            }
            this.email = result.email;
            this.organisation = result.organisation;
            this.image = this.organisation.imageUrl || this.image;
            this.signingUp = !result.exists;
            const initialState = this.$ngRedux.getState();

            if (getAuthIsLoggedIn(initialState)) {
                const user = getAuthUser(initialState);
                const provider = getAuthProvider(initialState);

                this.userEmail = user.email;
                if (this.userEmail === this.email && provider === 'local') {
                    const action = this.AuthActions.acceptOrganisationInvite(token);
                    return this.$ngRedux.dispatch(action).then(result => {
                        const { organisationUser, token } = result;
                        this.$state.go('intermediarypage.joined-organisation', {
                            isAdminAreaUser: organisationUser.user.isAdminAreaUser,
                            organisation: organisationUser.organisation,
                            role: organisationUser.role,
                            token
                        });
                    });
                } else {
                    this.emailMismatch = true;
                }
            }
            // If we haven't already accepted and navigated away, let's display the form
            this.ready = true;
        }, err => {
            console.warn('HER:<>QWE');
        });
    }

    togglePasswordType() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }

    accept() {
        let action;

        if (this.signingUp) {
            action = this.AuthActions.acceptOrganisationInviteSignup(this.signup.token, this.signup.password, this.signup.displayName);
        } else {
            action = this.AuthActions.acceptOrganisationInviteLogin(this.signup.token, this.email, this.signup.password);
        }

        this.$ngRedux.dispatch(action).then(result => {
            const { token } = result;
            if (this.signingUp) {
                this.$state.go('intermediarypage.joined-organisation-signup', {
                    isAdminAreaUser: result.user.isAdminAreaUser,
                    organisation: result.organisation,
                    token,
                });
            } else {
                this.$state.go('intermediarypage.joined-organisation', {
                    isAdminAreaUser: result.organisationUser.user.isAdminAreaUser,
                    organisation: result.organisationUser.organisation,
                    role: result.organisationUser.role,
                    token,
                });
            }
        }, err => {
            this.error = err.message;
        });
    }

    doSignIn() {
        this.signingUp = false;
        this.regex = null;
        this.minPasswordLength = 1;
    }

    doSignUp() {
        this.signingUp = true;
        this.regex = strongPasswordRegex;
        this.minPasswordLength = 8;
    }

    logout() {
        this.$ngRedux.dispatch(this.AuthActions.logout());
        this.emailMismatch = false;
    }
}