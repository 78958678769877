import './project-page-wysiwyg.scss';

import { getProjectPages, getProjectForms, getPageState } from '../../store/selectors';

import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
// import Quote from '@editorjs/quote';
// import Warning from '@editorjs/warning';
import Delimiter from '@editorjs/delimiter';
import ImageTool from '@editorjs/image';

/* @ngInject */
export default class ProjectPageWysiwygController {
    constructor($scope, $window, $ngRedux, ProjectPageActions, previewUrl) {
        const state = $ngRedux.getState();
        this.pages = getProjectPages(state);
        this.forms = getProjectForms(state).filter(f => !f.legacy);

        $window.addEventListener('message', e => {
            if (e.data === 'COREO_PAGE_PREVIEW_READY') {
                this.updatePreview();
            }
        });

        this.previewUrl = previewUrl;

        $scope.$on('$destroy', $ngRedux.connect(state => {
            const page = angular.copy(getPageState(state));
            this.updatePreview(page);
            return {
                page
            };
        }, ProjectPageActions)(this));
    }

    $onInit() {
        const vm = this;
        const editor = new EditorJS({
            autofocus: true,
            defaultBlock: 'paragraph',
            tools: {
                paragraph: {
                    class: CustomParagraph
                },
                header: CustomHeader,
                list: List,
                break: Delimiter,
                // quote: Quote,
                // warning: Warning,
                // table: Table,
                page: {
                    class: PageLink,
                    config: {
                        placeholder: 'Select a page...',
                        items: this.pages.filter(p => p.published && p.id !== this.page.id).map(p => ({ id: p.id, label: p.title }))
                    }
                },
                form: {
                    class: FormLink,
                    config: {
                        placeholder: 'Select a form...',
                        items: this.forms.map(f => ({ id: f.id, label: f.name }))
                    }
                },
                media: {
                    class: CustomImage,
                    config: {
                        uploader: {
                            uploadByFile(file) {

                                return new Promise((resolve) => {

                                    const id = -Date.now() + Math.floor(Math.random() * 100000);
                                    const item = {
                                        id,
                                        file,
                                        name: file.name,
                                        createdAt: (new Date()).toISOString(),
                                        size: file.size,
                                        caption: '',
                                        type: file.type
                                    };

                                    // This promise will resolve when the file has actually uploaded to the server
                                    vm.createPageMediaItem(item).then(media => {
                                        item.url = media.url;
                                        item.id = media.id;
                                    });

                                    const reader = new FileReader();
                                    reader.onloadend = function (event) {
                                        item.url = event.target.result;
                                        resolve({
                                            success: 1,
                                            file: item
                                        });
                                    };

                                    reader.readAsDataURL(file);
                                });
                            }
                        }
                    }
                }
            },
            data: this.page.config,
            onChange: () => {
                editor.save().then(data => {
                    this.page.config = data;
                    this.updatePageConfig(
                        this.page.config
                    );
                    this.updatePreview();
                });
            }
        });
    }

    updatePreview(content = this.page) {
        const payload = {
            type: 'COREO_PAGE_PREVIEW_UPDATE',
            page: JSON.stringify(content)
        };
        document.getElementById('preview').contentWindow.postMessage(payload, '*');
    }
}

class Link {
    static get toolbox() {
        return {
            title: 'Page Link',
            icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
        };
    }

    constructor({ data, api, config }) {
        this.data = data;
        this.api = api;
        this.config = config;
    }

    render() {
        const wrapper = document.createElement('div');
        wrapper.classList.add(this.api.styles.block);

        const input = document.createElement('input');
        input.value = this.data.text || '';
        input.classList.add(this.api.styles.input);
        wrapper.appendChild(input);

        const select = document.createElement('select');
        select.classList.add(this.api.styles.input);

        wrapper.appendChild(select);

        select.placeholder = this.config.placeholder || 'Select an option...';
        this.config.items.forEach(p => {
            const option = document.createElement('option');
            option.text = p.label;
            option.value = p.id;
            select.appendChild(option);
        });
        select.value = this.data.id;

        return wrapper;
    }

    save(blockContent) {
        const select = blockContent.querySelector('select');
        const input = blockContent.querySelector('input');

        return {
            id: Number(select.value),
            text: input.value
        }
    }

    validate(savedData) {
        if (!savedData.text.trim()) {
            return false;
        }

        return true;
    }
}

const alignmentEnhancer = (tool) => {
    const settings = [
        {
            name: 'left',
            icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m10 23h28c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m10 45h28c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/></svg>`
        },
        {
            name: 'center',
            icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m46 23c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m46 45c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/></svg>`
        },
        {
            name: 'right',
            icon: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 19h-28c-1.104 0-2 .896-2 2s.896 2 2 2h28c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 41h-28c-1.104 0-2 .896-2 2s.896 2 2 2h28c1.104 0 2-.896 2-2s-.896-2-2-2z"/></svg>`
        }
    ];

    // Initialise the alignment if it hasn't been set
    tool.data.alignment = tool.data.alignment || 'left';

    const CSS = {
        settingsButton: tool.api.styles.settingsButton,
        settingsButtonActive: tool.api.styles.settingsButtonActive,
        left: 'ce-paragraph--left',
        center: 'ce-paragraph--center',
        right: 'ce-paragraph--right'
    };

    const baseRenderSettings = tool.renderSettings;
    const baseSave = tool.save;
    const baseRender = tool.render;

    tool.renderSettings = () => {
        const wrapper = baseRenderSettings ? baseRenderSettings.call(tool) : document.createElement('div');
        settings.map(tune => {
            let button = document.createElement('div');

            button.classList.add(CSS.settingsButton);
            button.innerHTML = tune.icon;

            button.classList.toggle(CSS.settingsButtonActive, tune.name === tool.data.alignment);
            wrapper.appendChild(button);

            return button;

        }).forEach((button, index, buttons) => {
            button.addEventListener('click', () => {
                tool.data.alignment = settings[index].name;

                buttons.forEach((b, i) => {
                    const { name } = settings[i];
                    b.classList.toggle(CSS.settingsButtonActive, name === tool.data.alignment);
                    const el = tool.getElement ? tool.getElement() : tool._element;
                    if (el) {
                        el.classList.toggle(CSS[name], name === tool.data.alignment);
                    }
                });
            });
        })

        return wrapper;
    }

    tool.save = (contents) => {
        return Object.assign(baseSave.call(tool, contents), {
            alignment: tool.data.alignment
        });
    }

    tool.render = () => {
        const el = baseRender.call(tool);
        if (el && tool.data.alignment) {
            el.classList.add(CSS[tool.data.alignment]);
        }
        return el;
    }
};

class CustomHeader extends Header {
    constructor(obj) {
        super(obj);
        const alignment = obj.data && obj.data.alignment;
        this.data.alignment = alignment;
        alignmentEnhancer(this);
    }
}

class CustomParagraph extends Paragraph {
    constructor(obj) {
        super(obj);
        const alignment = obj.data && obj.data.alignment;
        this.data.alignment = alignment;
        alignmentEnhancer(this);
    }
}

class CustomImage extends ImageTool {
    constructor(obj) {
        super(obj);
        const alignment = obj.data && obj.data.alignment;
        this.data.alignment = alignment;
        alignmentEnhancer(this);
    }
    getElement() {
        return this.ui.nodes.imageContainer;
    }
}

class PageLink extends Link {

    static get toolbox() {
        return {
            title: 'Page Link',
            icon: '<i class="far fa-file toolbox-icon"></i>'
        };
    }
}

class FormLink extends Link {

    static get toolbox() {
        return {
            title: 'Form Link',
            icon: '<i class="far fa-clipboard toolbox-icon"></i>'
        };
    }
}