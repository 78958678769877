import angular from 'angular';

export const RecordCommentComponent = {
    template: require('./record-comment.component.html'),
    bindings: {
        comment: '<',
        onDelete: '&'
    },
    controllerAs: 'ctrl',
    controller: class RecordCommentComponent {
        /* @ngInject */
        constructor($http, $uibModal, $timeout) {
            this.$http = $http;
            this.$uibModal = $uibModal;
            this.$timeout = $timeout;

            this.editing = false;
            this.edited = {
                text: ""
            };
        }

        delete() {
            var modal = this.$uibModal.open({
                template: require('./delete-comment.html'),
                /* @ngInject */
                controller: function ($scope, $uibModalInstance) {
                    $scope.ok = function () {
                        $uibModalInstance.close();
                    };
                    $scope.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            });

            modal.result.then(() => {
                this.onDelete({ id: this.comment.id });
            }, angular.noop);
        }

        edit() {
            this.editing = true;
            this.edited.text = this.comment.text;
            this.$timeout(function () {
                angular.element(document.querySelector(".comment-edit"))[0].focus();
            }, 0, false);
        }

        cancelEdit() {
            this.editing = false;
        }

        saveEdit() {
            this.editing = false;
            this.$http.put('/records/' + this.comment.recordId + '/comments/' + this.comment.id, {
                text: this.edited.text
            }).then((response) => {
                this.comment.text = response.data.text;
                this.comment.updatedAt = response.data.updatedAt;
            });
        }
    }
}