import * as Sentry from '@sentry/browser';
import './admin-organisations.scss';
import { TabulatorFull as Tabulator } from 'tabulator-tables';

const MAX_AUTOCOMPLETE_USERS = 300;

/* @ngInject */
export default class AdminOrganisationsController {
    constructor($state, $mdDialog, OrganisationsService, toastr, CoreoAPI, apiHostname, Tabulator) {
        this.toastr = toastr;
        this.$state = $state;
        this.OrganisationsService = OrganisationsService;
        this.$mdDialog = $mdDialog;
        this.table = Tabulator.createTable('#admin-organisations-table', {
            columns: [{
                title: 'ID',
                field: 'id',
                width: '10%'
            }, {
                title: 'Name',
                field: 'name',
                headerFilter: true
            }, {
                title: 'Slug',
                field: 'slug',
                headerFilter: true
            }, {
                title: 'Tier',
                field: 'tier.name'
            }, {
                title: 'Created At',
                field: 'createdAt',
                formatter: cell => new Date(cell.getValue()).toLocaleString()
            }, {
                title: 'Free Trial End Date',
                field: 'freeTrialEnd',
                formatter: cell => cell.getValue() ? new Date(cell.getValue()).toLocaleString() : null
            }, {
                title: 'Free Trial Expired',
                field: 'freeTrialExpired'
            }
            ],
            initialSort: [
                { column: "id", dir: "desc" }
            ],
            layout: "fitColumns",
            pagination: true,
            paginationMode: "remote",
            paginationSize: 10,
            paginationSizeSelector: [10, 20, 50, 100],
            ajaxURL: apiHostname,
            ajaxRequestFunc: (_url, _config, params) => {
                let { filter, sort, page, size } = params;
                console.log('HERE', params);
                page = page ?? 1;
                size = size ?? 10;

                const whereParams = filter.reduce((acc, filter) => {
                    acc.push(`${filter.field}: { iLike: "%${filter.value}%"}`);
                    return acc;
                }, []).join(',');
                const where = whereParams.length ? `where: {${whereParams}},` : '';

                let order = `order:"reverse:id"`;
                if (sort.length) {
                    const { field, dir } = sort[0];

                    order = `order:"${dir === 'desc' ? 'reverse:' : ''}${field}"`;
                }

                // const query = `{
                //     data: users(${order},${where}offset: ${size * (page - 1)}, limit: ${size}){id,displayName,email,role,createdAt,username,lastActive,lastLoggedIn}
                //     count: orCount${where ? `(${where})` : ''}
                // }`;

                const query = `{
                    data: organisations(${order},${where}offset: ${size * (page - 1)}, limit: ${size}){id,name,slug,createdAt, freeTrialEnd, freeTrialExpired, tier { name }},
                    count: organisationsCount${where ? `(${where})` : ''}
                }`;
                return CoreoAPI.query(query).then(({ data, count }) => ({
                    data,
                    last_page: Math.ceil(count / size)
                }));
            },
            filterMode: "remote",
            sortMode: "remote"
        });

        this.table.on('rowClick', (_event, row) => {
            const { id } = row.getData();
            $state.go('admin.organisation', {
                id: id
            });
        });

        // this.table.setData();
    }

    createOrganisation(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('./create-organisation.modal.html'),
            controllerAs: 'ctrl',
            controller: class OrganisationBuySeatsController {
                /* @ngInject */
                constructor($mdDialog, CoreoAPI) {
                    this.$mdDialog = $mdDialog;
                    this.CoreoAPI = CoreoAPI;
                    this.name = null;
                    this.user = null;
                    this.userId = null;
                    this.userSearchText = this.user && this.user.displayName || '';
                }

                updateUser() {
                    this.userId = this.selectedUser ? this.selectedUser.id : null;
                    this.user = this.selectedUser;
                }

                cancel() {
                    this.$mdDialog.cancel();
                }

                create() {
                    this.$mdDialog.hide({
                        ownerId: this.userId,
                        name: this.name
                    })
                }

                getUsers(search) {
                    var query = `query CoreoAASearchUsers{
                        users(
                            where:{ 
                                or: [
                                    {displayName: { iLike: "%${search}%"}},
                                    {username: { iLike: "%${search}%"}}
                                ]
                            }, 
                            limit: ${MAX_AUTOCOMPLETE_USERS})
                        {
                            displayName,
                            username,
                            id
                        }
                    }`;
                    return this.CoreoAPI.query(query).then(result => {
                        return result.users;
                    });
                }
            }
        }).then((data) => {
            this.OrganisationsService.createOrganisation(data.ownerId, data.name).then(data => {
                this.toastr.success('Organisation Created');
                this.$state.go('admin.organisation', {
                    id: data.id
                });
            }, (err) => {
                console.error(err)
                this.toastr.error('Could not create organisation');
                Sentry.captureException(err);
            })
        }, angular.noop);
    }
}