import $ from 'jquery';
import angular from 'angular';
import _ from 'lodash';

import './theme/theme.scss';

import './constants';
import main from './main/main.module';
import auth from './auth/auth.module';
import admin from './admin/admin.module';
import projects from './projects/projects.module';
import records from './records/records.module';
import jobs from './jobs/jobs.module';
import legal from './legal/legal.module';
import home from './home/home.module';
import organisations from './organisations/organisations.module';
import permissions from './permissions/permissions.module';
import support from './support/support.module';
import intermediarypage from './intermediarypage/intermediarypage.module';

window.$ = $;
window._ = _;

const appModule = angular.module('app', [
    'app.constants',
    'app.main',
    'app.auth',
    'app.permissions',
    'app.admin',
    'app.home',
    'app.organisations',
    'app.projects',
    'app.records',
    'app.jobs',
    'app.legal',
    'app.support',
    'app.intermediarypage'
]);


export default appModule;