
import './record-media-edit.component.scss';
export const RecordMediaEditComponent = {
    template: require('./record-media-edit.component.html'),
    bindings: {
        url: '<',
        type: '<',
        onMediaDelete: '&',
        onMediaUpload: '&'
    },
    controllerAs: 'ctrl',
    controller: class RecordMediaEditController {
        /* @ngInject */
        constructor($scope, $sce) {
            this.$sce = $sce;
            this.$scope = $scope;
            this.uploadMedia = this.uploadMedia.bind(this);
            this.mode = null;
        }

        $onChanges() {
            if (!this.type) {
                return this.mode = null;
            }

            if (this.type === 'photo' || this.type.startsWith('image')) {
                this.mode = 'photo';
            } else if (this.type.startsWith('video')) {
                this.mode = 'video';
            } else if (this.type.startsWith('audio')) {
                this.mode = 'audio';
            } else {
                this.mode = null;
            }
        }

        deleteMedia() {
            this.url = null;
            this.onMediaDelete();
        }

        uploadMedia(e) {
            const file = (e.srcElement || e.target).files[0];
            this.file = file;
            const reader = new FileReader();
            reader.onloadend = event => {
        
                this.url = this.$sce.trustAsResourceUrl(event.target.result);
                this.$scope.$apply();
                this.onMediaUpload({file});
            };
        
            reader.readAsDataURL(file);
        }
    }
};

