import './project-forms-block-question.scss';

export const ProjectFormsBlockQuestionComponent = {
  bindings: {
    attribute: '<',
    labeltext: '<',
    readonly: '<'
  },
  template: require('./project-forms-block-question.component.html'),
  controllerAs: 'ctrl',
  controller: class ProjectFormsBlockQuestionComponent {
    /* @ngInject */
    constructor($scope, $element, $timeout, $uibModal, ProjectService) {

    }
  }
};
