import './project-record-status-settings.scss';
import { getProject, getProjectRole } from '../../store/selectors';
/* @ngInject */

export default class ProjectRecordStatusSettingsController {
  constructor($uibModal, $ngRedux, $scope, ProjectsService, toastr, ProjectActions) {

    this.$uibModal = $uibModal;
    this.ProjectsService = ProjectsService;
    this.toastr = toastr;

    $scope.$on('$destroy', $ngRedux.connect((state) => {
      const project = angular.copy(getProject(state));

      const compareState = (a, b) => {
        if (a.sort < b.sort) {
          return -1;
        } else if (a.sort < b.sort) {
          return 1;
        } else {
          return 0;
        }
      }

      project.states = project.states.sort(compareState);
      return {
        project,
        role: getProjectRole(state),
        stateListConfig: {
          axis: 'y',
          containment: '.status-list',
          tolerance: 'pointer',
          disabled: !project.verification
        }
      };
    }, ProjectActions)(this));

    this.readonly = this.role !== 'admin';
    this.selectedStateId = null;

    this.classMap = {
      null: 'label-clear',
      0: 'label-default',
      1: 'label-danger',
      2: 'label-warning',
      3: 'label-info',
      4: 'label-primary',
      5: 'label-success'
    };
  }

  updateOrder() {
    this.updateProjectRecordStatusOrder(this.project.states);
  };

  createState() {
    var modal = this.$uibModal.open({
      template: require('./create-record-status.modal.html'),
      /* @ngInject */
      controller: function ($scope, $uibModalInstance) {
        $scope.state = {
          verified: false,
          name: "",
          color: '#ffffff'
        };

        $scope.colorPickerOptions = {
          inputClass: 'form-control',
          format: 'hexString',
          allowEmpty: false,
          alpha: false,
          dynamicHue: false,
          required: true
        };

        $scope.ok = function () {
          $uibModalInstance.close($scope.state);
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
      }
    });

    modal.result.then((state) => {
      var stateId = Math.max.apply(null, _.map(this.project.states, function (state) {
        return state.stateId;
      }));

      state.stateId = stateId + 1;
      state.projectId = this.project.id;
      state.sort = this.project.states.length;
      this.createProjectRecordStatus(state);
    }, angular.noop);
  };

  toggleRecordStatuses() {
    this.updateProject({
      id: this.project.id,
      verification: this.project.verification
    });
  }

  editState(stateId) {
    this.selectedStateId = stateId;
    var state = _.find(this.project.states, { stateId: +stateId });
    var vm = this;

    var editedState = {
      stateId: stateId,
      verified: state.verified,
      name: state.name,
      default: state.default,
      projectId: this.project.id,
      color: state.color
    };

    var modal = this.$uibModal.open({
      template: require('./edit-record-status.modal.html'),
      /* @ngInject */
      controller: function ($scope, $uibModalInstance) {
        $scope.state = editedState;

        $scope.colorPickerOptions = {
          inputClass: 'form-control',
          format: 'hexString',
          allowEmpty: false,
          alpha: false,
          dynamicHue: false,
          required: true
        };

        $scope.ok = function () {
          $uibModalInstance.close();
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.delete = function () {
          $uibModalInstance.dismiss('delete');
        };

        $scope.default = function () {
          $uibModalInstance.dismiss('default');
        };
      }
    });

    modal.result.then(() => {
      state.name = editedState.name;
      state.verified = editedState.verified;
      state.color = editedState.color;

      this.updateProjectRecordStatus(editedState);
    }).catch((reason) => {
      if (reason === 'delete') {
        var subModal = this.$uibModal.open({
          template: require('./delete-record-status.modal.html'),
          /* @ngInject */
          controller: function ($scope, $uibModalInstance, ProjectsService, toastr) {
            $scope.ok = function () {
              vm.deleteProjectRecordStatus(vm.selectedStateId);
            };

            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          }
        });

        subModal.result.then(() => {
          this.project.states = _.filter(this.project.states, (state) => {
            return state.stateId !== this.selectedStateId;
          });
        }, angular.noop);
      } else if (reason === 'default') {
        editedState.default = true;
        this.updateProjectRecordStatus(editedState);
      }
    });
  };

}
