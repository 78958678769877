import angular, { IController } from "angular";
import ngRedux from 'ng-redux';
import { CoreoCollection, CoreoForm } from "../../types";
import { getProjectFormsContainingGeometry, getProjectCollections, getProjectId } from '../../store/selectors';
import { MapCustomLayerConfig } from "../../main/mapData.service";
import styleSpec from '../../main/mapbox-stye-spec.constant';

interface SourceType {
    id: string;
    label: string;
    styleable?: boolean;
    hasUrl?: boolean;
    information?: string;
}

export default class ProjectMapEditorNewLayerController implements IController {

    sourceTypes: SourceType[] = [{
        id: 'records',
        label: 'Coreo Records'
    }, {
        id: 'collections',
        label: 'Coreo Collections'
        }];

    layerTypes = [{
        id: 'heatmap',
        label: 'Heatmap'
    }, {
            id: 'raster',
        label: 'Raster Image'
        }];

    projectId: number;
    forms: CoreoForm[];
    collections: CoreoCollection[];
    name: string;
    layerConfig: MapCustomLayerConfig;
    imageWarning: boolean = false;
    uploading: boolean = false;

    /* @ngInject */
    constructor(
        private $mdDialog: angular.material.IDialogService,
        private $ngRedux: ngRedux.INgRedux,
        private $timeout: angular.ITimeoutService,
        private ProjectMapsService,
        private MapboxStyle) { }
    $onInit(): void {
        const state = this.$ngRedux.getState();
        this.projectId = getProjectId(state);
        this.forms = getProjectFormsContainingGeometry(state);
        this.collections = getProjectCollections(state).filter(c => c.geometric);
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    create() {
        console.log('HERE?!', this.layerConfig);
        if (this.layerConfig.layerType === 'heatmap') {
            // const default = styleSpec.heatmap.map
            this.layerConfig.paint = {};
            this.layerConfig.paint = this.MapboxStyle.getDefaults('paint', 'heatmap');
            this.layerConfig.type = 'heatmap';
        }
        this.$mdDialog.hide({
            name: this.name,
            config: this.layerConfig,
        });
    }

    uploadImage() {
        const file = document.getElementById('image-upload');
        this.imageWarning = false;
        file.click();
    }

    handleFile = (_e, files: FileList) => {
        const file = files[0];
        if (file?.type !== "image/tiff") {
            this.$timeout(() => this.imageWarning = true);
            return;
        }

        this.uploading = true;
        this.ProjectMapsService.uploadGeoTIFF(this.projectId, file).then(({ url, bounds }) => {
            this.$timeout(() => {
                this.imageWarning = false;
                this.uploading = false;
                this.layerConfig.source = url;
                this.layerConfig.layout = bounds.slice(0, 4);
                this.layerConfig.sourceType = 'image';
            });
        }, (err) => {
            this.$timeout(() => {
                this.imageWarning = true;
                this.uploading = false;
            });
        });
    }
}