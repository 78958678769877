import { getProjectCollection } from "../../store/selectors";

export const CollectionItemComponent = {
    bindings: {
        collectionId: '<',
        key: '<',
    },
    template: require('./collection-item.component.html'),
    controllerAs: 'ctrl',
    controller: class CollectionItemComponent {
        /* @ngInject */
        constructor($ngRedux) {
            this.$ngRedux = $ngRedux;
            this.collection = null;
            this.output = '';
        }

        $onInit() {
            if (Array.isArray(this.key)) {
                this.key = this.key.join(',');
            }
        }
    }
};
