/* @ngInject */
export default class AdminUserPasswordController {
    constructor(user, AdminUsersService, toastr) {
        this.user = user;
        this.AdminUsersService = AdminUsersService;
        this.toastr = toastr;
        this.updating = true;
        this.password = '';
        this.passwordConfirm = '';
        this.error = '';
    }

    updatePassword() {
        if (this.password !== this.passwordConfirm) {
            this.error = 'Passwords must match!';
            return;
        }
        this.error = '';

        this.AdminUsersService.updatePassword(this.user.id, this.password).then(() => {
            this.toastr.success('User password updated.');
            this.updating = false;
            this.password = this.passwordConfirm = '';
        });
    };
}
