import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { getProjects } from '../../store/selectors';

/* @ngInject */
export default class RequestsController {
    constructor($location, projects, AdminRequestsService, $state, apiHostname, Tabulator) {

        this.$state = $state;

        this.projects = projects.reduce((acc, project) => {
            acc[project.id] = project.name;
            return acc;
        }, { "": "" });
        // this.projects = projects;

        this.methods = [{
            id: 'GET',
            title: 'GET'
        }, {
            id: 'PUT',
            title: 'PUT'
        }, {
            id: 'POST',
            title: 'POST'
        }, {
            id: 'DELETE',
            title: 'DELETE'
        }];

        this.responseStatuses = [{
            id: 'SUCCESS',
            title: 'Success'
        }, {
            id: 'ABORTED',
            title: 'Aborted'
        }, {
            id: 'ERROR',
            title: 'Error'
        }];
        var deparam = function (querystring) {
            if (!querystring) {
                return undefined;
            }
            // remove any preceding url and split
            querystring = querystring.substring(querystring.indexOf('?') + 1).split('&');
            var params = {}, pair, d = decodeURIComponent, i;
            // march and parse
            for (i = querystring.length; i > 0;) {
                pair = querystring[--i].split('=');
                params[d(pair[0])] = d(pair[1]);
            }

            return params;
        };//--  fn  deparam


        var initialParams = $location.search();
        // console.log('Initial Params', initialParams);
        var sorting = deparam(initialParams.order) || { 'requestedAt': 'desc' };
        var filter = deparam(initialParams.filter) || {};
        if (filter && filter.projectId) {
            filter.projectId = parseInt(filter.projectId);
        }


        this.table = Tabulator.createTable('#admin_requests_table', {
            layout: "fitColumns",
            placeholder: "Loading...",
            pagination: true,
            paginationMode: "remote",
            paginationSize: 20,
            paginationSizeSelector: [10, 20, 50, 100],
            columns: [
                {
                    title: "ID",
                    field: "id",
                    headerSort: false,
                    formatter: (cell) => {
                        const id = cell.getValue();
                        const href = `${window.location.href}/${id}`;
                        const el = angular.element(`<a target="_blank">${cell.getValue()}</a>`).attr('href', href);
                        return el[0];
                    }
                },
                { title: "Path", field: "path", headerFilter: "input" },
                {
                    title: "Method",
                    field: "method",
                    headerFilter: "select",
                    headerFilterParams: {
                        values: {
                            "": "",
                            "GET": "GET",
                            "POST": "POST",
                            "PUT": "PUT",
                            "DELETE": "DELETE"
                        }
                    }
                },
                { title: "Time Taken", field: "timeTaken" },
                {
                    title: "Response Status",
                    field: "responseStatus",
                    headerFilter: "select",
                    headerFilterPlaceholder: "",
                    headerFilterParams: {
                        values: {
                            "": "",
                            "SUCCESS": "Success",
                            "ABORTED": "Aborted",
                            "ERROR": "Error"
                        }
                    }
                },
                {
                    title: "Project",
                    field: "project.name",
                    headerSort: false,
                    headerFilter: "select",
                    headerFilterPlaceholder: "",
                    headerFilterParams: this.projects,
                    formatter: 'lookup',
                    formatterParams: this.projects
                },
                { title: "User", field: "user.displayName", headerSort: false },
                { title: "Timestamp", field: "requestedAt" }
            ],
            initialSort: [
                { column: "requestedAt", dir: "desc" }
            ],
            ajaxURL: apiHostname,
            ajaxRequestFunc: (url, config, params) => {

                const q = {
                    limit: params.size,
                    offset: (params.page - 1) * params.size,
                    where: {}
                };

                if (params.sort.length > 0) {
                    q.order = {
                        [params.sort[0].field]: params.sort[0].dir
                    }
                }

                if (params.filter.length > 0) {
                    q.where = params.filter.reduce((acc, filter) => {
                        if (filter.field === 'project.name') {
                            acc.projectId = filter.value;
                        } else {
                            acc[filter.field] = filter.value;
                        }
                        return acc;
                    }, {});
                }

                return AdminRequestsService.getRequests(q).then(({ count, logs }) => {
                    return {
                        last_page: Math.ceil(count / params.size),
                        data: logs
                    };
                });
            },
            filterMode: "remote",
            sortMode: "remote"
        });
    }
}