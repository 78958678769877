/* @ngInject */
export default class AdminUserOrganisationsController {
  constructor(user, AdminUsersService, organisations, toastr) {
    this.user = user;
    this.organisations = organisations;
    this.AdminUsersService = AdminUsersService;
    this.toastr = toastr;
  }


  update(organisationId, role) {
    this.AdminUsersService.updateOrganisatonRole(this.user.id, organisationId, role).then(() => {
      this.toastr.success('Role updated');
    });
  };
}
