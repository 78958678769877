/* @ngInject */
export default class ProfileController {
  constructor($http, toastr, profile, Auth, $mdDialog) {
    this.$http = $http;
    this.$mdDialog = $mdDialog;
    this.Auth = Auth;
    this.toastr = toastr;
    this.profile = profile;
    this.apiKeyVisible = false;
  }

  update() {
    this.$http.put('/profile', this.profile).then(() => {
      this.toastr.success('Profile updated.');
    });
  }

  toggleApiKeyVisible() {
    this.apiKeyVisible = !this.apiKeyVisible;
  }
    
  createOrRefreshApiKey(ev) {
    const createOrRefresh = () => {
      return this.Auth.createOrRefreshApiKey().then(token => {
        this.profile.apiKey = {
          token
        };
        this.toastr.success('API Key updated');
      });
    }
    console.log(this.profile, !!this.profile.apiKey)
    if (!this.profile.apiKey) {
      createOrRefresh();
    } else {
      this.$mdDialog.show({
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        template: require('./refresh-apikey.modal.html'),
        controllerAs: 'ctrl',
        controller: DialogConfirmationController
      }).then(createOrRefresh, angular.noop);
    }

  }

  deleteApiKey(ev) {
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      template: require('./delete-apikey.modal.html'),
      controllerAs: 'ctrl',
      controller: DialogConfirmationController
    }).then(() => {
      this.Auth.deleteApiKey().then(() => {
        this.profile.apiKey = null;
        this.toastr.success('API Key deleted');
      });
    }, angular.noop);
  }
}


class DialogConfirmationController {
  /* @ngInject */
  constructor($mdDialog) {
      this.$mdDialog = $mdDialog;
  }

  ok() {
      this.$mdDialog.hide();
  };

  cancel() {
      this.$mdDialog.cancel();
  };
}