import bbox from '@turf/bbox';

export default class ProjectCollectionsGeometryDirective {
    /* @ngInject */
    constructor(Mapbox) {
        this.restrict = 'E';
        this.scope = {
            'geometry': '='
        };
        this.template = require('./project-collections-geometry.directive.html');
        this.Mapbox = Mapbox;
    }
    link(scope) {

        this.Mapbox.get().then(mapboxgl => {
            var map = new mapboxgl.Map({
                container: 'item-map',
                style: 'mapbox://styles/mapbox/streets-v12'
            });

            var existing = scope.geometry && scope.geometry.type;
            var draw;

            import(/* webpackChunkName: "mapbox-gl-draw" */'@mapbox/mapbox-gl-draw').then(({ default: MapboxDraw }) => {
                var drawOptions = {
                    displayControlsDefault: false,
                    boxSelect: true,
                    controls: {
                        point: !existing,
                        polygon: !existing,
                        line_string: !existing,
                        trash: true
                    }
                };
                draw = new MapboxDraw(drawOptions);
                map.addControl(draw);
            });

            map.on('load', function () {

                var geometry = _.assign({}, {
                    type: 'Feature',
                    properties: {},
                    geometry: scope.geometry
                });

                var bounds = bbox(scope.geometry);

                if (bounds) {
                    map.fitBounds(bounds, {
                        padding: 20,
                        duration: 0
                    });
                }

                draw.add(geometry);
                map.on('draw.update', function (e) {
                    _.assign(scope.geometry, e.features[0].geometry);
                });
            });
        });
    }
}