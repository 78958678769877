
import bbox from '@turf/bbox';
import { getProjectForm, getRecordsLayers, getProjectGeometry, getProjectBoundingBox, getProjectId, getProjectVerification, getRecordsData, getRecordsFilterBoundary, getRecordsFilterCustomBoundary, getRecordsFilterQuery, getRecordsFormId, getRecordsSelectedIds, getRecordsSelectionSource, getRecordsForms } from "../../../store/selectors";
import center from '@turf/center';

import FreehandMode from 'mapbox-gl-draw-freehand-mode';
import ResizeObserver from 'resize-observer-polyfill';

import angular from 'angular';
import mapboxgl, { GeoJSONSource, LngLatBoundsLike } from 'mapbox-gl';
import { MapBaseStyle } from '../../../main/mapbox-base-styles.constants';
import { MapData, MapStyleableLayer, MapRecordsSource, MapLayer } from '../../../main/mapData.service';
import { CoreoForm } from '../../../types';
import './project-records-map.scss';
import { MapLayerState } from '../../../store/records/records.reducer';
import ProjectService from '../../project.service';

export const ProjectRecordsMapComponent: angular.IComponentOptions = {
    template: require('./project-records-map.html'),
    controllerAs: 'ctrl',
    bindings: {
        onUpdateState: '&',
        onLoad: '&',
        filterOpen: '<'
    },
    controller: class ProjectRecordsMapComponent {

        isDrawing: boolean = false;
        isShowingProjectBounds: boolean = false;
        hasCustomBoundary: boolean = false;

        map: mapboxgl.Map;
        popup: any = null;
        projectId: number;
        verification: any;
        projectGeometry: any;
        projectBounds: any;
        selectedId: number = null;
        hoveredStateId: number = null;
        boundaryId: string = null;
        record: any = null;
        shiftKeyIsDown: boolean = false;
        ro: ResizeObserver;
        projectBoundsHandle: any;
        baseStyle: MapBaseStyle;

        mapSource: MapRecordsSource;
        mapRecordsLayer: MapStyleableLayer;
        layers: MapLayerState[] = [];
        private renderedLayers: (MapLayerState & { impl: MapLayer })[] = [];

        // Bindings
        filterOpen: boolean;
        onLoad: () => void;
        onUpdateState: (state: any) => void;

        // From store actions
        updateFilter: any;
        selectRecord: any;

        draw: any;

        /* @ngInject */
        constructor(
            private $element,
            private $scope,
            private $timeout,
            private $ngRedux,
            private CoreoAPI,
            private Mapbox,
            private RecordsActions,
            private MapUtils,
            private MapData: MapData,
            private ProjectService: ProjectService,
            MAP_BASE_STYLES: MapBaseStyle[]) {

            const state = $ngRedux.getState();
            this.projectId = getProjectId(state);
            this.verification = getProjectVerification(state);
            this.projectGeometry = getProjectGeometry(state);
            this.projectBounds = getProjectBoundingBox(state);
            this.selectedId = null;
            this.hoveredStateId = null;
            this.boundaryId = null;
            this.record = null;
            this.baseStyle = MAP_BASE_STYLES[0];
            this.layers = angular.copy(getRecordsLayers(state));

            const vm = this;
            function keyHandler(evt) {
                if (evt.keyCode !== 16) {
                    return;
                }
                vm.shiftKeyIsDown = evt.type === 'keydown';
            }

            document.addEventListener('keydown', keyHandler);
            document.addEventListener('keyup', keyHandler);

            $scope.$on('$destroy', () => {
                document.removeEventListener('keydown', keyHandler);
                document.removeEventListener('keyup', keyHandler);
            })
        }

        $onInit() {


            this.createMap().then(() => {
                this.$scope.$on('$records:refresh', () => {
                    this.mapRecordsLayer?.remove();
                    this.createLayers();
                });

                this.$scope.$on('$records:tableMouseOver', (_e, id) => {
                    this.setRecordFeatureState(id, { hover: true });
                });

                this.$scope.$on('$records:tableMouseOut', (_e, id) => {
                    this.removeRecordFeatureState(id, 'hover');
                });

                this.$scope.$on('$destroy', this.$ngRedux.connect((state) => {

                    const selectedIds = getRecordsSelectedIds(state);
                    const selectionSource = getRecordsSelectionSource(state);

                    this.removeAllRecordFeatureState();

                    for (const id of selectedIds) {
                        this.setRecordFeatureState(id, { selected: true });
                    }

                    // See if we should update our focus
                    if (selectedIds.length === 1 && selectionSource === 'table' && this.selectedId !== selectedIds[0]) {
                        this.selectedId = selectedIds[0];
                        const records = getRecordsData(state);
                        const record = records.find(r => r.id === this.selectedId);
                        if (typeof record !== 'undefined' && record.boundingBox) {
                            const b = bbox(record.boundingBox);
                            this.map.fitBounds(b as any, {
                                padding: {
                                    top: 40,
                                    bottom: 40,
                                    right: 40,
                                    left: this.filterOpen ? 160 : 40
                                },
                                linear: true,
                                maxZoom: 13
                            });
                        }

                    }


                    if (typeof this.map.getLayer('boundary') !== 'undefined') {
                        const boundary = getRecordsFilterBoundary(state);

                        if (boundary && this.boundaryId !== boundary.boundaryId) {
                            const source: GeoJSONSource = this.map.getSource('boundary') as GeoJSONSource;
                            source.setData(boundary.geometry);
                            const c = bbox(boundary.geometry);
                            this.boundaryId = boundary.boundaryId;
                            this.map.fitBounds(c as any, {
                                padding: 40
                            });
                        }
                        this.map.setLayoutProperty('boundary', 'visibility', boundary ? 'visible' : 'none');
                    }

                    const customBoundary = getRecordsFilterCustomBoundary(state);
                    if (!customBoundary) {
                        this.draw.deleteAll();
                    }

                    const surveyId = getRecordsFormId(state);

                    return {
                        // isSatellite,
                        mapSelecting: selectedIds.length > 0 && selectionSource === 'map',
                        hasCustomBoundary: !!customBoundary,
                        surveyId,
                        survey: getProjectForm(surveyId)(state)
                    };
                }, this.RecordsActions)(this));
            });
        }

        $onDestroy() {
            if (this.map) {
                this.map.remove();
            }
            if (this.ro) {
                this.ro.disconnect();
            }
        }

        setRecordFeatureState(id, state) {
            if (this.mapRecordsLayer) {
                this.map.setFeatureState({
                    source: this.mapRecordsLayer.id,
                    sourceLayer: this.mapSource.sourceLayer,
                    id
                }, state);
            }
        }

        removeRecordFeatureState(id, state) {
            if (this.mapRecordsLayer) {
                this.map.removeFeatureState({
                    source: this.mapRecordsLayer.id,
                    sourceLayer: this.mapSource.sourceLayer,
                    id
                }, state);
            }
        }

        removeAllRecordFeatureState() {
            if (this.mapSource && this.mapRecordsLayer) {
                if (typeof this.map.getSource(this.mapRecordsLayer.id) !== 'undefined') {
                    this.map.removeFeatureState({
                        source: this.mapRecordsLayer.id,
                        sourceLayer: this.mapSource.sourceLayer
                    });
                }
            }
        }

        createLayers() {
            const state = this.$ngRedux.getState();

            const { boundaryId, geometry, ...query } = getRecordsFilterQuery(state);

            this.mapSource = this.MapData.createRecordsSource({
                projectId: this.projectId,
                query,
                clusterMaxZoom: null,
                attributes: [],
                boundaryId,
                boundary: geometry?.contains
            });

            const layer = this.MapData.createStyleableLayer(this.mapSource);
            const surveys: CoreoForm[] = getRecordsForms(state);
            for (const survey of surveys) {
                layer.setStyle(survey.id, {
                    ...survey.style
                }, survey.mapSort, survey.color);
            }
            layer.addTo(this.map).then(() => {
                this.mapRecordsLayer = layer;
                this.handleLayersChange();
            });
        }

        initMapEvents() {
            const mouseEnterHandler = (e) => {
                this.map.getCanvas().style.cursor = 'pointer';

                if (e.features.length > 0) {
                    const feature = e.features[0];
                    if (!feature.id) {
                        return;
                    }

                    if (this.hoveredStateId) {
                        if (this.hoveredStateId === feature.id) {
                            return;
                        }
                        this.setRecordFeatureState(this.hoveredStateId, { hover: false });
                    }
                    this.hoveredStateId = feature.id;
                    this.setRecordFeatureState(this.hoveredStateId, { hover: true });
                }
            };

            const mouseLeaveHandler = (e) => {
                this.map.getCanvas().style.cursor = '';
                if (this.hoveredStateId) {
                    this.setRecordFeatureState(this.hoveredStateId, { hover: false });
                }
                this.hoveredStateId = null;
            };

            const clickHandler = (e) => {
                const { features } = e;
                if (features && features.length > 0) {
                    const data = features[0];
                    if (data.sourceLayer === 'records') {
                        this.$timeout(() => this.selectRecord([data.id], 'map', this.shiftKeyIsDown, 0));
                    } else if (data.sourceLayer.startsWith('collection')) {
                        // console.log('DO POUP!', data);
                        // const coordinates = data.geometry.coordinates.slice();
                        const p = center(data.geometry);

                        // new mapboxgl.Popup()
                        //     .setHTML(data.properties.value)
                        //     .setLngLat(p.geometry.coordinates)
                        //     .addTo(this.map);
                    }
                }
            };

            const clusterHandler = e => {
                const feature = e.features[0];
                if (!(feature && feature.properties && feature.properties.z)) {
                    return;
                }

                this.map.easeTo({
                    center: feature.geometry.coordinates,
                    zoom: feature.properties.z
                });
            }

            this.map.on('coreo.click', clickHandler);
            this.map.on('coreo.mousemove', mouseEnterHandler);
            this.map.on('coreo.mouseleave', mouseLeaveHandler);
            this.map.on('coreo.clusterClick', clusterHandler);
        }

        initBoundaryLayer() {
            this.map.addSource('boundary', {
                type: 'geojson',
                data: null
            });

            this.map.addLayer({
                id: 'boundary',
                source: 'boundary',
                type: 'line',
                paint: {
                    'line-color': '#666',
                    'line-width': 3
                },
                layout: {
                    'line-join': 'round'
                }
            });
        }

        handleStyleChange(style: MapBaseStyle) {
            this.baseStyle = style;
            this.map.once('styledata', () => {
                for (const layer of this.renderedLayers) {
                    layer.impl.remove();
                }
                this.renderedLayers = [];

                this.createLayers();
                if (this.isShowingProjectBounds) {
                    this.showProjectBounds();
                }
            });
            this.map.setStyle(style.url);

        }

        handleBoundsChange(bounds: boolean) {
            this.isShowingProjectBounds = bounds;
            if (this.isShowingProjectBounds) {
                this.showProjectBounds();
            } else {
                this.hideProjectBounds();
            }
        }

        async handleLayersChange() {
            for (const layer of this.layers) {
                const existing = this.renderedLayers.find(r => r.id === layer.id && r.layerType === layer.layerType);
                if (layer.visible) {
                    if (typeof existing !== 'undefined') {
                        existing.impl.show();
                    } else {
                        const impl = this.MapData.fromState(layer);
                        if (impl) {
                            this.renderedLayers.push({
                                ...layer,
                                impl
                            });
                            await impl.addTo(this.map);
                        }
                    }
                } else {
                    if (typeof existing !== 'undefined') {
                        existing.impl.hide();
                    }
                }
            }

            // Sort our rendered layers
            this.renderedLayers.sort((a, b) => this.layers.findIndex(l => l.id === a.id && l.layerType === a.layerType) - this.layers.findIndex(l => l.id === b.id && l.layerType === b.layerType))
            for (let i = this.renderedLayers.length - 1; i >= 0; i--) {
                this.renderedLayers[i].impl?.move();
            }

            // Move the records back to the top
            this.mapRecordsLayer.move();
            this.$ngRedux.dispatch(this.RecordsActions.updateLayers(angular.copy(this.layers)));
        }


        getBoundingBox() {
            return this.ProjectService.getRecordsBoundingBox(this.projectId, {});
        }

        createMap() {
            return new Promise<void>(async (resolve) => {
                const mapboxgl = await this.Mapbox.get();

                // const geometry = getProjectGeometry(this.$ngRedux.getState());
                const box = await (this.projectBounds ? Promise.resolve(this.projectBounds) : this.getBoundingBox());

                const mapOptions: mapboxgl.MapboxOptions = {
                    container: this.$element[0].querySelector('.project-map'),
                    style: 'mapbox://styles/mapbox/streets-v12',
                    renderWorldCopies: false,
                    fadeDuration: 0
                };
                if (box) {
                    mapOptions.bounds = box as any;
                    mapOptions.fitBoundsOptions = {
                        padding: 40,
                        maxZoom: 12
                    };
                }
                this.map = new mapboxgl.Map(mapOptions);

                this.$scope.update = (state) => {
                    this.onUpdateState({ state });
                };

                this.$scope.verification = this.verification;

                this.map.on('load', () => {
                    this.initMapEvents();
                    this.initBoundaryLayer();
                    this.createLayers();
                    this.onLoad();
                    resolve();
                });

                this.ro = new ResizeObserver(() => this.map.resize());
                this.ro.observe(this.$element[0]);

                import(/* webpackChunkName: "mapbox-gl-draw" */'@mapbox/mapbox-gl-draw').then(({ default: MapboxDraw }) => {
                    const draw = new MapboxDraw({
                        displayControlsDefault: false,
                        modes: {
                            simple_select: MapboxDraw.modes.simple_select,
                            "direct_select": MapboxDraw.modes.direct_select,
                            draw_polygon: FreehandMode
                        }
                    });
                    this.map.addControl(draw, 'top-right');
                    this.map.on('draw.create', (e) => {
                        this.$timeout(() => {
                            this.updateFilter({
                                customBoundary: e.features[0].geometry
                            });
                            draw.changeMode('simple_select');
                        }, 100);
                    });
                    this.map.on('draw.update', (e) => {
                        if (!e.features.length) {
                            return;
                        }
                        this.$timeout(() => {
                            this.updateFilter({
                                customBoundary: e.features[0].geometry
                            });
                            draw.changeMode('simple_select')
                        });
                    });
                    this.draw = draw;
                });
            });

        }

        toggleDraw() {
            if (this.hasCustomBoundary) {
                if (this.draw.getMode() === 'direct_select') {
                    this.draw.trash();
                } else {
                    this.draw.deleteAll();
                    this.updateFilter({
                        customBoundary: null
                    });
                    this.isDrawing = false;
                }
            } else {
                this.draw.changeMode('draw_polygon');
                this.isDrawing = true;
            }
        }

        showProjectBounds() {
            this.hideProjectBounds();
            this.projectBoundsHandle = this.MapUtils.addProjectBoundaryLayer(this.map, this.projectGeometry, this.baseStyle.dark ? '#fff' : '#000');
        }

        hideProjectBounds() {
            if (!this.projectBoundsHandle) {
                return;
            }
            this.projectBoundsHandle.remove();
            this.projectBoundsHandle = null;
        }

    }
};