import bbox from '@turf/bbox';
import { feature, polygon } from '@turf/helpers';
import difference from '@turf/difference';

export const safelyAddSource = (map, id, source, replace = false) => {
    // If the source already exists and we are not replacing it, return
    const existing = map.getSource(id);
    if (typeof existing !== 'undefined' && !replace) {
        return;
    }
    if (existing && replace) {
        map.removeSource(id);
    }
    map.addSource(id, source);
}

export const safelyAddLayer = (map, layer, replace = false) => {
    const existing = map.getLayer(layer.id);
    if (typeof existing !== 'undefined' && !replace) {
        return
    }
    if (existing && replace) {
        map.removeLayer(layer.id);
    }
    map.addLayer(layer);
}

export const safelyRemoveLayer = (map, id) => {
    if (typeof map.getLayer(id) !== 'undefined') {
        map.removeLayer(id);
    }
};

export const safelyRemoveSource = (map, id) => {
    if (typeof map.getSource(id) !== 'undefined') {
        map.removeSource(id);
    }
};

/* @ngInject */
export default class MapUtils {

    constructor($http, mapsUrl) {
        this.$http = $http;
        this.mapTilesUrl = `${mapsUrl}/tiles`;
    }

    geometryToBounds(geometry) {
        return bbox(geometry);
    }

    addProjectBoundaryLayer(map, bounds, color = '#000') {

        safelyAddSource(map, 'projectBoundsLineSource', {
            type: 'geojson',
            data: bounds
        }, true);

        const globalLayer = polygon([[
            [-180, -90],
            [-180, 90],
            [180, 90],
            [180, -90],
            [-180, -90]
        ]]);
        const appLayer = feature(bounds);
        const data = difference(globalLayer, appLayer);

        map.addSource('projectBoundsFillSource', {
            type: 'geojson',
            data
        });

        map.addLayer({
            id: 'projectBoundsLine',
            type: 'line',
            source: 'projectBoundsLineSource',
            paint: {
                'line-width': 2,
                'line-color': color
            }
        });

        map.addLayer({
            id: 'projectBoundsFill',
            type: 'fill',
            source: 'projectBoundsFillSource',
            paint: {
                'fill-opacity': 0.2,
                'fill-color': color
            }
        });

        return {
            setColor: (color) => {
                map.setPaintProperty('projectBoundsFill', 'fill-color', color);
                map.setPaintProperty('projectBoundsLine', 'line-color', color);
            },
            remove: () => {
                safelyRemoveLayer(map, 'projectBoundsFill');
                safelyRemoveLayer(map, 'projectBoundsLine');
                safelyRemoveSource(map, 'projectBoundsFillSource');
                safelyRemoveSource(map, 'projectBoundsLineSource');
            }
        }
    }

    getMapUrl(query) {
        return this.$http.post(`${this.mapTilesUrl}`, query).then(result => {
            const mapId = result.data.mapId;
            return `${this.mapTilesUrl}/${mapId}/{z}/{x}/{y}.mvt`;
        });
    }
}