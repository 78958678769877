/* @ngInject */
export default ($http, FileSaver, Blob, eventEmitter) => {

  var CoreoFilter = function (cfg) {
    angular.extend(this, {
      op: 'eq',
      looseMatch: true,
      value: undefined
    }, cfg || {});
  };

  var CoreoQuery = function (path, config) {
    this.path = path;
    this.config = angular.extend({}, {
      // flatten: false,
      // raw: false,
      include: [],
      // q: '',
      start: 0
    }, config);
    this.builder = this.config.builder;
    delete this.config.builder;

    this.filters = {};
    this.data = null;
  };
  eventEmitter.inject(CoreoQuery.prototype);

  CoreoQuery.prototype.buildQueryString = function (runfilters) {
    var self = this;

    function build(filters) {
      return _.reduce(filters, function (acc, cfgArray, key) {
        acc = acc.concat(cfgArray.filter(function (cfg) {
          return typeof cfg.value !== 'undefined' && cfg.value !== null;
        }).map(function (cfg) {
          var value = cfg.value;
          if ((cfg.op === 'ilike' || cfg.op === 'like') && cfg.looseMatch) {
            value = ['%', value, '%'].join('');
          }
          return [key, '$' + cfg.op, value].join(':');
        }));
        return acc;
      }, []);
    }

    return build(this.filters).concat(build(runfilters || {})).join('+');
  };

  CoreoQuery.prototype._buildParams = function (params, filters) {
    var q = this.buildQueryString(filters || {});
    if (params) {
      delete params.q;
    }

    return angular.extend({}, this.config, {
      q: q
    }, params || {});
  };

  CoreoQuery.prototype.run = function (runparams, runfilters) {

    var self = this;
    var params = this._buildParams(runparams, runfilters);

    function build(data) {
      if (angular.isFunction(self.builder)) {
        return new self.builder(data);
      } else {
        return data;
      }
    }

    return $http.get(this.path, {
      params: params
    }).then(function (response) {
      if (angular.isArray(response.data)) {
        self.data = _.map(response.data, build);
        self.count = undefined;
      } else {
        self.data = _.map(response.data.rows, build);
        self.count = response.data.count;
      }
      // self.data = response.data;
      self.emit('change', self);
      return self;
    });
  };

  CoreoQuery.prototype.clearFilter = function (item) {
    this.filters = {};
  };

  CoreoQuery.prototype.filter = function (column, cfg) {
    if (typeof cfg === 'undefined') {
      delete this.filters[column];
    } else {
      var filters;
      if (angular.isArray(cfg)) {
        filters = cfg.map(function (c) {
          return new CoreoFilter(c);
        });
      } else {
        if (angular.isObject(cfg)) {
          filters = [new CoreoFilter(cfg)];
        } else {
          filters = [new CoreoFilter({
            value: cfg
          })];
        }
      }
      this.filters[column] = filters;
    }
    this.start(0);
    this.emit('filter', column, this.filters);
    return this.filters;
  };

  CoreoQuery.prototype.q = function (q) {
    if (typeof q !== 'undefined') {
      this.config.q = q;
    }
    return this.config.q;
  };

  CoreoQuery.prototype.limit = function (limit) {
    if (limit === null || typeof limit === 'undefined') {
      delete this.config.limit;
    } else {
      this.config.limit = limit;
    }
    return this.config.limit;
  };

  CoreoQuery.prototype.getLimit = function () {
    return this.config.limit;
  };

  CoreoQuery.prototype.setLimit = function (limit) {
    if (limit === null || typeof limit === 'undefined') {
      delete this.config.limit;
    }
  };

  CoreoQuery.prototype.sort = function (sort) {
    if (typeof sort !== 'undefined') {
      this.config.sort = sort;
    }
    return this.config.sort;
  };

  CoreoQuery.prototype.sortDir = function (sortDir) {
    if (typeof sortDir !== 'undefined') {
      this.config.sortDir = sortDir;
    }
    return this.config.sortDir;
  };

  CoreoQuery.prototype.clearSort = function () {
    delete this.config.sort;
    delete this.config.sortDir;
  };

  CoreoQuery.prototype.start = function (start) {
    if (typeof start !== 'undefined') {
      this.config.start = start;
    }
    return this.config.start;
  };

  CoreoQuery.prototype.flatten = function (flatten) {
    if (typeof flatten !== 'undefined') {
      this.config.flatten = flatten;
    }
    return this.config.flatten;
  };

  CoreoQuery.prototype.raw = function (raw) {
    if (typeof raw !== 'undefined') {
      this.config.raw = raw;
    }
    return this.config.raw;
  };

  CoreoQuery.prototype.include = function (include) {
    if (typeof include !== 'undefined') {
      if (angular.isArray(include)) {
        this.config.include = include;
      } else {
        this.config.include.push(include);
      }
    }
    return this.config.include;
  };

  CoreoQuery.prototype.download = function (type, params, filename) {

    params = this._buildParams(params);
    params.count = false;

    return $http.get(this.path, {
      params: params,
      headers: {
        'Accept': type,
      },
      responseType: 'arraybuffer',
    }).then(function (response) {
      var data = new Blob([response.data], {
        type: 'text/plain;charset=utf-8'
      });
      FileSaver.saveAs(data, filename);
    });
  };

  CoreoQuery.prototype.clone = function () {
    var clone = new CoreoQuery(this.path, angular.extend({}, this.config, {
      builder: this.builder
    }));
    angular.extend(clone.filters, this.filters);
    return clone;
  };
  return CoreoQuery;
}
