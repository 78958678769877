import './project-settings.scss';
import { getProject, getProjectRole, getAuthIsAdmin, getProjectBoundingBox, getOrganisationRole, getOrganisationId } from '../../store/selectors';
import CloneProjectController from './clone-project.controller';
import bbox from '@turf/bbox';

const PROJECT_SETTINGS_ADMIN_KEYS = ['usersLimit', 'locked', 'allowContributors', 'slug'];

/* @ngInject */
export default class ProjectSettingsController {
    constructor($ngRedux, $scope, $state, $timeout, $uibModal, $window, ProjectsService, FileSaver, toastr, $mdDialog, ProjectActions, ProjectsActions, AuthActions, OrganisationsActions, $rootScope, Mapbox) {
        $scope.$on('$destroy', $ngRedux.connect((state) => ({
            project: angular.copy(getProject(state)),
            orgRole: getOrganisationRole(state),
            role: getProjectRole(state),
            bounds: getProjectBoundingBox(state),
            isAdmin: getAuthIsAdmin(state),
            organisationId: getOrganisationId(state),
        }), ProjectActions)(this));

        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.ProjectsService = ProjectsService;
        this.FileSaver = FileSaver;
        this.toastr = toastr;
        this.$mdDialog = $mdDialog;
        this.$window = $window;
        this.Mapbox = Mapbox;
        this.$ngRedux = $ngRedux;
        this.AuthActions = AuthActions;
        this.ProjectsActions = ProjectsActions;
        this.OrganisationsActions = OrganisationsActions;

        this.readonly = this.role !== 'admin';
        this.boundsDirty = false;

        this.sampleShortName = _.startCase(this.project.slug);

        $scope.setImage = (input) => {
            this.project.image = input.files[0];
        };
    }

    $onInit() {
        this.Mapbox.get().then(mapboxgl => {

            const mapOptions = {
                container: 'project-settings-bounds',
                style: 'mapbox://styles/mapbox/streets-v12',
                renderWorldCopies: false
            };

            if (this.project.geometry && this.bounds) {
                mapOptions.bounds = this.bounds;
                mapOptions.fitBoundsOptions = {
                    padding: 60
                };
            }

            this.map = new mapboxgl.Map(mapOptions);
            this.startBoundaryEdit();
        });
    }

    handleBoundsButton() {
        if (this.project.geometry) {
            this.project.geometry = null;
            this.draw.deleteAll();
            this.boundsDirty = true;
            return;
        }
        this.draw.changeMode('draw_polygon');
    }

    updateProjectGeometry = (fitBounds = false) => {
        this.$timeout(() => {
            const drawFeatures = this.draw.getAll();
            const coordinates = [];
            for (const feature of drawFeatures.features) {
                if (feature.geometry.type === "MultiPolygon") {
                    for (const c of feature.geometry.coordinates) {
                        coordinates.push(c);
                    }
                } else {
                    coordinates.push(feature.geometry.coordinates);
                }
            }
            if (coordinates.length === 0) {
                this.project.geometry = null;
            } else {
                this.project.geometry = {
                    type: 'MultiPolygon',
                    coordinates
                };
                this.bounds = bbox(this.project.geometry);
                if (fitBounds && this.map) {
                    this.map.fitBounds(this.bounds);
                }
            }

            this.boundsDirty = true;
        }, 0);
    };

    startBoundaryEdit() {
        import(/* webpackChunkName: "mapbox-gl-draw" */'@mapbox/mapbox-gl-draw').then(({ default: MapboxDraw }) => {
            const draw = this.draw = new MapboxDraw({
                displayControlsDefault: false
            });
            this.map.addControl(draw);

            // Load initial geometry onto map
            if (this.project.geometry) {
                for (const polygonCoordinates of this.project.geometry.coordinates) {
                    draw.add({
                        type: "Polygon",
                        coordinates: polygonCoordinates
                    });
                }
            }

            this.project.geometrySettings = {};
            this.map.on('draw.create', this.updateProjectGeometry);
            this.map.on('draw.update', this.updateProjectGeometry);
            this.map.on('draw.delete', this.updateProjectGeometry);

            if (!this.project.geometry) {
                this.draw.changeMode('draw_polygon');
            }
        });
    }

    update() {
        var projectSettingKeys = [
            'name',
            'description',
            'verification',
            'visible',
            'accessMode',
            'imageUrl',
            'facebookHandle',
            'twitterHandle',
            'instagramHandle',
            'id',
            'shortName',
            'access',
            'fromAddress',
            'accountVerificationUrl',
            'passwordResetUrl',
            'androidAppUrl',
            'iosAppUrl',
            'welcomePageId',
        ];

        if (this.isAdmin) {
            PROJECT_SETTINGS_ADMIN_KEYS.forEach(k => projectSettingKeys.push(k));
        }

        var update = _.omitBy(_.pick(this.project, projectSettingKeys), _.isNil);
        this.updateProject(update).then(() => {
            this.form.$setPristine();
        });
    };

    updateBounds() {
        this.updateProject({
            id: this.project.id,
            geometry: this.project.geometry
        }).then(() => {
            this.boundsDirty = false;
            this.draw.changeMode('simple_select');
        });
    }

    startBoundsUpload() {
        document.getElementById('project-settings-bounds-upload').click();
    }

    handleBoundsFileChange = (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = () => {
            this.$timeout(() => {
                const geojson = JSON.parse(reader.result);
                this.draw.deleteAll();
                this.draw.add(geojson);
                this.updateProjectGeometry(true);

            });
        };
        reader.readAsText(file);
    }

    cloneProjectModal(ev) {

        const postClone = () => {
            this.$ngRedux.dispatch(this.AuthActions.refreshPermissions()).then(() => {
                this.$ngRedux.dispatch(this.ProjectsActions.loadProjects());
                this.$ngRedux.dispatch(this.OrganisationsActions.loadOrganisation(this.project.organisation.slug));
                this.$rootScope.$broadcast('jobs:refresh');
            });
        };

        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('./clone-project.modal.html'),
            controllerAs: 'ctrl',
            clickOutsideToClose: false,
            escapeToClose: false,
            resolve: {
                project: () => this.project,
                isAdmin: () => this.isAdmin,
                organisationId: () => this.organisationId
            },
            controller: CloneProjectController
        }).then(({ action, projectId, organisationId, ownerId, locked, usersLimit, name }) => {
            if (action === 'clone') {
                if (this.isAdmin && organisationId && ownerId) {
                    return this.ProjectsService.adminCloneProject(this.project.id, organisationId, ownerId, locked, usersLimit, name)
                        .then(postClone, angular.noop);
                } else {
                    return this.ProjectsService.userCloneProject(this.project.id, name)
                        .then(postClone, angular.noop);
                }
            } else if (action === 'merge') {
                return this.ProjectsService.mergeProject(this.project.id, projectId)
                    .then(postClone, angular.noop);
            }
        });
    }

    deleteProjectModal(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('./delete-project-confirmation-modal.tmpl.html'),
            resolve: {
                project: () => this.project
            },
            controller: class ProjectDeleteController {
                /* @ngInject */
                constructor($scope, $mdDialog, project) {
                    $scope.projectSlug = _.kebabCase(project.name);
                    $scope.userInput = null;

                    $scope.ok = function () {
                        $mdDialog.hide();
                    }

                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    }
                }
            }
        }).then(() => {
            this.deleteProject().then(() => this.$state.go('home'), angular.noop);
        });
    }

}
