import CodeMirror from 'codemirror';

export const MapboxJsonEditorComponent = {
    template: require('./mapbox-json-editor.component.html'),
    controllerAs: 'ctrl',
    bindings: {
        value: '<',
        prop: '<',
        attributes: '<',
        onChange: '&'
    },
    controller: class MapboxJsonEditorComponent {
        /* @ngInject */
        constructor($element, $timeout, ProjectService) {
            this.editor = null;
            this.valid = false;
            this.collectionAttributes = [];
            this.$element = $element;
            this.$timeout = $timeout;
            this.ProjectService = ProjectService;
        }

        codeChangeHandler(e, source) {
            if (source.origin === 'setValue') {
                return;
            }

            var current = e.doc.getValue();
            this.$timeout(() => {
                try {
                    var result = eval(current);
                    this.valid = true;
                    this.onChange({ result: result });
                } catch (e) {
                    console.warn(e);
                    this.valid = false;
                }
            }, 0);
        }

        update(state) {
            this.editor.getDoc().setValue(JSON.stringify(state, null, "\t"));
            this.onChange({
                result: state
            });
        }
        $onChanges(changes) {
            if (!this.editor) {
                this.editor = CodeMirror(this.$element[0].querySelector('.code'), {
                    mode: 'javascript',
                    theme: 'darcula',
                    json: true,
                    lineNumbers: true
                });
                this.editor.on('change', this.codeChangeHandler.bind(this));
                var code = JSON.stringify(changes.value.currentValue, null, "\t");
                this.editor.getDoc().setValue(code || '');
            }

            if (changes.prop) {
                var code = JSON.stringify(changes.value.currentValue, null, "\t");
                this.editor.getDoc().setValue(code || '');
            }

            if (changes.attributes) {
                this.collectionAttributes = _.filter(changes.attributes.currentValue, { type: 'select' });
                console.log('COLLECT', this.collectionAttributes);
            }


        };

        fromAttribute(attribute) {
            var op = 'to-' + this.prop.type;
            var f = [
                this.prop.type,
                [op, ['get', attribute.path]],
                this.prop.default
            ];
            this.update(f);
        };

        fromCollectionAttribute(attribute) {
            var collection = this.ProjectService.getCollection(attribute.collectionId);
            var op = 'to-' + this.prop.type;

            var f = [
                this.prop.type,
                [
                    op,
                    ['match', ['get', attribute.path]]
                ],
                this.prop.default
            ];
            _.each(collection.items, function (item) {
                f[1][1].push(item.key);
                f[1][1].push(item.value);
            });
            f[1][1].push(""); // The fallback for the match
            this.update(f);
        };
    }
};