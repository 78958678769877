
export const PasswordFeedbackComponent = {
    bindings: {
        password: '<'
    },
    template: `<div class="my-2 h-20 overflow-hidden">
        <ul class="grid grid-cols-2 h-full" ng-show="ctrl.password.$invalid">
            <li ng-repeat="check in ctrl.passwordConfig" class="text-xs text-grey-70 flex"
                ng-class="{'text-success': !ctrl.password.$error[check.key], 'text-grey-70': ctrl.password.$error[check.key]}">
                <i class="fas mr-4 w-1 h-1 text-xs input-feedback-dot" ng-class="{'fa-circle': ctrl.password.$error[check.key], 'fa-check': !ctrl.password.$error[check.key]}"
                    />{{check.message}}
            </li>
        </ul>
        <div ng-show="ctrl.password.$valid" class="text-success bg-success-lighter rounded-md flex items-center justify-center w-full h-12 mt-2">Your password is secure and you're all set</div>
    </div>`,
    controllerAs: 'ctrl',
    controller: class PasswordFeedbackComponent {
        /* @ngInject */
        constructor() {
            this.passwordConfig = [
                { message: '8 or more characters', key: 'length' },
                { message: '1 or more uppercase letter', key: 'uppercase' },
                { message: '1 or more lowercase letter', key: 'lowercase' },
                { message: '1 or more number', key: 'number' },
                { message: '1 or more special character', key: 'symbol' },
            ];
        }
    }
};
