import angular from 'angular';
import './mapbox-prop-editor.component.scss';

export const MapboxPropEditorComponent = {
    template: require('./mapbox-prop-editor.component.html'),
    controllerAs: 'ctrl',
    bindings: {
        layer: '<',
        prop: '<',
        value: '<',
        images: '<',
        onChange: '&',
        onReset: '&'
    },
    controller: class MapboxPropEditorComponent {
        /* @ngInject */
        constructor(ProjectService) {
            this.ProjectService = ProjectService;
            this.editor = null;
            this.valid = false;
            this.view = 'value';
            this.attributes = [];
        }
        switchView(view) {
            this.view = view;
        };

        changed() {
            this.onChange({ result: this.value });
        };

        onJsonChange(result) {
            this.onChange({ result: result });
        };

        $onInit() {
            var filter = this.layer.sourceType === 'records' ? { surveyId: this.layer.sourceId } : { parentCollectionId: this.layer.sourceId };
            this.attributes = _.filter(this.ProjectService.getAttributes(), filter);
        }

        $onChanges(changes) {
            if (changes.prop) {
                if (angular.isArray(this.value)) {
                    this.view = 'json';
                } else {
                    this.view = 'value';
                }
            }
        };

        reset() {
            this.onReset();
            this.view = 'value';
        };
    }
}