import { getProjectPage, getPageConfig, getPageMediaItems, getPageId, getPageForUpdate, getProjectId } from '../selectors';

// const fileMap = {};
const fileMap = new Map();


/* @ngInject */
const projectPageActions = (ProjectPagesService, ProjectMediaService) => {

    const initPage = id => (dispatch, getState) => {
        const state = getState();
        const page = getProjectPage(id)(state);

        fileMap.clear();

        dispatch({
            type: 'PAGE_INIT',
            page
        });
        return page;
    };

    const publishPage = () => (dispatch, getState) => {
        const id = getPageId(getState());
        return ProjectPagesService.updatePage({ published: true, id }).then(_result => {
            dispatch({
                type: 'PAGE_PUBLISH_SUCCESS',
                toast: 'Page Published',
                id
            });
        }, err => {
            dispatch({
                type: 'PAGE_PUBLISH_FAILURE',
                toastError: 'Page failed to publish',
                err
            });
        })
    }

    const unpublishPage = () => (dispatch, getState) => {
        const id = getPageId(getState());

        return ProjectPagesService.updatePage({ published: false, id }).then(_result => {
            dispatch({
                type: 'PAGE_UNPUBLISH_SUCCESS',
                toast: 'Page Unpublished',
                id
            });
        }, err => {
            dispatch({
                type: 'PAGE_UNPUBLISH_FAILURE',
                toastError: 'Page failed to unpublish',
                err
            });
        })
    }

    const savePage = () => (dispatch, getState) => {
        const getInUseFileIds = page => {
            const mediaBlocks = page.type === 'wysiwyg' && page.config && page.config.blocks ? page.config.blocks.filter(b => b.type === 'media') : [];
            return mediaBlocks.map(b => b.data && b.data.file && b.data.file.id);
        }
        
        const state = getState();

        const pageId = getPageId(state);
        const projectId = getProjectId(state);
        const page = getPageForUpdate(state);
        const inUseFileIds = getInUseFileIds(page);

        // Create any required media items
        const pageMediaItemsPromises = getPageMediaItems(state)
            .filter(m => (m.id < 0 && inUseFileIds.includes(m.id)))
            .map(({ id, fileId }) => {
                const fileMapItem = fileMap.get(fileId);
                return ProjectMediaService.createPageMediaItem({}, pageId, projectId, fileMapItem.file).then(result => {
                    fileMap.delete(fileId);
                    fileMapItem.resolve(result);
                    dispatch({
                        type: 'PAGE_CREATE_MEDIA_SUCCESS',
                        mediaItemId: result.id,
                        prevId: id,
                        url: result.url
                    });
                }, err => {
                    dispatch({
                        type: 'PAGE_CREATE_MEDIA_FAILURE',
                        err
                    });
                });
            });

        return Promise.all(pageMediaItemsPromises).then(() => {
            // note this is required as mediaItems & config can be modified in the previous step
            const updatedState = getState();
            const page = getPageForUpdate(updatedState);

            return ProjectPagesService.updatePage(page).then(() => {
                const inUseIds = getInUseFileIds(page);
                const toDeleteIds = getPageMediaItems(updatedState).filter(m => !inUseIds.includes(m.id)).map(m => m.id);

                const promise = toDeleteIds.length === 0 ? Promise.resolve() : ProjectMediaService.deleteMediaItems(toDeleteIds)
                    .then(() => dispatch({
                        type: 'PAGE_DELETE_MEDIA_SUCCESS',
                        ids: toDeleteIds
                    }),
                        err => dispatch({
                            type: 'PAGE_DELETE_MEDIA_FAILURE',
                            err
                        }));
                return promise.then(() => {
                    dispatch({
                        type: 'PAGE_SAVE_SUCCESS',
                        toast: 'Page Saved',
                        page,
                        mediaItems: getPageMediaItems(getState())
                    });
                }, err => {
                    return dispatch({
                        type: 'PAGE_SAVE_ERROR',
                        errors: [],
                        err,
                        toastError: 'Page blocks media failed to save'
                    });
                })
            }, err => {
                return dispatch({
                    type: 'PAGE_SAVE_ERROR',
                    err,
                    errors: [],
                    toastError: 'Page blocks failed to save'
                });
            });
        });
    }

    const switchView = view => ({
        type: 'PAGE_SWITCH_VIEW',
        view
    });

    const updatePageSettings = (title, route, icon, permissions, published, listed, config) => ({
        type: 'PAGE_UPDATE_SETTINGS',
        title,
        route,
        icon,
        permissions,
        published,
        listed,
        config
    });

    const updatePageConfig = config => ({
        type: 'PAGE_UPDATE_CONFIG',
        config
    });

    const createPageMediaItem = mediaItem => (dispatch) => {

        return new Promise(resolve => {
            const fileId = String(Date.now() + Math.floor(Math.random() * 100000));
            fileMap.set(fileId, {
                file: mediaItem.file,
                resolve
            });
            // fileMap[fileId] = mediaItem.file;

            const { file, ...mediaItemRest } = mediaItem;

            dispatch({
                type: 'PAGE_CREATE_MEDIA',
                mediaItem: {
                    fileId,
                    ...mediaItemRest
                }
            });
        });
    }

    return {
        initPage,
        savePage,
        switchView,
        updatePageSettings,
        updatePageConfig,
        unpublishPage,
        publishPage,
        createPageMediaItem
    };
}

export default projectPageActions;