import { getFormErrors, getFormSelectedAttributeId, getFormAttributes } from '../../../store/selectors';

export const ProjectFormsBlockComponent = {
    bindings: {
        attributeId: '<',
        endOfSection: '<',
        onRemove: '&',
        onAddSection: '&'
    },
    template: require('./project-forms-block.component.html'),
    controllerAs: 'ctrl',
    controller: class ProjectFormsBlockComponent {
        /* @ngInject */
        constructor($scope, $ngRedux, ProjectFormActions) {
            this.$scope = $scope;
            this.$ngRedux = $ngRedux;
            this.ProjectFormActions = ProjectFormActions;
            this.calculatedTypes = ['rgeolocation', 'geometryquery', 'coordinatetransform', 'webhook'];
            this.canDelete = true;
        }

        $onInit() {
            this.$scope.$on('$destroy', this.$ngRedux.connect((state) => {
                const errors = getFormErrors(state);

                return {
                    attribute: getFormAttributes(state).find(a => a.id === this.attributeId),
                    errors: errors[this.attributeId],
                    active: getFormSelectedAttributeId(state) === this.attributeId
                };
            }, this.ProjectFormActions)(this));
            if (this.attribute.questionType === 'geometry' && this.attribute.id > 0) {
                this.canDelete = false;
            }
        }

        isCalculatedType (type){
            return this.calculatedTypes.includes(type);
        }

        break(event) {
            event.preventDefault();
            event.stopPropagation();
            this.onAddSection({
                attribute: this.attribute
            });
        }

        delete(event) {
            event.preventDefault();
            event.stopPropagation();
            this.onRemove();
        }
    }
}