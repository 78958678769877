import { getAttributesForForm, getRecord, getRecordDraft, getRecordId, getFormAssociationAttributes, getProjectRoleMatches } from "../selectors";

const simplifyDetailedRecord = record => {
    record = angular.copy(record);
    console.log(record);
    if (record.logs) {
        record.logs = record.logs.logs; //count is not used for now, so simplify here
    }
    return record;
}

/* @ngInject */
const recordActions = (CoreoAPI, RecordsService, ProjectService) => {
    const detailedRecordFragment = `
    id,
    title,
    parentId,
    projectId,
    surveyId,
    createdAt,
    deletedAt,
    lat,
    lng,
    userId,
    requestId,
    geometry{
        type,
        coordinates
    },
    state,
    verificationState,
    data: values,
    user{
        imageUrl,
        username,
        displayName,
        id
    },
    children{
        id,
        surveyId,
        title,
        createdAt,
        user{
            displayName
        }
    },
    parent{
        id,
        title,
        surveyId,
        user{
            displayName
        }
    },
    attachments{
        id,
        attributeId,
        size,
        mimeType,
        url
    },
    associatesCount,
    comments{
        id
    }
    messages {
        id
    }
    `;

    const detailedRecordFragmentWithLogs = `${detailedRecordFragment}
    logs(order:"reverse:createdAt") {
        logs{
            id
            revertedId
            createdAt
            operation
            diff
            user {
                id
                displayName
                imageUrl
            }
        }
    }
    `

    const formAssociatesRecordFragment = associateAttributeIds => `
    associates(where: {
        attributeId: {
            in: ${JSON.stringify(associateAttributeIds)}
        }
    }) {
        association {
            id,
            label,
            questionType
        },
        record {
            id,
            title,
            user {
                displayName
            }
        }
    }`;

    const getRecordDetailed = (id, state) => {
        const returnFragment = getProjectRoleMatches(['admin', 'moderator'])(state) ? detailedRecordFragmentWithLogs : detailedRecordFragment;
        var query = `query AAGetRecordDetailed($id: Int!){ 
            record(id: $id, showDeleted: true) {
                ${returnFragment}
            }
        } `;

        return CoreoAPI.query(query, { variables: { id } }).then((data) => {
            if (!data.record) {
                throw "No such record";
            }
            data.record = simplifyDetailedRecord(data.record);
            //return data.record;

            return getRecordFormAssociates(id, data.record.surveyId, state).then(associatesData => {
                data.record.associates = associatesData.associates;
                return data.record;
            });
        })
    };
    //Form associates, meaning association questions specific to this form
    const getRecordFormAssociates = (id, surveyId, state) => {
        const associationAttributes = getFormAssociationAttributes(surveyId)(state);
        var query = `query AAGetRecordDetailed($id: Int!){ 
            record(id: $id, showDeleted: true) {
                ${formAssociatesRecordFragment(associationAttributes.map(a => a.id))}
            }
        } `;
        return CoreoAPI.query(query, { variables: { id } }).then((data) => {
            if (!data.record) {
                throw "No such record";
            }
            if (data.record.logs) {
                data.record.logs = data.record.logs.logs; //count is not used for now, so simplify here
            }
            return data.record;
        });
    };

    const getDetailedRecord = (id) => (dispatch, getState) => {
        const state = getState();

        return getRecordDetailed(id, state).then(record => {
            dispatch({
                type: 'RECORD_DETAILS_LOAD_SUCCESS',
                record,
                id
            });
        }).catch(err => {
            throw new Error(err)
        });
    };

    const recordDetailsLoadSuccess = (record, id, toast) => ({
        type: 'RECORD_DETAILS_LOAD_SUCCESS',
        toast,
        record,
        id
    });

    const recordDetailsLoadFailure = (err) => ({
        type: 'RECORD_DETAILS_LOAD_FAILURE',
        toast: {
            type: 'error',
            message: 'Record failed to update. Ensure values entered are valid.'
        },
        err
    });


    const updateGeometryAndReload = (geometry) => (dispatch, getState) => {
        const state = getState();
        const id = getRecordId(state);

        var geometryStr = CoreoAPI.gqlGeometryStringify(geometry);
        var mutation = `mutation{result: updateRecord(input: {id: ${id}, geometry: ${geometryStr}})}`;

        dispatch({
            type: 'RECORD_GEOMETRY_UPDATE',
            geometry
        });
        return CoreoAPI.mutation(mutation)
            .then(() => getRecordDetailed(id, state))
            .then(record => dispatch(recordDetailsLoadSuccess(record, id, 'Record updated')))
            .catch((err) => dispatch(recordDetailsLoadFailure(err)));
    }

    const recordUpdateState = newState => (dispatch, getState) => {
        const state = getState();
        const id = getRecordId(state);

        const mutation = `mutation{result: verifyRecord(input: {id: ${id}, state: ${newState}})}`;
        return CoreoAPI.mutation(mutation).then(() => {
            dispatch({
                type: 'RECORDS_UPDATE_SELECTED_STATE_SUCCESS',
                state: newState,
            });
        }).then(() => getRecordDetailed(id, state))
            .then(record => dispatch(recordDetailsLoadSuccess(record, id, 'Record updated')))
            .catch((err) => dispatch(recordDetailsLoadFailure(err)));
    }

    const recordDeleteSuccess = () => ({
        type: 'RECORD_DELETE_SUCCESS',
        toast: 'Record deleted'
    })

    const recordDeleteFailure = (err) => ({
        type: 'RECORD_DELETE_FAILURE',
        toast: {
            type: 'error',
            message: 'Record did not successfully delete'
        },
        err
    })

    const recordDelete = () => (dispatch, getState) => {
        const state = getState();
        const id = getRecordId(state);

        dispatch({
            type: 'RECORD_DELETE'
        });

        return RecordsService.deleteRecord(id)
            .then(() => {
                dispatch(recordDeleteSuccess());
            })
            .catch((err) => dispatch(recordDeleteFailure(err)))
            .then(() => getRecordDetailed(id, state))
            .then(record => dispatch(recordDetailsLoadSuccess(record, id)))
            .catch((err) => dispatch(recordDetailsLoadFailure(err)));
    }

    const recordRestoreSuccess = () => ({
        type: 'RECORD_RESTORE_SUCCESS',
        toast: 'Record restored'
    })

    const recordRestoreFailure = (err) => ({
        type: 'RECORD_RESTORE_FAILURE',
        toast: {
            type: 'error',
            message: 'Record did not successfully restore'
        },
        err
    })

    const recordRestore = () => (dispatch, getState) => {
        const state = getState();
        const id = getRecordId(state);

        dispatch({
            type: 'RECORD_RESTORE'
        });

        return RecordsService.undeleteRecord(id)
            .then(() => {
                dispatch(recordRestoreSuccess());
            })
            .catch((err) => dispatch(recordRestoreFailure(err)))
            .then(() => getRecordDetailed(id, state))
            .then(record => dispatch(recordDetailsLoadSuccess(record, id, null)))
            .catch((err) => dispatch(recordDetailsLoadFailure(err)));
    };

    const recordEdit = (record = null) => ({
        type: 'RECORD_EDIT',
        record
    });

    const recordEditDiscard = () => ({
        type: 'RECORD_EDIT_DISCARD'
    });

    const recordEditSave = () => (dispatch, getState) => {
        const state = getState();
        const id = getRecordId(state);
        const draft = getRecordDraft(state);
        const base = getRecord(state);
        const attributes = getAttributesForForm(draft.surveyId)(state);
        const returnFragment = getProjectRoleMatches(['admin', 'moderator'])(state) ? detailedRecordFragmentWithLogs : detailedRecordFragment;

        dispatch({
            type: 'RECORD_EDIT_SAVE',
            draft
        });

        const mutation = `mutation AAEditRecord($data: SequelizeJSON, $refCreate: [CreateReferenceInput], $refDelete: [DeleteReferenceInput], $attachmentCreate: [CreateAttachmentInput], $attachmentDelete: [DeleteAttachmentInput]){
            result: changeRecord(input: {id: ${id}, data: $data, createReference: $refCreate, deleteReference: $refDelete, createAttachment: $attachmentCreate, deleteAttachment: $attachmentDelete}){
                ${returnFragment}
            }
        }`;

        const { data } = draft;
        const validPaths = attributes.filter(a => a.path).map(a => a.path);
        const cleanedData = Object.fromEntries(
            Object.entries(data).filter(([key]) => validPaths.includes(key))
        );

        const refCreate = [];
        const refDelete = [];

        // Go through the draft refs, find ones to create or replace
        for (const draftAssociation of draft.associates) {
            const attributeId = draftAssociation.association.id;
            // Find the match in the original record
            const baseAssociation = base.associates.find(a => a.association.id === attributeId);

            // If we didn't find it, add this association
            if (typeof baseAssociation === 'undefined') {
                refCreate.push({
                    id: draftAssociation.record.id,
                    attributeId
                });
            } else if (baseAssociation.record.id !== draftAssociation.record.id) {
                // Else we're replacing it
                refCreate.push({
                    id: draftAssociation.record.id,
                    attributeId
                });
                refDelete.push({
                    id: baseAssociation.record.id,
                    attributeId
                });
            }
        }

        // Go through the base refs, find ones to delete
        for (const baseAssociation of base.associates) {
            const e = draft.associates.find(d => d.association.id === baseAssociation.association.id);
            if (typeof e === 'undefined') {
                refDelete.push({
                    id: baseAssociation.record.id,
                    attributeId: baseAssociation.association.id
                });
            }
        }

        const attachmentCreate = [];
        const attachmentDelete = [];
        const attachmentPromises = [];

        // Find attachments to add
        for (const attachment of draft.attachments) {
            const { file, attribute } = attachment;
            if (file instanceof File) {
                attachmentPromises.push(ProjectService.uploadMedia(draft.projectId, attachment.file).then(url => {
                    attachmentCreate.push({
                        url,
                        attributeId: attribute.id
                    });
                    if (attribute.type !== 'attachment') {
                        cleanedData[attribute.path] = url;
                    }
                }));
            }
        }

        // And ones to delete
        for (const attachment of base.attachments) {
            const draftAttachmentIdx = draft.attachments.findIndex(a => a.id === attachment.id);
            if (draftAttachmentIdx === -1 && !!attachment.id) { // if id === 0 it's simulated and does not need to be deleted
                attachmentDelete.push({
                    id: attachment.id
                });
            }
        }

        const variables = {
            data: cleanedData,
            refCreate,
            refDelete,
            attachmentCreate,
            attachmentDelete
        };

        return Promise.all(attachmentPromises).then(() => CoreoAPI.mutation(mutation, {}, { variables })
            .then((result) => {
                result = simplifyDetailedRecord(result);
                return getRecordFormAssociates(id, draft.surveyId, state).then(associatesResult => {
                    result.associates = associatesResult.associates;
                    dispatch({
                        type: 'RECORD_EDIT_SAVE_SUCCESS',
                        record: result,
                        toast: 'Record Updated'
                    });
                });
            })
            .catch((err) => dispatch({
                type: 'RECORD_EDIT_SAVE_FAILURE',
                err
            }))
        );
    };

    const recordUpdateDraftData = (data) => ({
        type: 'RECORD_UPDATE_DRAFT_DATA',
        data
    });

    const recordUpdateDraftAssociation = (record, attribute) => ({
        type: 'RECORD_UPDATE_DRAFT_ASSOCIATION',
        attribute,
        record
    });

    const recordAddDraftAttachment = (attribute, file, url) => ({
        type: 'RECORD_ADD_DRAFT_ATTACHMENT',
        attribute,
        file,
        url
    });

    const recordDeleteDraftAttachment = (attribute, attachment) => ({
        type: 'RECORD_DELETE_DRAFT_ATTACHMENT',
        attribute,
        attachment
    });

    return {
        getDetailedRecord,
        updateGeometryAndReload,
        recordUpdateState,
        recordDelete,
        recordRestore,
        recordEdit,
        recordEditDiscard,
        recordEditSave,
        recordUpdateDraftData,
        recordUpdateDraftAssociation,
        recordAddDraftAttachment,
        recordDeleteDraftAttachment
    }
};


export default recordActions;