import './project-pages.scss';
import { getProjectRole, getProjectPages, getProject } from '../../store/selectors';

const DEFAULT_AUTH = 'all';
const DEFAULT_ICON = 'help';

/* @ngInject */
export default class ProjectPagesController {
  constructor($window, $scope, $state, $ngRedux, toastr, $mdDialog, ProjectPagesService, ProjectActions) {
    this.toastr = toastr;
    this.$mdDialog = $mdDialog;
    this.ProjectPagesService = ProjectPagesService;
    this.$window = $window;
    this.$scope = $scope;
    this.$state = $state;

    $scope.$on('$destroy', $ngRedux.connect((state) => {
      return {
        project: getProject(state),
        role: getProjectRole(state),
        pages: _.sortBy(angular.copy(getProjectPages(state)), ['order'])
      }
    }, ProjectActions)(this));

    this.readonly = this.role !== 'admin';

    this.pageListConfig = {
      axis: 'y',
      containment: 'parent'
    };

    this.typeMap = {
      wysiwyg: 'Custom',
      collection: 'Collection',
      map: 'Map'
    };

    this.pagesReordered = this.pagesReordered.bind(this);
  }

  $onInit() {
  }

  flattenPages(pages, depth, output) {
    pages.forEach(page => {
      output.push(_.assign({}, page, { depth: depth }));
      this.flattenPages(page.children, depth + 1, output);
    });
  }

  addPage(ev) {
    this.$mdDialog.show({
      // parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      template: require('./new-page.modal.html'),

      /* @ngInject */
      controller: function ($scope, $mdDialog, ProjectPagesService) {
        $scope.page = { type: 'wysiwyg' };


        $scope.selectType = function (type) {
          $scope.type = type;
        };

        $scope.templates = [{
          title: 'Custom',
          id: 'wysiwyg',
          description: 'Create your own page, using the Coreo page builder.',
          icon: 'glyphicon-pencil',
          type: 'wysiwyg',
          selected: true
        }, {
          title: 'Collection',
          id: 'collection',
          description: 'A List/Detail view of a collection',
          icon: 'glyphicon-list-alt',
          type: 'collection',
          selected: false
        }, {
          title: 'Map',
          id: 'Map',
          description: 'Displays a custom map',
          icon: 'glyphicon-map-marker',
          type: 'map',
          selected: false
        }];

        var defaultType = $scope.templates[0];

        $scope.type = defaultType.id;
        $scope.templateDescription = defaultType.description;

        $scope.onHoverOverTemplate = function (t) {
          $scope.templateDescription = t.description;
        };
        $scope.onLeaveTemplate = function (t) {
          $scope.templateDescription = _.find($scope.templates, { selected: true }).description;
        };

        $scope.onClickTemplate = function (t) {
          for (var i = 0; i < $scope.templates.length; i++) {
            $scope.templates[i].selected = false;
          }
          t.selected = true;
          $scope.page.type = t.type;
        };

        $scope.ok = function () {
          $mdDialog.hide({
            name: $scope.page.name,
            route: $scope.page.route,
            type: $scope.page.type
          });
        };

        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      }
    }).then((pageObj) => {
      var newPage = {
        title: pageObj.name,
        projectId: this.project.id,
        type: pageObj.type,
        slug: _.kebabCase(name),
        order: this.pages.length,
        published: false,
        auth: DEFAULT_AUTH,
        listed: true,
        icon: DEFAULT_ICON,
        config: {},
      };

      this.addProjectPage(newPage).then(page => {
        this.go(page);
      });
    }, angular.noop);
  };


  pagesReordered() {
    const pageUpdates = this.pages.reduce((acc, page, i) => {
      acc.push({
        id: page.id,
        order: i
      });
      return acc;
    }, []);

    if (pageUpdates.length > 0) {
      this.reorderProjectPages(pageUpdates);
    }
  };

  deletePage(page) {
    const vm = this;
    this.$mdDialog.show({
      template: require('../pages/delete-page.modal.html'),
      /* @ngInject */
      controller: ($scope, $mdDialog) => {
        $scope.ok = function () {
          vm.deleteProjectPage(page.id);
          $mdDialog.hide();
        };

        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      }
    });
  }

  go(page) {
    this.$state.go('project.page', { page_id: page.id });
  };
}
