import * as Sentry from '@sentry/browser';
import { unzip } from 'unzipit';
import angular from 'angular';
import './project-collections.scss';
import { parseDBFFieldNames } from '../records/project-records-import/helpers';
import { getProjectCollections, getProjectId } from '../../store/selectors';

/* @ngInject */
export default class ProjectCollectionsController {

    constructor($scope, $ngRedux, $state, ProjectActions, $timeout, toastr, $mdDialog, GeoJSON) {
        this.collection = null;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$mdDialog = $mdDialog;
        this.GeoJSON = GeoJSON;
        this.toastr = toastr;
        this.$scope = $scope;

      $scope.$on('$destroy', $ngRedux.connect((state) => {
          return {
              collections: getProjectCollections(state),
              projectId: getProjectId(state)
          }
      }, ProjectActions)(this));
  }

    newCollection(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('./new-collection.modal.html'),
            controllerAs: 'ctrl',
            locals: {
                projectId: this.projectId
            },
            bindToController: true,
            controller: class NewCollectionController {
                /* @ngInject */
                constructor($scope, $mdDialog, $timeout, $ngRedux, GeoJSON, ProjectActions) {
              this.$mdDialog = $mdDialog;
              this.$timeout = $timeout;
              this.GeoJSON = GeoJSON;
              this.saving = false;

              $scope.$on('$destroy', $ngRedux.connect(null, ProjectActions)(this));

              this.collection = {
                  name: '',
                  type: 'text',
                  geometryKeyProperty: '',
                  geometryValueProperty: ''
              };
              this.collectionTemplate = null;
              this.badFile = false;
              this.file = null;
              this.fields = [];
              this.geoJsonProperties = [];

              this.templates = [{
                  title: 'Empty',
                  id: 'empty',
                  description: 'An empty collection',
                  icon: 'glyphicon-unchecked',
                  type: 'text'
              }, {
                  title: 'From CSV',
                  id: 'csv',
                  description: 'Upload a CSV File',
                  icon: 'glyphicon-list',
                  type: 'text'
              }, {
                  title: 'From GeoJSON',
                  id: 'geojson',
                  description: 'Upload a GeoJSON file',
                  icon: 'glyphicon-map-marker',
                  type: 'geometry'
              }, {
                  title: 'From Shapefile (zipped)',
                  id: 'shapefile',
                  description: 'Upload a (zipped) Shapefile',
                  icon: 'glyphicon-map-marker',
                  type: 'geometry'
              }];
              this.readFile = this.readFile.bind(this);
              this.readGeoJSONFile = this.readGeoJSONFile.bind(this);
              this.readShapefile = this.readShapefile.bind(this);
          }

          ok() {
              this.saving = true;

            const files = this.collectionTemplate.id === 'empty' ? {} : { [this.collectionTemplate.id]: this.file };
            this.createCollection(this.collection, files)
                .then(collection => this.$mdDialog.hide(collection))
                .catch(e => this.$mdDialog.cancel(e))
                .then(() => this.saving = false);
        }

          cancel() {
              this.$mdDialog.cancel();
          }

          selectTemplate(t) {
              this.collectionTemplate = t;
              this.collection.type = t.type;
          };

          readFile(e) {
              this.$timeout(() => {
                  this.file = (e.srcElement || e.target).files[0];
              }, 0);
          };

          readGeoJSONFile(e) {
              var file = (e.srcElement || e.target).files[0];
              this.file = file;

            this.GeoJSON.readGeoJSONFile(file).then((result) => {
                this.geoJsonProperties = result.geoJsonProperties;
              this.fields = result.geoJsonPaths;

              if (this.fields.length > 0) {
                  this.collection.geometryKeyProperty = this.fields[0];
                  this.collection.geometryValueProperty = this.fields[1];
              }
              this.$scope.$apply();
          }, (err) => {
              console.warn(err);
              this.badFile = true;
          });
          };

          async readShapefile(e) {
              const file = (e.srcElement || e.target).files[0];
              this.file = file;
              let unzipped, entries, shpEntries, dbfEntry;
              try {
                  unzipped = await unzip(file);
                  //filter out files without extension as well as osx archive utility metadata 
                  entries = Object.values(unzipped.entries).filter(f => f.name.includes('.') && !f.name.includes('_MACOSX'));
              } catch (e) {
                  console.log(e);
                  throw new Error('Unable to unzip provided file')
              }
              shpEntries = entries.filter(f => f.name.split('.').pop() === 'shp');
              if (shpEntries.length === 0) {
                  throw new Error('No .shp found in provided zip, please shapefile is included in top directory of zip')
              }
              if (shpEntries.length > 1) {
                  throw new Error('More than one shapefile included in provided zip')
              }
              try {
                  dbfEntry = entries.find(f => f.name.split('.').pop() === 'dbf');
                  if (!dbfEntry) throw new Error();
              } catch (e) {
                  console.log(e);
                  throw new Error('Unable to find dbf file in provided shapefile zip')
              }
              try {
                  const dbfArrayBuffer = await dbfEntry.arrayBuffer();
                  this.fields = parseDBFFieldNames(dbfArrayBuffer);
                  this.$scope.$apply();
              } catch (e) {
                  console.log(e);
                  throw new Error('Unable to read dbf table in shapefile')
              }
          }
        }
    }).then((collection) => {
        this.go(collection);
        this.toastr.success('Collection created.');
    }, (err) => {
        if (err) {
            console.error(err);

          this.toastr.error('There was an error generating your collection. Please check your input source and try again. For further information see <a target="_blank" href="https://docs.coreo.io/data-model/collections">here</a>', {
              allowHtml: true
          });
          Sentry.captureException(err);
        }
    });

    };

    go(collection) {
        this.$state.go('project.collection', { collection_id: collection.id });
    };
}
