import './record-media.scss';
export const RecordMediaComponent = {
    template: require('./record-media.component.html'),
    bindings: {
        url: '=',
        type: '='
    },
    controllerAs: 'ctrl',
    controller: class RecordMediaComponentController {
        /* @ngInject */
        constructor(Lightbox) {
            this.Lightbox = Lightbox;
            this.mode = null;
        }

        $onChanges() {
            if (!this.type) {
                return this.mode = null;
            }

            if (this.type === 'photo' || this.type.startsWith('image')) {
                this.mode = 'photo';
            } else if (this.type.startsWith('video')) {
                this.mode = 'video';
            } else if (this.type.startsWith('audio')) {
                this.mode = 'audio';
            } else {
                this.mode = null;
            }
        }

        onMediaClick() {
            if (this.mode === 'photo') {
                this.Lightbox.openModal([{
                    url: this.url
                }], 0);
            }
        }
  }
}