import "./home.scss";
import { getAuthUser, getOrganisationsList, getProjects, getOrganisationsForRoles } from "../store/selectors";
import noIcon from '../assets/icons/no-icon.png';
import { USER_PREFERENCES, USER_PREFERENCE_VERSIONS, HOME_TUTORIAL } from '../helpers/user-preferences';

/* @ngInject */
export default class HomeController {

    constructor($scope, $ngRedux, $mdDialog, $state, $rootScope, $timeout, ProjectsActions, OrganisationsActions, AuthActions) {

        this.$mdDialog = $mdDialog;
        this.$state = $state;
        this.$ngRedux = $ngRedux;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.ProjectsActions = ProjectsActions;
        this.OrganisationsActions = OrganisationsActions;
        this.AuthActions = AuthActions;
        this.tutorialKey = HOME_TUTORIAL;

        $scope.$on('$destroy', $ngRedux.connect(state => {
            return {
                user: getAuthUser(state),
                organisations: getOrganisationsList(state),
                adminOrganisations: getOrganisationsForRoles(['owner', 'admin'])(state),
                projects: getProjects(state)
            }
        })(this));
        this.noIcon = noIcon;
        this.isOrganisationAdmin = this.adminOrganisations.length > 0;

        if (!this.user.preferences || !this.user.preferences[USER_PREFERENCES.WELCOME_MODAL] || this.user.preferences[USER_PREFERENCES.WELCOME_MODAL] !== USER_PREFERENCE_VERSIONS.WELCOME_MODAL) {
            this.$mdDialog.show({
                parent: angular.element(document.body),
                //targetEvent: ev,
                clickOutsideToClose: true,
                template: require('../main/welcome-modal.html'),
                controllerAs: 'ctrl',
                controller: class WelcomeModalController {
                    /* @ngInject */
                    constructor($scope, $mdDialog, $rootScope, $ngRedux, AuthActions) {
                        this.$mdDialog = $mdDialog;
                        this.$rootScope = $rootScope;
                        this.AuthActions = AuthActions;
                        this.$ngRedux = $ngRedux;

                        $scope.$on("$destroy", () => this.saveAndOpenTutorial());
                    }

                    saveAndOpenTutorial() {
                        // Store welcome viewed on user
                        // Initiate coachmarks
                        this.$ngRedux.dispatch(this.AuthActions.updateUserPreferences({ [USER_PREFERENCES.WELCOME_MODAL]: USER_PREFERENCE_VERSIONS.WELCOME_MODAL })).then(_ => {
                            this.$rootScope.$broadcast('$tutorial:restart');
                        });
                    }

                    ok() {
                        this.$mdDialog.hide();
                        this.saveAndOpenTutorial();
                    };
                },
            });
        }
    }

    $onInit() {
        const tutorialUserPref = this.user.preferences[USER_PREFERENCES[this.tutorialKey]];

        if (this.user.preferences && this.user.preferences[USER_PREFERENCES.WELCOME_MODAL] === USER_PREFERENCE_VERSIONS.WELCOME_MODAL && (!tutorialUserPref || tutorialUserPref !== USER_PREFERENCE_VERSIONS[this.tutorialKey])) {
            this.$timeout(() => this.$rootScope.$broadcast('$tutorial:restart'), 200);
        }
    }

    createProject(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('../projects/new-modal.html'),
            controllerAs: 'ctrl',
            resolve: {
                organisations: () => this.user.role === 'admin' ? this.organisations : this.adminOrganisations
            },
            controller: class HomeCreateProjectController {
                /* @ngInject */
                constructor($mdDialog, organisations) {
                    this.$mdDialog = $mdDialog;
                    this.organisations = organisations;
                    this.project = {};

                    if (this.organisations.length === 1) {
                        this.organisation = this.organisations[0];
                    }
                }

                ok() {
                    this.project.organisationId = this.organisation.id;
                    this.$mdDialog.hide(this.project);
                };

                cancel() {
                    this.$mdDialog.cancel();
                };
            }
        }).then(({ name, description, organisationId }) => {
            const project = {
                name,
                shortName: name,
                description,
                organisationId
            };

            const action = this.ProjectsActions.createProject(project);
            this.$ngRedux.dispatch(action).then(project => {
                const organisation = this.organisations.find(o => o.id === organisationId);
                this.$state.go('project.dashboard', { orgSlug: organisation.slug, id: project.slug });
            });

        }, angular.noop);
    }

    goToProject(project) {
        const orgSlug = project && project.organisation && project.organisation.slug;
        if (typeof orgSlug !== 'undefined') {
            this.$state.go('project.dashboard', {
                orgSlug,
                id: project.slug
            });
        }
    }

    openStartFreeTrialModal(ev) {
        console.log(this.OrganisationsActions)
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('../organisations/start-free-trial.html'),
            controllerAs: 'ctrl',
            controller: class HomeStartFreeTrialController {
                /* @ngInject */
                constructor($mdDialog,) {
                    this.$mdDialog = $mdDialog;
                    this.organisationName = null;
                }

                ok() {
                    this.$mdDialog.hide(this.organisationName);
                };

                cancel() {
                    this.$mdDialog.cancel();
                };
            }
        }).then(organisationName => {
            const action = this.OrganisationsActions.startFreeTrial(organisationName);
            this.$ngRedux.dispatch(action).then(organisation => {
                this.$state.go('organisation.dashboard', { orgSlug: organisation.slug });
            });
        }, angular.noop);
    }
}
