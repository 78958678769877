import './admin-mailing.scss';
import * as Sentry from '@sentry/browser';
/* @ngInject */
export default class AdminMailingController {
    constructor($http, domains, templates, projects, toastr) {
        this.projects = projects;
        this.domains = domains;
        this.templates = templates;
        this.toastr = toastr;
        this.$http;

        this.projectId = null;
        this.from = null;
        this.templateId = null;
        this.isMarketing = true;

        this.sending = false;
    }

    marketingWarning() {
        if (!this.isMarketing) {
            this.isMarketing = !confirm("Are you certain this message contains no elements of marketing?");
        }
    };

    send() {
        this.sending = true;
        var mail = {
            projectId: this.projectId,
            templateId: this.templateId,
            from: this.from || "coreo@coreo.io",//vm.from
            isMarketing: this.isMarketing
        };
        return this.$http.post('/mail/', mail).then((res) => {
            this.sending = false;
            this.toastr.success('Email sent!');
            return res.data;
        }, (err) => {
            this.sending = false;
            this.toastr.error('Email were not sent!');
            Sentry.captureException(err);
        });
    };
}
