/* @ngInject */
export default class AdminUserApiKeyController {
  constructor(AdminUsersService, user, apikey) {
    this.user = user;
    this.apikey = apikey;
    this.AdminUsersService = AdminUsersService;
  }

  create() {
    this.AdminUsersService.createApiKey(this.user.id).then((apikey) => {
      this.apikey = apikey;
    });
  }

  refresh() {
    this.AdminUsersService.refreshApiKey(this.user.id).then((apikey) => {
      this.apikey = apikey;
    });
  }

  remove() {
    this.AdminUsersService.removeApiKey(this.user.id).then((apikey) => {
      this.apikey = null;
    });
  }
}
