import { IComponentController } from 'angular';
import ngRedux from 'ng-redux';
import { getProjectFormsContainingGeometry, getProjectCollections, getProjectMapLayers } from '../../../../store/selectors';
import { CoreoCollection } from '../../../../types';

interface LayerState {
    name: string;
    enabled: boolean;
    type: 'records' | 'collection' | 'custom';
    id: number;
    sort: number;
}
export default class ProjectFormsBlockMapLayersController implements IComponentController {

    forms: LayerState[] = [];
    other: LayerState[] = [];
    /* @ngInject */
    constructor(private $mdDialog, private $ngRedux: ngRedux.INgRedux, private layers: any) {
    }

    $onInit(): void {
        const state = this.$ngRedux.getState();
        const enabledForms = this.layers?.filter(f => f.type === 'records').map(f => f.id) ?? [];
        const enabledCollections = this.layers?.filter(f => f.type === 'collection').map(f => f.id) ?? [];
        const enabledLayers = this.layers?.filter(f => f.type === 'custom').map(f => f.id) ?? [];

        console.log('HERE?!');

        this.forms = getProjectFormsContainingGeometry(state).map(form => ({
            name: form.name,
            type: 'records',
            enabled: enabledForms.indexOf(form.id) !== -1,
            id: form.id,
            sort: form.mapSort
        }));

        this.other = [];
        const collections: CoreoCollection[] = getProjectCollections(state).filter(c => c.geometric && c.mapLayer);
        for (const collection of collections) {
            this.other.push({
                type: 'collection',
                name: collection.name,
                id: collection.id,
                enabled: enabledCollections.indexOf(collection.id) !== -1,
                sort: collection.mapSort
            });
        }

        const layers = getProjectMapLayers(state);
        for (const layer of layers) {
            this.other.push({
                name: layer.name,
                type: 'custom',
                id: layer.id,
                enabled: enabledLayers.indexOf(layer.id) !== -1,
                sort: layer.sort
            });
        }
        this.other.sort((a, b) => a.sort - b.sort);
    }

    cancel() {
        this.$mdDialog.hide();
    }

    done() {
        const enabled = [
            ...this.forms.filter(s => s.enabled),
            ...this.other.filter(s => s.enabled)
        ].map(a => ({
            id: a.id,
            type: a.type
        }));
        this.$mdDialog.hide(enabled);
    }
};