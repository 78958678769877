import './join-project.scss';
import noIcon from '../../assets/icons/no-icon.png';
import ErrorMessages from '../errorMessages';
/* @ngInject */
export default class IntermediaryPageJoinProjectController {
    constructor($stateParams, IntermediaryPageService) {
        this.noIcon = noIcon;
        this.IntermediaryPageService = IntermediaryPageService;
        this.loading = true;
        this.error = false;
        const data = $stateParams.data;
        if (!data) {
            this.handleErr();
        }
        try {
            const decodedParams = JSON.parse(window.atob(data.replace(/_/g, '/').replace(/-/g, '+')));
            this.projectId = decodedParams.projectId;
            this.url = decodedParams.url;
            if (!this.url || !this.projectId) {
                this.handleErr();
            }
        } catch (_e) {
            console.log(_e)
            this.handleErr()
        }

    }

    $onInit() {
        if (this.loading) {
            this.IntermediaryPageService.getProjectOverview(this.projectId).then(projectOverview => {
                this.projectOverview = projectOverview;
                this.loading = false;
            }, e => {
                this.error = e;
                this.loading = false;
            })
        }
    } 

    handleErr() {
        this.loading = false;
        this.error = ErrorMessages.UnableToGetProjectOverview
    }
}
