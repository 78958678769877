import angular from 'angular';
import * as Sentry from '@sentry/browser';
import JobsService from './jobs.service';
import { getAuthIsLoggedIn } from '../store/selectors';
import {
    JOB_COMPLETE_STATUS,
    JOB_CREATED_STATUS,
    JOB_DELETED_STATUS,
    JOB_FAILED_STATUS,
} from './jobs.constants';

export default angular.module('app.jobs', [])
    .service('JobsService', JobsService)
    .config(function (toastrConfig) {
        angular.extend(toastrConfig, {
            autoDismiss: false,
            containerId: 'toast-container',
            maxOpened: 0,
            newestOnTop: true,
            positionClass: 'toast-bottom-right',
            preventDuplicates: false,
            preventOpenDuplicates: false,
            tapToDismiss: false,
            target: 'body'
        });
    })
    .run(function ($rootScope, $interval, $ngRedux, toastr, Auth, JobsService) {
        var intervalPromise = null;

        var baseToastOptions = {
            autoDismiss: false,
            allowHtml: true,
            timeOut: 0,
            extendedTimeOut: 0,
            hideDuration: 0,
            showDuration: 0,
            closeButton: true,
            positionClass: 'toast-bottom-right',
            onTap: function (toast) {
                // console.log('Toast??', toast);
            },
            onHidden: function (closedOnClick, toast) {
                if (closedOnClick) {
                    JobsService.deleteJob(toast.job).then(() => {
                        JobsService.deleteJobIndexEntry(toast.job.id);
                    })
                }
            }
        };

        const isLoggedIn = () => getAuthIsLoggedIn($ngRedux.getState());

        function jobTitle(job) {
            switch (job.type) {
                case 'export_csv': return job.project.name + ' - Export to CSV';
                case 'export_collection': return job.project.name + ' - Export Collection';
                case 'export_geojson': return job.project.name + ' - Export GeoJSON';
                case 'cloneProject': {
                    const isMerge = job.options && job.options.targetId;
                    return `${isMerge ? 'Merge' : 'Clone'} ${job.project.name} Project`
                };
                default: return job.type;
            }
        }

        function toastComplete(job) {


            if (job.url) {
                const cta = job.type === 'cloneProject' ? 'Go To New Project' : 'Download';
                const icon = job.type === 'cloneProject' ? 'fa-link' : 'fa-download';
                const link = `<a class="btn btn-material btn-default btn-full" href="${job.url}">
                    <i class="fas fa-fw ${icon}" />
                    ${cta}
                </a>`;
                return toastr.success(link, jobTitle(job), baseToastOptions);
            } else {
                return toastr.success('Job Completed', jobTitle(job), baseToastOptions);
            }
        }

        function toastPending(job) {
            var title = jobTitle(job);

            return toastr.info('Job Pending', title, angular.extend({}, baseToastOptions, {
            }));
        }

        function toastFailure(job) {
            if (!JobsService.isValidationError(job)) {
                Sentry.captureException(new Error(`Job ${job.id} failed - ${job.message}`));
            }
            return toastr.error('Job Failed:\n' + job.message ? ' ' + job.message : '', jobTitle(job), angular.extend({}, baseToastOptions, {

            }));
        }

        function toastActive(job) {
            var content = '<i class="spinner spinner-white"></i>Job Running';
            return toastr.info(content, jobTitle(job), angular.extend({}, baseToastOptions, {
                iconClass: 'toast-active'
            }));
        }

        function createToast(job) {
            var toast;

            switch (job.status) {
                case 'complete': toast = toastComplete(job); break;
                case 'pending': toast = toastPending(job); break;
                case 'processing': case 'active': toast = toastActive(job); break;
                case 'failed': toast = toastFailure(job); break;
                default: {
                    console.warn('UNKNOWN JOB STATUS', job);
                    return null;
                }
            }
            toast.job = job;
            return toast;
        }

        function processJobs() {
            if (!isLoggedIn()) {
                return Promise.resolve();
            }
            return JobsService.getJobs().then(function (jobs) {
                let jobsIndex = JobsService.getJobsIndex();
                for (const job of jobs) {
                    // New job, or job status has changed
                    if (!jobsIndex.hasOwnProperty(job.id) || (jobsIndex[job.id].status !== job.status)) {
                        if (jobsIndex[job.id]) {
                            toastr.clear(jobsIndex[job.id].toast);
                            JobsService.applyHook(job.status, job);
                        } else if (job.status !== JOB_COMPLETE_STATUS || job.status !== JOB_FAILED_STATUS) {
                            JobsService.applyHook(JOB_CREATED_STATUS, job);
                        }
                        var jobToast = createToast(job);
                        jobsIndex = JobsService.createOrUpdateJobsIndexEntry(job.id, {
                            toast: jobToast,
                            status: job.status
                        });
                    }
                }


                var jobsKeys = Object.keys(jobsIndex);
                for (const key of jobsKeys) {
                    var j = _.find(jobs, { id: +key });
                    if (typeof j === 'undefined') {
                        JobsService.clearJobToast(jobsIndex[key].toast.job.id);
                        JobsService.applyHook(JOB_DELETED_STATUS, jobsIndex[key].toast.job);
                        jobsIndex = JobsService.deleteJobsIndexEntry(key);
                    }
                }

                const shouldCancel = (jobsKeys.length === 0) || (jobs.every(a => a.status === 'complete'));

                if (shouldCancel) {
                    if (intervalPromise) {
                        $interval.cancel(intervalPromise);
                        intervalPromise = null;
                    }
                } else {
                    if (!intervalPromise) {
                        intervalPromise = $interval(processJobs, 3000);
                        intervalPromise.then(function () {
                            intervalPromise = null;
                        });
                    }
                }
            });
        }


        // Check for new jobs every 
        // $interval(processJobs, 5000);

        processJobs();
        $rootScope.$on('jobs:refresh', function () {
            processJobs();
        });
    });
