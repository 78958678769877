import { JOB_COMPLETE_STATUS } from './jobs.constants';

/* @ngInject */
export default class JobsService {
    constructor($http, CoreoAPI, toastr, $ngRedux, OrganisationsActions) {
        this.CoreoAPI = CoreoAPI;
        this.toastr = toastr;
        this.jobsIndex = {};
        this.jobsHooks = {
            [JOB_COMPLETE_STATUS]: {
                cloneProject: (job) => {
                    return $ngRedux.dispatch(OrganisationsActions.loadOrganisation(job.project.organisation.slug))
                },
            },
        };
    }

    applyHook(status, job) {
        if (this.jobsHooks[status] && this.jobsHooks[status][job.type]) {
            this.jobsHooks[status][job.type](job);
        }
    }

    getJobs() {
        const jobsQuery =
            "{jobs: viewer{jobs{id,uuid,status,url,type,message,options,project{name,imageUrl,slug,organisation{slug}}}}}";
        return this.CoreoAPI.query(jobsQuery, {
            ignoreLoadingBar: true,
        }).then(function (response) {
            return (response.jobs && response.jobs.jobs) || [];
        });
    }

    getJob(id) {
        return this.getJobs().then((jobs) => {
            return jobs.find((job) => job.id === id);
        });
    }

    deleteJob(job) {
        var mutation = 'mutation{deleteJob(uuid:"' + job.uuid + '")}';
        return this.CoreoAPI.mutation(mutation);
    }

    clearJobToast(jobId) {
        const indexEntry = this.jobsIndex[jobId];
        if (!indexEntry) {
            throw new Error(
                `Attempting to clear toast of non existent job. Job ID ${jobId}`
            );
        }
        this.toastr.clear(indexEntry.toast);
    }

    getJobsIndex() {
        return this.jobsIndex;
    }

    createOrUpdateJobsIndexEntry(id, val) {
        this.jobsIndex[id] = Object.assign({}, this.jobsIndex[id], val);
        return this.jobsIndex;
    }

    deleteJobsIndexEntry(id) {
        if (!this.jobsIndex[id]) {
            throw new Error(
                `Attempt to delete job index entry that does not exist! Job ID: ${id}`
            );
        }
        delete this.jobsIndex[id];
        return this.jobsIndex;
    }

    deleteAndClearJob(job) {
        return this.deleteJob(job).then(() => {
            this.clearJobToast(job.id);
            this.deleteJobsIndexEntry(job.id);
        });
    }

    isValidationError(job) {
        return job.message && job.message.includes("Validation errors");
    }
}
