const initialState = {
    user: null,
    token: null,
    isLoggingIn: false,
    isLoggedIn: false,
    isAdmin: false,
    isImpersonating: false,
    accountVerificationToken: null,
    passwordResetToken: null,
    loginError: null,
    oauthError: null,
    oauthErrorDescription: null,
    projectPermissions: {},
    organisationPermissions: {}
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN': {
            return {
                ...initialState,
                isLoggingIn: true
            };
        }

        case 'LOGIN_AUTH_SUCCESS': {
            const { token, user } = action;

            return {
                ...state,
                token,
                user,
                isAdmin: user.role === 'admin',
                isLoggedIn: true,
                loginError: null
            };
        }

        case 'LOGIN_UPDATE_PERMISSIONS':
        case 'LOGIN_SUCCESS': {
            const { projectPermissions, organisationPermissions, usedFreeTrial } = action;

            return {
                ...state,
                isLoggedIn: true,
                isLoggingIn: false,
                projectPermissions,
                organisationPermissions,
                user: {
                    ...state.user,
                    usedFreeTrial
                }
            };
        }

        case 'LOGIN_FAILURE': {
            const { loginError } = action;
            return {
                ...state,
                loginError,
                isLoggingIn: false,
                isLoggedIn: false
            };
        }

        case 'AUTH_IMPERSONATE_SUCCESS': {
            return {
                ...state,
                isImpersonating: true
            }
        }

        case 'AUTH_UNIMPERSONATE_SUCCESS': {
            return {
                ...state,
                isImpersonating: false
            }
        };

        case 'AUTH_PASSWORD_RESET_TOKEN':
        case 'AUTH_ACCOUNT_VERIFICATION_TOKEN':
        case 'AUTH_OAUTH_ERROR': {
            return {
                ...state,
                ...action
            }
        }

        case 'LOGOUT': {
            return initialState;
        }

        case 'CREATE_PROJECT_SUCCESS': {
            const { project } = action;
            return {
                ...state,
                projectPermissions: {
                    ...state.projectPermissions,
                    [project.id]: 'admin'
                }
            };
        }

        case 'ORGANISATION_INVITE_ACCEPT_SUCCESS': {
            return {
                ...state,
                organisationPermissions: {
                    ...state.organisationPermissions,
                    [action.organisationId]: action.role
                }
            };
        }

        case 'ORGANISATION_START_FREE_TRIAL_SUCCESS': {
            return {
                ...state,
                user: {
                    ...state.user,
                    usedFreeTrial: true
                }
            }
        }
        case 'OAUTH_TRANSFER_SUCCESS': {
            const { token } = action;
            return {
                ...state,
                token
            }
        }

        case 'UPDATE_USER_PREFERENCES_SUCCESS': {
            return {
                ...state,
                user: {
                    ...state.user,
                    preferences: action.preferences,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export default authReducer;