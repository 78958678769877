import './admin-boundaries.scss';
import center from '@turf/center';

/* @ngInject */
export default class AdminBoundariesController {
    constructor($rootScope, $mdDialog, Mapbox, boundarySets, Boundaries, mapsUrl) {
        this.Mapbox = Mapbox;
        this.$mdDialog = $mdDialog;
        this.$rootScope = $rootScope;
        this.boundarySets = boundarySets;
        this.Boundaries = Boundaries;
        this.mapsUrl = mapsUrl;
    }

    $onInit() {
        const mapContainer = document.getElementById('boundary_map');
        const boundarySet = this.boundarySets[0];

        this.Mapbox.get().then(mapboxgl => {
            const map = new mapboxgl.Map({
                container: mapContainer,
                style: 'mapbox://styles/mapbox/streets-v12',
                bounds: boundarySet.boundingBox,
                fitBoundsOptions: {
                    padding: 40
                }
            });

            map.on('load', () => {
                map.addSource('boundaries', {
                    type: 'vector',
                    tiles: [
                        `${this.mapsUrl}/boundaries/${boundarySet.setId}/{z}/{x}/{y}.mvt`
                    ],
                    tileSize: 512
                });

                map.addLayer({
                    'id': 'boundaries',
                    'type': 'line',
                    'source': 'boundaries',
                    'source-layer': boundarySet.setId,
                    'layout': {},
                    'paint': {
                        // 'fill-color': '#088',
                        // 'fill-opacity': 0.8
                    }
                });

                const popup = new mapboxgl.Popup({
                    closeButton: false,
                    closeOnClick: false
                });

                map.on('mouseenter', 'boundaries', e => {
                    // Change the cursor style as a UI indicator.
                    map.getCanvas().style.cursor = 'pointer';

                    const feature = e.features && e.features[0];
                    if (!feature) {
                        return;
                    }

                    var coordinates = feature.geometry.coordinates.slice();
                    var description = `<h3>${feature.properties.name}</h3><p>${feature.properties.boundaryId}</p>`;

                    // Ensure that if the map is zoomed out such that multiple
                    // copies of the feature are visible, the popup appears
                    // over the copy being pointed to.
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }

                    const boundaryCenter = center(e.features[0].geometry);

                    // Populate the popup and set its coordinates
                    // based on the feature found.
                    popup
                        .setLngLat(boundaryCenter.geometry.coordinates)
                        .setHTML(description)
                        .addTo(map);
                });

                map.on('mouseleave', 'places', function () {
                    map.getCanvas().style.cursor = '';
                    popup.remove();
                });
            });
        });
    }

    newBoundarySet(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('./admin-boundaries-create.modal.html'),
            controllerAs: 'ctrl',
            /* @ngInject */
            controller: function ($timeout, $mdDialog) {
                this.name = '';
                this.description = '';
                this.nameField = '';
                this.idField = '';
                this.boundarySetId = '';
                this.file = null;

                this.ok = () => {
                    $mdDialog.hide({
                        name: this.name,
                        description: this.description,
                        nameField: this.nameField,
                        boundarySetId: this.boundarySetId,
                        idField: this.idField,
                        file: this.file
                    });
                };

                this.cancel = () => {
                    $mdDialog.cancel();
                };

                this.readFile = (e) => {
                    $timeout(() => {
                        this.file = (e.srcElement || e.target).files[0];
                    }, 0);
                };
            }
        }).then(({ name, description, nameField, idField, boundarySetId, file }) => {
            this.Boundaries.createBoundarySet(name, description, nameField, idField, boundarySetId, file).then(() => {
                this.$rootScope.$broadcast('jobs:refresh');
            });
        }, angular.noop);
    }
}