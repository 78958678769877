
export const AppTutorialCoachmarkComponent = {
    controllerAs: 'ctrl',
    template: require('./tutorial-coachmark.component.html'),
    require: {
        tutorialCtrl: '^tutorial'
    },
    bindings: {
        target: '@'
    },
    transclude: {
        'icon': '?coachmarkIcon',
        'title': 'coachmarkTitle',
        'content': 'coachmarkContent'
    },
    controller: class AppTutorialCoachmarkComponent {
        /* @ngInject */
        constructor($element) {
            this.$element = $element;
        }

        $onInit() {
            this.tutorialCtrl.addCoachmark(this);
        }

        activate() {
            this.$element.addClass('active');
        }

        deactivate() {
            this.$element.removeClass('active');
        }
    }
};
