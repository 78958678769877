import { getProjects } from "../../store/selectors";

/* @ngInject */
export default class SupportTicketController {

    constructor($mdDialog, $ngRedux, toastr, SupportService, ProjectsActions) {
        this.$mdDialog = $mdDialog;
        this.SupportService = SupportService;
        this.toastr = toastr;

        this.category = null;
        this.projectId = null;
        this.description = '';

        this.projects = getProjects($ngRedux.getState());
        if (!this.projects) {
            $ngRedux.dispatch(ProjectsActions.loadProjects()).then(result => {
                this.projects = result;
            });
        }
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    submit() {
        console.log('Submitting', this.SupportService, this.category, this.projectId, this.message);
        this.SupportService.openTicket({
            description: this.description,
            category: this.category,
            sourceVersion: 'web',
            projectId: this.projectId
        }).then(() => {
            this.$mdDialog.hide();
            this.toastr.success('Message sent. We\'ll be in touch ASAP');
        });
    }
}