export default class LightboxDirective {
    constructor(Lightbox) {
        this.restrict = 'A';
        this.Lightbox = Lightbox;
    }
    link(scope, element, attributes) {
        element.bind('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.Lightbox.openModal([{
                url: element[0].href
            }], 0);
        });
    }
}
