import angular from 'angular';
import './comments.scss';
import { getAuthUser } from '../../store/selectors';

export const RecordCommentsComponent = {
    template: require('./record-comments.component.html'),
    controllerAs: 'ctrl',
    bindings: {
        recordId: '<'
    },
    controllerAs: 'ctrl',
    controller: class RecordCommentsComponent {
        /* @ngInject */
        constructor($scope, $ngRedux, $http, $timeout, RecordsService, AuthActions) {
            this.$http = $http;
            this.RecordsService = RecordsService;
            this.comments = [];
            this.newComment = {
                text: ''
            };
            this.$timeout = $timeout;

            $scope.$on('$destroy', $ngRedux.connect(state => ({
                user: getAuthUser(state)
            }), AuthActions)(this));
        }

        $onChanges(changes) {
            if (changes.recordId) {
                this.RecordsService.getComments(changes.recordId.currentValue).then(comments => {
                    this.comments = comments;
                });
            }
        }

        post() {
            if (this.newComment.text) {
                this.$http.post('/records/' + this.recordId + '/comments', {
                    text: this.newComment.text
                }).then((response) => {
                    this.newComment.text = '';
                    response.data.user = angular.copy(this.user);
                    this.comments.push(response.data);

                    this.$timeout(function () {
                        var box = angular.element(document.getElementById("commentBox"));
                        box.scrollTop(box[0].scrollHeight);
                    }, 0, false);
                });
            }
        }
    }
}