import { getProject, getProjectApiKeys, getProjectRole } from "../../store/selectors";
import "./project-apikeys.scss";

/* @ngInject */
export default class ProjectApiKeyController {
  constructor($scope, $ngRedux, ProjectActions) {

    $scope.$on('$destroy', $ngRedux.connect((state) => {
      return {
        project: getProject(state),
        apikeys: getProjectApiKeys(state),
        role: getProjectRole(state)
      }
    }, ProjectActions)(this));
  }

  createToken() {
    this.createApiKey();
  };

  revokeToken(apikey) {
    if (confirm('Are you sure?')) {
      this.deleteApiKey(apikey.id);
    }
  };

}
