import './oauth.scss';

/* @ngInject */
export default class OAuthController {
    constructor($ngRedux, AuthActions, $stateParams) {
        this.$ngRedux = $ngRedux;
        this.AuthActions = AuthActions;
        this.oauth_transfer_redirect = !!$stateParams.oauth_transfer_redirect;
        console.log(this.oauth_transfer_redirect)
    }

    oauth(type) {
        this.$ngRedux.dispatch(this.AuthActions.login(type, null, this.oauth_transfer_redirect))
    }
}