import './get-app.scss';

export const GetAppComponent = {
    bindings: {},
    transclude: true,
    template: require('./get-app.html'),
    controllerAs: 'ctrl',
    controller: class GetAppController {
        /* @ngInject */
        constructor(androidUrl, iosUrl) {
            this.androidUrl = androidUrl;
            this.iosUrl = iosUrl;
        }

        $onInit() {

        }
    }

};
