export const RecordMessageComponent = {
  bindings: {
    message: '<'
  },
  template: require('./record-message.component.html'),
  controllerAs: 'ctrl',
  controller: class RecordMessageComponent {
    /* @ngInject */
    constructor($http) {
      this.$http = $http;
    }
    markAsRead() {
      this.$http.post('/messages/mark/' + this.message.id).then((response) => {
        this.message.read = true;
      });
    }
  }
}