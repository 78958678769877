/* @ngInject */
export default class MapboxStyle {

    constructor(MAPBOX_STYLE_SPEC) {
        this.spec = MAPBOX_STYLE_SPEC;
    }

    getDefaults(group, layerType) {
        var props = this.spec[layerType];

        return _.reduce(_.filter(props, { group: group }), function (acc, prop) {
            if (prop.hasOwnProperty('default')) {
                acc[prop.key] = prop.default;
            }
            return acc;
        }, {});
    }

    getLayerTypes() {
        return Object.keys(this.spec);
    }
}