import { unzip } from 'unzipit';
import './project-records-import.scss';
import { getProjectForms, getProjectAttributes, getProjectRole } from '../../../store/selectors';
import { parseDBFFieldNames } from './helpers';
export default class ProjectRecordsImportController {
    /* @ngInject */
    constructor($rootScope, $scope, $interval, $mdDialog, $timeout, $ngRedux, CoreoAPI, JobsService, GeoJSON) {
        this.$rootScope = $rootScope;
        this.$mdDialog = $mdDialog;
        this.$timeout = $timeout;
        this.$interval = $interval;
        this.$scope = $scope;
        this.CoreoAPI = CoreoAPI;
        this.JobsService = JobsService;
        this.GeoJSON = GeoJSON;

        this.ImportGeometryType = {
            NONE: 'none',
            LATLNG: 'latlng',
            WKT: 'wkt',
        }
        this.ImportGeometryFileFormat = {
            CSV: 'CSV',
            GeoJSON: 'GeoJSON',
            Shapefile: 'Shapefile',
        }

        $scope.$on('$destroy', $ngRedux.connect((state) => {
            return {
                surveys: getProjectForms(state),
                attributes: angular.copy(getProjectAttributes(state)),
                role: getProjectRole(state)
            }
        }, null)(this));

        this.format = this.ImportGeometryFileFormat.CSV;
        this.step = 1;
        this.stepReady = false;
        this.readFile = this.readFile.bind(this);
        this.fileError = null;
        this.map = {}; // data structure for import request submissions , { col : path }
        this.geometryType = this.ImportGeometryType.NONE;
        this.pairs = []; // data structure for UI {attribute, col}[]
        this.$scope.$on('$destroy', () => {
            this.endPoll();
        });
    }

    readFile(e) {
        this.$timeout(async () => {
            const file = (e.srcElement || e.target).files[0];
            if (file) {
                try {
                    await this.parseFile(file);
                    this.fileError = null;
                } catch (e) {
                    this.file = null;
                    this.columns = null;
                    this.fileError = e;
                }
            } else {
                this.columns = null;
            }
            this.updateReady();
            this.$scope.$apply();
        }, 0);
    }

    async parseFile(file) {
        this.file = file;
        switch (this.format) {
            case this.ImportGeometryFileFormat.CSV: {
                const readCSVColumns = async file => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = (_e) => {
                            try {
                                const text = reader.result;
                                const firstLine = text.replace('\r', '').split('\n').shift();
                                return resolve(firstLine.split(','));
                            } catch (e) {
                                return reject(new Error('Unable to read columns'))
                            }

                        };
                        reader.readAsText(file, 'UTF-8');
                    })
                }
                this.columns = await readCSVColumns(file);
                break;
            }
            // Shapefile ZIP
            case this.ImportGeometryFileFormat.Shapefile: {
                let unzipped, entries, shpEntries, dbfEntry, prjEntry, prjString;
                try {
                    unzipped = await unzip(file);
                    //filter out files without extension as well as osx archive utility metadata 
                    entries = Object.values(unzipped.entries).filter(f => f.name.includes('.') && !f.name.includes('_MACOSX'));
                } catch (e) {
                    console.log(e);
                    throw new Error('Unable to unzip provided file')
                }
                shpEntries = entries.filter(f => f.name.split('.').pop() === 'shp');
                if (shpEntries.length === 0) {
                    throw new Error('No .shp found in provided zip, please shapefile is included in top directory of zip')
                }
                if (shpEntries.length > 1) {
                    throw new Error('More than one shapefile included in provided zip')
                }
                try {
                    dbfEntry = entries.find(f => f.name.split('.').pop() === 'dbf');
                    if (!dbfEntry) throw new Error();
                } catch (e) {
                    console.log(e);
                    throw new Error('Unable to find dbf file in provided shapefile zip')
                }
                // Allow uncheck projections for now as API will convert implicitly

                // prjEntry = entries.find(f => f.name.split('.').pop() === 'prj');
                // if (prjEntry) { //Not required by shp spec, but common
                //     const WGS_84_STRING = 'WGS_1984';
                //     try {
                //         prjString = await (await prjEntry.blob()).text();
                //     } catch (e) {
                //         console.log(e);
                //         throw new Error('Cannot read included .prj file included in zip')
                //     }
                //     if (!prjString.includes(WGS_84_STRING)) {
                //         throw new Error('Coordinate reference system in provided shapefile is not EPSG 4326 / WGS 84 - please convert the file before uploading')
                //     }
                // }
                try {
                    const dbfArrayBuffer = await dbfEntry.arrayBuffer();
                    this.columns = parseDBFFieldNames(dbfArrayBuffer);
                } catch (e) {
                    console.log(e);
                    throw new Error('Unable to read dbf table in shapefile')
                }
                break;
            }
            case this.ImportGeometryFileFormat.GeoJSON: {
                try {
                    await this.GeoJSON.readGeoJSONFile(file).then((result) => {
                        this.columns = result.geoJsonPaths;
                    });
                } catch (_e) {
                    throw new Error('Unable to read GeoJSON File');
                }
                break;
            }
            default: {
                console.warn('Unknown file type!', file);
            }
        }
    }
    import() {
        const input = {
            surveyId: this.survey.id,
            geometryWKTColumn: this.geometryWKTColumn,
            geometryLatColumn: this.geometryLatColumn,
            geometryLngColumn: this.geometryLngColumn,
            map: '$map',
            format: this.format,
        };

        var u = this.CoreoAPI.gqlStringify(input);

        // var query = 'mutation($where: SequelizeJSON){data: createShapefileExport(input:{projectId: ' + projectId + ', where: $where}){id}}';
        // return this.CoreoAPI.mutation(query, null, {

        var mutation = `mutation($map: SequelizeJSON){result: importRecords(input:${u})}`;
        this.stepReady = false;
        this.step++;

        return this.CoreoAPI.mutation(mutation, {
            file: this.file
        }, {
            variables: {
                map: this.map
            }
        }).then((jobId) => {
            this.$rootScope.$broadcast('jobs:refresh');
            this.step = 4;
            this.pollJob(jobId);
        }).catch(e => {
            this.error = e;
            console.log('import error', e);
            this.step = -1;
        })
    }

    endPoll() {
        if (this.poll) {
            this.$interval.cancel(this.poll);
            this.poll = null;
        }
    }

    pollJob(jobId) {
        this.poll = this.$interval(() => {
            this.JobsService.getJob(jobId).then(job => {
                if (!job) {
                    this.step = 5;
                    this.endPoll();
                }
                if (job.status === "failed") {
                    if (this.JobsService.isValidationError(job)){
                        this.validationErrors = this.parseValidationErrorsMessage(job.message);
                    } else {
                        this.error = job.message;
                    }
                    this.JobsService.deleteAndClearJob(job);
                    this.endPoll();
                    this.step = -1;
                }
            });
        }, 1000);
    }

    parseValidationErrorsMessage(msg) {
        try {
            const VALIDATION_ERROR_SEPERATOR = ',\n';
            const VALIDATION_ERRORS_HEADER = 'Validation errors:\n';
            const [, errorString] = msg.split(VALIDATION_ERRORS_HEADER);
            return errorString.split(VALIDATION_ERROR_SEPERATOR).map(e => e.trim());
        } catch (_e) {
            return [ msg ];
        }
    }

    next() {
        if (this.step === 3) {
            this.import();
        } else {
            if (this.step === 1) {


                const attributesCopy = this.attributes.slice(0, this.attributes.length); //shallow copy

                if (this.role === 'admin') {
                    // pseudo attribute to allow mapping of userId column
                    attributesCopy.push({
                        id: 0,
                        path: 'userId',
                        label: 'Coreo User ID',
                        required: false,
                        questionType: null,
                        surveyId: this.survey.id
                    })
                }
                this.surveyAttributes = attributesCopy.filter(a => a.surveyId === this.survey.id);
                this.mappableAttributes = this.surveyAttributes.filter(a => !!a.path);
                this.pairs = this.mappableAttributes.filter(a => a.required).map(attribute => ({ attribute, column: null }));
                this.pairs.map(p => this.removeMappableAttribute(p.attribute.id));
                this.geometryAttribute = this.surveyAttributes.find(a => a.questionType === 'geometry');
                this.surveyHasGeometry = !!this.geometryAttribute || this.survey.bespoke;
                this.surveyHasPointGeometry = this.surveyHasGeometry ?
                    this.geometryAttribute.config && this.geometryAttribute.config.types && this.geometryAttribute.config.types.includes('point') || this.survey.bespoke : false
                
                if (this.surveyHasGeometry && this.geometryAttribute.required) {
                    this.geometryType = this.surveyHasPointGeometry ? this.ImportGeometryType.LATLNG : this.ImportGeometryType.WKT;
                }
                if (!this.surveyHasGeometry || this.format !== this.ImportGeometryFileFormat.CSV) {
                    this.step++; //skip geometry column mapping step if not CSV
                }
            }
            this.step++;
            this.updateReady();
        }
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    done() {
        this.$mdDialog.hide(this.survey.id);
    }

    searchAttributesText(searchText) {
        if(!searchText) {
            return this.mappableAttributes;
        }
        return this.mappableAttributes.filter(a => a.label.toLowerCase().includes(searchText.toLowerCase()));
    }

    handleNewPair(attribute) {
        if (!attribute) {
            return;
        }
        this.pairs.push({
            attribute,
            column: null
        });
        this.removeMappableAttribute(attribute.id);
        this.newPairSelect = undefined;
        this.updateReady();
    }

    removeMappableAttribute(id) {
        const idx = this.mappableAttributes.findIndex(a => a.id === id);
        if (idx === -1) {
            throw new Error(`Tried to remove non-existant mappable attribute with id ${id}`)
        }
        this.mappableAttributes = [
            ...this.mappableAttributes.slice(0, idx),
            ...this.mappableAttributes.slice(idx + 1)
        ]
    }

    addMappableAttribute(attribute) {
        this.mappableAttributes.push(attribute);
    }

    searchColumnsText(searchText) {
        if(!searchText) {
            return this.columns;
        }
        return this.columns.filter(c => c.toLowerCase().includes(searchText.toLowerCase()));
    }

    selectPairColumn(idx, column) {
        this.pairs[idx].column = column;
        this.updateReady();
    }

    removePair(idx) {
        if ((idx > this.pairs.length -1) || (idx < 0)) {
            throw new Error('Tried to remove pair with invalid index');
        }
        const attribute = this.pairs[idx].attribute;
        this.pairs = [
            ...this.pairs.slice(0, idx),
            ...this.pairs.slice(idx + 1)
        ]
        this.addMappableAttribute(attribute);
        this.updateReady();
    }

    resetGeometryColumns() {
        this.geometryLatColumn = undefined;
        this.geometryLngColumn = undefined;
        this.geometryWKTColumn = undefined;
        this.updateReady();
    }

    updateReady() {
        switch (this.step) {
            case 1: {
                this.stepReady = this.file && this.survey && this.columns && !this.fileError;
                break;
            }
            case 2: {
                this.stepReady = (this.geometryType === this.ImportGeometryType.NONE) || (this.geometryType && (this.geometryWKTColumn || (this.geometryLatColumn && this.geometryLngColumn)));
                break;
            }
            case 3: {
                this.stepReady = !this.pairs.some(p => !p.column);
                this.map = this.pairs.reduce((acc, pair) => {
                    acc[pair.attribute.path] = pair.column;
                    return acc;
                }, {});
                break;
            }
        }
    }
}