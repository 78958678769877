import './project-config.scss';
import { getAuthIsAdmin, getProjectBespoke } from '../../store/selectors';

/* @ngInject */
export default class ProjectConfigController {
    constructor($ngRedux, $scope) {
        $scope.$on('$destroy', $ngRedux.connect(state => {
            return {
                bespoke: getProjectBespoke(state),
                isAdmin: getAuthIsAdmin(state)
            };
        }, null)(this));

        this.sections = [
            {
                title: 'Build Project',
                items: [{
                    id: 'projectforms',
                    title: 'Forms',
                    icon: 'fas fa-clipboard-list',
                    state: 'project.forms',
                    description: 'Build and edit forms - the data collection element of your project'
                }, {
                    id: 'projectcollections',
                    title: 'Collections',
                    icon: 'fas fa-layer-group',
                    state: 'project.collections',
                    description: 'Add or edit collections. These are curated lists of items you wish to use in forms or pages'
                }, {
                    id: 'projectpages',
                    title: 'Pages',
                    icon: 'fas fa-th',
                    state: 'project.pages',
                    description: 'Build informative pages for your project including reference guides'
                },
                // {
                //     id: 'projectmaps',
                //     title: 'Map Layers',
                //     icon: 'fas fa-map',
                //     state: 'project.map-layers',
                //     description: 'Configure map layers',
                //     coreoAdminOnly: true
                // },
                {
                    id: 'projectmaps',
                    title: 'Map Builder',
                    icon: 'fas fa-map',
                    state: 'project.maps-config',
                    description: 'Configure map layers'
                }
                ]
            },
            {
                title: 'General Settings',
                items: [{
                    id: 'projectsettings',
                    title: 'Project Settings',
                    icon: 'fa fa-cog',
                    state: 'project.settings',
                    description: 'Edit project name, description and social media handles'
                },
                {
                    id: 'projectstyling',
                    title: 'Styling',
                    icon: 'fas fa-palette',
                    state: 'project.styling',
                    description: 'Add an icon and custom theming to your project'
                },
                {
                    id: 'projectstatus',
                    title: 'Record Status Settings',
                    icon: 'fa fa-check-double',
                    state: 'project.verificationsettings',
                    description: 'Allows admins to assign a status to records to assist with workflow'
                }
                ]
            },
        ];

        if (this.isAdmin) this.sections.push({
            title: 'Advanced',
            items: [{
                id: 'projectapikeys',
                title: 'API Keys',
                icon: 'fas fa-key',
                state: 'project.apikeys',
                description: 'Generate API keys for integrating your project with other applications'
            }, {
                id: 'projectoauth',
                title: 'OAuth Credentials',
                icon: 'fas fa-lock',
                state: 'project.credentials',
                description: 'OAuth Settings',
                bespokeOnly: true
            }, {
                id: 'projecthooks',
                title: 'Hooks',
                icon: 'fas fa-puzzle-piece',
                state: 'project.hooks',
                description: 'Create bespoke actions for your project'
            }]
        });

        this.items = this.sections.reduce((acc, section) => acc.concat(section.items), []);
    }
}
