export interface MapBaseStyle {
    id: string;
    url: string;
    name: string;
    preview: string;
    dark: boolean;
}

const mapBaseStyles: MapBaseStyle[] = [{
    id: 'streets',
    url: 'mapbox://styles/mapbox/streets-v12',
    name: 'Streets',
    preview: 'mapbox-streets-preview.png',
    dark: false,
}, {
    id: 'satellite',
    url: 'mapbox://styles/mapbox/satellite-v9',
    name: 'Satellite',
    preview: 'mapbox-satellite-preview.png',
    dark: true

}, {
    id: 'satellite-streets',
    url: 'mapbox://styles/mapbox/satellite-streets-v12',
    name: 'Satellite Streets',
    preview: 'mapbox-satellite-streets-preview.jpeg',
    dark: true,
}, {
    id: 'outdoors',
    url: 'mapbox://styles/mapbox/outdoors-v12',
    name: 'Outdoors',
    preview: 'mapbox-outdoors-preview.png',
    dark: false
}];

export default mapBaseStyles;