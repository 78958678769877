import { getProjectMaps, getProjectId } from "../../store/selectors";

/* @ngInject */
export default class ProjectMapsController {

    constructor($state, $location, $timeout, $ngRedux, map, ProjectMapsService) {
        this.$state = $state;
        this.$timeout = $timeout;
        this.$location = $location;
        this.ProjectMapsService = ProjectMapsService;
        this.mapHandle = null;

        map.name = 'Default Map';

        const state = $ngRedux.getState();
        this.projectId = getProjectId(state);
        this.maps = [map].concat(getProjectMaps(state) || []);
        this.mapConfig = null;

        // If we have a m in the url
        if ($state.params.m) {
            this.map = _.find(this.maps, { id: +$state.params.m });
        }

        if (!this.map) {
            this.map = this.maps[0];
        }
        this.switchMap();
    }

    switchMap() {
        if (this.map.id === 'default') {
            this.mapConfig = this.map;
        } else {
            this.loadMap(this.map.id).then(mapConfig => {
                this.mapConfig = mapConfig;
                if (this.mapHandle && mapConfig.center && mapConfig.center.coordinates && mapConfig.zoom) {
                    console.log('HRE', mapConfig.center);
                    this.mapHandle.easeTo({
                        center: mapConfig.center.coordinates,
                        zoom: mapConfig.zoom,
                        padding: 40
                    });
                }
            });
        }
    }

    loadMap(id) {
        return this.ProjectMapsService.getMap(this.projectId, id);
    }


    handleMap(map) {
        var id = this.mapConfig.id;
        var $timeout = this.$timeout;
        var $location = this.$location;
        this.mapHandle = map;

        map.on('mousemove', function (e) {
            var features = map.queryRenderedFeatures(e.point, { radius: 10 });
            map.getCanvas().style.cursor = features.length ? '' : 'pointer';
        });

        function updateMapParams() {
            $timeout(function () {
                $location.search({
                    z: map.getZoom(),
                    c: map.getCenter().toArray().join(','),
                    m: id
                });
            });
        }

        map.on('moveend', _.debounce(updateMapParams, 200));
    };

    handleMapClick(data) {
        this.$state.go('project.record', { record_id: data.properties.id, project_id: data.properties.projectId });
    };

}