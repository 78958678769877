import './project-record-history.component.scss';
import { getProjectStates } from '../../../store/selectors';

export const ProjectRecordHistoryComponent = {
    template: require('./project-record-history.component.html'),
    bindings: {
        record: '<',
    },
    controllerAs: 'ctrl',
    controller: class ProjectRecordHistoryComponent {
        /* @ngInject */
        constructor($element, $ngRedux, RecordsService, $scope, $mdDialog, toastr, $state) {
            this.RecordsService = RecordsService;
            this.$element = $element;
            this.$scope = $scope;
            this.$mdDialog = $mdDialog;
            this.sortedLogs = null;
            this.toastr = toastr;
            this.$state = $state;
            this.states = getProjectStates($ngRedux.getState());
            this.stateMap = {}
        }

        calculateStateFromLog(log) {
            const verificationLogs = this.sortedLogs.filter(l => l.operation === 'verification');
            const idx = verificationLogs.findIndex(l => l.id === log.id);
            if (idx === 0) { //is the first verification log (then state is equal to the record's)
                return this.states.find(state => state.stateId === this.record.state);
            } else { //future log exists which contains the state this log changed to
                const previousLog = verificationLogs[idx - 1];
                //verification log diffs have the form: [{"op": "replace", "path": "/state", "value": 2}]
                return this.states.find(state => state.stateId === previousLog.diff[0].value);
            }
        }

        calculatePreviousStateFromLog(log) {
            const verificationLogs = this.sortedLogs.filter(l => l.operation === 'verification');
            //previous state is stored in the diff of the record log
            return this.states.find(state => state.stateId === (verificationLogs.find(l => l.id === log.id).diff[0].value));
        }

        generateStateMap() {
            if (!this.sortedLogs) {
                return;
            }
            this.stateMap = {};
            this.sortedLogs.filter(l => l.operation === 'verification').map(vLog => {
                this.stateMap[vLog.id] = {
                    state: this.calculateStateFromLog(vLog),
                    previousState: this.calculatePreviousStateFromLog(vLog)
                }
            })
        }

        $onInit() {
            this.sortedLogs = [...this.record.logs]; // logs are requested in createdAt desc
            this.generateStateMap();
        }

        $onChanges() {
            this.sortedLogs = [...this.record.logs];
            this.generateStateMap();
        }

        viewChanges(ev, log) {
            const record = this.record;
            this.$mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                template: require('./record-changes.modal.html'),
                /* @ngInject */
                controller: function ($mdDialog, $scope) {
                    $scope.record = record;
                    $scope.log = log;
                    $scope.ok = function () {
                        $mdDialog.hide($scope.name);
                    };
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                },
                controllerAs: 'ctrl'
            }).then((name) => {
            }, angular.noop);
        }

        revert(ev, log) {
            const record = this.record;
            this.$mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                template: require('./record-revert.modal.html'),
                /* @ngInject */
                controller: ($mdDialog, $scope) => {
                    $scope.beforeOnly = true;
                    $scope.record = record;
                    $scope.log = log;
                    $scope.ok = function () {
                        $mdDialog.hide();
                    };
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                },
                controllerAs: 'ctrl'
            }).then(() => {
                this.RecordsService.revertRecord(record.id, log.id).then(() => {
                    this.toastr.success('Record reverted to previous state');
                    this.$state.reload();
                });
            }, angular.noop);
        }

        // $onChanges() {
        //     this.RecordsService.getRecordAssociates(this.record.id).then(data => {
        //         this.data = data;
        //         if (this.table) {
        //             this.table.setData(data);
        //         }
        //         console.log({ data });
        //         //this.onLoad({ data });
        //     });
        // }
    }
};

export default ProjectRecordHistoryComponent;
