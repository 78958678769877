import './account-verification.scss';

/* @ngInject */
export default class AccountVerificationController {
    constructor($state, $scope, $ngRedux, $location, project, AuthActions, toastr) {
        this.$state = $state;
        this.project = project;
        this.toastr = toastr;
        this.$location = $location;

        $scope.$on('$destroy', $ngRedux.connect(null, AuthActions)(this));
    }

    $onInit() {
        const search = this.$location.search();
        const token = search.account_verification_token;

        this.verifyAccount(token).then(() => {
            if (this.project) {
                this.submitted = true;
            } else {
                this.toastr.success('Welcome to Coreo.', 'Account Validated!');
                this.$state.go('home');
            }
        }, () => {
            if (this.project) {
                this.submitted = true;
                this.error = true;
            } else {
                this.toastr.error('The validation token has either expired or already been used.', 'Validation failed');
                this.$state.go('login');
            }
        });
    }
}
