import './list.directive.scss';

export class ListDirective {
  constructor() {
    this.restrict = 'E',
      this.transclude = true;
    this.template = `<div class="list" ng-class="{'stacked': stacked, 'align-right': alignment === 'right'}" layout="column" ng-transclude></div>`;
    this.replace = true;
    this.scope = {
      alignment: '@',
      stacked: '@'
    }
  }
}

export class ListItemDirective {
  constructor() {
    this.restrict = 'E';
    this.require = '^list',
      this.scope = {
        label: '@'
      };
    this.transclude = true;
    this.replace = true;
    this.template = `<div class="list-item">
      <div class="list-item-label">{{label}}</div>
      <div class="list-item-value" ng-transclude></div>
      </div>`;
  }
}