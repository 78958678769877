export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';


/* @ngInject */
const projectsActions = (CoreoAPI, ProjectService) => {

    const loadProjects = () => (dispatch) => {

        dispatch({
            type: 'LOAD_PROJECTS'
        });

        const query = `{
            data: viewer{
                projects(roles:[MEMBER, MODERATOR, ADMIN]){
                    id,
                    name,
                    description,
                    imageUrl,
                    slug,
                    organisationId,
                    organisation: organisationOverview{
                        slug,
                        name
                    }
                }
            }
        }`
        return CoreoAPI.query(query).then(result => {
            const projects = result && result.data && result.data.projects || [];
            for (const project of projects) {
                if (project.imageUrl) {
                    project.imageUrl = project.imageUrl.replace('https://coreo.s3-eu-west-1.amazonaws.com', 'https://coreo.imgix.net') + '?w=512';
                }
            }

            dispatch({
                type: 'LOAD_PROJECTS_SUCCESS',
                projects
            });
            return projects;
        });
    };

    const createProject = project => dispatch => {

        project.colors = project.colors || {
            default: '#FFFFFF',
            defaultText: '#111111',
            primary: '#233680',
            primaryText: '#FFFFFF',
            secondary: '#3C8DBC',
            secondaryText: '#FFFFFF',
            background: '#233680',
            theme: '#3C8DBC',
            appleStatusBar: 'black'
        };

        dispatch({
            type: CREATE_PROJECT,
            project
        });

        return ProjectService.createProject(project).then(result => {
            dispatch({
                type: CREATE_PROJECT_SUCCESS,
                project: result,
                toast: 'Project created'
            });
            return result;
        }, err => dispatch({
            type: CREATE_PROJECT_FAILURE,
            err,
            toast: {
                type: 'error',
                message: 'Error creating project'
            }
        }));
    }

    return {
        loadProjects,
        createProject
    };
}

export default projectsActions;