import { openGetInTouchModal } from "../helpers";
import "./free-trial-expired.scss";
/* @ngInject */
export default class OrganisationFreeTrialExpiredController {

    constructor($state, $mdDialog) {
        this.$state = $state;
        this.$mdDialog = $mdDialog;
    }

    openEmail(ev) {
        openGetInTouchModal(this.$mdDialog, ev);
    }

    goHome() {
        this.$state.go('home');
    }
}