import { parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getProjectAttributesForForm } from '../../store/selectors';
import './record-attributes-form.component.scss';
export const RecordAttributesFormComponent = {
    template: require('./record-attributes-form.component.html'),
    bindings: {
        record: '<',
        onDataChange: '&',
        onMediaChange: '&',
        onAssociationChange: '&',
        expressionEvaluator: '<',
    },
    controllerAs: 'ctrl',
    controller: class RecordAttributeFormComponent {
        /* @ngInject */
        constructor($ngRedux, $timeout, toastr, RecordsService, ProjectService) {
            this.toastr = toastr;
            this.RecordsService = RecordsService;
            this.ProjectService = ProjectService;
            this.$ngRedux = $ngRedux;
            this.$timeout = $timeout;
        }

        getRecordAttributesForDisplay(surveyId) {
            const attributes = getProjectAttributesForForm(surveyId)(this.$ngRedux.getState());
            return attributes
                .filter(a => a.visible && !a.parentCollectionId && !(a.questionType === 'child' || a.questionType === 'text'));
        }

        $onChanges() {
            if (!this.record) {
                return;
            }
            this.attributes = this.getRecordAttributesForDisplay(
                this.record.surveyId
            );

            this.associations = this.attributes
                .filter((a) => a.questionType === "association")
                .reduce((acc, associationAttribute) => {
                    const r = (this.record.associates || []).find(
                        (a) => a.association.id === associationAttribute.id
                    );
                    if (typeof r === "undefined") {
                        return acc;
                    }

                    acc[associationAttribute.id] = angular.copy(r.record);
                    return acc;
                }, {});

            this.attachments = this.record.attachments.reduce(
                (acc, attachment) => {
                    if (typeof acc[attachment.attributeId] === "undefined") {
                        acc[attachment.attributeId] = [];
                    }
                    acc[attachment.attributeId].push(attachment);
                    return acc;
                },
                {}
            );

            this.data = angular.copy(this.record.data);
            // for (const attribute of this.attributes) {
            //     // date // datetime inputs require date objects
            //     const dateString = this.data[attribute.path];
            //     if ((attribute.type === 'date' || attribute.type === 'datetime') && !(dateString instanceof Date)) {
            //         this.data[attribute.path] = dateString ? this.dateToUTCDate(parseISO(dateString)) : dateString;
            //     }
            // }
        }

        dateToUTCDate(date) {
            if (typeof date === 'string') {
                date = parseISO(date);
            }
            return zonedTimeToUtc(date);
        }

        startUpload(attribute) {
            document.getElementById('attachment-upload-' + attribute.id).click();
        }

        handleDataChange(attribute) {
            let data = this.data;
            if (attribute.type === 'date' && this.data[attribute.path]) {
                // Remake date object in utc without timezone offset
                // const dateStr = this.data[attribute.path];
                // this.data[attribute.path] = this.dateToUTCDate(dateStr);
                // this.data[attribute.path]
            }


            if (this.getRecordAttributesForDisplay(this.record.surveyId).some(a => a.questionType === 'expression')) {
                const expressionAttributes = this.expressionEvaluator.getExpressionEvaluationOrder().filter(a => a.surveyId === this.record.surveyId);
                const expressionData = {};
                if (expressionAttributes.length > 0) {
                    expressionAttributes.forEach(a => {
                        this.expressionEvaluator.setRecord({ ...this.record, data: { ...this.data, ...expressionData } });
                        this.expressionEvaluator.setExpressionAttribute(a);
                        const value = this.expressionEvaluator.evaluateExpression();
                        expressionData[a.path] = a.type === 'float' ? Math.round(value * 100) / 100 : value;
                    });
                }

                data = { ...data, ...expressionData };
            }

            this.onDataChange({ data, attribute });
        }

        handleAssociatedToChange(attribute) {
            this.onAssociationChange({ attribute, record: this.associations[attribute.id] });
        }

        handleMediaDelete(attribute, attachment) {
            this.onMediaChange({ attribute, attachment });
        }

        handleMediaChange(attribute, file) {
            this.onMediaChange({ attribute, file });
        }

        handleAttachmentDelete(attribute, attachment) {
            this.onMediaChange({ attribute, attachment });
            this.$timeout(() => this.validateAttachments(attribute), 0);
        }

        handleAttachmentAdd(attribute) {
            return (e) => {
                const target = (e.srcElement || e.target);
                const file = target.files[0];
                this.file = file;

                const reader = new FileReader();
                reader.onloadend = event => {
                    target.value = '';
                    this.onMediaChange({
                        attribute,
                        file,
                        url: event.target.result
                    });
                    this.$timeout(() => this.validateAttachments(attribute), 0);
                };

                reader.readAsDataURL(file);
            };
        }

        validateAttachments(attribute) {
            const [max, min] = this.getAttachmentMaxMin(attribute);
            const current = this.attachments[attribute.id] ? this.attachments[attribute.id].length : 0;
            this.recordForm[attribute.path].$setValidity('minAttachments', current >= min);
            this.recordForm[attribute.path].$setValidity('maxAttachments', current <= max);
        }

        getAttachmentMaxMin(attribute) {
            const config = attribute.config || {};

            const max = (typeof config.max === 'undefined' || config.max === null) ? Number.MAX_SAFE_INTEGER : config.max;
            const min = (typeof config.min === 'undefined' || config.min === null) ? 0 : config.min;
            return [max, min];
        }

        moreAttachments(attribute) {
            const current = this.attachments[attribute.id]?.length ?? 0;
            const [max] = this.getAttachmentMaxMin(attribute);
            return current < max;
        }
    }
};

