import * as Sentry from '@sentry/browser';
import { unzip } from 'unzipit';
import { parseDBFFieldNames } from '../records/project-records-import/helpers';
import { getProject, getProjectCollection, getAttributesForCollection, getProjectRole } from '../../store/selectors';

class EditAttributeController {
    /* @ngInject */
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }
    ok() {
        this.$mdDialog.hide(this.attribute);
    }

    cancel() {
        this.$mdDialog.cancel('cancel');
    }

    delete() {
        this.$mdDialog.cancel('delete');
    }
}

/* @ngInject */
export default class ProjectCollectionController {
    constructor($rootScope, $scope, $state, $timeout, $ngRedux, $stateParams, ProjectActions, GeoJSON, toastr, $mdDialog, ProjectAttributesService, ProjectDependencyErrorService) {
        this.$mdDialog = $mdDialog;
        this.GeoJSON = GeoJSON;
        this.toastr = toastr;
        this.ProjectAttributesService = ProjectAttributesService;
        this.ProjectDependencyErrorService = ProjectDependencyErrorService;
        this.sortFieldOptions = [];
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$state = $state;

        const collectionId = +$stateParams.collection_id;

        $scope.$on('$destroy', $ngRedux.connect((state) => {
            const collection = angular.copy(getProjectCollection(collectionId)(state));
            if (!collection) {
                return {};
            }

            const attributes = angular.copy(getAttributesForCollection(collectionId)(state));

            const items = collection.items;
            const showImages = _.some(items, item => item.image);
            return {
                collection,
                attributes,
                items,
                showImages,
                project: getProject(state),
                role: getProjectRole(state),
                processing: collection.status === 'processing'
            };
        }, ProjectActions)(this));

        if (!this.collection) {
            return $state.go('project.collections');
        }

        // Show spinner while items are loading
        this.loadingItems = true;

        this.sortFieldOptions = ['key', 'value'].concat(this.collection.fields);

        this.stateListConfig = {
            axis: 'y',
            containment: '.state-list',
            handle: '.fa-bars',
            disabled: this.items.length > 100 || this.collection.sortMode !== 'manual' // too many sortable items will cause the page to crash
        };

        this.attributeListConfig = {
            axis: 'y',
            containment: '.attribute-list',
            handle: '.fa-bars',
        };

        this.collectionTypes = [{
            key: 'text',
            label: 'Text'
        }, {
            key: 'geometry',
            label: 'Geometry'
        }];
        $scope.readCSVFile = function (e) {
            $timeout(() => {
                this.file = (e.srcElement || e.target).files[0];
            }, 0);
        };
    }

    updateItemOrder() {
        var updateItems = [];
        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].sort !== i) {
                this.items[i].sort = i;
                const update = {
                    id: this.items[i].id,
                    sort: i
                };
                updateItems.push(update);
            }
        }
        if (updateItems.length > 0) {
            this.updateCollectionItems(this.collection.id, updateItems);
        }
    };

    updateAttributeOrder() {
        let i = 0;
        for (const attribute of this.attributes) {
            if (attribute.order === i) {
                i++;
            } else {
                attribute.order = i;
                this.updateAttribute({
                    ...attribute,
                    order: i++
                });
            }
        }
        this.attributes = this.attributes.sort((a, b) => a.order - b.order);
    };

    addItems(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('./project-collection-add-items.modal.html'),
            locals: {
                collection: this.collection,
            },
            bindToController: true,
            controllerAs: '$ctrl',
            controller: class CollectionAddItemsController {
                /* @ngInject */
                constructor($mdDialog, $timeout, $scope, GeoJSON, collection) {
                  this.collection = collection
                  this.$mdDialog = $mdDialog; 
                  this.$timeout = $timeout;
                  this.$scope = $scope;
                  this.GeoJSON = GeoJSON;
                  this.mode = '';
                  this.item = {
                      collectionId: this.collection.id,
                      key: '',
                      value: ''
                  };
                  this.file = null;
                  this.badFile = false;
                  this.fields = [];
                  this.geoJsonProperties = [];
                  this.readFile = this.readFile.bind(this);
                  this.readGeoJSONFile = this.readGeoJSONFile.bind(this);
                  this.readShapefile = this.readShapefile.bind(this);
                }

                updateValue(form) {
                  if (!(form.key && form.key.$dirty)) {
                      this.item.key = _.snakeCase(this.item.value);
                  }
                };

                readFile(e) {
                  this.$timeout(() => {
                      this.file = (e.srcElement || e.target).files[0];
                  }, 0);
                };

                readGeoJSONFile(e) {
                  var file = (e.srcElement || e.target).files[0];
                  this.file = file;

                  this.GeoJSON.readGeoJSONFile(file).then((result) => {
                      this.geoJsonProperties = result.geoJsonProperties;
                      this.fields = result.geoJsonPaths;

                      if (this.fields.length > 0) {
                          this.item.geometryKeyProperty = this.fields[0];
                          this.item.geometryValueProperty = this.fields[1];
                      }

                  }, (err) => {
                      console.warn(err);
                      this.badFile = true;
                  });
                };
                      
                async readShapefile(e) {
                  const file = (e.srcElement || e.target).files[0];
                  this.file = file;
                  let unzipped, entries, shpEntries, dbfEntry;
                  try {
                      unzipped = await unzip(file);
                      //filter out files without extension as well as osx archive utility metadata 
                      entries = Object.values(unzipped.entries).filter(f => f.name.includes('.') && !f.name.includes('_MACOSX'));
                  } catch (e) {
                      console.log(e);
                      throw new Error('Unable to unzip provided file')
                  }
                  shpEntries = entries.filter(f => f.name.split('.').pop() === 'shp');
                  if (shpEntries.length === 0) {
                      throw new Error('No .shp found in provided zip, please shapefile is included in top directory of zip')
                  }
                  if (shpEntries.length > 1) {
                      throw new Error('More than one shapefile included in provided zip')
                  }
                  try {
                      dbfEntry = entries.find(f => f.name.split('.').pop() === 'dbf');
                      if (!dbfEntry) throw new Error();
                  } catch (e) {
                      console.log(e);
                      throw new Error('Unable to find dbf file in provided shapefile zip')
                  }
                  try {
                      const dbfArrayBuffer = await dbfEntry.arrayBuffer();
                      this.fields = parseDBFFieldNames(dbfArrayBuffer);
                      this.$scope.$apply();
                  } catch (e) {
                      console.log(e);
                      throw new Error('Unable to read dbf table in shapefile')
                  }
                }
              
                selectMode(mode) {
                    this.mode = mode;
                };

                ok() {
                    this.$mdDialog.hide({
                        mode: this.mode,
                        item: this.item,
                        file: this.file
                    });
                }
                cancel() {
                    this.$mdDialog.cancel('cancel');
                }
            }
        }).then((result) => {
            var mode = result.mode;
            var item = result.item;
            var file = result.file;

            switch (mode) {
                case 'manual': {
                    this.createCollectionItem(item).catch(e => {
                        this.toastr.error(e);
                        Sentry.captureException(e);
                    });
                    break;
                }
                case 'geojson':
                case 'shapefile':
                case 'csv': {
                    var files = {}, update = {};
                    files[mode] = file;
                    if (mode === 'geojson' || mode === 'shapefile') {
                        update = {
                            geometryKeyProperty: item.geometryKeyProperty,
                            geometryValueProperty: item.geometryValueProperty
                        };
                    }

                    this.importCollectionItems(this.collection.id, update, files);
                    break;
                }
            }
        }, angular.noop);
    };

    editCollection(ev) {
        this.$mdDialog.show({
            clickOutsideToClose: true,
            template: require('./edit-collection.modal.html'),
            controllerAs: '$ctrl',
            fullscreen: true,
            locals: {
                collection: this.collection,
                attributes: this.attributes.filter(a => a.type === 'text' || a.type === 'integer' || a.type === 'float')
            },
            bindToController: true,
            /* @ngInject */
            controller: function ($mdDialog, collection) {
                this.collection = collection;
                // Sort attribute ids are handled as strings since ng-options refuses to work here
                if (this.collection.sortAttributeId) {
                    this.collection.sortAttributeId = '' + this.collection.sortAttributeId;
                }

                this.isValid = function () {
                    if (!this.collection.name || this.collection.name.length < 1) {
                        return false;
                    }
                    else if (this.collection.sortMode === 'attribute' && !this.collection.sortAttributeId) {
                        return false;
                    } else {
                        return true;
                    }
                }
                this.ok = function () {
                    $mdDialog.hide(this.collection);
                };
                this.cancel = function () {
                    $mdDialog.cancel('cancel');
                };
                this.delete = function () {
                    $mdDialog.cancel('delete');
                };
            }
        }).then(() => {
            if (this.collection.sortAttributeId) { // Convert back to number for update request
                this.collection.sortAttributeId = +(this.collection.sortAttributeId);
            }
            this.updateCollection(this.collection).then(() => {
                this.toastr.success('Collection updated.');
                if (this.collection.sortMode !== 'manual') {
                    this.stateListConfig.disabled = true;
                } else {
                    this.stateListConfig.disabled = false;
                }
            });
        }, (reason) => {
            if (reason === 'delete') {
                this.deleteCollectionModal();
            } else if (reason !== 'cancel') {
                throw reason;
            }
        });
    };

    deleteCollectionModal() {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            template: require('./delete-collection.modal.html'),
            /* @ngInject */
            controller: function ($scope, $mdDialog) {
                $scope.ok = function () {
                    $mdDialog.hide();
                };
                $scope.cancel = function () {
                    $mdDialog.cancel();
                };
            }
        }).then(() => {
            this.deleteCollection(this.collection.id).then((result) => {
                if (result) {
                    this.$state.go('project.collections');
                }
            });
        }, angular.noop);
    };

    editAttribute(attribute) {

        var modal = this.$mdDialog.show({
            parent: angular.element(document.body),
            template: require('./edit-collection-attribute.modal.html'),
            controllerAs: 'ctrl',
            locals: {
                isNew: false,
                attribute: angular.copy(attribute)
            },
            bindToController: true,
            controller: EditAttributeController
        });
        modal.then((result) => {
            this.updateAttribute(result);
        }, (reason) => {
            if (reason === 'delete') {
                this.deleteAttribute(attribute.id);
            }
        });
    };

    newAttribute() {
        var attribute = {
            order: this.attributes.length + 1,
            visible: true,
            type: 'text',
            questionType: null,
            parentCollectionId: this.collection.id
        };

        var modal = this.$mdDialog.show({
            parent: angular.element(document.body),
            template: require('./edit-collection-attribute.modal.html'),
            controllerAs: 'ctrl',
            locals: {
                isNew: true,
                attribute
            },
            bindToController: true,
            controller: EditAttributeController
        });

        modal.then((result) => {
            result.path = _.snakeCase(result.label);
            this.createAttribute(result);
        }, angular.noop);
    };

    export() {
        this.exportCollection(this.collection.id).then(() => {
            this.$rootScope.$broadcast('jobs:refresh');
        });
    };
}
