import bbox from "@turf/bbox";
import { polygon } from '@turf/helpers'
import booleanContains from "@turf/boolean-contains";
import { createSelector } from "reselect";
import { CoreoCollection } from "../../types";
import { MapLayerState } from "../records/records.reducer";

export const getProject = (state) => state.project;
export const getProjectError = (state) => state.error;
export const getProjectLoading = (state) => state.loading;
export const getProjectColorsInitialised = state => state.colorsInitialised;
export const getProjectId = createSelector(getProject, project => project && project.id);
export const getProjectOrganisationId = createSelector(getProject, project => project && project.organisationId);
export const getProjectVerification = createSelector(getProject, project => project && project.verification);
export const getProjectAccessMode = createSelector(getProject, project => project && project.accessMode);
export const getProjectName = createSelector(getProject, project => project && project.name);
export const getProjectSlug = createSelector(getProject, project => project && project.slug);
export const getProjectOrg = createSelector(getProject, project => project && project.organisation);
export const getProjectOrgSlug = createSelector(getProjectOrg, org => org && org.slug);
export const getProjectShareableLink = createSelector(getProject, project => project && project.shareableLink);
export const getProjectAllowContributors = createSelector(getProject, project => project && project.allowContributors);
export const getProjectForms = createSelector(getProject, project => project && project.forms);
export const getProjectPages = createSelector(getProject, project => project && project.pages);
export const getProjectCollections = createSelector(getProject, project => project && project.collections);
export const getProjectMaps = createSelector(getProject, project => project && project.maps);
export const getProjectMapLayers = createSelector(getProject, project => project && project.mapLayers);
export const getProjectMapLayer = id => createSelector(getProjectMapLayers, layers => layers.find(l => l.id === +id));
export const getProjectMap = id => createSelector(getProjectMaps, maps => maps.find(m => m.id === +id));
export const getProjectCredentials = createSelector(getProject, project => project && project.credentials);
export const getProjectAttributes = createSelector(getProject, project => project && project.attributes);
export const getProjectApiKeys = createSelector(getProject, project => project && project.apiKeys);
export const getProjectStats = createSelector(getProject, project => project && project.stats);
export const getProjectStates = createSelector(getProject, project => project && project.states);
export const getProjectBespoke = createSelector(getProject, project => project && project.bespoke);
export const getProjectLocked = createSelector(getProject, project => project && project.locked);
export const getProjectCollection = id => createSelector(getProjectCollections, collections => collections.find(c => c.id === +id));
export const getProjectHooks = createSelector(getProject, project => project && project.hooks);
export const getProjectForm = id => createSelector(getProjectForms, forms => forms.find(f => f.id === +id));
export const getProjectPage = id => createSelector(getProjectPages, pages => pages.find(p => p.id === +id));
export const getProjectAttribute = id => createSelector(getProjectAttributes, attributes => attributes.find(a => a.id === +id));
export const getProjectGeometry = createSelector(getProject, project => project.geometry);
export const getProjectBoundingBox = createSelector(getProjectGeometry, geometry => {
    if (!geometry || !booleanContains(polygon([[
        [-180, -90],
        [-180, 90],
        [180, 90],
        [180, -90],
        [-180, -90]
    ]]), {
        type: 'Polygon',
        coordinates: geometry.coordinates[0]
    })) {
        return null;
    }
    return bbox(geometry);
});
export const getProjectAttributesForCollection = (id) => createSelector(getProjectAttributes, attributes => {
    return attributes
        .filter(a => a.parentCollectionId === id)
        .sort((a, b) => a.order - b.order);
});
export const getProjectAttributesForForm = (id) => createSelector(getProjectAttributes, getProjectForm(id), (attributes, form) => {
    const formAttributes = attributes.filter(a => a.surveyId === id);
    if (!form.titleAttributeId) {
        return formAttributes;
    }
    const idx = formAttributes.findIndex(a => a.id === form.titleAttributeId);
    if (idx === -1) {
        return formAttributes;
    }

    return [
        formAttributes[idx],
        ...formAttributes.slice(0, idx),
        ...formAttributes.slice(idx + 1)
    ];
});
export const getProjectHasEmptySeats = createSelector(getProject, project => project && project.hasEmptySeats);
export const getProjectUsersLimit = createSelector(getProject, project => project && project.usersLimit);
export const getProjectHasSurveysUnavailableForShapefileExport = createSelector(getProjectForms, forms => forms.some(f => !f.shapefileExportAvailable))

export const getProjectFormsContainingGeometry = createSelector(getProjectForms, getProjectAttributes, (forms, attributes) => {
    return forms.filter(form => typeof attributes.find(a => a.questionType === 'geometry' && a.surveyId === form.id && !a.archived) !== 'undefined')
});

export const getProjectMapLayersState = createSelector<any, CoreoCollection[], any, MapLayerState[]>(getProjectCollections, getProjectMapLayers, (collections, mapLayers) => {

    const layers: MapLayerState[] = [];

    for (const c of collections.filter(c => c.geometric && c.mapLayer)) {
        layers.push({
            id: c.id,
            name: c.name,
            layerType: 'collection',
            sort: c.mapSort,
            visible: false
        });
    }

    for (const l of mapLayers) {
        layers.push({
            id: l.id,
            name: l.name,
            layerType: 'custom',
            sort: l.sort,
            visible: false
        });
    }
    return layers;
});