import './min-screen-size-block.component.scss';
export const MinScreenSizeBlockComponent = {
    bindings: {
        isExpired: '=',
        freeTrialEnd: '='
    },
    template: require('./min-screen-size-block.component.html'),
    controllerAs: 'ctrl',
    controller: class MinScreenSizeBlockController {
        /* @ngInject */
        constructor(IntermediaryPageService) {
            this.hostUrl = IntermediaryPageService.getHostUrl();
            this.displayHostUrl = IntermediaryPageService.getDisplayHostUrl();
        }
    }
}