import angular from 'angular';
import './record.scss';

// Constants
import RECORD_COLUMNS from './record-columns.constant';

// Factories
import Record from './record';
// Services
import RecordsService from './records.service';

// Components
import { RecordAttributesComponent } from './record-attributes/record-attributes.component';
import { RecordAttributesFormComponent } from './record-attributes-form/record-attributes-form.component';
import { RecordAssociatesComponent } from './record-associates/record-associates.component';
import { RecordMapComponent } from './record-map/record-map.component';
import { RecordCommentsComponent } from './record-comments/record-comments.component';
import { RecordCommentComponent } from './record-comments/record-comment.component';
import { RecordMediaEditComponent } from './record-media-edit/record-media-edit.component';
import { RecordMediaComponent } from './record-media/record-media.component';
import { RecordMessagesComponent } from './record-messages/record-messages.component';
import { RecordMessageComponent } from './record-messages/record-message.component';
import { RecordSearchComponent } from './record-search/record-search.component';

export default angular.module('app.records', [])
    .factory('Record', Record)
    .service('RecordsService', RecordsService)
    .component('recordAttributes', RecordAttributesComponent)
    .component('recordAttributesForm', RecordAttributesFormComponent)
    .component('recordAssociates', RecordAssociatesComponent)
    .component('recordMap', RecordMapComponent)
    .component('recordComments', RecordCommentsComponent)
    .component('recordComment', RecordCommentComponent)
    .component('recordMedia', RecordMediaComponent)
    .component('recordMediaEdit', RecordMediaEditComponent)
    .component('recordMessages', RecordMessagesComponent)
    .component('recordMessage', RecordMessageComponent)
    .component('recordSearch', RecordSearchComponent)
    .constant('RECORD_COLUMNS', RECORD_COLUMNS)
    ;
