export const SlackHookSummaryComponent = {
    template: '<small>{{$ctrl.url}}</small>',
    bindings: {
        hook: '<'
    },
    controller: class SlackHookSummaryComponent {
        $onInit() {
            this.url = this.hook.config.url;
        }
    }
};