/* @ngInject */
export default class SupportService {

    constructor($mdDialog, CoreoAPI) {
        this.$mdDialog = $mdDialog;
        this.api = CoreoAPI;
    }

    openTicketDialog(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('./ticket/support-ticket.html'),
            controllerAs: 'ctrl',
            controller: 'SupportTicketController as ctl'
        });
    }

    openTicket(ticket) {

        const query = `mutation AACreateSupportTicket($ticket: CreateTicketInput!){
            result: createTicket(input: $ticket)
        }`;

        const variables = {
            ticket: {
                ...ticket,
                source: 'ADMIN_FORM'
            }
        };

        return this.api.mutation(query, null, { variables });
    }
}