import './select-autocomplete.component.scss';

export const SelectAutocompleteComponent = {
    bindings: {
    },
    template: require('./select-autocomplete.component.html'),
    controllerAs: 'ctrl',
    controller: class SelectAutocompleteComponent {
        /* @ngInject */
        constructor($element) {
            console.log($element);
        }

        $onInit() {

        }

        clearSearchTerm() {
        }
    }
};
