import './app-header.directive.scss';
import { getAuthUser, getAuthIsAdmin, getAuthIsImpersonating, getAuthUserImage } from '../../store/selectors';
import userAvatar from '../../assets/images/user.svg';

export default class AppHeaderDirective {
    /* @ngInject */
    constructor($state, $mdMenu, $ngRedux, AuthActions, $rootScope, $transitions, $location, docsUrl, SupportService) {
        this.restrict = 'E';
        this.$state = $state;
        this.$mdMenu = $mdMenu;
        this.$transitions = $transitions;
        this.template = require('./app-header.directive.html');
        this.$ngRedux = $ngRedux;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.AuthActions = AuthActions;
        this.docsUrl = docsUrl;
        this.hasTutorial = false;
        this.SupportService = SupportService;
        this.imageUrl = userAvatar;
    }

    link($scope) {

        $scope.$on('$destroy', this.$ngRedux.connect((state) => {
            return {
                user: getAuthUser(state),
                isAdmin: getAuthIsAdmin(state),
                isImpersonating: getAuthIsImpersonating(state),
                imageUrl: getAuthUserImage(state) || userAvatar
            };
        }, this.AuthActions)($scope));

        $scope.cancelImpersonate = $scope.isImpersonating ? () => {
            $scope.unimpersonate().then(() => this.$state.reload());
        } : null;

        $scope.openMenu = ($mdMenu, ev) => {
            $mdMenu.open(ev);
        };

        $scope.closeMenu = ($mdMenu) => {
            $mdMenu.close();
        };

        $scope.doLogout = () => {
            $scope.logout();
            this.$location.path('/login');
        }

        $scope.loading = false;

        $scope.$on('$destroy', this.$transitions.onStart({}, (trans) => {
            $scope.loading = true;
            trans.promise.finally(() => $scope.loading = false);
        }));

        $scope.tutorial = () => {
            this.$rootScope.$broadcast('$tutorial:restart', { fromGuide: true });
        };

        $scope.ticket = (ev) => {
            this.SupportService.openTicketDialog(ev);
        };

        const updateTutorial = () => {
            let state = this.$state.current;
            $scope.hasTutorial = state && state.data && state.data.hasTutorial;
        };

        $scope.docsUrl = this.docsUrl;
        $scope.tutorialUrl = `${this.docsUrl}/docs/tutorial/getting-started`;

        $scope.$on('$destroy', this.$transitions.onSuccess({}, () => {
            updateTutorial();
        }));
        updateTutorial();
    }
}