import angular from 'angular';

// Services
import IntermediaryPageService from './intermediarypage.service';
// Controllers
import IntermediaryPageAccountVerificationController from './account-verification/account-verification.controller';
import IntermediaryPageAuthenticateController from './authenticate/authenticate.controller';
import IntermediaryPageJoinedOrganisationController from './joined-organisation/joined-organisation.controller';
import IntermediaryPageJoinedOrganisationSignupController from './joined-organisation-signup/joined-organisation-signup.controller';
import IntermediaryPageAddedToProjectController from './added-to-project/added-to-project.controller';
import IntermediaryPageAddedToOrganisationController from './added-to-organisation/added-to-organisation.controller';
import IntermediaryPageResetPasswordController from './reset-password/reset-password.controller';
import IntermediaryPageJoinProjectController from './join-project/join-project.controller';
// Components
import { ToAdminAreaComponent } from './components/to-admin-area/to-admin-area.component';
import { ToAppComponent } from './components/to-app/to-app.component';
import { GetAppComponent } from './components/get-app/get-app.component';
export default angular
    .module('app.intermediarypage', ['ui.router'])
    .service('IntermediaryPageService', IntermediaryPageService)
    .controller('IntermediaryPageAccountVerificationController', IntermediaryPageAccountVerificationController)
    .controller('IntermediaryPageAuthenticateController', IntermediaryPageAuthenticateController)
    .controller('IntermediaryPageJoinedOrganisationController', IntermediaryPageJoinedOrganisationController)
    .controller('IntermediaryPageJoinedOrganisationSignupController', IntermediaryPageJoinedOrganisationSignupController)
    .controller('IntermediaryPageAddedToProjectController', IntermediaryPageAddedToProjectController)
    .controller('IntermediaryPageAddedToOrganisationController', IntermediaryPageAddedToOrganisationController)
    .controller('IntermediaryPageResetPasswordController', IntermediaryPageResetPasswordController)
    .controller('IntermediaryPageJoinProjectController', IntermediaryPageJoinProjectController)
    .component('toAdminArea', ToAdminAreaComponent)
    .component('toApp', ToAppComponent)
    .component('getApp', GetAppComponent)
    .config(function ($stateProvider) {
        $stateProvider
            .state('intermediarypage', {
                abstract: true,
                template: require('../layouts/intermediarypage.layout.html'),
                url: '/u',
            })
            .state('intermediarypage.authenticate', {
                url: '/authenticate',
                template: require('./authenticate/authenticate.html'),
                controller: 'IntermediaryPageAuthenticateController as ctrl',
                params: {
                    token: null
                },
            })
            .state('intermediarypage.verify', {
                url: '/verify',
                template: require('./account-verification/account-verification.html'),
                controller: 'IntermediaryPageAccountVerificationController as ctrl',
                params: {
                    freeTrialVerified: null //if the user is already verified, skip to success
                }
            })
            .state('intermediarypage.joined-organisation', {
                url: '/joined-organisation',
                template: require('./joined-organisation/joined-organisation.html'),
                controller: 'IntermediaryPageJoinedOrganisationController as ctrl',
                params: {
                    organisation: null,
                    isAdminAreaUser: null,
                    role: null,
                    token: null,
                },
            })
            .state('intermediarypage.joined-organisation-signup', {
                url: '/joined-organisation',
                template: require('./joined-organisation-signup/joined-organisation-signup.html'),
                controller: 'IntermediaryPageJoinedOrganisationSignupController as ctrl',
                params: {
                    organisation: null,
                    isAdminAreaUser: null,
                    token: null,
                },
            })
            .state('intermediarypage.added-to-project', {
                url: '/added-to-project',
                template: require('./added-to-project/added-to-project.html'),
                controller: 'IntermediaryPageAddedToProjectController as ctrl',
                params: {
                    name: null,
                    role: null,
                    projectUrl: null,
                },
            })
            .state('intermediarypage.added-to-organisation', {
                url: '/added-to-organisation',
                template: require('./added-to-organisation/added-to-organisation.html'),
                controller: 'IntermediaryPageAddedToOrganisationController as ctrl',
                params: {
                    orgname: null,
                    projectname: null,
                    projectrole: null,
                    // org role will always be a member as when bumping a contributor up no option is provided
                },
            })
            .state('intermediarypage.reset-password', {
                url: '/reset-password',
                template: require('./reset-password/reset-password.html'),
                controller: 'IntermediaryPageResetPasswordController as ctrl',
                params: {
                    password_reset_token: null
                },
            })
            .state('intermediarypage.join-project', {
                url: '/join-project/:data',
                template: require('./join-project/join-project.html'),
                controller: 'IntermediaryPageJoinProjectController as ctrl',
                params: {
                    data: null
                },
            })
    });
  