/* @ngInject */
export default class AdminMailingService {
    constructor($http, CoreoAPI) {
        this.$http = $http;
        this.CoreoAPI = CoreoAPI;
    }

    getTemplates() {
        return this.$http.get('/mail/templates').then(function (res) {
            return res.data.templates;
        });
    }

    getDomains() {
        return this.$http.get('/mail/domains').then(function (res) {
            return res.data.domains;
        });
    }

    getProjects() {
        var query = '{projects{id,name}}';
        return this.CoreoAPI.query(query).then(function (data) {
            return data.projects;
        });
    }
}
