import { getAttributesForForm } from "../selectors";
import { questionTypes } from './form.reducer';

/* @ngInject */
const projectFormActions = (CoreoAPI) => {

    const initForm = id => (dispatch, getState) => {
        const state = getState();
        const form = state.project.project.forms.find(f => f.id === +id);
        const attributes = getAttributesForForm(form.id)(state);

        return dispatch({
            type: 'FORM_INIT',
            form,
            attributes
        });
    };

    const isCalculatedAttribute = (attribute) => ['rgeolocation', 'geometryquery', 'coordinatetransform'].indexOf(attribute.type) !== -1;

    const initializeAttributeConfig = (type, questionType) => {
        const match = questionTypes.find(q => {
            return (q.questionType === questionType) && (angular.isArray(q.type) ? q.type.includes(type) : q.type === type);
        });
        return match && match.initConfig || {};
    };

    const validateAttribute = (attribute, attributes) => {
        const errors = {};
        const isCalculated = isCalculatedAttribute(attribute);
        const { questionType, text, type, label, associatedSurveyId, config, collectionId, exportPath, id } = attribute;
        const otherExportPaths = attributes.filter(a => a.id !== id).map(a => a.exportPath);

        if (questionType === 'expression') {
            if (!type) errors.noCalculatedFieldType = true;
            if (type && !config.expression) errors.noCalculatedFieldExpression = true;
            return errors; // return early so other validation errors are not dragged in on this check for UI reasons
        }

        if (questionType === 'text' || questionType === 'section') {
            return errors;
        }

        if (!exportPath && id >= 0 && questionType !== 'geometry') { // exportPath will be autocreated on attribute create, but not after
            errors.noExportPath = true;
        }

        if (otherExportPaths.includes(exportPath) && questionType !== 'geometry' && !(id < 0 && !exportPath)) {
            console.log(exportPath)
            errors.duplicateExportPath = true;
        }

        if (!text && !isCalculated) {
            errors.noQuestion = true;
        }

        if (!label && (type || questionType === 'child' || questionType === 'association')) {
            errors.noLabel = true;
        }
        if (!associatedSurveyId && (questionType === 'child' || questionType === 'association')) {
            errors.noAssociatedSurvey = true;
        }


        switch (type) {
            case 'select':
            case 'multiselect': {
                if (!collectionId) {
                    errors.noCollection = true;
                }
                break;
            }
            case 'float':
            case 'integer': {
                if (questionType === 'slider') {
                    const min = parseInt(config.min, 10);
                    const max = parseInt(config.max, 10);
                    if (isNaN(min) || isNaN(max)) {
                        errors.minMax = true;
                    }
                }
                break;
            }
            case 'rgeolocation': {
                if (!config.field) {
                    errors.noRgeoField = true;
                }
                break;
            }
            case 'coordinatetransform': {
                if (!config.projection) {
                    errors.noCTProjection = true;
                }
                break;
            }
            case 'geometryquery': {
                if (!config.operation) {
                    errors.noGQOperation = true;
                }
                if (!collectionId) {
                    errors.noGQCollection = true;
                }
                break;
            }
        }
        return errors;
    };

    const validateFormSelectedAttribute = () => (dispatch, getState) => {
        const { form: { selectedAttributeId, attributes, errors } } = getState();
        const newErrors = {
            ...errors
        }
        const selectedAttribute = attributes.find(a => a.id === selectedAttributeId);

        newErrors[selectedAttributeId] = validateAttribute(selectedAttribute, attributes);

        dispatch({
            type: 'FORM_VALIDATE_SELECTED_ATTRIBUTE',
            errors: newErrors
        });
    }

    const saveForm = () => (dispatch, getState) => {
        const { form: { id: formId, name, title, thankyou, titleAttributeId, secondaryTitleAttributeId, attributes, deleted, postProcessUrl, postProcessSecret, style, allowMemberUpdate, visible, private: formPrivate }, project: { project: { id: projectId } } } = getState();

        let errors = {};
        if (!name) {
            errors['settings'] = { 'noName': true };
        }
        if (!title) {
            errors['settings'] = {
                ...(errors['settings'] || {}),
                noTitle: true
            };
        }

        errors = attributes.reduce((acc, attribute) => {
            const attributeErrors = validateAttribute(attribute, [...attributes]);
            if (Object.keys(attributeErrors).length > 0) {
                acc[attribute.id] = attributeErrors;
            }
            return acc;
        }, errors);


        if (Object.keys(errors).length > 0) {
            console.log('ERRORS!', errors);
            dispatch({
                type: 'FORM_SAVE_VALIDATION_ERROR',
                toast: {
                    type: 'error',
                    message: 'Validation errors'
                },
                errors
            });
            return Promise.reject();
        }

        dispatch({
            type: 'FORM_SAVE'
        });

        // Delete all the ones that require deleting
        const toDelete = deleted.filter(a => a.id > 0);
        const deletePromises = toDelete.map(attributeToDelete => {
            const query = `mutation{data: deleteAttribute(input:{id: ${attributeToDelete.id}})}`;
            return CoreoAPI.mutation(query).then(() => {
                dispatch({
                    type: 'FORM_DELETE_ATTRIBUTE_SUCCESS',
                    id: attributeToDelete.id
                });
            })
        });

        // Create/update everything else
        const attributesToUpdate = [];
        const attributePromises = [...attributes].map(attribute => {
            const attributeProps = ['uuid', 'surveyId', 'required', 'text', 'order', 'visible', 'label', 'path', 'exportPath', 'type', 'sectionIdx', 'description', 'questionType', 'meta', 'parentCollectionId', 'config', 'filterable', 'collectionId', 'associatedSurveyId', 'conditions'];
            const data = _.pick(attribute, attributeProps);
            let operation;
            if (attribute.id < 0) {
                operation = 'createAttribute';
                data.projectId = projectId;
                data.surveyId = formId;
                // Ensure a path is set
                if (data.questionType !== 'text' && data.questionType !== 'geometry' && !data.path) {
                    data.path = `${_.snakeCase(data.label)}_${Math.random().toString(36).substring(7)}`;
                }
            } else if (attribute.dirty) {
                data.id = attribute.id;
                operation = 'updateAttribute';
                attributesToUpdate.push(data);
                return Promise.resolve(data);
            } else {
                data.id = attribute.id;
                return Promise.resolve(data);
            }

            const query = `mutation{result: ${operation}(input: ${CoreoAPI.gqlStringifyNoVariables(data)}){id,${attributeProps.join(',')}}}`;

            return CoreoAPI.mutation(query).then(result => {
                dispatch({
                    type: 'FORM_SAVE_ATTRIBUTE_SUCCESS',
                    id: result.id,
                    prevId: attribute.id,
                    result
                });
                return result;
            }, err => {
                dispatch({
                    type: 'FORM_SAVE_ATTRIBUTE_FAILURE',
                    id: attribute.id,
                    prev: attribute
                });
                throw err;
            });
        });

        let bulkUpdatePromise = Promise.resolve();
        if (attributesToUpdate.length) {
            const query = `mutation{result: updateAttributes(input: {attributes: ${CoreoAPI.gqlStringifyNoVariables(attributesToUpdate)}})}`;

            const updateMutation = CoreoAPI.mutation(query).then(result => {
                dispatch({
                    type: 'FORM_SAVE_ATTRIBUTES_SUCCESS',
                    ids: attributesToUpdate.map(a => a.id)
                });
                return result;
            }, err => {
                for (const attribute of attributesToUpdate) {
                    dispatch({
                        type: 'FORM_SAVE_ATTRIBUTE_FAILURE',
                        id: attribute.id,
                        prev: attribute
                    });
                }
                throw err;
            });

            bulkUpdatePromise = updateMutation;
        }

        const input = {
            id: formId,
            name,
            title,
            titleAttributeId,
            secondaryTitleAttributeId,
            thankyou,
            postProcessUrl,
            postProcessSecret,
            style,
            allowMemberUpdate,
            visible,
            private: formPrivate
        };

        const updateFormQuery = `mutation CoreoAAUpdateForm($input: SurveyUpdateInput!){
            result: updateSurvey(input:$input){
                id,
                titleAttributeId, 
                secondaryTitleAttributeId
            }
        }`;

        const formSave = CoreoAPI.gql(updateFormQuery, { input });
        const deletePromise = Promise.all(deletePromises);
        const attributePromise = Promise.all(attributePromises);

        return Promise.all([
            attributePromise,
            formSave,
            deletePromise,
            bulkUpdatePromise
        ]).then(([attributeResult]) => {
            // We send the form information so the project reducer can update
            dispatch({
                type: 'FORM_SAVE_SUCCESS',
                toast: 'Form Saved',
                form: {
                    id: formId,
                    name,
                    title,
                    style,
                    titleAttributeId,
                    secondaryTitleAttributeId,
                    thankyou,
                    postProcessUrl,
                    postProcessSecret,
                    visible,
                    attributes: attributeResult,
                    private: formPrivate
                }
            });
        }, err => dispatch({
            type: 'FORM_SAVE_ERROR',
            err,
            toast: {
                type: 'error',
                message: 'There was an error saving the form ' + (err && err.toString && err.toString())
            }
        }));
    };

    const switchView = view => ({
        type: 'FORM_SWITCH_VIEW',
        view
    });

    const selectAttribute = id => ({
        type: 'FORM_SELECT_ATTRIBUTE',
        id
    });

    const addAttribute = (aType, questionType, index, sectionIdx, surveyId) => ({
        type: 'FORM_ADD_ATTRIBUTE',
        aType,
        questionType,
        index,
        sectionIdx,
        surveyId,
        config: initializeAttributeConfig(aType, questionType)
    });

    const deleteAttribute = id => ({
        type: 'FORM_DELETE_ATTRIBUTE',
        id
    });

    const restoreAttribute = id => ({
        type: 'FORM_RESTORE_ATTRIBUTE',
        id
    });

    const setAttributeOrder = order => ({
        type: 'FORM_SET_ATTRIBUTE_ORDER',
        order
    });

    const addSectionDivider = (id) => ({
        type: 'FORM_ADD_SECTION_DIVIDER',
        id
    });

    const removeSectionDivider = (id) => ({
        type: 'FORM_REMOVE_SECTION_DIVIDER',
        id
    });

    const addConditionRule = () => ({
        type: 'FORM_ADD_CONDITION_RULE'
    });

    const removeConditionRule = (index) => ({
        type: 'FORM_REMOVE_CONDITION_RULE',
        index
    });

    const updateConditionRules = (conditions) => ({
        type: 'FORM_UPDATE_CONDITION_RULES',
        conditions
    });

    const updateSelectedAttribute = (update) => ({
        type: 'FORM_UPDATED_SELECTED_ATTRIBUTE',
        update
    });

    const updateFormSettings = (name, title, titleAttributeId, thankyou, postProcessUrl, postProcessSecret, style, allowMemberUpdate, visible, secondaryTitleAttributeId, formPrivate) => ({
        type: 'FORM_UPDATE_SETTINGS',
        name,
        title,
        titleAttributeId,
        secondaryTitleAttributeId,
        thankyou,
        postProcessUrl,
        postProcessSecret,
        style,
        allowMemberUpdate,
        visible,
        private: formPrivate
    });

    return {
        initForm,
        saveForm,
        switchView,
        selectAttribute,
        setAttributeOrder,
        addAttribute,
        deleteAttribute,
        restoreAttribute,
        addSectionDivider,
        removeSectionDivider,
        addConditionRule,
        updateConditionRules,
        removeConditionRule,
        updateSelectedAttribute,
        updateFormSettings,
        validateFormSelectedAttribute
    };
}

export default projectFormActions;
