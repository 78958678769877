import * as Sentry from '@sentry/browser';
import { TransferOrganisationOwnershipController } from './transfer-organisation-ownership-modal.controller';
/* @ngInject */
export default class AdminOrganisationController {
    constructor(CoreoAPI, organisation, toastr, organisationTiers, $mdDialog) {
        this.organisation = organisation;
        this.CoreoAPI = CoreoAPI;
        this.toastr = toastr;
        this.today = new Date();
        this.$mdDialog = $mdDialog;
        this.organisationTiers = organisationTiers
    }

    clearDate() {
        if (this.organisation.freeTrialEnd !== null) {
            this.organisationForm.$dirty = true;
        }
        this.organisation.freeTrialEnd = null;
    }

    transferOwnership() {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            //targetEvent: ev,
            locals: {
                organisation: this.organisation,
            },
            clickOutsideToClose: true,
            template: require('./transfer-organisation-ownership-modal.html'),
            controllerAs: 'ctrl',
            controller: TransferOrganisationOwnershipController,
        });
    }

    update() {
        const { name, id, seats, contributorSeats, freeTrialEnd, organisationTierId } = this.organisation;

        const query = `mutation updateOrganisation($id: Int!, $name: String!, $seats: Int!, $contributorSeats: Int!, $freeTrialEnd: Date, $organisationTierId: Int!){
            result: updateOrganisation(input: { id: $id, name: $name, seats: $seats, contributorSeats: $contributorSeats, freeTrialEnd: $freeTrialEnd, organisationTierId: $organisationTierId}){
                id
            }
        }`;

        this.CoreoAPI.mutation(query, null, {
            variables: {
                name,
                id,
                seats,
                contributorSeats,
                freeTrialEnd,
                organisationTierId
            }
        }).then(() => {
            this.toastr.success('Organisation updated');
        }, err => {
            this.toastr.error('Organisation failed to update');
            Sentry.captureException(err);
        })
    }
}