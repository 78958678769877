import './to-admin-area.scss';
export const ToAdminAreaComponent = {
    bindings: {
        token: '<'
    },
    transclude: true,
    template: require('./to-admin-area.html'),
    controllerAs: 'ctrl',
    controller: class ToAdminAreaController {
        /* @ngInject */
        constructor($state) {
            this.$state = $state;
        }

        $onInit() { }
        
        onClick(_e) {
            this.$state.go('intermediarypage.authenticate', { token: this.token });
        }
    }

};
