import { urlBase64Decode } from "../helpers/utils";
import ErrorMessages from "./errorMessages";

const SCREEN_WIDTH_CUTOFF = 800; //px

/* @ngInject */
export default class IntermediaryPageService {
    constructor(CoreoAPI, $q, port, hostname, protocol) {
        this.CoreoAPI = CoreoAPI;
        this.$q = $q;
        let urlPort = '';
        if (port && port !== '80' && port !== '443') {
            urlPort = ':' + port;
        }
    
        this.hostUrl = [protocol, '://', hostname, urlPort].join('');
        this.displayHostUrl = [hostname, urlPort].join('');
    }

    getHostUrl() {
        return this.hostUrl;
    }


    getDisplayHostUrl() {
        return this.displayHostUrl;
    }
    
    parseUserFromToken(token) {
        try {
            const encoded = token.split('.')[1];
            return JSON.parse(urlBase64Decode(encoded))
        }
        catch (e) {
            console.log(e);
            throw new Error ('Failed to parse token')
        }
    }

    verifyAccount(token) {
        // Throwing in `inAdminAreaUser` field so we don't have to make a second request
        // does mean we return a bit of a chunkier object, might be worth separating this out
        const mutation = `
            mutation verifyAccount($input: VerifyAccountInput!){
                result: verifyAccount(input: $input){
                    token,
                    user {
                        isAdminAreaUser
                    }
                }
            }`;
        return this.$q((resolve, reject) => {
            this.CoreoAPI.mutation(mutation, null, {
                variables: {
                    input: { token }
                }
            }).then((res) => {
                if (typeof res.token === 'undefined') {
                    reject(new Error(ErrorMessages.VerificationFailed));
                }
                const user = this.parseUserFromToken(res.token);
                user.isAdminAreaUser = res.user.isAdminAreaUser;
                user.token = res.token;
                return resolve(user);
            }, e => {
                console.log(e)
                return reject(new Error(ErrorMessages.VerificationFailed));
            }).catch(e => {
                console.log(e)
                return reject(new Error(ErrorMessages.VerificationFailed));
            });
        })
    }


    updatePassword(passwordResetToken, newPassword) {
        const input = this.CoreoAPI.gqlStringify({ token: passwordResetToken, password: newPassword });
        const mutation = 'mutation{result: passwordReset(input: ' + input + '){token, user { isAdminAreaUser }}}';
        return this.CoreoAPI.mutation(mutation).then((res) => {
            return {
                token: res.token,
                isAdminAreaUser: res.user.isAdminAreaUser
            };
        });
    };

    getProjectOverview(id) {
        const query = `{
            projectOverview(id:${id}){
                name,
                description,
                imageUrl
            }
        }`
        return this.CoreoAPI.query(query).then(res => {
            if (!res.projectOverview) {
                throw new Error(ErrorMessages.UnableToGetProjectOverview);
            }
            return res.projectOverview;
        }, _e => {
           throw new Error(ErrorMessages.UnableToGetProjectOverview);
        })
    }

    // isAdminAreaUserToken(token) {
    //     return this.$q((resolve, reject) => {
    //         const query = `{
    //             viewer{
    //                 isAdminAreaUser
    //             }
    //         }`
    //         this.CoreoAPI.query(query).then(res => {
    //             return resolve(res.viewer.isAdminAreaUser);
    //         }, _e => {
    //             return reject(new Error(ErrorMessages.UnableToDetermineUserRole));
    //         })
    //     })
    // }
}
 