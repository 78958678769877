import './project-forms-block-editor.scss';
import { getFormSelectedAttribute, getFormState, getProjectForms, getProjectCollections, getProjectMaps, getFormSelectedAttributeCollection, getFormSelectedAttributeAssociationForm, getFormSelectedAttributeErrors, getAuthUser, getProjectGeometry } from '../../../../store/selectors';
import ProjectFormsBlockEditorGeometryStylingController from './project-forms-block-editor-geometry-styling.controller.modal';
import ProjectFormsBlockEditorExpressionsController from './project-forms-block-editor-expressions.modal.controller';
import ProjectFormsBlockMapLayersController from './project-forms-block-map-layers.modal.controller';
import { RGEO_FIELDS, RGEO_LOCALISATIONS } from './rgeo.constants';
import angular from 'angular';
import { CoreoAttribute, CoreoCollection, CoreoForm } from '../../../../types';

export const ProjectFormsBlockEditorComponent = {
    template: require('./project-forms-block-editor.component.html'),
    controllerAs: 'ctrl',
    controller: class ProjectFormsBlockEditorComponent {

        allGeometryTypes = [
            { label: 'Point', value: 'point' },
            { label: 'LineString', value: 'linestring' },
            { label: 'Polygon', value: 'polygon' },
            { label: 'MultiPoint', value: 'multipoint' },
            { label: 'MultiLineString', value: 'multilinestring' },
            { label: 'MultiPolygon', value: 'multipolygon' },
        ];

        mediaTypes = [
            { label: 'Any file', value: null },
            { label: 'Video', value: 'video' },
            { label: 'Text file', value: 'text' }
        ];

        rgeoFields = RGEO_FIELDS;
        rgeoLocalisations = RGEO_LOCALISATIONS;

        projections = [{
            label: 'UK Grid Ref',
            value: '27700'
        }, {
            label: 'Irish Grid Ref',
            value: '29903'
        }];

        // geometryQueries = [
        //     { label: 'Within', value: 'within' },
        //     { label: 'Contains', value: 'contains' }
        // ];

        calculatedFieldDisplayTypes = [
            { label: 'Text', value: 'text' },
            { label: 'Date', value: 'date' },
            { label: 'Date/time', value: 'datetime' },
            { label: 'Number', value: 'float' },
            { label: 'Yes/No', value: 'boolean' }
        ];
        mapBaseStyles: any;
        geometricCollections: CoreoCollection[] = [];

        questionError = false;
        labelError = false;
        exportPathError = false;
        projectHasBounds: boolean;
        hasMap: boolean = false;
        isInteger: boolean;
        isMultiple: boolean;
        formId: number;
        collection: CoreoCollection;
        collections: CoreoCollection[];
        form: any;
        user: any;
        errors: any;
        collectionTooltip: any;
        baseLayersCount: number = 0;
        block: CoreoAttribute;
        geometryTypes: any[];
        formExportPaths: any[];

        // Bound Thunks
        updateSelectedAttribute: (attribute: Partial<CoreoAttribute>) => void;
        createCollection: (collection: any) => Promise<any>;
        updateCollection: (collection: any) => Promise<any>;
        validateFormSelectedAttribute: () => any;


        /* @ngInject */
        constructor(
            private $ngRedux,
            private $timeout,
            private $element,
            private $scope,
            private $mdDialog,
            MAP_BASE_STYLES,
            ProjectFormActions,
            ProjectActions,
            private Mapbox) {

            const calculatedTypes = ['rgeolocation', 'geometryquery', 'coordinatetransform', 'webhook'];
            const labelessQuestionTypes = ['geometry'];
            this.projectHasBounds = !!getProjectGeometry($ngRedux.getState());

            this.mapBaseStyles = MAP_BASE_STYLES;

            $scope.$on('$destroy', $ngRedux.connect(state => {
                const { form: { associations } } = state;
                const formExportPaths = state.form.attributes.map(a => a.exportPath);
                const block = angular.copy(getFormSelectedAttribute(state));
                const collection = getFormSelectedAttributeCollection(state);
                const isAssociationQuestion = !!block && (block.questionType === 'child' || block.questionType === 'association');
                const associationName = isAssociationQuestion && block.associatedSurveyId ? getFormSelectedAttributeAssociationForm(state).name : null;
                const collectionSummary = collection && collection.items && ((collection.items.slice(0, 5).map(i => i.value).join(', ') + (collection.items.length > 5 ? `...(${collection.items.length - 5} more)` : '')));

                const alternateQuestionTypes = (!block || (block.type === null) || (block.questionType === 'expression')) ? [] : state.form.questionState.reduce((acc, state) => {
                    if ((state.type === block.type) && (state.questionType !== block.questionType)) {
                        acc.push(state);
                    }
                    return acc;
                }, []);

                const hasMap =
                    (block?.type === 'select' && block?.questionType === 'geometryselect') ||
                    (block?.questionType === 'geometry') ||
                    (block?.questionType === 'association');

                const form = getFormState(state);
                const result = {
                    block,
                    type: block?.type,
                    questionType: block && block.questionType,
                    alternateQuestionTypes,
                    attribute: block,
                    associationName,
                    form,
                    errors: getFormSelectedAttributeErrors(state),
                    collections: getProjectCollections(state),
                    geometricCollections: getProjectCollections(state).filter(collection => collection.geometric),
                    collection,
                    collectionSummary,
                    formExportPaths,
                    forms: getProjectForms(state),
                    associatableForms: getProjectForms(state).filter(f => f.id !== form.id),
                    maps: getProjectMaps(state),
                    isInteger: block && block.type === 'integer',
                    isMultiple: block && block.type === 'multiselect',
                    collectionTooltip: block && block.config && block.config.collectionTooltip,
                    hasQuestion: block && block.questionType !== 'webhook' && calculatedTypes.indexOf(block && block.type) === -1,
                    hasLabel: labelessQuestionTypes.indexOf(block && block.questionType) === -1,
                    isCalculatedType: calculatedTypes.indexOf(block && block.type) !== -1,
                    isCalculatedField: block && block.questionType === 'expression',
                    associations,
                    user: getAuthUser(state),
                    geometryTypes: this.getGeometryTypes(block),
                    baseLayersCount: block?.config?.layers?.length ?? 0,
                    hasMap
                };
                return result;
            }, {
                ...ProjectFormActions,
                ...ProjectActions
            })(this));
        }

        update() {
            this.updateSelectedAttribute({
                text: this.block.text,
                label: this.block.label,
                description: this.block.description,
                exportPath: this.block.exportPath,
                type: this.block.type,
                required: this.block.required,
                visible: this.block.visible,
                config: this.block.config,
                collectionId: this.block.collectionId,
                associatedSurveyId: this.block.associatedSurveyId
            });
        }

        switchType(questionType) {
            this.updateSelectedAttribute({
                questionType
            });
        }

        changeSubForm(form) {
            this.formId = form.id;
        }

        collectionModal(ev, collection) {
            return this.$mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                template: require('./project-forms-block-collection-editor.modal.html'),
                fullscreen: true,
                locals: {
                    collection
                },
                bindToController: true,
                controllerAs: 'ctrl',
                /* @ngInject */
                controller: function ($mdDialog) {
                    this.cancel = () => {
                        $mdDialog.cancel();
                    };

                    this.done = () => {
                        // Ensure all items have
                        $mdDialog.hide(this.collection);
                    };
                }
            });
        }


        createCollectionModal(event) {
            this.collectionModal(event, {
                name: '',
                type: 'text',
                items: []
            }).then(result => {
                result.items.forEach(item => delete item.id);
                this.createCollection(result).then(c => {
                    this.block.collectionId = c.id;
                    this.update();
                })
            }, angular.noop);
        }

        editCollectionModal(event) {
            this.collectionModal(event, angular.copy(this.collection)).then(result => {
                return this.updateCollection(result);
            }, angular.noop);
        }

        openGeometryPickerStylingModal(event) {
            return this.$mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: event,
                clickOutsideToClose: true,
                template: require('./project-forms-block-editor-geometry-styling.modal.html'),
                fullscreen: true,
                locals: {
                    config: this.block.config.layersStyling || {}
                },
                bindToController: true,
                controllerAs: 'ctrl',
                /* @ngInject */
                controller: ProjectFormsBlockEditorGeometryStylingController
            }).then(layersStyling => {
                this.block.config = Object.assign(this.block.config, {
                    layersStyling
                });
                this.update();
            }, angular.noop);
        }

        changeInteger() {
            this.block.type = this.isInteger ? 'integer' : 'float';
            this.update();
        }

        changeMultiple() {
            this.block.type = this.isMultiple ? 'multiselect' : 'select';
            this.update();
        }

        changeCollectionTooltip() {
            this.block.config.collectionTooltip = this.collectionTooltip;
            this.update();
        }

        onRequiredChange() {
            if (this.block.required) {
                this.block.visible = true;
                if (this.block.questionType === 'association') {
                    this.block.config.max = 1;
                    this.block.config.min = 1;
                }
            } else {
                if (this.block.questionType === 'association') {
                    this.block.config.max = 1;
                    this.block.config.min = 0;
                }
            }
            this.update();
        }

        onExportPathChange() {
            this.exportPathError = !this.formExportPaths.includes(this.block.exportPath);
        }

        onGeometryTypeChange(idx) {
            // Update the block
            this.block.config.types = this.geometryTypes.filter(g => g.selected).map(g => g.value);

            // Don't allow zero
            if (this.block.config.types.length === 0) {
                this.geometryTypes[idx].selected = true;
                this.onGeometryTypeChange(idx);
            }
            this.update();
        }

        openMenu($mdMenu, ev) {
            // originatorEv = ev;
            $mdMenu.open(ev);
        };

        getGeometryTypes(block) {

            if (!block || block.questionType !== 'geometry') {
                return [];
            }

            const blockTypes = block.config && block.config.types || [];

            // If this is a saved block, filter out unused types and return
            if (block.id > 0) {
                return this.allGeometryTypes
                    .filter(type => blockTypes.includes(type.value))
                    .map(t => ({ ...t, selected: true }));
            }

            const result = [];
            for (const type of this.allGeometryTypes) {
                result.push({
                    ...type,
                    selected: blockTypes.includes(type.value)
                });
            }
            const hasType = typeof result.find(t => t.selected) !== 'undefined';
            if (!hasType) {
                result[0].selected = true;
            }
            return result;
        }

        openExpressionsEditor(ev) {
            this.validateFormSelectedAttribute();
            if (this.errors.noCalculatedFieldType) {
                return;
            }
            return this.$mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                template: require('./project-forms-block-editor-expressions.modal.html'),
                fullscreen: true,
                locals: {
                    block: this.block,
                    attributes: this.form.attributes,
                    collections: this.collections,
                    user: this.user,
                },
                bindToController: true,
                controllerAs: 'ctrl',
                /* @ngInject */
                controller: ProjectFormsBlockEditorExpressionsController,
            }).then(result => {
                if (!result) return;
                this.block.config.expression = result.expression;
                this.block.config.es5Expression = result.es5Expression;
                this.update();
            });
        }

        openBaseLayersEditor(ev) {
            return this.$mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                template: require('./project-forms-block-map-layers.modal.html'),
                // fullscreen: true,
                locals: {
                    layers: this.block.config.layers
                },
                bindToController: true,
                controllerAs: 'ctrl',
                /* @ngInject */
                controller: ProjectFormsBlockMapLayersController
            }).then(result => {
                if (!result) {
                    return;
                }
                this.block.config.layers = result;
                this.update();
            });
        }

    }
}
