import './project-page.scss';
import { getProject, getProjectMaps, getProjectRole, getProjectAttributes, getProjectPages, getPageState } from '../../store/selectors';


/* @ngInject */
export default class ProjectPageController {

  constructor($ngRedux, ProjectPagesService, ProjectPageActions, ProjectActions, toastr, $scope, $state, $mdDialog, appDomain, $timeout, $transitions) {

    this.ProjectPagesService = ProjectPagesService;
    this.toastr = toastr;
    this.$mdDialog = $mdDialog;
    this.appDomain = appDomain;
    this.$timeout = $timeout;

    $scope.$on('$destroy', $ngRedux.connect(state => {
      const page = $scope.page = angular.copy(getPageState(state));
      const project = getProject(state);
      return {
        project,
        role: getProjectRole(state),
        page,
        view: 'settings',
        maps: getProjectMaps(state),
        errors: page.errors,
      };
    }, Object.assign(ProjectPageActions, { updateProject: ProjectActions.updateProject }))(this));

    this.isWelcomePage = this.project.welcomePageId === this.page.id
    this.readonly = this.role !== 'admin';
    this.focusedTextBlock = -1; // Forces summernote to the front, to prevent overlapping issues between multiple text blocks
    // Setup the breadcrumbs

    this.authTypes = [
      {
        display: 'Everyone',
        value: 'all'
      },
      {
        display: 'Signed in members',
        value: 'user'
      },
      {
        display: 'Moderators and admins',
        value: 'moderator'
      },
      {
        display: 'Admins',
        value: 'admin'
      }
    ];


    this.pageListConfig = {
      axis: 'y',
      containment: 'parent',
      tolerance: 'pointer'
    };

    this.rootExists = true;

    var skipCheck = false;

    $scope.$on('$destroy', $transitions.onStart({}, (trans) => {
      var $state = trans.router.stateService;
      var toState = trans.to();
      var toParams = trans.params();

      if (skipCheck) {
        skipCheck = false;
        return true;
      }

      if (this.page.dirty) {
        // event.preventDefault();
        return this.checkUnsaved().then(function (result) {
          if (!result) {
            return false;
          }
          if (result === 'save') {
            vm.save();
          }
          skipCheck = true;
          $state.target(toState.name, toParams);
        });
      }
    }));

    var vm = this;

    window.onbeforeunload = () => {
      if (this.page.dirty) {
        return "The current page has unsaved changes. Are you sure you wish to leave?";
      }
    };

    $scope.$on('$destroy', () => {
      window.onbeforeunload = null;
    });
  }

  changeView(view) {
    this.switchView(view);
  }

  routeChange() {
    this.updateSettings();
  }

  save() {
    if (this.isWelcomePage && this.project.welcomePageId !== this.page.id) {
      this.updateProject({
        id: this.project.id,
        welcomePageId: this.page.id
      });
    }
    if (!this.isWelcomePage && this.project.welcomePageId === this.page.id) {
      this.updateProject({
        id: this.project.id,
        welcomePageId: null
      })
    }
    this.savePage();
  }

  updateSettings() {
    this.updatePageSettings(
      this.page.title,
      this.page.route,
      this.page.icon,
      this.page.permissions,
      this.page.published,
      this.page.listed,
      this.page.config
    );
  }

  publish() {
    this.checkUnsaved('publishing', 'Save and Publish', true)
      .then(() => {
        this.publishPage();
      })

  };

  unpublish() {
    this.unpublishPage();
  };

  checkUnsaved(actionName, saveText, hideDontSave, ev) {
    if (typeof actionName === 'undefined') {
      actionName = 'leaving';
    }
    if (typeof saveText === 'undefined') {
      saveText = 'Save & Exit';
    }

    if (!this.page.dirty) {
      return Promise.resolve();
    }

    return this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      template: require('./unsaved-page.modal.html'),
      /* @ngInject */
      controller: function ($scope, $mdDialog) {
        $scope.actionName = actionName;
        $scope.hideDontSave = hideDontSave;
        $scope.saveText = saveText;
        $scope.ok = function () {
          $mdDialog.hide('save');
        };
        $scope.dontSave = function () {
          $mdDialog.hide('dont');
        };

        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      }
    }).catch(angular.noop);
  }

}
