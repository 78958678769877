import './project-forms-rules.scss';
import { getProjectCollections, getFormAttributes, getFormSelectedAttribute } from '../../../store/selectors';

const UNARY_OPERATORS = ['answered', 'unanswered'];

export const ProjectFormsRulesComponent = {
  template: require('./project-forms-rules.component.html'),
  controllerAs: 'ctrl',
  controller: class ProjectFormsRulesComponent {
    /* @ngInject */
    constructor($ngRedux, $scope, ProjectFormActions) {
      $scope.$on('$destroy', $ngRedux.connect((state) => {

        const attribute = angular.copy(getFormSelectedAttribute(state));
        const attributes = angular.copy(getFormAttributes(state));
        const questions = attribute ? attributes.filter(a => a.path && a.label && a.id !== attribute.id) : [];
        const questionIndex = _.keyBy(questions, 'path');
        const collections = angular.copy(getProjectCollections(state));
        const collectionIndex = _.keyBy(collections, 'id');

        return {
          blocks: attributes,
          questions,
          questionIndex,
          block: attribute,
          collections,
          collectionIndex,
          rules: attribute && attribute.conditions && attribute.conditions.rules
        };
      }, ProjectFormActions)(this));
    }

    isUnaryOperator(operand) {
      return UNARY_OPERATORS.includes(operand);
    }

    addItem() {
      this.addConditionRule();
    }

    removeItem(index) {
      this.removeConditionRule(index);
    }

    updateCondition(rule, conditionKey, comparandKey) {
      if (rule && this.isUnaryOperator(rule[conditionKey])) {
        delete rule[comparandKey];
      }
      this.update()
    }

    update() {
      this.updateConditionRules(this.block.conditions);
    }

    changedQuestion(rule) {
      const attribute = this.blocks.find(a => a.path === rule.path);

      if (attribute) {
        var type = attribute.type;
        switch (type) {
          case 'integer':
          case 'float':
            if (!rule.numberCondition) {
              rule.numberCondition = 'eq';
              rule.numberComparand = 0;
            }
            break;
          case 'text':
            if (!rule.textCondition) {
              rule.textCondition = 'is';
              rule.textComparand = '';
            }
            break;
          case 'date':
            if (!rule.dateCondition) {
              rule.dateCondition = 'is';
              rule.dateComparand = (new Date()).toUTCString();
            }
            break;
          case 'datetime':
            if (!rule.datetimeCondition) {
              rule.datetimeCondition = 'before';
              rule.datetimeComparand = (new Date()).toUTCString();
            }
            break;
          case 'select':
            if (!rule.selectCondition) {
              rule.selectCondition = 'is';
              const attribute = this.blocks.find(a => a.path === rule.path);
              const collectionId = attribute && attribute.collectionId;
              if (collectionId) {
                const collection = this.collections.find(c => c.id === collectionId);
                if (typeof collection !== 'undefined' && collection.items.length > 0) {
                  rule.selectComparand = collection.items[0].key;
                }
              }
            }
            break;
          case 'boolean':
            if (typeof rule.isTrue === 'undefined') {
              rule.isTrue = true;
            }
            break;
        }
        this.update();
      }
    }
    getDate(dateString) {
      return new Date(dateString);
    }
    fromDate(date) {
      return date ? date.toUTCString() : '';
    }

  }
};
export default ProjectFormsRulesComponent;