import './to-app.scss';
import { APP_URL } from '../../intermediarypage.constants';

export const ToAppComponent = {
    bindings: {
        url: '<' // optional override
    },
    transclude: true,
    template: require('./to-app.html'),
    controllerAs: 'ctrl',
    controller: class ToAppController {
        /* @ngInject */
        constructor() {
            this.APP_URL = APP_URL;
        }

        $onInit() {

        }
    }

};
