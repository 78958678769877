// import mapboxgl from 'mapbox-gl';
import './record-map.scss';
import { buildDrawStyle } from './draw-styles';
import booleanContains from '@turf/boolean-contains';
import mapboxgl from 'mapbox-gl';
import _ from 'lodash';
import { CoreoDataLayerStyle } from '../../types';
import { defaultDataLayerStyle } from '../../main/mapData.service';

const toMultiType = (features) => {
    const geometry = {
        type: null,
        coordinates: []
    };

    if (features.length === 0) return geometry;
    geometry.type = 'Multi' + features[0].geometry.type;

    for (let feature of features) {
        geometry.coordinates.push(feature.geometry.coordinates)
    }

    return geometry;
}

const fromMultiType = (multiGeom, type) => {
    const features = [];

    for (let coords of multiGeom.coordinates) {
        features.push({
            type: 'Feature',
            properties: {},
            geometry: {
                type,
                coordinates: coords
            }
        });
    }
    return features;
}

export const RecordMapComponent = {
    bindings: {
        geometry: '<',
        projectBounds: '<',
        noControls: '<',
        layerStyle: '<',
        onUpdate: '&',
        onSave: '&',
        save: '=',
    },
    template: require('./record-map.component.html'),
    controllerAs: 'ctrl',
    controller: class RecordMapComponent {

        featureUpdated: boolean = false;
        featureSplit: boolean = false;
        isSatellite: boolean = false;
        map: mapboxgl.Map = null;
        draw: any = null;
        featureId: number = null;

        projectBoundsHandle: any;


        // Input Bindings
        projectBounds: any;
        geometry: any;
        noControls: boolean;
        layerStyle: CoreoDataLayerStyle;

        // Output Bindings
        onSave: () => Promise<void>;
        onUpdate: (event: { geometry: any }) => Promise<void>;

        /* @ngInject */
        constructor(private $element, private MapUtils, private Mapbox, private toastr) {
        }

        toggleSatellite() {
            this.isSatellite = !this.isSatellite;
            if (this.map.getLayer('satellite')) {
                this.map.setLayoutProperty('satellite', 'visibility', this.isSatellite ? 'visible' : 'none');
            }
            if (this.projectBoundsHandle) {
                this.projectBoundsHandle.setColor(this.isSatellite ? '#fff' : '#000');
            }
        }

        $onChanges(changes) {
            // don't have a requirement to handle multi geometries / non-points yet
            if (this.draw && changes.geometry && !this.featureSplit && this.geometry.coordinates && this.geometry.coordinates[0] && this.geometry.coordinates[1]) {
                this.draw.delete(this.featureId);
                const feature = _.assign({}, {
                    type: 'Feature',
                    properties: {},
                    geometry: this.geometry
                });
                this.featureId = this.draw.add(feature);
                // var bounds = this.MapUtils.geometryToBounds(this.geometry);
                // this.map.fitBounds(bounds, {
                //     padding: 40,
                //     maxZoom: 16,
                //     linear: false,
                //     duration: 0
                // });

            }
        }

        $onInit() {
            const vm = this;

            function SaveRecordControl() { }

            SaveRecordControl.prototype.onAdd = function (map) {
                this._map = map;
                const button = document.createElement(`button`);
                button.className = `<button class="mapboxgl-ctrl-icon" title="Delete"></button>`;

                const icon = document.createElement('i');
                icon.className = 'fas fa-save';
                button.appendChild(icon);

                this._container = document.createElement('div');
                this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
                this._container.appendChild(button);
                this._container.addEventListener('click', () => {
                    if (vm.featureUpdated) {
                        if (vm.projectBounds) {
                            const boundsPolygons = vm.projectBounds.coordinates.map(coordinates => ({
                                type: 'Polygon',
                                coordinates
                            }));

                            const features = vm.draw.getAll().features;
                            for (const feature of features) {
                                for (const bounds of boundsPolygons) {
                                    if (!booleanContains(bounds, feature)) {
                                        vm.toastr.error('The geometry must be entirely within the project bounds');
                                        return false;
                                    }
                                }
                            }
                        }

                        vm.onSave().then(() => {
                            vm.draw.changeMode('simple_select');
                            vm.featureUpdated = false;
                            if (vm.projectBoundsHandle) {
                                vm.projectBoundsHandle.remove();
                                vm.projectBoundsHandle = null;
                            }
                        });
                    }
                });

                return this._container;
            };

            SaveRecordControl.prototype.onRemove = function () {
                this._container.parentNode.removeChild(this._container);
                this._map = undefined;
            };

            const { geometry } = this;
            var coordinates = geometry && geometry.coordinates;

            if (!coordinates) {
                return;
            }

            var container = this.$element[0].querySelector('.record-map');
            this.Mapbox.get().then(mapboxgl => {
                this.map = new mapboxgl.Map({
                    container: container,
                    style: 'mapbox://styles/mapbox/streets-v12'
                });

                if (!this.noControls) {
                    this.map.addControl(new mapboxgl.FullscreenControl());
                    this.map.addControl(new SaveRecordControl());
                }
                var bounds = this.MapUtils.geometryToBounds(geometry);
                this.map.fitBounds(bounds, {
                    padding: 40,
                    maxZoom: 16,
                    linear: false,
                    duration: 0
                });

                var existing = geometry && geometry.type;

                import(/* webpackChunkName: "mapbox-gl-draw" */'@mapbox/mapbox-gl-draw').then(({ default: MapboxDraw }) => {
                    var drawOptions = {
                        displayControlsDefault: false,
                        boxSelect: true,
                        controls: {
                            point: !existing,
                            polygon: !existing,
                            line_string: !existing
                        },
                        styles: buildDrawStyle(this.layerStyle ?? defaultDataLayerStyle())
                    };
                    this.draw = new MapboxDraw(drawOptions);
                    this.map.addControl(this.draw);
                });

                this.map.on('load', () => {
                    this.map.addLayer({
                        id: 'satellite',
                        source: {
                            type: "raster",
                            url: "mapbox://mapbox.satellite",
                            tileSize: 256
                        },
                        type: "raster",
                        layout: {
                            visibility: `${this.isSatellite ? 'visible' : 'none'}`
                        },
                    });

                    const geometryType = this.geometry.type;
                    if (geometryType.slice(0, 5) === 'Multi') {
                        this.featureSplit = true;
                        let features = fromMultiType(this.geometry, geometryType.slice(5, geometryType.length));
                        for (let feature of features) {
                            this.draw.add(feature);
                        }
                    } else {
                        let feature = _.assign({}, {
                            type: 'Feature',
                            properties: {},
                            geometry: this.geometry
                        });
                        this.featureId = this.draw.add(feature);
                    }


                    this.map.on('draw.update', (e) => {
                        if (this.featureSplit) {
                            this.onUpdate({ geometry: toMultiType(this.draw.getAll().features) });
                        } else {
                            this.onUpdate({ geometry: e.features[0].geometry });
                        }
                        this.featureUpdated = true;
                    });

                    if (this.projectBounds) {
                        this.map.on('draw.selectionchange', e => {
                            if (e.features.length === 0 && this.projectBoundsHandle && !this.featureUpdated) {
                                this.projectBoundsHandle.remove();
                                this.projectBoundsHandle = null;
                            } else if (!this.projectBoundsHandle) {
                                this.projectBoundsHandle = this.MapUtils.addProjectBoundaryLayer(this.map, this.projectBounds, this.isSatellite ? '#fff' : '#000');
                            }
                        });
                    }

                    this.map.on('draw.modechange', e => {
                        // console.log('HERE?1', e);
                    });

                    // Move to bounds

                });
            });
        }
    }
}
