import './help-tooltip.directive.scss';

export default class HelpTooltipDirective {
    constructor() {
        this.restrict = 'E';
        this.scope = {
            text: '='
        };
        this.template = '<i class="fa fa-question-circle help-tooltip" uib-tooltip="{{text}}" tooltip-placement="right" tooltip-append-to-body="true"></i>';
    }
}