import './record-search.component.scss';
import { getProjectAttributesForForm } from '../../store/selectors';

export const RecordSearchComponent = {
    template: require('./record-search.component.html'),
    controllerAs: 'ctrl',
    bindings: {
        surveyId: '<',
        ngModel: '<',
        multiple: '<',
        required: '<'
    },
    require: {
        ngModelCtrl: 'ngModel'
    },
    controller: class RecordSearchComponent {

        /* @ngInject */
        constructor($scope, $ngRedux, $element, $timeout, CoreoAPI) {
            this.$scope = $scope;
            this.$element = $element;
            this.$timeout = $timeout;
            this.CoreoAPI = CoreoAPI;
            this.records = [];
            this.searchText = '';
            this.selectedItem = null;
            this.$ngRedux = $ngRedux;
            this.selected = [];
            this.offset = 0;
            this.limit = 20;
        }

        $onInit() {
            let now = new Date().getTime();
            this.selectedItem = this.ngModel;
            // console.log('INIT');
            // if (this.ngModel) {
            //     this.get(this.ngModel.id).then(record => {
            //         this.searchText = record.title;
            //         this.selectedItem = record;
            //     });
            // }

            this.$timeout(() => {
                const rep = angular.element(document.querySelector('.md-virtual-repeat-scroller'));
                rep.on('scroll', () => {
                    if (rep[0].scrollTop + rep[0].offsetHeight >= rep[0].scrollHeight)
                        if (new Date().getTime() - now > 100) {
                            now = new Date().getTime();
                            this.$scope.$apply(() => {
                                this.offset += this.limit;
                                this.queryRecords().then(records => {
                                    console.log('HER?!', records);
                                    this.records.push.apply(this.records, records);
                                });
                            });
                        }
                });
            }, 0);
        }

        $onChanges(changes) {
            // console.log('onChanges?', changes);
            const t = getProjectAttributesForForm(this.surveyId)(this.$ngRedux.getState());
            this.searchPath = t[0].path;
        }

        onSelected() {
            // console.log('onSelected?!', this.selectedItem);
            this.ngModelCtrl.$setViewValue(this.selectedItem);
        }

        get(id) {
            const query = `query getRecord($id: Int!){
                data: record(id: $id){
                    title,
                    id
                }
            }`;
            return this.CoreoAPI.query(query, {
                variables: {
                    id
                }
            }).then(result => {
                return result.data;
            });
        }

        query() {
            this.offset = 0;
            return this.queryRecords().then(records => {
                this.records = records;
                return records;
            });
        }

        queryRecords() {

            const query = `query searchRecords($surveyId: Int!, $limit: Int!, $offset: Int!, $search: String!, $order: String!){
                data: records(order: $order, where: { surveyId: $surveyId, data: { ${this.searchPath}: { iLike: $search } } }, limit: $limit, offset: $offset){
                    title
                    id
                }
            }`;

            return this.CoreoAPI.query(query, {
                variables: {
                    surveyId: this.surveyId,
                    search: `%${this.searchText}%`,
                    limit: this.limit,
                    offset: this.offset,
                    order: `data.${this.searchPath}`
                }
            }).then(result => {
                return result.data;
            });
        }
    }
};
