import './searchable-list.component.scss';

export const SearchableListComponent = {
    bindings: {
        items: '<',
        placeholder: '@'
    },
    template: require('./searchable-list.component.html'),
    controllerAs: 'ctrl',
    controller: class SearchableListComponent {
        /* @ngInject */
        constructor($element) {
            this.selection = [];
            this.searchTerm = '';
            this.placeholder = 'Search...';
            this.$element = $element;
        }

        $onInit() {
            this.$element.find('input').on('keydown', function (ev) {
                ev.stopPropagation();
            });
        }
        clearSearchTerm() {
            this.searchTerm = '';
        }

        // $onChanges(changes) {

        // }
    }
};
