import './media-manager-item.component.scss';
export const MediaManagerItemComponent = {
    bindings: {
        item: '<',
        showName: '@',
        onClick: '&'
    },
    template: require('./media-manager-item.component.html'),
    controllerAs: 'ctrl',
    controller: class MediaManagerItemComponent {
        /* @ngInject */
        constructor($sce) {
            this.$sce = $sce;
            this.type = 'image';
        }

        $onInit() {
            this.url = this.$sce.trustAsResourceUrl(this.item.url);
            this.showName = typeof this.showName === 'undefined' ? true : this.showName === 'true';
            if (this.item.type) {
                var mimeParts = this.item.type.split('/');
                this.type = mimeParts[0];
            }
        }

        handleClick(event) {
            this.onClick({
                event,
                item: this.item
            });
        }
    }

};
