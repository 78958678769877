import './project-records-filter.component.scss';
import { v4 as uuid } from 'uuid';
import { getProjectForms, getProjectAttributes, getProjectCollections, getRecordsFilterCustomOperator, getRecordsFilterCustom } from '../../../store/selectors';

const emptyFilter = {
    formId: null,
    attributeId: null,
    attributePath: null,
    operatorId: null,
    value: null
};

export const ProjectRecordsFilterComponent = {
    template: require('./project-records-filter.component.html'),
    controllerAs: 'ctrl',
    controller: class ProjectRecordsFilterComponent {
        /* @ngInject */
        constructor($rootScope, $scope, $ngRedux, RecordsActions) {
            this.$ngRedux = $ngRedux;
            this.RecordsActions = RecordsActions;
            this.$rootScope = $rootScope;

            const state = $ngRedux.getState();
            this.filters = getRecordsFilterCustom(state);
            this.operator = getRecordsFilterCustomOperator(state);
            this.forms = getProjectForms(state);

            this.attributes = getProjectAttributes(state).reduce((acc, attribute) => {
                if (attribute.surveyId && attribute.type) {
                    acc[attribute.surveyId] = acc[attribute.surveyId] || [];
                    acc[attribute.surveyId].push(attribute);
                }
                return acc;
            }, {});
            this.collections = getProjectCollections(state);

            $scope.$on('$records:clearFilter', () => {
                this.filters = [];
                this.operator = 'and';
            });

            $scope.$on('$destroy', $ngRedux.connect((state) => ({
                operator: getRecordsFilterCustomOperator(state),
                filters: getRecordsFilterCustom(state)
            }), null)(this));
        }

        onFilterUpdate(filter) {
            this.$ngRedux.dispatch(this.RecordsActions.updateCustomFilter(filter));
        }

        onFilterDelete(id) {
            this.$ngRedux.dispatch(this.RecordsActions.deleteCustomFilter(id));
        }

        dispatchUpdate() {
            this.$ngRedux.dispatch(this.RecordsActions.updateCustomFilter(this.filters));
        }

        add() {
            this.$ngRedux.dispatch(this.RecordsActions.addCustomFilter());
        }

        updateOperator(op) {
            if (this.operator !== op) {
                this.operator = op;
                this.$ngRedux.dispatch(this.RecordsActions.updateCustomFilterOperator(op));
            }
        }

    }
};