/* @ngInject */
export default class ProjectDependencyErrorService {
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    display(error) {
        if(error.code !== 'ModelDependencyError'){
            return;
        }
        this.$mdDialog.show({
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            template: require('./project-dependency-error.modal.html'),
            /* @ngInject */
            controller: function ($scope, $mdDialog) {
                $scope.error = error;
                $scope.ok = function () {
                    $mdDialog.hide();
                };
            }
        }).then(angular.noop);
    };
}
