import './reset-password.scss';

const VIEWS = {
    RESET: 'reset',
    SUCCESS: 'success',
    ERROR: 'error',
}

/* @ngInject */
export default class IntermediaryPageResetPasswordController {
    constructor($location, IntermediaryPageService) {
        this.$location = $location;
        this.IntermediaryPageService = IntermediaryPageService
        this.hostUrl = this.IntermediaryPageService.getHostUrl();
        this.displayHostUrl = this.IntermediaryPageService.getDisplayHostUrl();
        this.view = VIEWS.RESET;
        this.newPassword = null;
        this.error = null;
        const search = $location.search();
        this.resetToken = search.password_reset_token;

        if (!this.resetToken) {
            this.view = VIEWS.RESET;
        }
    }

    $onInit() {}
    
    update() {
        this.IntermediaryPageService.updatePassword(this.resetToken, this.newPassword).then(({ token, isAdminAreaUser }) => {
            this.$location.search({});
            this.$location.replace();
            this.authToken = token;
            this.isAdminAreaUser = isAdminAreaUser;
            this.view = VIEWS.SUCCESS;
        }, err => {
            this.view = VIEWS.ERROR;
            this.error = err.message;
        })
    }
}
