import { createSelector } from "reselect";

export const getOrganisationsLoading = state => state.loading;
export const getOrganisationsError = state => state.error;
export const getOrganisationsList = state => state.organisations;
export const getOrganisation = state => state.organisation;
export const getOrganisationMemberships = state => state.memberships || [];
export const getOrganisationMembership = state => state.membership;

export const getOrganisationId = createSelector(getOrganisation, organisation => organisation && organisation.id);
export const getOrganisationSlug = createSelector(getOrganisation, organisation => organisation && organisation.slug);
export const getOrganisationImage = createSelector(getOrganisation, organisation => organisation && organisation.imageUrl);
export const getOrganisationRole = createSelector(getOrganisation, organisation => organisation && organisation.viewerRole);
export const getOrganisationFreeTrialEnd = createSelector(getOrganisation, organisation => organisation && organisation.freeTrialEnd);
export const getOrganisationFreeTrialExpired = createSelector(getOrganisation, organisation => organisation && organisation.freeTrialExpired);