export const deepReplaceKeys = (obj, toReplace, newValue) => {
    for (const key of Object.keys(obj)) {
        if (typeof obj[key] === 'object') {
            deepReplaceKeys(obj[key], toReplace, newValue)
        }
        if (key.includes(toReplace)) {
            obj[key.replace(new RegExp(toReplace, 'g'), newValue)] = obj[key];
            delete obj[key]
        }
    }
}

export const MEDIA_ITEM_ID_ATTRIBUTE = 'media-item-id';

export const slugify = (text) => {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    const to = "aaaaaeeeeeiiiiooooouuuunc------"

    const newText = text.split('').map(
        (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

    return newText
        .toString()                     // Cast to string
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                         // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/&/g, '-y-')           // Replace & with 'and'
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}

export const urlToImgix = (string, params = {}) => {
    const s3Url = new URL(string);

    if (!s3Url.hostname.startsWith('coreo')) {
        return string;
    }

    const imgixUrl = new URL(`https://coreo.imgix.net/${s3Url.pathname}`);
    for (const [key, value] of Object.entries(params)) {
        imgixUrl.searchParams.append(key, value);
    }
    return imgixUrl.toString();
}

export const urlBase64Decode = (str) => {
    var output = str.replace('-', '+').replace('_', '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw 'Illegal base64url string!';
    }
    return window.atob(output);
}
