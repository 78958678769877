import angular from 'angular';

/* @ngInject */
export default class AdminUserController {
    constructor($scope, $uibModal, $state, $ngRedux, user, toastr, AdminUsersService, AuthActions) {
        this.user = user;
        this.$uibModal = $uibModal;
        this.AdminUsersService = AdminUsersService;
        this.$state = $state;
        this.toastr = toastr;
        $scope.$on('$destroy', $ngRedux.connect(null, AuthActions)(this));
    }


    deleteUser() {
        const vm = this;
        var modal = this.$uibModal.open({
            template: require('./admin-user-delete-modal.html'),
            /* @ngInject */
            controller: function ($scope, $uibModalInstance) {
                $scope.user = vm.user;
                $scope.ok = function (force) {
                    $uibModalInstance.close(force);
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss('cancel');
                };
            }
        });

        modal.result.then((force) => {
            this.AdminUsersService.deleteUser(vm.user.id, force).then((deletedUser) => {
                if (force) {
                    this.$state.go('admin.users');
                } else {
                    angular.extend(this.user, deletedUser);
                }
                this.toastr.success('User deleted.');
            });
        }, angular.noop);
    };

    restoreUser() {
        this.AdminUsersService.restoreUser(this.user.id).then((restoredUser) => {
            this.user = restoredUser;
        });
    };

    impersonateUser() {
        this.impersonate(this.user.id).then(() => {
            this.$state.go('home');
        });
    };

}

