import coreoIcon from '../../assets/icons/coreo.svg';

/* @ngInject */
export default class PasswordResetController {
  constructor($state, $stateParams, $ngRedux, AuthActions, toastr) {
    this.$stateParams = $stateParams;
    this.toastr = toastr;
    this.$state = $state;
    this.email = '';
    this.submitted = false;
    this.coreoIcon = coreoIcon;
    this.$ngRedux = $ngRedux;
    this.AuthActions = AuthActions;
  }

  reset() {
    this.$ngRedux.dispatch(this.AuthActions.passwordReset(this.email)).then(() => {
      if (typeof this.$stateParams.project_id !== 'undefined') {
        this.submitted = true;
      } else {
        this.toastr.success('Password reset request sent. Make sure to check your spam/junk folders too.');
        this.$state.go('login');
      }
    });
  };
}
