// Vendor Libraries
import ngSanitize from "angular-sanitize";
import uiRouter from "@uirouter/angularjs";
import LocalStorageModule from "angular-local-storage";
import jQueryUI from "jquery-ui-dist/jquery-ui";
import bootstrap from "bootstrap/dist/js/bootstrap";
import bootstrapLightbox from "angular-bootstrap-lightbox";
import bootstrapIconPicker from "bootstrap-iconpicker/bootstrap-iconpicker/js/bootstrap-iconpicker";
import uiBootstrap from "angular-ui-bootstrap";
import uiSortable from "angular-ui-sortable";
import ngFileSaver from "angular-file-saver";
import toastr from "angular-toastr";
import hcMarked from "angular-marked";
import uiCodemirror from "angular-ui-codemirror";
import ngAria from "angular-aria";
import ngMessages from "angular-messages";
import ngMaterial from "angular-material";
import logger from "angular-simple-logger";
import summernote from "summernote";
import angularSummernote from "angular-summernote/dist/angular-summernote";
import colorPicker from "angularjs-color-picker";
import Sortable from "sortablejs";
import ngSortable from "ng-sortable";
import ngClipboard from "ngclipboard/dist/ngclipboard";

require("angular-legacy-sortablejs-maintained");
import * as Sentry from "@sentry/browser";

// Redux
import ngRedux from "ng-redux";
import createStore, { middleware, enhancers } from "../store/store";
import rootReducer from "../store/root-reducer";

// Action Services
import ProjectActions from "../store/project/project.actions";
import ProjectsActions from "../store/projects/projects.actions";
import ProjectFormActions from "../store/form/form.actions";
import ProjectPageActions from "../store/page/page.actions";
import AuthActions from "../store/auth/auth.actions";
import RecordsActions from "../store/records/records.actions";
import RecordActions from "../store/record/record.actions";
import OrganisationsActions from "../store/organisations/organisations.actions";

// Middleware
import { toastMiddleware, errorMiddleware } from "../store/middleware";
import {
    recordsMiddleware,
    recordsRefreshMiddleware,
} from "../store/records/records.middleware";

// Services
import CoreoAPI from './coreoApi';
import GeoJSON from './geojson';
import MapUtils from './mapUtils';
import Mapbox from './mapbox.service';
import MapboxStyle from './mapboxStyle.service';
import Tabulator from './tabulator.service';
import Boundaries from './boundaries.service';
import Modal from './modal.service';
import MapDataLayerService from './mapData.service';

// Factories
import CoreoGraphQLQuery from "./coreoGraphQLQuery";
import coreoQuery from "./coreoQuery";
import eventEmitter from "./utils/eventemitter.mixin";

// Directives
import AppHeaderDirective from "./app-header/app-header.directive";
import { ListDirective, ListItemDirective } from "./list/list.directive";
import HelpTooltipDirective from "./help-tooltip/help-tooltip.directive";
import FileChangeDirective from "./file-change/file-change.directive";
import CompareToDirective from "./compareTo.directive";
import StringToNumberDirective from "./stringToNumber.directive";
import DateOnlyDirective from "./dateOnly.directive";
import IFrameOnLoadDirective from "./iframeOnLoad.directive";
import CoreoSortableDirective from "./coreoSortable.directive";
import IconPickerDirective from "./icon-picker/icon-picker.directive";
import UsernameCheckDirective from "./usernameCheck.directive";
import PasswordCheckDirective from "./passwordCheck.directive";

// Components
import { CollectionItemComponent } from "./collection-item/collection-item.component";
import { CollectionSelectComponent } from "./collection-select/collection-select.component";
import { SearchableListComponent } from "./searchable-list/searchable-list.component";
import { PasswordFeedbackComponent } from "./passwordFeedback.component";
import { UsernameFeedbackComponent } from "./usernameFeedback.component";
import { AppBreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { AppTutorialComponent } from "./tutorial/tutorial.component";
import { AppTutorialCoachmarkComponent } from "./tutorial/tutorial-coachmark.component";
import { SelectAutocompleteComponent } from "./select-autocomplete/select-autocomplete.component";
import { MinScreenSizeBlockComponent } from "./min-screen-size-block/min-screen-size-block.component";
import { SidebarContentComponent } from "./sidebar-content/sidebar-content.component";

// Filters
import ioniconFilter from "./filters/ionicon.filter";
import capitalizeFilter from "./filters/capitalize.filter";
import bytesFilter from "./filters/bytes.filter";
import booleanFilter from "./filters/boolean.filter";
import cutFilter from "./filters/cut.filter";
import validationErrFilter from "./filters/validationErr.filter";
import collectionSelectAttributes from "./filters/collectionSelectAttributes.filter";
import authProviderNameFilter from "./filters/authProviderName.filter";
import authProviderIconFilter from "./filters/authProviderIcon.filter";
import imgixFilter from "./filters/imgix.filter";

// Constants
import MAP_BASE_STYLES from "./mapbox-base-styles.constants";
import MAPBOX_STYLE_SPEC from "./mapbox-stye-spec.constant";

// CSS
import "./main.scss";
import LightboxDirective from "./directives/lightbox.directive";

export default angular
    .module("app.main", [
        "ui.router",
        "LocalStorageModule",
        "bootstrapLightbox",
        "color.picker",
        "ui.bootstrap",
        "ui.sortable",
        "ngFileSaver",
        "ngAnimate",
        "toastr",
        "hc.marked",
        "ui.codemirror",
        "ngAria",
        "ngMessages",
        "ngMaterial",
        "summernote",
        "app.auth",
        "ng-sortable",
        "as.sortable",
        ngRedux,
        "ngclipboard",
    ])
    .service('CoreoAPI', CoreoAPI)
    .service('GeoJSON', GeoJSON)
    .service('MapUtils', MapUtils)
    .service('Mapbox', Mapbox)
    .service('MapboxStyle', MapboxStyle)
    .service('Tabulator', Tabulator)
    .service('Boundaries', Boundaries)
    .service('ModalService', Modal)
    .service('ProjectActions', ProjectActions)
    .service('ProjectsActions', ProjectsActions)
    .service('ProjectFormActions', ProjectFormActions)
    .service('ProjectPageActions', ProjectPageActions)
    .service('AuthActions', AuthActions)
    .service('RecordsActions', RecordsActions)
    .service('RecordActions', RecordActions)
    .service('OrganisationsActions', OrganisationsActions)
    .factory('toastMiddleware', toastMiddleware)
    .factory('errorMiddleware', errorMiddleware)
    .factory('recordsMiddleware', recordsMiddleware)
    .factory('recordsRefreshMiddleware', recordsRefreshMiddleware)
    .factory('CoreoGraphQLQuery', CoreoGraphQLQuery)
    .factory('CoreoQuery', coreoQuery)
    .factory('eventEmitter', eventEmitter)
    .service('MapData', MapDataLayerService)
    .directive('appHeader', ($state, $mdMenu, $ngRedux, AuthActions, $rootScope, $transitions, $location, docsUrl, SupportService) => new AppHeaderDirective($state, $mdMenu, $ngRedux, AuthActions, $rootScope, $transitions, $location, docsUrl, SupportService))
    .directive('list', () => new ListDirective())
    .directive('listItem', () => new ListItemDirective())
    .directive('helpTooltip', () => new HelpTooltipDirective())
    .directive('fileChange', () => new FileChangeDirective())
    .directive('compareTo', () => new CompareToDirective())
    .directive('convertToNumber', () => new ConvertToNumberDirective())
    .directive('iframeOnload', () => new IFrameOnLoadDirective())
    .directive('coreoSortable', () => new CoreoSortableDirective())
    .directive('iconPicker', () => new IconPickerDirective())
    .directive('coreoPermissions', () => new CoreoPermissionsDirective())
    .directive('usernameCheck', ($q, CoreoAPI) => new UsernameCheckDirective($q, CoreoAPI))
    .directive('passwordCheck', ($q) => new PasswordCheckDirective($q, CoreoAPI))
    .directive('lightbox', (Lightbox) => new LightboxDirective(Lightbox))
    .component('collectionItem', CollectionItemComponent)
    .component('collectionSelect', CollectionSelectComponent)
    .component('searchableList', SearchableListComponent)
    .component('passwordFeedback', PasswordFeedbackComponent)
    .component('usernameFeedback', UsernameFeedbackComponent)
    .component('appBreadcrumbs', AppBreadcrumbsComponent)
    .component('appTutorial', AppTutorialComponent)
    .component('appTutorialCoachmark', AppTutorialCoachmarkComponent)
    .component('tutorial', AppTutorialComponent)
    .component('coachmark', AppTutorialCoachmarkComponent)
    .component('selectAutocomplete', SelectAutocompleteComponent)
    .component('minScreenSizeBlock', MinScreenSizeBlockComponent)
    .component('sidebarContent', SidebarContentComponent)
    .filter('ionicon', ioniconFilter)
    .filter('capitalize', capitalizeFilter)
    .filter('authProviderName', authProviderNameFilter)
    .filter('authProviderIcon', authProviderIconFilter)
    .filter('bytes', bytesFilter)
    .filter('boolean', booleanFilter)
    .filter('cut', cutFilter)
    .filter('validationErr', validationErrFilter)
    .filter('collectionSelectAttributes', collectionSelectAttributes)
    .filter('imgix', imgixFilter)
    .constant('MAP_BASE_STYLES', MAP_BASE_STYLES)
    .constant('MAPBOX_STYLE_SPEC', MAPBOX_STYLE_SPEC)
    .config(function ($urlServiceProvider, $stateProvider, $locationProvider, $httpProvider, $mdThemingProvider, $mdInkRippleProvider, $ariaProvider, $ngReduxProvider, $animateProvider, $mdDateLocaleProvider) {
        Sentry.init({
            dsn: "https://82670caaccb34fedab2ee67c5c942413@o416754.ingest.sentry.io/1313429",
        });

        $locationProvider.html5Mode(true);
        $locationProvider.hashPrefix("");
        $urlServiceProvider.config.strictMode(false);

        // A list of states that you can enter whether or not your are authenticated
        const allowedUnauthenticatedStates = [
            "organisation-invitation",
            "intermediarypage.joined-organisation",
            "intermediarypage.join-project",
        ];

        $stateProvider
            .state("app", {
                abstract: true,
                template: require("../layouts/app.layout.html"),
                controller: "AppLayoutController as ctrl",
                resolve: {
                    organisations: function ($ngRedux, OrganisationsActions) {
                        return $ngRedux.dispatch(
                            OrganisationsActions.loadOrganisations()
                        );
                    },
                    projects: function ($ngRedux, ProjectsActions) {
                        return $ngRedux.dispatch(
                            ProjectsActions.loadProjects()
                        );
                    },
                },
            })
            .state("unauthenticated", {
                abstract: true,
                template: require("../layouts/unauthenticated.layout.html"),
                onEnter: ($transition$, $ngRedux, AuthActions) => {
                    const toState = $transition$.to();
                    return AuthActions.init($ngRedux.dispatch).then(
                        (authenticated) => {
                            if (
                                authenticated &&
                                !allowedUnauthenticatedStates.includes(
                                    toState.name
                                )
                            ) {
                                return $transition$.router.stateService.target(
                                    "home"
                                );
                            }
                            return true;
                        }
                    );
                },
                /* @ngInject */
                controller: function ($scope, $state) {
                    $scope.state = $state;
                },
            });

        $urlServiceProvider.rules.otherwise("/");

        // alternatively, register the interceptor via an anonymous factory
        $httpProvider.interceptors.push(function () {
            return {
                response: function (response) {
                    if (
                        response.config.url.indexOf("/graphql") !== -1 &&
                        response.data.errors
                    ) {
                        console.warn(
                            "GraphQL Error",
                            response.data.errors,
                            response
                        );
                    }
                    return response;
                },
            };
        });

        // Setup theme
        $mdThemingProvider
            .theme("default")
            .primaryPalette("blue-grey")
            .accentPalette("blue");
        $mdThemingProvider.disableTheming();
        $mdInkRippleProvider.disableInkRipple();

        $ariaProvider.config({
            bindRoleForClick: false,
            bindKeydown: false,
        });

        $animateProvider.classNameFilter(/^((?!(spinner)).)*$/);

        $ngReduxProvider.createStoreWith(
            rootReducer,
            middleware,
            enhancers,
            {}
        );
    })
    .run(function (
        $window,
        $location,
        $transitions,
        googleAnalyticsId,
        $mdDateLocale,
        $filter
    ) {
        // $trace.enable('TRANSITION');
        // Setup Google Analytics
        if (googleAnalyticsId) {
            $window.ga("create", googleAnalyticsId, "auto");
        }

        $transitions.onSuccess({}, () => {
            window.scrollTo(0, 0);
            if (googleAnalyticsId) {
                $window.ga("send", "pageview", $location.path());
            }
        });

        // Angular material  now reparses the date after formatting meaning the parse
        // function must be ready to accept our british datestrings which the default Date object
        // won't recognise (e.g. 24/07/2020)

        // Also for some reason somewhere in angular material is calling this with date objects..?
        // Hence the extra handling

        $mdDateLocale.parseDate = function (dateString) {
            function isValidDate(d) {
                return !isNaN(d.valueOf());
            }
            if (
                (dateString instanceof Date && !isValidDate(dateString)) ||
                (typeof dateString === "string" && dateString.length === 0)
            ) {
                return new Date(NaN);
            } else if (dateString instanceof Date) {
                return dateString;
            }
            const splitDateString = dateString.split("/");
            const americanisedDateString = [
                splitDateString[1],
                splitDateString[0],
                splitDateString[2],
            ];
            return new Date(americanisedDateString);
        };

        $mdDateLocale.formatDate = function (date) {
            return date ? $filter("date")(date, "dd/MM/yyyy") : "";
        };
    });
