import './project-collection-item.scss';
import { getProjectCollections, getProjectRole, getAttributesForCollection } from '../../store/selectors';
/* @ngInject */
export default class ProjectCollectionItemController {
    constructor($scope, $state, $stateParams, $uibModal, $ngRedux, ProjectActions, toastr) {

        this.$state = $state;
        this.itemId = +$stateParams.item_id;
        this.collectionId = +$stateParams.collection_id;

        $scope.$on('$destroy', $ngRedux.connect((state) => {
            const collection = angular.copy(getProjectCollections(state).find(c => c.id === this.collectionId));
            const item = (collection && collection.items || []).find(i => i.id === this.itemId);
            return {
                collection,
                itemValue: (item && item.value) || '',
                attributes: collection ? getAttributesForCollection(collection.id)(state) : [],
                role: getProjectRole(state),
                mediaItems: (item && item.mediaItems) || []
            }
        }, ProjectActions)(this));

        this.$state = $state;
        this.$uibModal = $uibModal;
        this.toastr = toastr;
        $state.current.data.breadcrumb = {
            // { state: 'project.config', name: 'Configuration' },
            // { state: 'project.collections', name: 'Collections' },
            // { state: `project.collection({collection_id: ${this.collection.id}})`, name: this.collection.name },
            label: this.itemValue,
            parent: 'project.collection'
        };
    }

    $onInit() {
        this.loadCollectionItem(this.collection.id, this.itemId).then(item => {
            this.item = angular.copy(item);
            this.images = angular.copy(item.images);

            const attributePaths = this.attributes.map(a => a.path);

            for (const path of Object.keys(item.data || {})) {
                if (!attributePaths.includes(path)) { //if collection has data without defined attribute
                    delete this.item.data[path];
                }
            }

        });

        this.onMediaUpload = (items) => {
            const promises = [];
            for (const item of items) {
                promises.push(this.createItemMediaItem(this.collectionId, this.itemId, item));
            }
            return promises;
        }

        this.onMediaUpdate = (mediaItem) => {
            this.updateItemMediaItem(this.collectionId, this.itemId, mediaItem.id, mediaItem);
        }

        this.onMediaSort = (orderMap) => {
            this.sortItemMediaItems(this.collectionId, this.itemId, orderMap)
        }

        this.onMediaDelete = (mediaItem) => {
            this.deleteItemMediaItem(this.collectionId, this.itemId, mediaItem.id)
        }
    }

    deleteItem() {
        var modal = this.$uibModal.open({
            templateUrl: 'item-delete-modal.html',
            controllerAs: '$ctrl',
            controller: class ProjectCollectionItemDeleteController {
                /* @ngInject */
                constructor($uibModalInstance) {
                    this.$uibModalInstance = $uibModalInstance;
                }
                ok() {
                    this.$uibModalInstance.close();
                }
                cancel() {
                    this.$uibModalInstance.dismiss('cancel');
                }
            }
        });

        modal.result.then(() => {
            this.deleteCollectionItem(this.collection.id, this.item.id).then(() => {
                this.$state.go('project.collection', { collection_id: this.collection.id });
            });
        }, angular.noop);
    };

    save() {
        this.updateCollectionItem(this.collection.id, angular.copy(this.item));
    };
}
