import './project-record-diff.component.scss';
import { applyPatch, deepClone } from 'fast-json-patch';

export const ProjectRecordDiffComponent = {
    template: require('./project-record-diff.component.html'),
    bindings: {
        record: '<',
        logId: '<',
        beforeOnly: '<'
    },
    controllerAs: 'ctrl',
    controller: class ProjectRecordDiffComponent {
        /* @ngInject */
        constructor($element, RecordsService, ProjectService) {
            this.RecordsService = RecordsService;
            this.ProjectService = ProjectService;
            this.$element = $element;
        }

        $onInit() {
            this.geometryChanged = this.hasGeometryDiff();
            console.log(this.geometryChanged)
            //updates are the only operation we consider
            this.logs = this.record.logs.filter(l => l.operation === 'update' || l.operation === 'revert'); 

            let providedLogIndex;
            if (this.logId){
                providedLogIndex = this.logs.findIndex(l => l.id === this.logId);
            } else { //is IDless created log
                providedLogIndex = this.logs.length - 1;
            }
            
            if(!providedLogIndex && providedLogIndex !== 0) throw new Error("Log does not exist with provided ID");
            this.recordBeforeUpdate = this.revertRecordToStateAtLog(providedLogIndex);
            this.recordAfterUpdate = providedLogIndex - 1 >= 0 ? this.revertRecordToStateAtLog(providedLogIndex -1) : this.record;
            //diff maps are not needed to only show the updated record
            if (!this.beforeOnly) this.diffMaps = this.createDiffMaps(this.logs[providedLogIndex].diff); 
        }

        hasGeometryDiff() {
            //create record log is always last
            const log = this.logId ? this.record.logs.find(l => l.id === this.logId) : this.record.logs[this.record.logs.length - 1]; 
            for (const change of log.diff) {
                if (change.path.includes('/geometry/')) return true;
            }
            return false;
        }

        revertRecordToStateAtLog(logIdx) {
            let record = deepClone(this.record);
            for(let i =0; i <= logIdx; i++){
                record = applyPatch(record, this.logs[i].diff).newDocument;
            }
            return record;
        }

        createDiffMaps(patch) { //maps of changed attributes used for styling by other components
            const getRawPath = (path) => { //clean path, and return false if it's not a 'data' path
                if (!path || path.slice(0,6) !== '/data/') return false;
                return path.slice(6,path.length);
            }

            const before = {};
            const after = {}
            for (const change of patch){
                const rawPath = getRawPath(change.path);

                if(!rawPath) continue;
                switch (change.op) { //supported json patch operations
                    case 'add': { //patches are stored in reverse order
                        after[rawPath] = 'remove';
                        break;
                    }
                    case 'replace': {
                        after[rawPath] = 'add';
                        before[rawPath] = 'remove';
                        break;
                    }
                    case 'remove': {
                        after[rawPath] = 'add';
                        break;
                    }
                }
            }
            return {
                before,
                after
            }
        }
    }
};

export default ProjectRecordDiffComponent;
