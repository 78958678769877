/* @ngInject */
import { Tabulator as TabulatorTables, AjaxModule, PageModule, FilterModule, SortModule, FormatModule, MoveColumnsModule, SelectRowModule, InteractionModule, EditModule, MutatorModule, ResizeTableModule, FrozenColumnsModule } from 'tabulator-tables';

TabulatorTables.registerModule([
    AjaxModule,
    PageModule,
    FilterModule,
    SortModule,
    FormatModule,
    MoveColumnsModule,
    SelectRowModule,
    InteractionModule,
    EditModule,
    MutatorModule,
    ResizeTableModule,
    FrozenColumnsModule
])
export default class Tabulator {

    createTable(div, options) {
        return new TabulatorTables(div, options);
    }
}