import './project-forms-block-editor-geometry-styling.modal.scss';
import bbox from '@turf/bbox';
import { deepReplaceKeys } from '../../../../helpers/utils';

const DASH_CONFIG = [5, 5];

export const DEFAULT_GEOMETRY_PICKER_CONFIG = {
    polygon: {
        fill: {
            'fill-color': "#D9AF3D",
            'fill-opacity': 0.5
        },
        line: {
            'line-color': '#000000'
        }
    },
    linestring: {
        line: {
            'line-color': '#000000',
            'line-width': 2
        }
    },
    point: {
        circle: {
            'circle-color': '#D9AF3D',
            'circle-stroke-color': '#000000',
            'circle-stroke-width': 2,
            'circle-radius': 5,
            'circle-opacity': 1
        }
    }
}

const EXAMPLE_GEOMETRIES = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [100.0, 0.0], [101.0, 0.0], [101.0, 1.0],
                        [100.0, 1.0], [100.0, 0.0]
                    ]
                ]
            },
            "properties": {}
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [102.0, 0.0], [103.0, 1.0], [104.0, 0.0], [105.0, 1.0]
                ]
            },
            "properties": {}
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [102.0, 0.5]
            },
            "properties": {}
        }
    ]
}

const EXAMPLE_GEOMETRY_TYPE_MAP = {
    polygon: 0,
    linestring: 1,
    point: 2
}

const LINE_STYLES = [
    {
        name: 'Solid',
        value: false
    },
    {
        name: 'Dashed',
        value: true
    }
]

const geometryTypeGeoJSONTypeMap = {
    polygon: 'Polygon',
    linestring: 'LineString',
    point: 'Point'
}

const DEFAULT_TYPE = 'polygon';

export default class ProjectFormsBlockEditorGeometryStylingController {
    /* @ngInject */
    constructor($mdDialog, Mapbox) {
        this.$mdDialog = $mdDialog;
        this.Mapbox = Mapbox
        this.colorPickerOptions = {
            inputClass: 'color-picker-container',
            format: 'hexString',
            allowEmpty: false,
            alpha: false,
            dynamicHue: false,
            required: true
        };

        this.geometryType = DEFAULT_TYPE; // 'polygon' | 'linestring' | 'point'
        // config is bound to this on modal creation
        // GraphQL wont let us use the paint properties containing dashes in a query,
        // so we replace them with underscores to ensure they're compliant - 
        // we will transform them back when they're used with a map
        deepReplaceKeys(this.config, '_', '-');
        this.config = angular.copy(Object.assign(DEFAULT_GEOMETRY_PICKER_CONFIG, this.config));
        this.isDashed = this.isStyleLineDashed(this.geometryType);

        this.colorPickerAPIs = {
            polygon: {
                fill: this.createColorPickerAPI('polygon', 'fill'),
                line: this.createColorPickerAPI('polygon', 'line')
            },
            linestring: {
                line: this.createColorPickerAPI('linestring', 'line')
            },
            point: {
                circle: this.createColorPickerAPI('point', 'circle'),
                'circle-stroke': this.createCircleStrokeColorPickerAPI('point')
            }
        }

        this.lineStyles = LINE_STYLES;

        this.Mapbox.get().then(mapboxgl => {
            const mapContainer = document.getElementById('styling-map');
            const defaultBounds = bbox(EXAMPLE_GEOMETRIES.features[EXAMPLE_GEOMETRY_TYPE_MAP[this.geometryType]]);
            this.map = new mapboxgl.Map({
                container: mapContainer,
                style: 'mapbox://styles/mapbox/streets-v12',
                bounds: defaultBounds,
                fitBoundsOptions: {
                    padding: 40
                }
            });

            this.map.setMaxBounds(this.map.getBounds());

            this.map.on('load', () => {
                this.map.addSource('example', {
                    type: 'geojson',
                    data: EXAMPLE_GEOMETRIES
                });

                this.addLayerFromStyle('polygon', 'fill');
                this.addLayerFromStyle('polygon', 'line');
                this.addLayerFromStyle('linestring', 'line');
                this.addLayerFromStyle('point', 'circle');
            })
        })
    }

    addLayerFromStyle(geometryType, layerType) {
        this.map.addLayer({
            id: geometryType + '-' + layerType,
            type: layerType,
            source: 'example',
            layout: {},
            paint: this.config[geometryType][layerType],
            filter: [
                'any',
                ['==', ['geometry-type'], geometryTypeGeoJSONTypeMap[geometryType]],
                ['==', ['geometry-type'], 'Multi' + geometryTypeGeoJSONTypeMap[geometryType]]
            ]
        });
    }

    updateGeometryType(type) {
        this.geometryType = type;
        if (this.geometryType !== 'point') {
            this.isDashed = this.isStyleLineDashed(type);
        }
        this.map.setMaxBounds();
        const bounds = bbox(EXAMPLE_GEOMETRIES.features[EXAMPLE_GEOMETRY_TYPE_MAP[type]]);
        this.map.fitBounds(bounds, {
            padding: type === 'point' ? 120 : 40,
            duration: 0
        });
        this.map.setMaxBounds(this.map.getBounds());
    }

    updateLineStyle(type) {
        if (this.isDashed) {
            this.config[type].line['line-dasharray'] = DASH_CONFIG;
            this.map.setPaintProperty(type + '-line', 'line-dasharray', DASH_CONFIG);
            return;
        } else if (this.config[type].line['line-dasharray']) {
            delete this.config[type].line['line-dasharray'];
            this.map.setPaintProperty(type + '-line', 'line-dasharray', undefined);
        }
    }

    createColorPickerAPI(geometryType, layerType) {
        return {
            onChange: (_color, _event) => {
                this.map.setPaintProperty(geometryType + '-' + layerType,
                    layerType + '-color',
                    this.config[geometryType][layerType][layerType + '-color']);
            }
        }
    }

    createCircleStrokeColorPickerAPI(type) {
        return {
            onChange: (_color, _event) => {
                this.map.setPaintProperty(type + '-circle', 'circle-stroke-color', this.config[type].circle['circle-stroke-color']);
            }
        }
    }

    resetCurrent() {
        for (const layerType of Object.keys(DEFAULT_GEOMETRY_PICKER_CONFIG[this.geometryType])) {
            this.map.removeLayer(this.geometryType + '-' + layerType);
            this.config[this.geometryType] = angular.copy(DEFAULT_GEOMETRY_PICKER_CONFIG[this.geometryType]);
            this.addLayerFromStyle(this.geometryType, layerType);
        }
    }

    cancel() {
        this.$mdDialog.cancel();
    };

    done() {
        deepReplaceKeys(this.config, '-', '_')
        this.$mdDialog.hide(this.config);
    };

    isStyleLineDashed(geometryType) {
        return !!this.config[geometryType].line['line-dasharray'];
    }
}