import noIcon from '../assets/icons/no-icon.png';

export const ProjectWidgetComponent = {
  bindings: {
    project: '<'
  },
  template: require('./project-widget.html'),
  controllerAs: 'ctrl',
  controller: class ProjectWidgetComponent {
    /* @ngInject */
    constructor($state, $element) {
      this.$state = $state;
      this.$element = $element;
      this.noIcon = noIcon;
    }
    $onInit() {
      this.stats = this.project.stats;
      this.$element.bind('click', (event) => {
        event.preventDefault();
        this.$state.go('project.dashboard', {
          id: this.project.slug
        });
      });
    }
  }
}