import angular from 'angular';

import LegalTermsController from './terms/terms.controller';
import LegalPrivacyController from './privacy/privacy.controller';

import './legal.scss';

export default angular
    .module('app.legal', ['ui.router', 'LocalStorageModule'])
    .controller('LegalTermsController', LegalTermsController)
    .controller('LegalPrivacyController', LegalPrivacyController)
    .config(($stateProvider) => {
        $stateProvider
            .state('legal', {
                abstract: true,
                template: require('./legal.html'),
                url: '/legal'
            })
            .state('legal.terms', {
                url: '/terms',
                template: require('./terms/terms.html'),
                controller: 'LegalTermsController as ctrl'
            })
            .state('legal.privacy', {
                url: '/privacy',
                template: require('./privacy/privacy.html'),
                controller: 'LegalPrivacyController as ctrl'
            });
    });
