import HomeController from './home.controller';

export default angular
    .module('app.home', ['ui.router'])
    .controller('HomeController', HomeController)
    .config(function ($stateProvider) {

        $stateProvider
            .state('home', {
                parent: 'app',
                url: '/',
                template: require('./home.html'),
                controller: 'HomeController as ctrl',
                data: {
                    hasTutorial: true
                }
            })
    });