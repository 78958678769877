import { getProject, getProjectCredentials, getProjectRole } from '../../store/selectors';

/* @ngInject */
export default class ProjectCredentialsController {
    constructor($scope, $ngRedux, toastr, ProjectsService, ProjectActions, $mdDialog) {

        $scope.$on('$destroy', $ngRedux.connect(state => {
            const credentials = angular.copy(getProjectCredentials(state));
            const credentialsIndexed = _.keyBy(credentials, 'provider');
            return {
                credentials,
                credentialsIndexed,
                role: getProjectRole(state),
                project: getProject(state)
            };
        }, ProjectActions)(this));
        this.types = ['google', 'facebook', 'twitter'];
        this.toastr = toastr;
        this.ProjectsService = ProjectsService;
        this.$mdDialog = $mdDialog;
    }

    updateCredential(credential) {
        return this.updateCredential({
            id: credential.id,
            clientID: credential.clientID,
            clientSecret: credential.clientSecret
        }).then(() => {
            this.toastr.success('Credential updated.');
        });
    };


    create(ev) {
        var project = this.project;
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('./new-project-credential.html'),
            /* @ngInject */
            controller: function ($scope, $mdDialog) {
                $scope.credential = {
                };

                $scope.types = [{
                    name: 'Google',
                    provider: 'google',
                    clientIDLabel: 'Client ID',
                    clientSecretLabel: 'Client Secret'
                }, {
                    name: 'Facebook',
                    provider: 'facebook',
                    clientIDLabel: 'Client ID',
                    clientSecretLabel: 'Client Secret'
                }, {
                    name: 'Twitter',
                    provider: 'twitter',
                    clientIDLabel: 'Consumer Key',
                    clientSecretLabel: 'Consumer Secret'
                }];

                $scope.ok = function () {
                    $scope.credential.provider = $scope.selectedType.provider;
                    $mdDialog.hide($scope.credential);
                };

                $scope.cancel = function () {
                    $mdDialog.cancel();
                };
            }
        }).then((data) => {
            this.createCredential(data);
        }, angular.noop);
    }
}
